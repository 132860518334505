import { Injectable } from '@angular/core';
import { Patient } from '../../models/Patient/Patient';
import { Subject, Observable, BehaviorSubject, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PatientProfileSyncService {
  patientMap:Map<string,Patient> = new Map();
  subject = new Subject<Map<string,Patient>>();
  refreshSubject = new Subject<any>();

  constructor() { }

  refreshListener(){
    return this.refreshSubject.asObservable();
  }

  refresh(){
    this.refreshSubject.next(true);
  }

  public addPatient(patient:Patient){
    this.patientMap.set(patient.docvitaId,patient);
    this.subject.next(this.patientMap);
  }

  public getPatient(patientId:string){
    if(this.patientMap.has(patientId)){
      return this.patientMap.get(patientId);
    }else{
      return null;
    }
  }

  public removePatient(patientId:string){
    this.patientMap.delete(patientId);
    this.subject.next(this.patientMap);
  }

  public listener():Observable<Map<string,Patient>>{
    return this.subject.asObservable();
  }

  public extractPatientIdFromPath(path:String){
    let detailPath = "patient-detail";
    if(path && path.indexOf(detailPath) > -1){
      let i = path.indexOf(detailPath) + detailPath.length + 1;
      let str = path.substring(i);
      let j = str.indexOf("/");
      return path.substring(i,i+j);
    }else{
      return null;
    }
  }
}
