import { AddressObject, ContactNumber, DateActions } from "./Demographics";
import { BasicModelInterface } from "./BasicModelInterface";
import { ObjectKeys } from "../constants/object-keys";

export class Person implements BasicModelInterface {
  // Basic Info
  private _photoUrl: string;
  private _salutation: string; //
  private _name: string; //
  private _gender: string; //
  private _dateOfBirth: string; // yyyy-mm-dd
  private _age: number; //
  private _ageObject: {} = {};
  private _language: string;

  private _qrCodeId: string;

  // Variable to control immediate sign out

  private _status: "active" | "suspended";

  // Email
  private _primaryEmail: string;
  private _secondaryEmails: string[];

  // On DocVita App
  private _hasDownloadedDocVitaApp: boolean;
  private _lastSeenOnDocVitaApp: number;

  // Contact Number

  private _primaryContactNumber: ContactNumber = new ContactNumber(); //
  private _secondaryContactNumber: ContactNumber = new ContactNumber();
  private _primaryTelephoneNumber: ContactNumber = new ContactNumber();
  private _secondaryContactNumbers: ContactNumber[] = [];
  // Addresses
  private _primaryAddress: AddressObject = new AddressObject();
  private _secondaryAddresses: AddressObject[] = [];

  // Social Login Information
  private _SocialLoginInfo: SocialLoginInfoObject[] = [];

  private _date_of_birth_obj: {} = {}; //Just for interfacing

  constructor() {}

  toJSON(): {} {
    let json = {};
    if (this.photoUrl) {
      json[ObjectKeys.photoUrl] = this.photoUrl;
    }
    if (this.photoUrl) {
      json[ObjectKeys.photoUrl] = this.photoUrl;
    }
    if (this.salutation) {
      json[ObjectKeys.salutation] = this.salutation;
    }
    if (this.name) {
      json[ObjectKeys.nameKey] = this.name;
    }
    if (this.gender) {
      json[ObjectKeys.gender] = this.gender;
    }
    if (this.primaryTelephoneNumber) {
      json[ObjectKeys.primaryTelephoneNumber] = this.primaryTelephoneNumber;
    }
    if (this.dateOfBirth) {
      json[ObjectKeys.dateOfBirth] = this.dateOfBirth;
    }
    if (this.age) {
      json[ObjectKeys.age] = this.age;
    }
    if (this.ageObject) {
      json[ObjectKeys.ageObject] = this.ageObject;
    }
    if (this.language) {
      json[ObjectKeys.language] = this.language;
    }
    if (this.qrCodeId) {
      json[ObjectKeys.qrCodeId] = this.qrCodeId;
    }
    if (this.status) {
      json[ObjectKeys.status] = this.status;
    }
    if (this.primaryEmail) {
      json[ObjectKeys.primaryEmail] = this.primaryEmail;
    }
    if (this.secondaryEmails) {
      json[ObjectKeys.secondaryEmails] = this.secondaryEmails;
    }
    if (this.hasDownloadedDocVitaApp) {
      json[ObjectKeys.hasDownloadedDocVitaApp] = this.hasDownloadedDocVitaApp;
    }
    if (this.lastSeenOnDocVitaApp) {
      json[ObjectKeys.lastSeenOnDocVitaApp] = this.lastSeenOnDocVitaApp;
    }
    if (this.primaryContactNumber) {
      json[
        ObjectKeys.primaryContactNumber
      ] = this.primaryContactNumber.toJSON();
    }

    if (this.secondaryContactNumber) {
      json[
        ObjectKeys.secondaryContactNumber
      ] = this.secondaryContactNumber.toJSON();
    }
    if (this.primaryTelephoneNumber) {
      json[
        ObjectKeys.primaryTelephoneNumber
      ] = this.primaryTelephoneNumber.toJSON();
    }

    if (this.secondaryContactNumbers) {
      let tempJSON = [];
      this.secondaryContactNumbers.forEach(val => {
        tempJSON.push(val.toJSON());
      });
      json[ObjectKeys.secondaryContactNumbers] = tempJSON;
    }
    if (this.primaryAddress) {
      json[ObjectKeys.primaryAddress] = this.primaryAddress.toJSON();
    }
    if (this.secondaryAddresses) {
      let tempJSON = [];
      this.secondaryAddresses.forEach(val => {
        tempJSON.push(val.toJSON());
      });
      json[ObjectKeys.secondaryAddresses] = tempJSON;
    }
    if (this.SocialLoginInfo) {
      let tempJSON = [];
      this.SocialLoginInfo.forEach(val => {
        tempJSON.push(val.toJSON());
      });
      json[ObjectKeys.SocialLoginInfo] = tempJSON;
    }
    return json;
  }
  initFromJSON(json: {}): void {
    if (json[ObjectKeys.photoUrl]) {
      this.photoUrl = json[ObjectKeys.photoUrl];
    }
    if (json[ObjectKeys.primaryTelephoneNumber]) {
      this.primaryTelephoneNumber = json[ObjectKeys.primaryTelephoneNumber];
    }
    if (json[ObjectKeys.secondaryContactNumber]) {
      this.secondaryContactNumber = json[ObjectKeys.secondaryContactNumber];
    }
    if (json[ObjectKeys.salutation]) {
      this.salutation = json[ObjectKeys.salutation];
    }
    if (json[ObjectKeys.nameKey]) {
      this.name = json[ObjectKeys.nameKey];
    }
    if (json[ObjectKeys.gender]) {
      this.gender = json[ObjectKeys.gender];
    }
    if (json[ObjectKeys.dateOfBirth]) {
      this.dateOfBirth = json[ObjectKeys.dateOfBirth];
      let dates = this.dateOfBirth.split("-");
      this.date_of_birth_obj = {
        year: +dates[0],
        month: +dates[1],
        day: +dates[2]
      };
    }
    if (json[ObjectKeys.age]) {
      this.age = json[ObjectKeys.age];
    }
    if (json[ObjectKeys.ageObject]) {
      this.ageObject = json[ObjectKeys.ageObject];
    }
    if (json[ObjectKeys.language]) {
      this.language = json[ObjectKeys.language];
    }
    if (json[ObjectKeys.qrCodeId]) {
      this.qrCodeId = json[ObjectKeys.qrCodeId];
    }
    if (json[ObjectKeys.status]) {
      this.status = json[ObjectKeys.status];
    }
    if (json[ObjectKeys.primaryEmail]) {
      this.primaryEmail = json[ObjectKeys.primaryEmail];
    }
    if (json[ObjectKeys.secondaryEmails]) {
      this.secondaryEmails = json[ObjectKeys.secondaryEmails];
    }
    if (json[ObjectKeys.hasDownloadedDocVitaApp]) {
      this.hasDownloadedDocVitaApp = json[ObjectKeys.hasDownloadedDocVitaApp];
    }
    if (json[ObjectKeys.lastSeenOnDocVitaApp]) {
      this.lastSeenOnDocVitaApp = json[ObjectKeys.lastSeenOnDocVitaApp];
    }
    if (json[ObjectKeys.primaryContactNumber]) {
      let c: ContactNumber = new ContactNumber();
      c.initFromJSON(json[ObjectKeys.primaryContactNumber]);
      this.primaryContactNumber = c;
    }
    if (json[ObjectKeys.secondaryContactNumber]) {
      let c: ContactNumber = new ContactNumber();
      c.initFromJSON(json[ObjectKeys.secondaryContactNumber]);
      this.secondaryContactNumber = c;
    }
    if (json[ObjectKeys.primaryTelephoneNumber]) {
      let c: ContactNumber = new ContactNumber();
      c.initFromJSON(json[ObjectKeys.primaryTelephoneNumber]);
      this.primaryTelephoneNumber = c;
    }
    if (json[ObjectKeys.secondaryContactNumbers]) {
      let objArr: ContactNumber[] = [];
      let arr = json[ObjectKeys.secondaryContactNumbers];
      arr.forEach(val => {
        let c: ContactNumber = new ContactNumber();
        c.initFromJSON(val);
        objArr.push(c);
      });
      this.secondaryContactNumbers = objArr;
    }
    if (json[ObjectKeys.primaryAddress]) {
      let c: AddressObject = new AddressObject();
      c.initFromJSON(json[ObjectKeys.primaryAddress]);
      this.primaryAddress = c;
    }
    if (json[ObjectKeys.secondaryAddresses]) {
      let objArr: AddressObject[] = [];
      let arr = json[ObjectKeys.secondaryAddresses];
      arr.forEach(val => {
        let c: AddressObject = new AddressObject();
        c.initFromJSON(val);
        objArr.push(c);
      });
      this.secondaryAddresses = objArr;
    }
    if (json[ObjectKeys.SocialLoginInfo]) {
      let objArr: SocialLoginInfoObject[] = [];
      let arr = json[ObjectKeys.SocialLoginInfo];
      arr.forEach(val => {
        let c: SocialLoginInfoObject = new SocialLoginInfoObject();
        c.initFromJSON(val);
        objArr.push(c);
      });
      this.SocialLoginInfo = objArr;
    }
  }

  public getNameWithSalutation(): string {
    let str = "";
    if (this.salutation) {
      let val = "";
      for (let a of PersonConstants.SALUTATIONS) {
        if (a["value"] == this.salutation) {
          val = a["label"];
        }
      }
      if (val.length > 0) {
        str += val + " ";
      }
    }
    if (this.name) {
      str += this.name;
    }
    return str;
  }

  /**
   * This is to display patint's age and gender
   */
  public get sex_age() {
    let str = "";
    if (this.gender != null) {
      str += Person.genderCamelCaseConversion(this.gender);
    }
    if (this.ageObject != null && this.ageObject["years"] >= 0) {
      if (this.ageObject["years"] > 0) {
        str += ", " + this.ageObject["years"] + " Yr";
      }
      if (this.ageObject["years"] <= 0) {
        if (this.ageObject["months"] > 0) {
          str += ", " + this.ageObject["months"] + " Mn";
        }
        if (this.ageObject["months"] <= 0) {
          if (this.ageObject["days"] > 0) {
            str += ", " + this.ageObject["days"] + " D";
          }
        }
      }
    }
    return str;
  }

  public getShortBio(): string {
    let shortBio: string = "";
    // var ageObject: {} = {};
    // if (this.gender != undefined && this.gender.length > 0) {
    //   shortBio += Person.genderCamelCaseConversion(this.gender);
    // }
    shortBio += this.sex_age;
    // if (this.sex_age != null && this.sex_age.length > 0) {

    // }

    // if (this.dateOfBirth && this.dateOfBirth.length > 0) {
    //   if (shortBio.length > 0) {
    //     shortBio += "";
    //   }
    //   ageObject = Person.getAgeFromDateOfBirthInYMD(this.dateOfBirth);
    //   if (ageObject != null && ageObject["years"] >= 0) {
    //     if (ageObject["years"] > 0) {
    //       if (ageObject["years"] === 1) {
    //         shortBio += ", " + ageObject["years"] + " Yr";
    //       } else {
    //         shortBio += ", " + ageObject["years"] + " Yr";
    //       }
    //     }
    //     if (ageObject["years"] <= 0) {
    //       if (ageObject["months"] > 0) {
    //         if (ageObject["months"] === 1) {
    //           shortBio += ", " + ageObject["months"] + " Mn";
    //         } else {
    //           shortBio += ", " + ageObject["months"] + " Mn";
    //         }
    //       }
    //       if (ageObject["months"] <= 0) {
    //         if (ageObject["days"] > 0) {
    //           if (ageObject["days"] === 1) {
    //             shortBio += ", " + ageObject["days"] + " D";
    //           } else {
    //             shortBio += ", " + ageObject["days"] + "D";
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    if (
      this.primaryContactNumber &&
      this.primaryContactNumber.number &&
      this.primaryContactNumber.number.length > 0
    ) {
      // if (shortBio.length > 0) {
      //   shortBio += " | ";
      // }
      shortBio += " | " + this.primaryContactNumber.number;
    }
    if (this.primaryEmail && this.primaryEmail.length > 0) {
      // if (shortBio.length > 0) {
      //   shortBio += " | ";
      // }
      shortBio += " | " + this.primaryEmail;
    }
    return shortBio;
  }

  public getGenderCamelString = (): string => {
    return Person.genderCamelCaseConversion(this.gender);
  };

  // Static Convertors
  public static genderCamelCaseConversion(genderText: string): string {
    switch (genderText) {
      case "male":
        return "Male";
      case "MALE":
        return "Male";
      case "female":
        return "Female";
      case "FEMALE":
        return "Female";
      case "other":
        return "Other";
      case "others":
        return "Other";
      case "OTHER":
        return "Other";
      case "OTHERS":
        return "Other";
      default:
        return genderText;
    }
  }

  public static getAgeFromDateOfBirth(date_of_birth_string): number {
    if (date_of_birth_string && date_of_birth_string.length > 0) {
      var today = new Date();
      var birthDate = new Date(date_of_birth_string);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m == 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    } else {
      return undefined;
    }
  }

  public static getAgeFromDateOfBirthInYMD(date_of_birth_string): {} {
    if (date_of_birth_string && date_of_birth_string.length > 0) {
      var birthDate = date_of_birth_string;
      var daysInMonth = 30.436875; // Days in a month on average.
      var dob = new Date(birthDate);
      var aad = new Date();

      var yearAad = aad.getFullYear();
      var yearDob = dob.getFullYear();
      var years = yearAad - yearDob; // Get age in years.
      dob.setFullYear(yearAad); // Set birthday for this year.
      var aadMillis = aad.getTime();
      var dobMillis = dob.getTime();
      if (aadMillis < dobMillis) {
        --years;
        dob.setFullYear(yearAad - 1); // Set to previous year's birthday
        dobMillis = dob.getTime();
      }
      var days = (aadMillis - dobMillis) / 86400000;
      var monthsDec = days / daysInMonth; // Months with remainder.
      var months = Math.floor(monthsDec); // Remove fraction from month.
      days = Math.floor(daysInMonth * (monthsDec - months));

      var ageObject: {} = { years: years, months: months, days: days };
      return ageObject;
    } else {
      return undefined;
    }
  }

  public get_age() {
    var today = new Date();
    var birthDate = new Date(this._dateOfBirth);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  public get_ageInYMD() {
    var daysInMonth = 30.436875; // Days in a month on average.
    var dob = new Date(this._dateOfBirth);
    var aad = new Date();

    var yearAad = aad.getFullYear();
    var yearDob = dob.getFullYear();
    var years = yearAad - yearDob; // Get age in years.
    dob.setFullYear(yearAad); // Set birthday for this year.
    var aadMillis = aad.getTime();
    var dobMillis = dob.getTime();
    if (aadMillis < dobMillis) {
      --years;
      dob.setFullYear(yearAad - 1); // Set to previous year's birthday
      dobMillis = dob.getTime();
    }
    var days = (aadMillis - dobMillis) / 86400000;
    var monthsDec = days / daysInMonth; // Months with remainder.
    var months = Math.floor(monthsDec); // Remove fraction from month.
    days = Math.floor(daysInMonth * (monthsDec - months));

    var ageObject: {} = { years: years, months: months, days: days };
    return ageObject;
  }

  /**
   * Getter $date_of_birth_obj
   * @return {{} }
   */
  public get date_of_birth_obj(): {} {
    return this._date_of_birth_obj;
  }

  /**
   * Setter $date_of_birth_obj
   * @param {{} } value
   */
  public set date_of_birth_obj(value: {}) {
    this._date_of_birth_obj = value;
    if (typeof value === "object" && value !== null) {
      // console.log("setting date of birth", value, this._date_of_birth_obj);
      this._dateOfBirth = DateActions.getDateJSONToStringWithMonthDiff(value);
      this._age = this.get_age();
      this._ageObject = this.get_ageInYMD();
    } else {
      //If DOB is null, do nothing
      // console.log("DOB Value is null");
    }
    this._date_of_birth_obj = value;
  }

  /**
   * Getter language
   * @return {string}
   */
  public get language(): string {
    return this._language;
  }

  /**
   * Setter language
   * @param {string} value
   */
  public set language(value: string) {
    this._language = value;
  }

  /**
   * Getter status
   * @return {"active" | "suspended"}
   */
  public get status(): "active" | "suspended" {
    return this._status;
  }

  /**
   * Setter status
   * @param {"active" | "suspended"} value
   */
  public set status(value: "active" | "suspended") {
    this._status = value;
  }

  /**
   * Getter hasDownloadedDocVitaApp
   * @return {boolean}
   */
  public get hasDownloadedDocVitaApp(): boolean {
    return this._hasDownloadedDocVitaApp;
  }

  /**
   * Getter primaryTelephoneNumber
   * @return {ContactNumber }
   */
  public get primaryTelephoneNumber(): ContactNumber {
    return this._primaryTelephoneNumber;
  }

  /**
   * Setter primaryTelephoneNumber
   * @param {ContactNumber } value
   */
  public set primaryTelephoneNumber(value: ContactNumber) {
    this._primaryTelephoneNumber = value;
  }

  /**
   * Getter lastSeenOnDocVitaApp
   * @return {number}
   */
  public get lastSeenOnDocVitaApp(): number {
    return this._lastSeenOnDocVitaApp;
  }

  /**
   * Setter hasDownloadedDocVitaApp
   * @param {boolean} value
   */
  public set hasDownloadedDocVitaApp(value: boolean) {
    this._hasDownloadedDocVitaApp = value;
  }

  /**
   * Setter lastSeenOnDocVitaApp
   * @param {number} value
   */
  public set lastSeenOnDocVitaApp(value: number) {
    this._lastSeenOnDocVitaApp = value;
  }
  /**
   * Getter photoUrl
   * @return {string}
   */
  public get photoUrl(): string {
    return this._photoUrl;
  }

  /**
   * Getter salutation
   * @return {string}
   */
  public get salutation(): string {
    return this._salutation;
  }

  /**
   * Getter name
   * @return {string}
   */
  public get name(): string {
    return this._name;
  }

  /**
   * Getter gender
   * @return {string}
   */
  public get gender(): string {
    return this._gender;
  }

  /**
   * Getter dateOfBirth
   * @return {string}
   */
  public get dateOfBirth(): string {
    return this._dateOfBirth;
  }

  /**
   * Getter age
   * @return {number}
   */
  public get age(): number {
    return this._age;
  }

  public get ageObject(): {} {
    return this._ageObject;
  }

  /**
   * Getter qrCodeId
   * @return {string}
   */
  public get qrCodeId(): string {
    return this._qrCodeId;
  }

  /**
   * Getter primaryEmail
   * @return {string}
   */
  public get primaryEmail(): string {
    return this._primaryEmail;
  }

  /**
   * Getter secondaryEmails
   * @return {string[]}
   */
  public get secondaryEmails(): string[] {
    return this._secondaryEmails;
  }

  /**
   * Getter primaryAddress
   * @return {AddressObject}
   */
  public get primaryAddress(): AddressObject {
    return this._primaryAddress;
  }

  /**
   * Getter secondaryAddresses
   * @return {AddressObject[]}
   */
  public get secondaryAddresses(): AddressObject[] {
    return this._secondaryAddresses;
  }

  /**
   * Getter SocialLoginInfo
   * @return {SocialLoginInfoObject[]}
   */
  public get SocialLoginInfo(): SocialLoginInfoObject[] {
    return this._SocialLoginInfo;
  }

  /**
   * Setter photoUrl
   * @param {string} value
   */
  public set photoUrl(value: string) {
    this._photoUrl = value;
  }

  /**
   * Setter salutation
   * @param {string} value
   */
  public set salutation(value: string) {
    this._salutation = value;
  }

  /**
   * Setter name
   * @param {string} value
   */
  public set name(value: string) {
    this._name = value;
  }

  /**
   * Setter gender
   * @param {string} value
   */
  public set gender(value: string) {
    this._gender = value;
  }

  /**
   * Setter dateOfBirth
   * @param {string} value
   */
  public set dateOfBirth(value: string) {
    this._dateOfBirth = value;
    if (this._dateOfBirth && this._dateOfBirth.length > 0) {
      this._age = Person.getAgeFromDateOfBirth(this._dateOfBirth);
      this._ageObject = Person.getAgeFromDateOfBirthInYMD(this._dateOfBirth);
    }
  }

  /**
   * Setter age
   * @param {number} value
   */
  public set age(value: number) {
    this._age = value;
    if (this._ageObject != null && !this._ageObject["years"]) {
      this._ageObject["years"] = this._age;
    }
  }

  /**
   * Setter age
   * @param {ageObject} value
   */
  public set ageObject(value: {}) {
    this._ageObject = value;
  }

  /**
   * Setter qrCodeId
   * @param {string} value
   */
  public set qrCodeId(value: string) {
    this._qrCodeId = value;
  }

  /**
   * Setter primaryEmail
   * @param {string} value
   */
  public set primaryEmail(value: string) {
    this._primaryEmail = value;
  }

  /**
   * Setter secondaryEmails
   * @param {string[]} value
   */
  public set secondaryEmails(value: string[]) {
    this._secondaryEmails = value;
  }

  /**
   * Setter primaryAddress
   * @param {AddressObject} value
   */
  public set primaryAddress(value: AddressObject) {
    this._primaryAddress = value;
  }

  /**
   * Setter secondaryAddresses
   * @param {AddressObject[]} value
   */
  public set secondaryAddresses(value: AddressObject[]) {
    this._secondaryAddresses = value;
  }

  /**
   * Setter SocialLoginInfo
   * @param {SocialLoginInfoObject[]} value
   */
  public set SocialLoginInfo(value: SocialLoginInfoObject[]) {
    this._SocialLoginInfo = value;
  }

  /**
   * Getter primaryContactNumber
   * @return {ContactNumber}
   */
  public get primaryContactNumber(): ContactNumber {
    return this._primaryContactNumber;
  }

  /**
   * Getter secondaryContactNumbers
   * @return {ContactNumber[]}
   */
  public get secondaryContactNumbers(): ContactNumber[] {
    return this._secondaryContactNumbers;
  }

  /**
   * Setter primaryContactNumber
   * @param {ContactNumber} value
   */
  public set primaryContactNumber(value: ContactNumber) {
    this._primaryContactNumber = value;
  }

  /**
   * Setter secondaryContactNumbers
   * @param {ContactNumber[]} value
   */
  public set secondaryContactNumbers(value: ContactNumber[]) {
    this._secondaryContactNumbers = value;
  }

  /**
   * Getter secondaryContactNumber
   * @return {ContactNumber }
   */
  public get secondaryContactNumber(): ContactNumber {
    return this._secondaryContactNumber;
  }

  /**
   * Setter secondaryContactNumber
   * @param {ContactNumber } value
   */
  public set secondaryContactNumber(value: ContactNumber) {
    this._secondaryContactNumber = value;
  }
}

export class SocialLoginInfoObject implements BasicModelInterface {
  private _displayName: string;
  private _socialEmailId: string;
  private _socialPhoneNumber: string;
  private _socialLoginProviderId: string;
  private _isEmailVerified: boolean;

  public toJSON(): {} {
    let json = {};
    if (this.displayName) {
      json[ObjectKeys.displayName] = this.displayName;
    }
    if (this.socialEmailId) {
      json[ObjectKeys.socialEmailId] = this.socialEmailId;
    }
    if (this.socialPhoneNumber) {
      json[ObjectKeys.socialPhoneNumber] = this.socialPhoneNumber;
    }
    if (this.socialLoginProviderId) {
      json[ObjectKeys.socialLoginProviderId] = this.socialLoginProviderId;
    }
    if (this.isEmailVerified) {
      json[ObjectKeys.isEmailVerified] = this.isEmailVerified;
    }
    return json;
  }
  public initFromJSON(json: {}): void {
    if (json[ObjectKeys.displayName]) {
      this.displayName = json[ObjectKeys.displayName];
    }
    if (json[ObjectKeys.socialEmailId]) {
      this.socialEmailId = json[ObjectKeys.socialEmailId];
    }
    if (json[ObjectKeys.socialPhoneNumber]) {
      this.socialPhoneNumber = json[ObjectKeys.socialPhoneNumber];
    }
    if (json[ObjectKeys.socialLoginProviderId]) {
      this.socialLoginProviderId = json[ObjectKeys.socialLoginProviderId];
    }
    if (json[ObjectKeys.isEmailVerified]) {
      this.isEmailVerified = json[ObjectKeys.isEmailVerified];
    }
  }
  /**
   * Getter displayName
   * @return {string}
   */
  public get displayName(): string {
    return this._displayName;
  }

  /**
   * Setter displayName
   * @param {string} value
   */
  public set displayName(value: string) {
    this._displayName = value;
  }

  /**
   * Getter socialEmailId
   * @return {string}
   */
  public get socialEmailId(): string {
    return this._socialEmailId;
  }

  /**
   * Setter socialEmailId
   * @param {string} value
   */
  public set socialEmailId(value: string) {
    this._socialEmailId = value;
  }

  /**
   * Getter socialPhoneNumber
   * @return {string}
   */
  public get socialPhoneNumber(): string {
    return this._socialPhoneNumber;
  }

  /**
   * Setter socialPhoneNumber
   * @param {string} value
   */
  public set socialPhoneNumber(value: string) {
    this._socialPhoneNumber = value;
  }

  /**
   * Getter socialLoginProviderId
   * @return {string}
   */
  public get socialLoginProviderId(): string {
    return this._socialLoginProviderId;
  }

  /**
   * Setter socialLoginProviderId
   * @param {string} value
   */
  public set socialLoginProviderId(value: string) {
    this._socialLoginProviderId = value;
  }

  /**
   * Getter isEmailVerified
   * @return {boolean}
   */
  public get isEmailVerified(): boolean {
    return this._isEmailVerified;
  }

  /**
   * Setter isEmailVerified
   * @param {boolean} value
   */
  public set isEmailVerified(value: boolean) {
    this._isEmailVerified = value;
  }
}

export enum PersonAccountStatus {
  "BLOCKED" = "BLOCKED",
  "ACTIVE" = "ACTIVE"
}

export class PersonConstants {
  public static LANGUAGE = [
    {
      label: "English",
      value: "english"
    },
    {
      label: "Hindi",
      value: "hindi"
    },
    {
      label: "Gujarati",
      value: "gujarati"
    }
  ];
  public static GENDER = [
    {
      label: "Male",
      value: "male"
    },
    {
      label: "Female",
      value: "female"
    },
    {
      label: "Other",
      value: "other"
    }
  ];

  public static SALUTATIONS = [
    {
      label: "Master",
      value: "master"
    },
    {
      label: "Mr.",
      value: "mr"
    },
    {
      label: "Miss",
      value: "miss"
    },
    {
      label: "Ms.",
      value: "ms"
    },
    {
      label: "Mrs.",
      value: "mrs"
    },
    {
      label: "Dr.",
      value: "dr"
    }
  ];

  public static COUNTRY_CODES = [
    {
      label: "+91",
      value: "91"
    },
    {
      label: "+1",
      value: "1"
    },
    {
      label: "+971",
      value: "971"
    }
  ];
}
