import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  AfterViewInit,
  AfterContentChecked,
  ChangeDetectorRef
} from "@angular/core";
import { Billing } from "../shared/models/Billing/Billing";
import { ActivatedRoute, Router } from "@angular/router";
import * as uuid from "uuid";
import { BillingItem } from "../shared/models/Billing/Billing";
import { Observable, from, of, Subscription } from "rxjs";
import { NotifyService } from "../shared/services/notify/notify.service";
import { GlobalVariables } from "../globar-var/globarVariables";
import { PatientProfileSyncService } from "../shared/services/component-communication/patient-profile-sync.service";
import { Appointment } from "../shared/models/Scheduling/Appointment";
import { DateActions } from "../shared/models/Demographics";
import { FormControl, FormGroup } from "@angular/forms";

import { map, startWith } from "rxjs/operators";
import * as moment from "moment";

import {
  OPDTypeAndFee,
  procedureTypeAndFee
} from "../shared/models/MD - Member";
import { ConfirmationDialogService } from "../shared/services/confirmation-dialog/confirmation-dialog.service";
import { BillingService } from "../shared/services/billing/billing.service";

@Component({
  selector: "app-billing-component",
  templateUrl: "./billing-component.component.html",
  styleUrls: ["./billing-component.component.css"]
})
export class BillingComponentComponent implements OnInit, AfterContentChecked {
  billing: Billing = new Billing();
  // appointment: Appointment;
  invoiceNumber: string;
  i: number = 0;
  invoiceDate: string;
  selectedOPD: string;
  selectedLab: string;
  discount: number;
  patientName: string;
  patientSexAgeString: string;
  patientGender: string;
  patientAge: any;
  patientMobile: any;
  discountType: number;
  adjustment: string;
  isRefreshing: boolean = false;
  total: string;
  items: BillingItem[] = [];
  itemsProcedure: BillingItem[] = [];
  itemsMap: Map<string, BillingItem> = new Map();
  activatedRouteSubscription: Subscription;
  billingSubsription: Subscription;
  billingJSON: {} = {};
  today = new Date();
  dd = String(this.today.getDate()).padStart(2, "0");
  mm = String(this.today.getMonth() + 1).padStart(2, "0"); //January is 0!
  yyyy = this.today.getFullYear();
  todaysDate;
  dateValue: {};
  currentSelectedDate: string = "";

  a = new Appointment();

  autocompletesForProcedures = [];
  autocompleteForOPD = [];
  formGroupProcedures: FormGroup;

  constructor(
    private activatedRoute: ActivatedRoute,
    private _router: Router,
    private _notifyService: NotifyService,
    private _confirmationDialogService: ConfirmationDialogService,
    private _patientProfileSyncService: PatientProfileSyncService,
    private _billingService: BillingService,
    private _ref: ChangeDetectorRef
  ) {}

  ngAfterContentChecked() {
    this._ref.detectChanges();
  }

  ngOnInit() {
    console.log(this.items);
    // autosuggest
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map(value => this._filter(value))
    );
    this.filteredProcedureOptions = this.myControl2.valueChanges.pipe(
      startWith(""),
      map(value => this._filterProcedure(value))
    );

    this.activatedRouteSubscription = this.activatedRoute.params.subscribe(
      params => {
        this.currentSelectedDate = params["date"];
      }
    );
    this.initialiseDate();

    this.billingSubsription = this._billingService
      .getAppointment()
      .subscribe(val => {
        // console.log(val);
        if (val) {
          this.a.initFromJSON(val);
          if (this.a) {
            // this.appointment = a;
            if (this.a.organisationId) {
              this.billing.organisationId = this.a.organisationId;
            }
            if (this.a.organisationName) {
              this.billing.organisationName = this.a.organisationName;
            }
            if (this.a.patientId) {
              this.billing.patientId = this.a.patientId;
            }
            this.billing.patientAge = this.a.getAgeString();
            if (this.a.sex_age) {
              this.patientSexAgeString = this.a.sex_age;
            }
            if (this.a.patientGender) {
              this.patientGender = this.a.patientGender;
              this.billing.patientGender = this.a.patientGender;
            }
            if (this.a.patientName) {
              this.patientName = this.a.patientName;
              this.billing.patientName = this.a.patientName;
            }
            if (this.a.patientPrimaryContactNumber) {
              this.patientMobile = this.a.patientPrimaryContactNumber;
              this.billing.patientMobileNumber = this.a.patientPrimaryContactNumber;
            }
            if (this.a.patientPrimaryEmail) {
              this.billing.patientEmailAddress = this.a.patientPrimaryEmail;
            }
            if (this.a.doctorId) {
              this.billing.receiverId = this.a.doctorId;
            }
            if (this.a.doctorName) {
              this.billing.receiverName = this.a.doctorName;
            }
            if (this.a.billingId) {
              this.billing.billingId = this.a.billingId;
              //Fetch billing info
              this.fetchBillingDetails(this.billing.billingId);
            } else {
              this.addDefaultItems(this.a);
            }
            this.billing.appointmentDate = this.a.date;
            this.billing.appointmentId = this.a.appointmentId;
          }
        }
      });
  }
  fetchBillingDetails(billingId) {
    this.items = [];
    this.itemsProcedure = [];
    this.billing.itemsMap.clear();
    this.quantityChanged();
    this.isRefreshing = true;
    this._billingService

      .getBill(billingId)
      .then(resp => {
        this.isRefreshing = false;
        let b = new Billing();

        if (
          resp &&
          resp["statusCode"] == 200 &&
          resp["body"] &&
          resp["body"]["success"] &&
          resp["body"]["data"]
        ) {
          this.billing.initFromJSON(resp["body"]["data"]);
          this.billing.itemsMap.forEach((val, key) => {
            if (val.isTypeOPD()) {
              this.autocompleteForOPD.push({
                options: Array.from(
                  GlobalVariables.getOrganisation().opdCharges.values()
                ),
                control: new FormControl()
              });
              this.items.push(val);
            }
          });
          this.billing.itemsMap.forEach((val, key) => {
            if (val.isTypeProcedure()) {
              this.autocompletesForProcedures.push({
                options: Array.from(
                  GlobalVariables.getOrganisation().procedureCharges.values()
                ),
                control: new FormControl()
              });
              this.itemsProcedure.push(val);
            }
          });
          let dateTemp = new Date(this.billing.date);
          this.dateValue = {
            day: dateTemp.getDate(),
            month: dateTemp.getMonth() + 1,
            year: dateTemp.getFullYear()
          };
        } else {
        }
        this.quantityChanged();
      })
      .catch(err => {
        this.isRefreshing = false;
        console.error(err);
      });
  }
  validateDateValue() {}

  saveDateValueIntoBillingObject() {}

  initialiseDate() {
    let currentSelectedDate = new Date(this.currentSelectedDate);
    this.billing.date = DateActions.getDateString(currentSelectedDate);
    this.dateValue = {
      day: currentSelectedDate.getDate(),
      month: currentSelectedDate.getMonth() + 1,
      year: currentSelectedDate.getFullYear()
    };
  }

  onDateSelection() {
    this.billing.date = DateActions.getDateString(
      new Date(
        this.dateValue["year"],
        this.dateValue["month"] - 1,
        this.dateValue["day"]
      )
    );
  }

  serviceSelectionChanged(
    option: OPDTypeAndFee,
    currentItem: BillingItem,
    i: number
  ) {
    if (option.serviceName) {
      currentItem.title = option.serviceName;
    } else {
      currentItem.title = "";
    }
    if (option.serviceDescription) {
      currentItem.description = option.serviceDescription;
    } else {
      currentItem.description = "";
    }
    if (
      option &&
      option.doctorWisePricing &&
      option.doctorWisePricing.has(this.a.doctorId)
    ) {
      currentItem.price = option.doctorWisePricing.get(this.a.doctorId).amount;
    }
    this.items[i] = currentItem;
    this.quantityChanged();
  }
  serviceSelectionChangedProcedure(
    option: procedureTypeAndFee,
    currentItem: BillingItem,
    i: number
  ) {
    if (option.serviceName) {
      currentItem.title = option.serviceName;
    } else {
      currentItem.title = "";
    }
    if (option.serviceDescription) {
      currentItem.description = option.serviceDescription;
    } else {
      currentItem.description = "";
    }
    if (option.price) {
      currentItem.price = option.price;
    }
    currentItem.billingType = "procedure";
    setTimeout(() => {
      this.itemsProcedure[i] = currentItem;
      this.quantityChanged();
    }, 1000);
  }

  addDefaultItems(appointmentObj: Appointment) {
    let doctorId: string = appointmentObj.doctorId;
    if (
      appointmentObj.purposeOfVisitId &&
      GlobalVariables.getOrganisation().appointmentProfilesMap &&
      GlobalVariables.getOrganisation().appointmentProfilesMap.size > 0 &&
      GlobalVariables.getOrganisation().appointmentProfilesMap.has(
        appointmentObj.purposeOfVisitId
      )
    ) {
      let p = GlobalVariables.getOrganisation().appointmentProfilesMap.get(
        appointmentObj.purposeOfVisitId
      );
      this.items.splice(0, this.items.length);
      p.generallyIncludedConsultation.forEach((val, key) => {
        if (
          GlobalVariables.getOrganisation().opdCharges &&
          GlobalVariables.getOrganisation().opdCharges.size > 0 &&
          GlobalVariables.getOrganisation().opdCharges.has(key) &&
          GlobalVariables.getOrganisation()
            .opdCharges.get(key)
            .doctorWisePricing.has(doctorId)
        ) {
          this.autocompleteForOPD.push({
            options: Array.from(
              GlobalVariables.getOrganisation().opdCharges.values()
            ),
            control: new FormControl()
          });
          let defaultServiceItem: OPDTypeAndFee = GlobalVariables.getOrganisation().opdCharges.get(
            key
          );
          let item1 = new BillingItem();
          let newKey = uuid.v4();

          item1.billingItemId = newKey;
          item1.quantity = 1.0;
          item1.price = defaultServiceItem.doctorWisePricing.get(
            doctorId
          ).amount;
          item1.title = defaultServiceItem.serviceName;
          item1.doctorId = doctorId;
          if (defaultServiceItem.serviceDescription) {
            item1.description = defaultServiceItem.serviceDescription;
          }
          this.items.push(item1);
        }
      });
      this.itemsProcedure.splice(0, this.itemsProcedure.length);
      p.generallyIncludedProcedures.forEach((val, key) => {
        if (
          GlobalVariables.getOrganisation().procedureCharges &&
          GlobalVariables.getOrganisation().procedureCharges.size > 0 &&
          GlobalVariables.getOrganisation().procedureCharges.has(key)
        ) {
          this.autocompletesForProcedures.push({
            options: Array.from(
              GlobalVariables.getOrganisation().procedureCharges.values()
            ),
            control: new FormControl()
          });
          let defaultServiceItem: procedureTypeAndFee = GlobalVariables.getOrganisation().procedureCharges.get(
            key
          );
          let item1 = new BillingItem();
          let newKey = uuid.v4();

          item1.billingItemId = newKey;
          item1.quantity = 1.0;
          item1.billingType = "procedure";
          item1.price = defaultServiceItem.price;
          item1.title = defaultServiceItem.serviceName;
          item1.doctorId = doctorId;
          if (defaultServiceItem.serviceDescription) {
            item1.description = defaultServiceItem.serviceDescription;
          }
          this.itemsProcedure.push(item1);
        }
      });
      this.quantityChanged();
    }
  }

  addOpdItems() {
    if (GlobalVariables.getOrganisation().opdCharges.size > 0) {
      this.autocompleteForOPD.push({
        options: Array.from(
          GlobalVariables.getOrganisation().opdCharges.values()
        ),
        control: new FormControl()
      });
      let doctorId = this.a.doctorId;
      let defaultServiceItem: OPDTypeAndFee = Array.from(
        GlobalVariables.getOrganisation().opdCharges.values()
      )[0];
      let item1 = new BillingItem();
      let newKey = uuid.v4();

      item1.billingItemId = newKey;
      item1.quantity = 1.0;
      item1.price = defaultServiceItem.doctorWisePricing.get(doctorId).amount;
      item1.title = defaultServiceItem.serviceName;
      item1.doctorId = doctorId;
      if (defaultServiceItem.serviceDescription) {
        item1.description = defaultServiceItem.serviceDescription;
      }
      this.items.push(item1);
    } else {
      this.autocompleteForOPD.push({
        options: Array.from(
          GlobalVariables.getOrganisation().opdCharges.values()
        ),
        control: new FormControl()
      });
      let item1 = new BillingItem();
      let newKey = uuid.v4();

      item1.billingItemId = newKey;
      item1.quantity = 1.0;
      item1.doctorId = this.a.doctorId;
      this.items.push(item1);
    }
    this.quantityChanged();
  }

  addProcedure() {
    this.autocompletesForProcedures.push({
      options: Array.from(
        GlobalVariables.getOrganisation().procedureCharges.values()
      ),
      control: new FormControl()
    });
    let item = new BillingItem();
    let newKey = uuid.v4();

    item.billingItemId = newKey;
    item.quantity = 1.0;
    item.price = 0.0;
    item.billingType = "procedure";
    item.title = "";
    item.description = "";
    this.itemsProcedure.push(item);
  }

  onAddClicked() {
    // this.i = this.i + 1;
    // let j = this.i.toString()
    // for (j in this.itemsMap) {
    //   let item = new BillingItem();
    //   this.itemsMap.set(j, item);
    // }
    // console.log(this.itemsMap)

    let newKey = uuid.v4();
    let item = new BillingItem();
    item.billingItemId = newKey;
    item.quantity = 1.0;
    item.price = 0.0;

    this.items.push(item);
  }

  onDeleteClicked(index) {
    let key: any;
    key = this.items[index]["key"];
    this.billing.itemsMap.delete(key);
    const valueToRemove = this.items[index];
    this.items = this.items.filter(function(item) {
      return item !== valueToRemove;
    });
    this.quantityChanged();
    // console.log("Items =>", this.items);
    // console.log(this.billing);
  }
  onDeleteClickedProcedure(index) {
    let key: any;
    key = this.itemsProcedure[index]["key"];
    this.billing.itemsMap.delete(key);
    const valueToRemove = this.itemsProcedure[index];
    this.itemsProcedure = this.itemsProcedure.filter(function(item) {
      return item !== valueToRemove;
    });
    this.quantityChanged();
  }

  getDate() {
    return this.dd + "/" + this.mm + "/" + this.yyyy;
  }

  quantityChanged() {
    this.itemsMap.clear();
    for (let i = 0; i < this.items.length; i++) {
      this.itemsMap.set(this.items[i].billingItemId, this.items[i]);
    }
    for (let i = 0; i < this.itemsProcedure.length; i++) {
      this.itemsMap.set(
        this.itemsProcedure[i].billingItemId,
        this.itemsProcedure[i]
      );
    }
    this.billing.itemsMap = this.itemsMap;
    // this.billing.initFromJSON(this.billing);
    // console.log(this.billing.toJSON());
  }

  onSaveClicked() {
    if (this.dateValue)
      this.billing.date = DateActions.getDateJSONToStringWithMonthDiff(
        this.dateValue
      );
    this.isRefreshing = true;
    let request;
    if (!this.billing.billingId) {
      request = this._billingService.createBillingRecipt(
        GlobalVariables.getOrganisationId(),
        GlobalVariables.getOrganisation().organisationName,
        GlobalVariables.getMemberId(),
        GlobalVariables.getMemberName(),
        this.billing
      );
    } else {
      request = this._billingService.updateBillingRecipt(
        GlobalVariables.getOrganisationId(),
        GlobalVariables.getOrganisation().organisationName,
        GlobalVariables.getMemberId(),
        GlobalVariables.getMemberName(),
        this.billing
      );
    }
    request
      .then(resp => {
        this.isRefreshing = false;
        if (
          resp &&
          resp["statusCode"] &&
          resp["statusCode"] == 200 &&
          resp["body"] &&
          resp["body"]["success"]
        ) {
          let billingId = this.billing.billingId;
          if (!billingId && resp["body"]["billingId"]) {
            billingId = resp["body"]["billingId"];
          }
          if (billingId) {
            this.printDialog(billingId);
          } else {
            //Route to main screen
            this.goBack();
          }
        }
      })
      .catch(err => {
        console.error(err);
        this._notifyService.showErrorMessage("Please try again");
      });
  }

  printDialog(billingId) {
    this._confirmationDialogService
      .confirm("Print", "Do you want to print the receipt?", "Print", "BACK")
      .then(confirmed => {
        if (confirmed) {
          return this._billingService
            .fetchInvoicePDF(billingId, GlobalVariables.getOrganisationId())
            .then((data: BlobPart) => {
              if (data != null) {
                let file = new Blob([data], {
                  type: "application/pdf"
                });
                let fileURL = URL.createObjectURL(file);
                // this.isRefreshing = false;
                window.open(fileURL).print();
              }
              this.isRefreshing = false;
              //Route to main screen
              this.goBack();
              this._notifyService.showSuccessMessage("Saved successfully");
            })
            .catch(err => {
              console.error(err);
              this._notifyService.showErrorMessage("Please try again");
            });
        } else {
          this.isRefreshing = false;
          //Route to main screen
          this.goBack();
          this._notifyService.showSuccessMessage("Saved successfully");
          console.log("cancelled");
        }
      })
      .catch(err => {});
  }

  goBack() {
    this._router.navigate(["../../"], { relativeTo: this.activatedRoute });
  }

  // autosuggest
  myControl = new FormControl();
  options: OPDTypeAndFee[] = Array.from(
    GlobalVariables.getOrganisation().opdCharges.values()
  );
  filteredOptions: Observable<OPDTypeAndFee[]>;

  private _filter(value: OPDTypeAndFee): OPDTypeAndFee[] {
    let val = value.serviceName || "";
    const filterValue = val.toLowerCase();
    return this.options.filter(
      option => option.serviceName.toLowerCase().indexOf(filterValue) === 0
    );
  }

  // autosuggest for procedure
  myControl2 = new FormControl();
  optionsProcedure: procedureTypeAndFee[] = Array.from(
    GlobalVariables.getOrganisation().procedureCharges.values()
  );
  filteredProcedureOptions: Observable<procedureTypeAndFee[]>;

  private _filterProcedure(value: procedureTypeAndFee): procedureTypeAndFee[] {
    let val = value.serviceName || "";
    const filterValue = val.toLowerCase();
    return this.optionsProcedure.filter(
      optionsProcedure =>
        optionsProcedure.serviceName.toLowerCase().indexOf(filterValue) === 0
    );
  }
}
