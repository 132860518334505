import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import * as urls from "../../../../environments/environment";
import { GlobalVariables } from "src/app/globar-var/globarVariables";
import { Billing } from "../../models/Billing/Billing";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class BillingService {
  json: any = {};
  private subject = new BehaviorSubject<any>(this.json);

  constructor(private _http: HttpClient) {}

  /**
   *
   * @param billingId
   */
  public fetchPdfFromService(billingId: string, organisationId: string) {
    return new Promise((resolve, reject) => {
      let options = {};
      let headers = {
        "Content-Type": "application/pdf",
        Accept: "application/pdf"
      };
      let params = {};
      params["billingId"] = billingId;
      params["organisationId"] = organisationId;

      options["headers"] = headers;
      options["params"] = params;
      options["responseType"] = "arraybuffer";
      let val = this._http
        .get(urls.environment["get-billing-pdf"], options)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  public deleteBill(billingDetails: Billing) {
    return new Promise((resolve, reject) => {
      let options = {};
      let params = {};
      params["billingId"] = billingDetails["billingId"];
      params["organisationId"] = billingDetails["organisationId"];
      params["appointmentId"] = billingDetails["appointmentId"];
      params["appointmentDate"] = billingDetails["appointmentDate"];
      options["params"] = params;
      let val = this._http
        .delete(urls.environment["delete-patient-bill"], options)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  /**
   * This is the API to print the overall finance list sheet
   * @param date
   */
  public printDailyOPDCollection(
    date: string,
    doctorId: string,
    locationId: string
  ) {
    return new Promise((resolve, reject) => {
      let options = {};
      let headers = {
        "Content-Type": "application/pdf",
        Accept: "application/pdf"
      };
      let body = {};
      body["date"] = date;
      body["locationId"] = locationId;
      body["organisationId"] = GlobalVariables.getOrganisationId();
      body["doctorId"] = doctorId;
      options["headers"] = headers;
      options["responseType"] = "arraybuffer";
      let val = this._http
        .post(urls.environment["print-collection-pdf"], body, options)
        .subscribe(
          res => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  public selectedAppointment(json) {
    this.json = json;
    this.subject.next(json);
  }

  public getAppointment(): Observable<JSON> {
    return this.subject.asObservable();
  }

  /**
   * Create billing recipt
   *
   * @param organisationId
   * @param organisationName
   * @param receiverId
   * @param receiverName
   * @param billingObject
   * @param billingType
   */
  public createBillingRecipt(
    organisationId: string,
    organisationName: string,
    receiverId: string,
    receiverName: string,
    billingObject: Billing
  ) {
    return new Promise((resolve, reject) => {
      billingObject.receiverId = receiverId;
      billingObject.receiverName = receiverName;
      billingObject.organisationId = organisationId;
      billingObject.organisationName = organisationName;
      billingObject.createdById = receiverId;
      billingObject.createdByName = receiverName;
      let body = billingObject.toJSON();
      let val = this._http
        .post(urls.environment["create-patient-bill"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  /**
   * Create billing recipt
   * @param organisationId
   * @param organisationName
   * @param receiverId
   * @param receiverName
   * @param billingObject
   */
  public updateBillingRecipt(
    organisationId: string,
    organisationName: string,
    receiverId: string,
    receiverName: string,
    billingObject: Billing
  ) {
    return new Promise((resolve, reject) => {
      billingObject.receiverId = receiverId;
      billingObject.receiverName = receiverName;
      billingObject.organisationId = organisationId;
      billingObject.organisationName = organisationName;
      billingObject.createdById = receiverId;
      billingObject.createdByName = receiverName;
      let body = billingObject.toJSON();

      let val = this._http
        .post(urls.environment["update-patient-bill"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  public fetchInvoicePDF(billingId: string, organisationId: string) {
    return new Promise((resolve, reject) => {
      let options = {};
      let headers = {
        "Content-Type": "application/pdf",
        Accept: "application/pdf"
      };
      let body = {};
      body["billingId"] = billingId;
      body["organisationId"] = organisationId;
      options["headers"] = headers;
      options["params"] = body;
      options["responseType"] = "arraybuffer";
      let val = this._http
        .get(urls.environment["get-billing-pdf"], options)
        .subscribe(
          res => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  public getBill(billingId: string) {
    return new Promise((resolve, reject) => {
      let options = {};
      let body = {};
      body["billingId"] = billingId;
      body["organisationId"] = GlobalVariables.getOrganisationId();
      options["params"] = body;
      let val = this._http
        .get(urls.environment["get-patient-bill"], options)
        .subscribe(
          res => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  public printReport(startDate: string, endDate: string) {
    return new Promise((resolve, reject) => {
      let options = {};
      let headers = {
        "Content-Type": "application/pdf",
        Accept: "application/pdf"
      };
      let body = {};
      body["organisationId"] = GlobalVariables.getOrganisationId();
      body["startDate"] = startDate;
      body["endDate"] = endDate;
      options["headers"] = headers;
      options["responseType"] = "arraybuffer";
      let val = this._http
        .post(urls.environment["get-billing-report"], body, options)
        .subscribe(
          res => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            reject(err);
          }
        );
    });
  }

  public getSingleAppointment(
    appointmentId: string,
    patientId?: string,
    appointmentDate?: string
  ) {
    return new Promise((resolve, reject) => {
      let params = {};
      let options = {};
      params["organisationId"] = GlobalVariables.getOrganisationId();
      params["appointmentId"] = appointmentId;
      if (patientId) {
        params["patientId"] = patientId;
      }
      if (appointmentDate) {
        params["appointmentDate"] = appointmentDate;
      }
      options["params"] = params;
      console.log(params);
      let val = this._http
        .get(urls.environment["fetch-details"], options)
        .subscribe(
          res => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            reject(err);
          }
        );
    });
  }
}
