import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import * as urls from "../../../../environments/environment";
import { ObjectKeys } from "../../constants/object-keys";
import { GlobalVariables } from "src/app/globar-var/globarVariables";

@Injectable({
  providedIn: "root"
})
export class DashboardService {
  constructor(private _http: HttpClient) {}

  /**
   * Fetch patient count
   * @param {string} organisationId
   */
  public fetchPatientCount(organisationId: string) {
    return new Promise((resolve, reject) => {
      let options = {};
      let params = {};
      params["organisationId"] = organisationId;
      options["params"] = params;
      let val = this._http
        .get(urls.environment["patient-count"], options)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  /**
   * Fetch patient follow ups
   * @param {string} organisationId
   * @param {string} date
   */
  public fetchFollowUps(organisationId: string, date: string) {
    return new Promise((resolve, reject) => {
      let options = {};
      let params = {};
      params["organisationId"] = organisationId;
      params["followUpDate"] = date;
      options["params"] = params;
      let val = this._http
        .get(urls.environment["follow-up-list"], options)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  /**
   * Fetch patient list whose birthdays are on this date
   * @param {string} organisationId
   * @param {string} date
   */
  public fetchBirthdayPatientList(organisationId: string, date: string) {
    return new Promise((resolve, reject) => {
      let options = {};
      let params = {};
      params["organisationId"] = organisationId;
      params["date"] = date;
      options["params"] = params;
      let val = this._http
        .get(urls.environment["date-of-birth-list"], options)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  /**
   * Fetch patient list whose birthdays are on this date
   * @param {string} organisationId
   * @param {string} startDate
   * @param {string} endDate
   */
  public fetchStatsByDateRange(
    organisationId: string,
    startDate: string,
    endDate: string
  ) {
    return new Promise((resolve, reject) => {
      let options = {};
      let params = {};
      params["organisationId"] = organisationId;
      params["startDate"] = startDate;
      params["endDate"] = endDate;
      options["params"] = params;
      let val = this._http
        .get(urls.environment["stats-by-date-range"], options)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  public sendBirthdaySMS = (
    message: string,
    organisationId: string,
    contactNumber: string,
    dateSelected: string,
    patientId: string
  ) => {
    return new Promise((resolve, reject) => {
      let body = {};
      body["organisationId"] = organisationId;
      body["message"] = message;
      body["dateSelected"] = dateSelected;
      body["contactNumber"] = contactNumber;
      body["patientId"] = patientId;
      let val = this._http
        .post(urls.environment["send-birthday-sms"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
    1;
  };

  public changeFollowupDate = (followupId: string, date: string) => {
    return new Promise((resolve, reject) => {
      let body = {};
      body["newDate"] = date;
      body["followupId"] = followupId;
      body[ObjectKeys.organisationId] = GlobalVariables.getOrganisationId();
      let val = this._http
        .post(urls.environment["change-followup-date"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  };

  public printReport(startDate: string, endDate: string) {
    return new Promise((resolve, reject) => {
      let options = {};
      let headers = {
        "Content-Type": "application/pdf",
        Accept: "application/pdf"
      };
      let body = {};
      body["organisationId"] = GlobalVariables.getOrganisationId();
      body["startDate"] = startDate;
      body["endDate"] = endDate;
      options["headers"] = headers;
      options["responseType"] = "arraybuffer";
      let val = this._http
        .post(
          urls.environment["get-missing-cancelled-appointment-report"],
          body,
          options
        )
        .subscribe(
          res => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            reject(err);
          }
        );
    });
  }
}
