import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";

@Pipe({
  name: "shortDateWithoutYearFilter"
})
export class ShortDateWithoutYearFilterPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value && typeof value === "object") {
      let date = new Date(value.year, value.month - 1, value.day);
      return super.transform(date, "EEE, dd MMM");
    } else if (value && typeof value === "string") {
      var day = value.split("-");
      let date = new Date(
        parseInt(day[0]),
        parseInt(day[1]) - 1,
        parseInt(day[2])
      );
      return super.transform(date, "EEE, dd MMM");
    } else {
      return null;
    }
  }
}
