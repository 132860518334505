import { Injectable } from "@angular/core";
import { AudioRecordingService } from "../audioRecorder/audio-recording.service";

@Injectable({
  providedIn: "root"
})
export class HotkeysService {
  constructor(private audioRecordService: AudioRecordingService) {}
  voiceActivation() {
    this.audioRecordService.startRecording();
    console.log("its recording");
  }
  voiceDisable() {
    this.audioRecordService.stopRecording();
    console.log("recording stopped");
  }
  back() {
    console.log("back");
  }
  cross() {
    console.log("escaped");
  }
}
