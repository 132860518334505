import { Component, OnInit, Input } from "@angular/core";
import { Patient } from "../../models/Patient/Patient";
import { DashboardService } from "../../services/dashboard/dashboard.service";
import { NotifyService } from "../../services/notify/notify.service";
import { GlobalVariables } from "src/app/globar-var/globarVariables";

@Component({
  selector: "app-send-patient-communication",
  templateUrl: "./send-patient-communication.component.html",
  styleUrls: ["./send-patient-communication.component.css"]
})
export class SendPatientCommunicationComponent implements OnInit {
  @Input() title: string = "Send Birthday Greetings";
  @Input() patient: Patient = new Patient();
  @Input() communicationDate: string = "2019-06-23";

  @Input() newMessageForSending: string = ""; // bind to text area

  constructor(private _dashboardService:DashboardService, private _nofityService:NotifyService) {}

  ngOnInit() {
    
  }

  goToPatientProfile(docvitaId) {
    // Open the patient profile in new Tab.
  }

  isValidForSending() {
    // implement this function here
    return true;
  }

  onSubmit(){
    if(this.isValidForSending()){
      // this._dashboardService.sendCustomSMS(this.newMessageForSending,GlobalVariables.getOrganisationId(),this.patient.primaryContactNumber.countryCode
      // +this.patient.primaryContactNumber.number).then((resp)=>{
      //   if(resp && resp["statusCode"] && resp["statusCode"]==200){
      //     this._nofityService.showSuccessMessage("SMS Sent!")
      //     //TODO dialog close
      //   }else{
      //     this._nofityService.showErrorMessage("Please try again")
      //   }
      // }).catch(err=>{
      //   this._nofityService.showErrorMessage("Please try again")
      // })
    }
  }
}
