import {
  Component,
  OnInit,
  Output,
  ElementRef,
  EventEmitter
} from "@angular/core";
import { DentalChartEntry } from "../../models/Patient/Dental/DentalChartEntry";
import { DentalConstants } from "../../models/Patient/Dental/DentalConstants";
import { Subscription } from "rxjs";
import { SideSectionDialogManagementService } from "../../services/component-communication/side-section-dialog-management.service";
import {
  AssistantStateManagementService,
  CurrentAssistantState
} from "../../services/component-communication/asssitant-state-management.service";
import { DentalCaseHistoryService } from "../../services/dental-case-history/dental-case-history.service";
import { ActivatedRoute, Router } from "@angular/router";
import { PatientProfileService } from "../../services/patient/patient-profile.service";
import { Patient } from "../../models/Patient/Patient";
import { DentalHistory } from "../../models/Patient/DentalHistory";
import { VoiceService } from "../../services/voice/voice.service";
import { PatientProfileSyncService } from "../../services/component-communication/patient-profile-sync.service";
import * as firebase from "firebase";
import { FirestoreCollections } from "../../constants/object-keys";
import { GlobalVariables } from "../../../globar-var/globarVariables";
import { DentalCaseHistory } from "../../models/Patient/Dental/DentalCaseHistory";
import { DentalChartEntryService } from "../../services/dental-chart-entry/dental-chart-entry.service";
import { Organisation } from "../../models/MD - Member";
import { Examination } from "../../models/Patient/Dental/Examination";
import { NewDentalChartService } from "../../services/component-communication/new-dental-chart.service";
import { Treatment } from "../../models/Patient/Dental/Treatment";
import { DateActions } from "../../models/Demographics";

@Component({
  selector: "app-dental-chart-entry",
  templateUrl: "./dental-chart-entry.component.html",
  styleUrls: ["./dental-chart-entry.component.css"]
})
export class DentalChartEntryComponent implements OnInit {
  @Output() buttonClicked = new EventEmitter();
  db = firebase.firestore();
  remark: string;
  patientUuid: string;
  organisationId: string;
  createdById: string;
  createdByName: string;
  caseHistoryId: string;
  dentalCaseHistoryEntryListener;
  examinationSelectOptionsMap: Map<string, {}> = new Map<string, {}>();
  dentalCaseHistoryEntries: Array<DentalCaseHistory> = new Array<
    DentalCaseHistory
  >();
  treatmentSelectOptionsMap: Map<string, {}> = new Map<string, {}>();
  selectedPatient: Patient = new Patient();
  patientDocVitaId = "";
  isEditingEntry: boolean = false; //To decide if the component is performing editing or adding new data
  activatedRouteSubscription: Subscription;
  dentalChartEntry: DentalChartEntry = new DentalChartEntry();
  caller: string; //To decide who is calling this component
  examinationSelectConfig: any = {
    create: true,
    optgroupField: "group",
    optgroupLabelField: "label",
    labelField: "label",
    valueField: "value",
    highlight: true,
    searchField: ["label"],
    maxItems: 100,
    closeAfterSelect: true
  };

  treatmentSelectConfig: any = {
    create: true,
    optgroupField: "group",
    optgroupLabelField: "label",
    labelField: "label",
    valueField: "value",
    highlight: true,
    searchField: ["label"],
    sortField: [{ field: "label", direction: "asc" }],
    maxItems: 100,
    closeAfterSelect: true
  };

  examinationSelectGroups: any = DentalConstants.examinationSelectGroups;
  examinationSelectOptions: any = DentalConstants.examinationSelectOptions;
  examinationSelectValue: string[] = [];
  tempExaminationsMap = new Map<string, string>();

  treatmentSelectGroups: any = DentalConstants.treatmentSelectGroups;
  treatmentSelectOptions: any = DentalConstants.treatmentSelectOptions;
  treatmentSelectValue: string[] = [];
  tempTreatmentsMap = new Map<string, string>();

  isGroupTeeth: boolean = false;

  sub: Subscription;
  constructor(
    private _assistantStateManagementService: AssistantStateManagementService,
    private _sideSectionDialogManagementService: SideSectionDialogManagementService,
    private _DentalChartEntryService: DentalChartEntryService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private el: ElementRef,
    private _newDentalChartService: NewDentalChartService
  ) {}

  assistantStateManagementSubscription: Subscription;

  ngOnInit() {
    this.sub = this._sideSectionDialogManagementService
      .listener()
      .subscribe(map => {
        if (map && map.has("type")) {
          let type = map.get("type");
          if (type == "new_dental_entry") {
            this.isEditingEntry = <boolean>map.get("isEditing");
            if (
              map.has("dentalChartEntry") &&
              map.get("dentalChartEntry") != null
            ) {
              this.dentalChartEntry = <DentalChartEntry>(
                map.get("dentalChartEntry")
              );
              this.onEditClicked();
            } else {
              this.dentalChartEntry = new DentalChartEntry();
              this.examinationSelectValue = [];
              this.tempExaminationsMap.clear();
              this.treatmentSelectValue = [];
              this.tempTreatmentsMap.clear();
            }
          }
        }
      });
    // this.activateListener();
  }

  sortDentalCaseHistoryEntries() {
    this.dentalCaseHistoryEntries.sort((a, b) => {
      if (a.date < b.date) {
        return 1;
      } else {
        return -1;
      }
    });
  }
  toggleIsGroupTeeth() {
    this.isGroupTeeth = !this.isGroupTeeth;
  }

  onEditClicked() {
    const that = this;
    this.remark = this.dentalChartEntry.remark;
    this.dentalChartEntry.teeth.forEach(value => {});
    if (this.dentalChartEntry.teeth && this.dentalChartEntry.teeth.length > 1) {
      this.isGroupTeeth = true;
    } else {
      this.isGroupTeeth = false;
    }
    let temp: string[] = [];
    this.tempExaminationsMap = new Map<string, string>();
    this.dentalChartEntry.examinations.forEach((value, key) => {
      temp.push(value.value);
      this.tempExaminationsMap.set(value.group + value.value, key);
    });

    this.examinationSelectValue = temp;
    temp = [];
    this.tempTreatmentsMap = new Map<string, string>();
    this.dentalChartEntry.treatments.forEach((value, key) => {
      temp.push(value.value);
      this.tempTreatmentsMap.set(value.group + value.value, key);
    });
    this.treatmentSelectValue = temp;
  }

  close() {
    this.onDestroyListener();
    this._assistantStateManagementService.closeDentalChartForm();
  }

  onDestroyListener() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.onDestroyListener();
  }

  discardNewDentalChartEntry() {
    this.dentalChartEntry = new DentalChartEntry();
    this.examinationSelectValue = [];
    this.tempExaminationsMap.clear();
    this.treatmentSelectValue = [];
    this.tempTreatmentsMap.clear();
    this.remark = "";
  }
  addNewDentalChartEntry() {}

  sendMessage() {
    let path = this.router.url;
    if (path.indexOf("patient-detail") > -1) {
      let finalPathForRouting = path.substring(
        0,
        path.indexOf("patient-detail/")
      );
      let sub = path.substring(
        path.indexOf("patient-detail/") + "patient-detail/".length
      );
      let patientId = sub.substring(0, sub.indexOf("/"));
      if (patientId != null && patientId.length > 0) {
        this.patientUuid = patientId;
        console.log("Patient Id: ", this.patientUuid);
      } else {
        console.log("You're an idiot!");
      }
    }
    this.dentalChartEntry.date = DateActions.getDateString(new Date());
    this.dentalChartEntry.patientId = this.patientUuid;
    this.dentalChartEntry.organisationId = GlobalVariables.getOrganisationId();
    this.dentalChartEntry.createdBy = GlobalVariables.getMemberId();
    this.dentalChartEntry.createdByName = GlobalVariables.getMemberName();

    this.dentalChartEntry.remark = this.remark;
    this.dentalChartEntry.examinations = this.getExaminations();
    this.dentalChartEntry.examinationsName = this.getExaminationForTable(
      this.dentalChartEntry.examinations
    );
    this.dentalChartEntry.treatments = this.getTreatments();
    this.dentalChartEntry.treatmentsName = this.getTreatmentForTable(
      this.dentalChartEntry.treatments
    );

    // send message to subscribers via observable subject
    this._newDentalChartService.sendMessage(
      this.dentalChartEntry,
      this.isGroupTeeth
    );
    this._assistantStateManagementService.closeDentalChartForm();
  }
  clearMessages(): void {
    // clear messages
    this._newDentalChartService.clearMessages();
  }
  getExaminations() {
    const examinations: Map<string, Examination> = new Map<
      string,
      Examination
    >();
    if (this.examinationSelectValue.length > 0) {
      this.examinationSelectValue.forEach(value => {
        const temp = new Examination();

        //get selected examination details and store it in temp
        let found = false;
        for (let examination of this.examinationSelectOptions) {
          if (examination.value === value) {
            found = true;
            temp.value = examination["value"];
            temp.label = examination["label"];
            temp.group = examination["group"];
            temp.groupLabel = examination["groupLabel"];
          }
        }

        if (!found) {
          temp.value = value;
          temp.label = value;
          temp.group = "extras";
          temp.groupLabel = "Extras";
        }

        if (!this.tempExaminationsMap.has(temp.group + temp.value)) {
          temp.examinationId = firebase
            .database()
            .ref()
            .push().key;

          temp.currentStatus = "advised";
          temp.date = this.dentalChartEntry.date;
          temp.caseHistoryId = this.dentalChartEntry.caseHistoryId;
          temp.dentalChartEntryId = this.dentalChartEntry.dentalChartEntryId;
          examinations.set(temp.examinationId, temp);
        } else {
          temp.examinationId = this.tempExaminationsMap.get(
            temp.group + temp.value
          );
          temp.date = this.dentalChartEntry.date;
          temp.currentStatus = this.dentalChartEntry.examinations.get(
            temp.examinationId
          ).currentStatus;
          temp.caseHistoryId = this.dentalChartEntry.caseHistoryId;
          temp.dentalChartEntryId = this.dentalChartEntry.dentalChartEntryId;
          examinations.set(temp.examinationId, temp);
        }
      });
    }

    return examinations;
  }

  getExaminationForTable(examinations: Map<string, Examination>) {
    let examinations1 = "";

    examinations.forEach(value => {
      if (value.groupLabel) {
        examinations1 += value.groupLabel + " - " + value.label + ", ";
      } else {
        examinations1 += value.label + ", ";
      }
    });

    return examinations1.replace(/,\s*$/, "");
  }

  getTreatments() {
    const treatments: Map<string, Treatment> = new Map<string, Treatment>();
    if (this.treatmentSelectValue.length > 0) {
      this.treatmentSelectValue.forEach(value => {
        const temp = new Treatment();

        let found = false;
        for (let treatments of this.treatmentSelectOptions) {
          if (treatments.value === value) {
            found = true;
            temp.value = treatments["value"];
            temp.label = treatments["label"];
            temp.group = treatments["group"];
            temp.groupLabel = treatments["groupLabel"];
          }
        }

        if (!found) {
          temp.value = value;
          temp.label = value;
          temp.group = "extras";
          temp.groupLabel = "Extras";
        }
        if (!this.tempTreatmentsMap.has(temp.group + temp.value)) {
          temp.treatmentId = firebase
            .database()
            .ref()
            .push().key;

          temp.currentStatus = "advised";
          temp.date = this.dentalChartEntry.date;
          temp.caseHistoryId = this.dentalChartEntry.caseHistoryId;
          temp.dentalChartEntryId = this.dentalChartEntry.dentalChartEntryId;
          treatments.set(temp.treatmentId, temp);
        } else {
          temp.treatmentId = this.tempTreatmentsMap.get(
            temp.group + temp.value
          );
          temp.date = this.dentalChartEntry.date;
          temp.currentStatus = this.dentalChartEntry.treatments.get(
            temp.treatmentId
          ).currentStatus;
          temp.caseHistoryId = this.dentalChartEntry.caseHistoryId;
          temp.dentalChartEntryId = this.dentalChartEntry.dentalChartEntryId;
          treatments.set(temp.treatmentId, temp);
        }
      });
    }

    return treatments;
  }

  getTreatmentForTable(treatments: Map<string, Treatment>) {
    let treatments1 = "";

    treatments.forEach(value => {
      const statusval = this.convertStatus(value.currentStatus);
      if (value.groupLabel) {
        treatments1 += value.groupLabel + " - " + value.label + ", ";
      } else {
        treatments1 += value.label + ", ";
      }
    });

    return treatments1.replace(/,\s*$/, "");
  }
  convertStatus(status: string) {
    const map = {};
    map["advised"] = "Advised";
    map["in_progress"] = "In progress";
    map["discontinued"] = "Discontinued";
    map["completed"] = "Completed";
    if (status) {
      return map[status];
    } else {
      return "Advised";
    }
  }
}
