import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from "@angular/router";
import { Observable } from "rxjs";
import * as firebase from "firebase";
import { GlobalVariables } from "src/app/globar-var/globarVariables";

@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    var self = this;
    return new Promise((resolve, reject) => {
      if (GlobalVariables.getMemberId() != null && GlobalVariables.getMemberId().length > 0) {
        //User Logged In
        resolve(true);
      } else {
        // User Not Logged In
        resolve(false);
        self.router.navigate(["/auth"]);
      }
    });
  }
}
