import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
  HostListener
} from "@angular/core";
import { Patient } from "../../models/Patient/Patient";
import { PatientProfileService } from "../../services/patient/patient-profile.service";
import { GlobalVariables } from "src/app/globar-var/globarVariables";
import { PatientRegistrationModalService } from "../../services/modalStateManagement/patient-registration-modal.service";
import { Observable } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import { NotifyService } from "../../services/notify/notify.service";
import { HotkeysService } from "../../services/hotkeys/hotkeys.service";
import { SelectizeConfigs } from "../../constants/object-keys";
import { Router } from "@angular/router";
import {
  OrganisationMember,
  OPDTypeAndFee,
  AppointmentProfile
} from "../../models/MD - Member";
import { DateActions } from "../../models/Demographics";
// import { SearchService } from "src/app/services/elasticSearch/search.service";
// import { CurrentUserService } from "src/app/services/currentUser/current-user.service";

export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
  ENTER = 13,
  ESC = 27,
  DOWN_ARROW = 40,
  UP_ARROW = 38
}

@Component({
  selector: "app-patient-search",
  templateUrl: "./patient-search.component.html",
  styleUrls: ["./patient-search.component.css"]
})
export class PatientSearchComponent implements OnInit, OnChanges {
  locationId: string = "";
  selectedDoctorId = "";
  locationOptions = [];
  availableDoctorOptions = [];
  singleSelectConfig = SelectizeConfigs.singleSelectConfig;

  currentIndex = 0;
  @Input() searchText: string = "";
  @Input() clearSearchText: boolean = false;
  @Input() placeHolder: string = "Search...";
  @Input() searchCellType: string; // Mandatory Input - docQueueSearch or newAppointmentSearch

  @ViewChild("searchTextInputElement", { static: true })
  searchTextInputElement: ElementRef;

  @ViewChild("addToQueueFocus", { static: false })
  addToQueueFocus: ElementRef;

  @ViewChild("addToBooking", { static: false })
  addToBooking: ElementRef;

  @ViewChild("printFocus", { static: false })
  printFocus: ElementRef;

  @Output() addToQueue = new EventEmitter<Map<string, any>>();
  @Output() addToNetwork = new EventEmitter<Patient>();
  @Output() scheduleVisit = new EventEmitter<Patient>();
  @Output() cellClicked = new EventEmitter<Patient>();

  searchingPatient: boolean;
  patients: Array<Patient> = [];
  showPatientProfile: Patient = new Patient();
  appointmentProfilesMap: Map<string, AppointmentProfile> = new Map();
  availableVisitTypes = [];
  selectedPurposeOfVisitId: string;
  selectedSlotLength = 30;
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      map(term => (term === "" ? [] : this.patients))
    );

  constructor(
    private _router: Router,
    private _notifyService: NotifyService,
    private _patientProfileService: PatientProfileService,
    private _patientRegistrationModalService: PatientRegistrationModalService,
    private _hotKeysService: HotkeysService
  ) {}

  ngOnInit() {
    this.initialize();
    this._patientRegistrationModalService
      .getNewAddedPatient()
      .subscribe((user: Patient) => {
        this.initialize();
        if (user) {
          console.log("FIRE3");
          if (user.docvitaId) {
            this.onCellClickAction(true, user);
            // console.log("detectign new patient in search component");
            // this.searchText = "";
            // this.patients.slice(0, this.patients.length);
            // this.patients.push(user);
            this._patientRegistrationModalService.clearNewAddedPatient();
          } else {
            console.log("FIRE");
            setTimeout(() => {
              this.initialize();
              this.searchPatient();
            }, 1000);
          }
        } else {
          console.log("FIRE2");
          setTimeout(() => {
            this.initialize();
            this.searchPatient();
          }, 1000);
        }
      });
  }

  initialize() {
    this.appointmentProfilesMap.clear();
    this.appointmentProfilesMap = GlobalVariables.getOrganisation().appointmentProfilesMap;
    this.availableVisitTypes = [];
    this.appointmentProfilesMap.forEach(val => {
      this.availableVisitTypes.push({
        value: val.appointmentProfileId,
        label: val.name
      });
    });

    this.availableVisitTypes.sort((a, b) => {
      if (a && b && a["label"] && b["label"]) {
        return (a["label"] + "").localeCompare(b["label"] + "");
      } else {
        return 0;
      }
    });
    this.selectedSlotLength = GlobalVariables.getOrganisation().slotLength;

    if (
      this.availableVisitTypes &&
      this.availableVisitTypes[0] &&
      this.availableVisitTypes[0].value &&
      this.appointmentProfilesMap.get(this.availableVisitTypes[0].value)
    ) {
      let data = this.appointmentProfilesMap.get(
        this.availableVisitTypes[0].value
      );
      if (data.convertDurationInMinutes()) {
        this.selectedSlotLength = data.convertDurationInMinutes();
      }
    }
    if (this.availableVisitTypes.length > 0) {
      this.selectedPurposeOfVisitId = this.availableVisitTypes[0]["value"];
    }
    this.getDoctorsList();
    this.loadAvailableLocations();
  }

  selectPurposeOfVisit(purposeOfVisitId: string) {
    if (purposeOfVisitId && this.appointmentProfilesMap.has(purposeOfVisitId)) {
      this.selectedPurposeOfVisitId = purposeOfVisitId;
    }
  }

  updateDuration(id: string) {
    let data = this.appointmentProfilesMap.get(id);
    if (data && data.convertDurationInMinutes()) {
      this.selectedSlotLength = data.convertDurationInMinutes();
    }
  }

  /**
   * Load locations from organisation obejct
   */
  loadAvailableLocations() {
    GlobalVariables.getOrganisation().locations.forEach((val, key) => {
      this.locationId = key;
      let json = {};
      json["label"] = val.title;
      json["value"] = key;
      this.locationOptions.push(json);
    });

    if (this.pathHasLocationId()) {
      this.locationId = this.pathHasLocationId();
    } else {
      if (this.locationOptions.length > 0) {
        this.locationId = this.locationOptions[0]["value"];
      }
    }
  }
  pathHasDoctorId() {
    let id = null;
    let url = this._router.url;
    if (
      url &&
      (url.indexOf("opd/today") > -1 || url.indexOf("opd/calendar") > -1)
    ) {
      let localStrArray = url.split("/");
      // console.log(localStrArray);
      if (localStrArray && localStrArray.length >= 4) {
        id = localStrArray[4];
      }
    }
    return id;
  }

  pathHasLocationId() {
    let id = null;
    let url = this._router.url;
    if (
      url &&
      (url.indexOf("opd/today") > -1 || url.indexOf("opd/calendar") > -1)
    ) {
      let localStrArray = url.split("/");
      // console.log(localStrArray);
      if (localStrArray && localStrArray.length >= 4) {
        id = localStrArray[3];
      }
    }
    return id;
  }

  doctors: Map<string, OrganisationMember> = new Map();

  getDoctorsList() {
    this.doctors.clear();
    this.availableDoctorOptions.splice(0, this.doctors.size);
    GlobalVariables.getOpdScheduleAvailableMembersMap(
      GlobalVariables.getOrganisationId()
    ).forEach((val, key) => {
      let temp = {};
      temp["label"] = val.name;
      temp["value"] = key;
      this.availableDoctorOptions.push(temp);
      this.doctors.set(key, val);
    });
    this.availableDoctorOptions.sort((a, b) => {
      return (a.value + "").localeCompare(b.value + "");
    });
    let docId = GlobalVariables.getMemberId();
    if (localStorage.getItem("selectedDoctorId")) {
      docId = localStorage.getItem("selectedDoctorId");
    } else if (
      !GlobalVariables.getMember().getOpdScheduleAvailability(
        GlobalVariables.getOrganisationId()
      ) &&
      this.availableDoctorOptions.length > 0
    ) {
      docId = this.availableDoctorOptions[0]["value"];
    }
    this.selectDoctor(docId);
  }

  selectDoctor(memberId: string) {
    if (this.availableDoctorOptions.length > 0) {
      for (let a of this.availableDoctorOptions) {
        if (a["value"] == memberId) {
          this.selectedDoctorId = memberId;
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    // this.searchPatient();
    // cåonsole.log("Clear Patient Search Text: ", this.clearSearchText);
    if (this.clearSearchText) {
      this.searchText = "";
      this.focusOnSearchInput();
    }
  }

  focusOnSearchInput() {
    // alert();
    setTimeout(() => {
      this.searchTextInputElement.nativeElement.focus();
    }, 200);
  }

  focusOnAddToQueue() {
    setTimeout(() => {
      this.addToQueueFocus.nativeElement.focus();
      this.currentIndex = 0;
    }, 100);
  }
  focusOnBooking() {
    setTimeout(() => {
      this.addToBooking.nativeElement.focus();
      this.currentIndex = 1;
    }, 100);
  }
  focusOnPrintPatientInfo() {
    setTimeout(() => {
      this.printFocus.nativeElement.focus();
      this.currentIndex = 2;
    }, 100);
  }

  // ngOnChanges(changes: SimpleChanges) {

  // }
  searchPatient() {
    // alert("YO")
    this.searchingPatient = true;
    this._patientProfileService
      .patientTextSearch(this.searchText, GlobalVariables.getOrganisationId())
      .then(array => {
        this.searchingPatient = false;
        this.patients = [];
        this.patients = <Patient[]>array;
      })
      .catch(err => {
        console.error(err);
      });
  }

  onNewRegisterClicked() {
    this._patientRegistrationModalService.setInitialTextFornewUser(
      this.searchText
    );
    setTimeout(() => {
      this.searchText = "";
    }, 150);
    this._patientRegistrationModalService.selectPatientProfileForEditing(
      new Patient()
    );
  }
  requestToConnect(success: boolean, patient: Patient) {
    this.addToNetwork.emit(patient);
  }
  onCellClickAction(success: boolean, patient: Patient) {
    this.searchText = "";
    this.cellClicked.emit(patient);
  }

  requestToAddToQueue(success: boolean, patient: Patient) {
    this.searchText = "";
    let map = new Map();
    map.set("patient", patient);
    map.set("selectedDoctorId", this.selectedDoctorId);
    map.set("selectedPurposeOfVisitId", this.selectedPurposeOfVisitId);
    map.set("locationId", this.locationId);
    map.set("duration", this.selectedSlotLength);
    this.addToQueue.emit(map);
    this.closeProfile();
  }

  requestToScheduleVisit(success: boolean, patient: Patient) {
    this.searchText = "";
    this.scheduleVisit.emit(patient);
    this.closeProfile();
  }

  tempSearchText: string = "";

  editProfile(p: Patient) {
    this._patientRegistrationModalService.selectPatientProfileForEditing(p);
    this._patientRegistrationModalService.openPatientProfileEditing();
  }

  selectedItem($event) {
    $event.preventDefault();
    let patient = $event.item;
    this.showPatientProfile = patient;
    this.tempSearchText = this.searchText;
    this.searchText = "";
    if (this.searchCellType == "newAppointmentSearch") {
      this.cellClicked.emit(patient);
      this.closeProfile();
    }
    this.focusOnAddToQueue();
  }

  // onDoctorChanged() {}

  // onLocationChange() {}

  backToSearch() {
    this.closeProfile();
    this.focusOnSearchInput();
    // $("#searchTextInputElement").focus();
    $("#searchTextInputElement").focus();
    this.searchText = "  ";
    this.searchText = this.tempSearchText;
  }

  closeProfile() {
    this.showPatientProfile = new Patient();
  }

  //Print patient information on the letterhead
  printPatientInfo(patient: Patient) {
    this._patientProfileService
      .printPatientInto(
        patient.docvitaId,
        GlobalVariables.getMemberId(),
        GlobalVariables.getOrganisationId()
      )
      .then((data: BlobPart) => {
        var file = new Blob([data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(file);
        // this.isRefreshing = false;
        window.open(fileURL).print();
        this._notifyService.showSuccessMessage("Pdf generated");
      })
      .catch(err => {
        this._notifyService.showErrorMessage("Please try again.");
      });
  }

  // navigate using arrow
  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    // console.log(event);

    if (event.keyCode === KEY_CODE.ESC) {
      this.closeProfile();
    }
    if (event.keyCode === KEY_CODE.ENTER) {
    }
    if (event.keyCode === KEY_CODE.RIGHT_ARROW) {
      this.handleRightKeyCode();
    }
    if (event.keyCode === KEY_CODE.DOWN_ARROW) {
      this.handleDownKeyCode();
    }
    if (event.keyCode === KEY_CODE.LEFT_ARROW) {
      this.handleLeftKeyCode();
    }
    if (event.keyCode === KEY_CODE.UP_ARROW) {
      this.handleTopKeyCode();
    }
  }

  handleEnterKeyCode() {
    switch (this.currentIndex) {
      case 0: {
        //Add to queue
        this.requestToAddToQueue(true, this.showPatientProfile);
        break;
      }
      case 1: {
        //Booking appointment
        this.requestToScheduleVisit(true, this.showPatientProfile);
        break;
      }
      case 2: {
        //Print patient info
        this.printPatientInfo(this.showPatientProfile);
        break;
      }
    }
  }

  handleRightKeyCode() {
    //Handle current focus index
    switch (this.currentIndex) {
      case 0: {
        //Add to queue
        this.focusOnBooking();
        break;
      }
      case 1: {
        //Booking appointment
        this.focusOnPrintPatientInfo();
        break;
      }
      case 2: {
        //Print patient info
        //Do nothing
        break;
      }
    }
  }
  handleDownKeyCode() {
    switch (this.currentIndex) {
      case 0: {
        this.focusOnPrintPatientInfo();
        break;
      }
      case 1: {
        this.focusOnPrintPatientInfo();
        break;
      }
      case 2: {
        // do nothing
        break;
      }
    }
  }
  handleLeftKeyCode() {
    switch (this.currentIndex) {
      case 0: {
        // do nothing
        break;
      }
      case 1: {
        this.focusOnAddToQueue();
        break;
      }
      case 2: {
        this.focusOnBooking();
        break;
      }
    }
  }
  handleTopKeyCode() {
    switch (this.currentIndex) {
      case 0: {
        // do nothing
        break;
      }
      case 1: {
        // do nothing
        break;
      }
      case 2: {
        this.focusOnBooking();
        break;
      }
    }
  }
}
