// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
let baseUrl = "https://india-pro-api.docvita.app";
export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCStUB506JnLWp4V5ntef6Vt8RT_AVqczo",
    authDomain: "docvita-pro.firebaseapp.com",
    databaseURL: "https://docvita-pro.firebaseio.com",
    projectId: "docvita-pro",
    storageBucket: "docvita-pro.appspot.com",
    messagingSenderId: "867083659261",
    appId: "1:867083659261:web:3829ad0eb3ab0a2de91b94",
    measurementId: "G-PTDZ7NYTJ8"
  },
  intercomId: "j3zaxu43",
  segmentKey: "g7N4R2jdDpVRXJ3A4XSfHntRyeMF0mAh",
  elasticSearch: "https://e57bdd38412247f5a2e2d197ef167ec0.ap-south-1.aws.elastic-cloud.com:9243",
  elasticSearchAPIKey: "elastic:sgZhW60ZrDB0EsqOAeI7c2TJ",
  firebaseMessagingKey:
    "BAg5bTP0N7z3vmRNf7C_PN8DmCKIei7gM74iDvVQfFwEj4HE9TpJFt-c_OIc_xOAL2GqQnW7j4juca-X40MVydA",
  "patient-profile": baseUrl + "/v1/patient/profile",
  "patient-details": baseUrl + "/v1/patient/details",
  "validate-unique-identifier":
    baseUrl + "/v1/patient/profile/validate-unique-identifier",
  "make-connection": baseUrl + "/v1/patient/profile/make-connection",
  "opd-sms-status": baseUrl + "/v1/appointment/receive-daily-opdsms",
  "book-appointment": baseUrl + "/v2/appointment/book",
  "cancel-appointment": baseUrl + "/v2/appointment/cancel",
  "complete-appointment": baseUrl + "/v2/appointment/complete",
  "end-complete-appointment": baseUrl + "/v2/appointment/end-complete",
  "arrive-appointment": baseUrl + "/v2/appointment/arrive",
  "noshow-appointment": baseUrl + "/v2/appointment/no-show",
  "get-appointment-list": baseUrl + "/v1/get-appointments-list-pdf",
  "send-appointments-remainder":
    baseUrl + "/v1/appointment/send-appointments-reminder-manually",
  "add-to-queue": baseUrl + "/v2/appointment/add-to-queue",
  "reschedule-appointment": baseUrl + "/v2/appointment/reschedule",
  "member-profile": baseUrl + "/v1/member/profile",
  "list-of-available-doctors":
    baseUrl + "/v2/opd-schedule/list-of-available-doctors",
  "fetch-member-schedule": baseUrl + "/v1/opd-schedule/fetch-member-schedule",
  "fetch-member-slots": baseUrl + "/v2/opd-schedule/fetch-member-slots",
  "weekly-slot": baseUrl + "/v1/opd-schedule/weekly-slot",
  "specific-availability-slot":
    baseUrl + "/v1/opd-schedule/specific-availability-slot",
  "specific-unavailability-slot":
    baseUrl + "/v1/opd-schedule/specific-unavailability-slot",
  prescription: baseUrl + "/v1/prescription",
  "prescription-template": baseUrl + "/v1/prescription-template",
  "patient-prescription": baseUrl + "/v1/prescription/get-all-for-patient",
  "voice-command": baseUrl + "/v1/voice/assistant/endpoint",
  "blood-group": baseUrl + "/v1/patient/profile/blood-group",
  "create-patient-bill": baseUrl + "/v1/create-patient-bill",
  "update-patient-bill": baseUrl + "/v1/update-patient-bill",
  "share-prescription-with-patient":
    baseUrl + "/v1/share-prescription-with-patient",
  allergies: baseUrl + "/v1/patient/profile/allergies",
  habits: baseUrl + "/v1/patient/profile/habits",
  "dental-history": baseUrl + "/v1/patient/profile/dental-history",
  "medical-history": baseUrl + "/v1/patient/profile/medical-history",
  "current-medications": baseUrl + "/v1/patient/profile/current-medications",
  "past-history": baseUrl + "/v1/patient/profile/past-history",
  "generate-otp": baseUrl + "/v1/auth/generate-otp",
  "resend-otp": baseUrl + "/v1/auth/resend-otp",
  "verify-otp": baseUrl + "/v1/auth/verify-otp",
  vitals: baseUrl + "/v1/vitals",
  "patient-count": baseUrl + "/v1/dashboard/patient-count",
  "follow-up-list": baseUrl + "/v1/dashboard/follow-up-list",
  "date-of-birth-list": baseUrl + "/v1/dashboard/date-of-birth-list",
  "custom-sms": baseUrl + "/v1/patient-communication/custom-sms",
  "medication-auto-search": baseUrl + "/v1/medication-auto-search",
  "generate-otp-signup": baseUrl + "/v1/auth/generate-otp/signup",
  "verify-otp-signup": baseUrl + "/v1/auth/verify-otp/signup",
  "activate-free-plan": baseUrl + "/v1/organisation/activate-free-plan",
  "quick-note": baseUrl + "/v1/patient/profile/quick-note",
  "remove-from-network": baseUrl + "/v1/patient/profile/remove-connection",
  "consent-form-template": baseUrl + "/v1/consent-form/template",
  "consent-form-category": baseUrl + "/v1/consent-form/category",
  "share-consent-form": baseUrl + "/v1/share-consent-form-with-patient",
  "fetch-all-consent-form-template": baseUrl + "/v1/consent-form/template/all",
  "consent-form": baseUrl + "/v1/consent-form",
  instruction: baseUrl + "/v1/instruction",
  "instruction-category": baseUrl + "/v1/instruction/category",
  "instruction-template": baseUrl + "/v1/instruction/template",
  "share-instruction-form": baseUrl + "/v1/share-instruction-form-with-patient",
  "fetch-all-instruction-template": baseUrl + "/v1/instruction/template/all",
  "fetch-instruction-pdf": baseUrl + "/v1/instruction/generate-pdf",
  "dental-case-history": baseUrl + "/v1/dental/case-history",
  "dental-chart-entry": baseUrl + "/v1/dental/dental-chart-entry",
  "dental-treatment": baseUrl + "/v1/dental/treatment",
  "stats-by-date-range": baseUrl + "/v1/dashboard/stats-by-date-range",
  "send-birthday-sms": baseUrl + "/v1/patient-communication/birthday-sms",
  "token-registration": baseUrl + "/v1/notification/register-token",
  "token-deregistration": baseUrl + "/v1/notification/deregister-token",
  "fetch-member-slots-with-multiple-dates":
    baseUrl + "/v1/opd-schedule/fetch-member-slots/multiple-dates",
  "change-followup-date": baseUrl + "/v1/dashboard/change-followup-date",
  "vital-settings": baseUrl + "/v1/organisation/vital-settings",
  "patient-info-pdf": baseUrl + "/v1/get-patient-info-pdf",
  "get-billing-pdf": baseUrl + "/v1/get-billing-pdf",
  "get-patient-bill": baseUrl + "/v1/get-patient-bill",
  "delete-patient-bill": baseUrl + "/v1/delete-patient-bill",
  "delete-appointment": baseUrl + "/v2/appointment/delete",
  "print-collection-pdf": baseUrl + "/v1/billing/finances/print-collection-pdf",
  "generate-manual-token": baseUrl + "/v1/generate-manual-token",
  "update-appointment-purpose":
    baseUrl + "/v2/appointments/update-appointment-purpose",
  "create-opd-charges": baseUrl + "/v1/organisation/create-opd-charges",
  "update-opd-charges": baseUrl + "/v1/organisation/update-opd-charges",
  "create-procedure-charges":
    baseUrl + "/v1/organisation/create-procedure-charges",
  "update-procedure-charges":
    baseUrl + "/v1/organisation/update-procedure-charges",
  "delete-opd-charges": baseUrl + "/v1/organisation/delete-opd-charges",
  "create-appointment-profile":
    baseUrl + "/v1/organisation/create-appointment-profile",
  "update-appointment-profile":
    baseUrl + "/v1/organisation/update-appointment-profile",
  "delete-appointment-profile":
    baseUrl + "/v1/organisation/delete-appointment-profile",
  "toggle-org-patient-signup-settings":
    baseUrl + "/v1/toggle-org-patient-signup-settings",
  "get-org-patient-signup-settings":
    baseUrl + "/v1/get-org-patient-signup-settings",
  "update-opd-prescription-settings":
    baseUrl + "/v1/organisations/update-opd-prescription-settings",
  "create-start-visit-timestamp":
    baseUrl + "/v1/appointment/create-start-visit-timestamp",
  "create-end-visit-timestamp":
    baseUrl + "/v1/appointment/create-end-visit-timestamp",
  "get-next-appointment": baseUrl + "/v1/appointment/get-next-appointment",
  "get-billing-report": baseUrl + "/v1/billing/finances/get-billing-report",
  "get-missing-cancelled-appointment-report":
    baseUrl + "/v2/appointment/get-missing-cancelled-appointment-report",
  "book-requested-appointment": baseUrl + "/v2/book-requested-appointment",
  "create-order-dynamically": baseUrl + "/v1/cashfree/create-order-dynamically",
  "return-url": baseUrl + "/v1/cashfree/return-order-notify",
  "notify-url": baseUrl + "/v1/cashfree/successful-order-notify",
  "reject-requested-appointment": baseUrl + "/v2/reject-requested-appointment",
  "fetch-details": baseUrl + "/v2/appointment/fetch-details"
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
