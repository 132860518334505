import { BasicModelInterface } from "../BasicModelInterface";
import { ContactNumber } from "../Demographics";
import { ObjectKeys, DiscountType } from "../../constants/object-keys";

export class Billing implements BasicModelInterface {
  private _billingId: string;
  private _receiptNumber: string;
  private _organisationId: string;
  private _organisationName: string;
  private _date: string;
  private _createdOn: number;
  private _createdById: string;
  private _createdByName: string;
  private _receiverId: string;
  private _receiverName: string;
  private _patientId: string;
  private _patientName: string;

  private _patientAge: string;
  private _patientDateOfBirth: string;
  private _patientGender: string;
  private _patientMobileNumber: string;
  private _patientCountryCode: string;
  private _patientEmailAddress: string; //Optional
  private _itemsMap: Map<string, BillingItem> = new Map();
  private _totalAmount: number;
  private _discountType: string = DiscountType.Percentage; //Percent or directAmount, default is precentage (for now)
  private _discountNumber: number; //Percent or direct amount
  private _adjustmentTitle: string = "Adjustments"; //Just the name
  private _adjustmentNumber: number; //Percent or direct amount, can be positive or negative
  private _appointmentId: string;
  private _appointmentDate: string;

  toJSON(): {} {
    this.totalAmount = this.getTotal();
    let json = {};
    if (this.billingId) {
      json[ObjectKeys.billingId] = this.billingId;
    }
    if (this.patientName) {
      json[ObjectKeys.patientName] = this.patientName;
    }
    if (this.receiptNumber) {
      json[ObjectKeys.receiptNumber] = this.receiptNumber;
    }
    if (this.organisationId) {
      json[ObjectKeys.organisationId] = this.organisationId;
    }
    if (this.organisationName) {
      json[ObjectKeys.organisationName] = this.organisationName;
    }
    if (this.date) {
      json[ObjectKeys.date] = this.date;
    }
    if (this.createdOn) {
      json[ObjectKeys.createdOn] = this.createdOn;
    }
    if (this.createdById) {
      json[ObjectKeys.createdById] = this.createdById;
    }
    if (this.createdByName) {
      json[ObjectKeys.createdByName] = this.createdByName;
    }
    if (this.receiverId) {
      json[ObjectKeys.receiverId] = this.receiverId;
    }
    if (this.receiverName) {
      json[ObjectKeys.receiverName] = this.receiverName;
    }
    if (this.patientId) {
      json[ObjectKeys.patientId] = this.patientId;
    }
    if (this.patientAge) {
      json[ObjectKeys.patientAge] = this.patientAge;
    }
    if (this.patientDateOfBirth) {
      json[ObjectKeys.patientDateOfBirth] = this.patientDateOfBirth;
    }
    if (this.patientGender) {
      json[ObjectKeys.patientGender] = this.patientGender;
    }
    if (this.patientCountryCode) {
      json[ObjectKeys.patientCountryCode] = this.patientCountryCode;
    }
    if (this.patientMobileNumber) {
      json[ObjectKeys.patientMobileNumber] = this.patientMobileNumber;
    }
    if (this.totalAmount != null) {
      json[ObjectKeys.totalAmount] = this.totalAmount;
    }
    if (this.discountType) {
      json[ObjectKeys.discountType] = this.discountType;
    }
    if (this.discountNumber) {
      json[ObjectKeys.discountNumber] = this.discountNumber;
    }
    if (this.adjustmentNumber) {
      json[ObjectKeys.adjustmentNumber] = this.adjustmentNumber;
    }
    if (this.adjustmentTitle) {
      json[ObjectKeys.adjustmentTitle] = this.adjustmentTitle;
    }
    if (this.appointmentId) {
      json[ObjectKeys.appointmentId] = this.appointmentId;
    }
    if (this.appointmentDate) {
      json["appointmentDate"] = this.appointmentDate;
    }
    if (this.itemsMap) {
      let itemsJSON = {};
      this.itemsMap.forEach((val, key) => {
        itemsJSON[key] = val.toJSON();
      });
      json[ObjectKeys.itemsMap] = itemsJSON;
    }
    return json;
  }

  initFromJSON(json: {}): void {
    if (json[ObjectKeys.billingId]) {
      this.billingId = json[ObjectKeys.billingId];
    }
    if (json[ObjectKeys.patientName]) {
      this.patientName = json[ObjectKeys.patientName];
    }
    if (json[ObjectKeys.appointmentId]) {
      this.appointmentId = json[ObjectKeys.appointmentId];
    }
    if (json["appointmentDate"]) {
      this.appointmentDate = json["appointmentDate"];
    }
    if (json[ObjectKeys.receiptNumber]) {
      this.receiptNumber = json[ObjectKeys.receiptNumber];
    }
    if (json[ObjectKeys.organisationId]) {
      this.organisationId = json[ObjectKeys.organisationId];
    }
    if (json[ObjectKeys.organisationName]) {
      this.organisationName = json[ObjectKeys.organisationName];
    }
    if (json[ObjectKeys.date]) {
      this.date = json[ObjectKeys.date];
    }
    if (json[ObjectKeys.createdOn]) {
      this.createdOn = json[ObjectKeys.createdOn];
    }
    if (json[ObjectKeys.createdByName]) {
      this.createdByName = json[ObjectKeys.createdByName];
    }
    if (json[ObjectKeys.createdById]) {
      this.createdById = json[ObjectKeys.createdById];
    }
    if (json[ObjectKeys.receiverId]) {
      this.receiverId = json[ObjectKeys.receiverId];
    }
    if (json[ObjectKeys.receiverName]) {
      this.receiverName = json[ObjectKeys.receiverName];
    }
    if (json[ObjectKeys.patientId]) {
      this.patientId = json[ObjectKeys.patientId];
    }
    if (json[ObjectKeys.patientAge]) {
      this.patientAge = json[ObjectKeys.patientAge];
    }
    if (json[ObjectKeys.patientDateOfBirth]) {
      this.patientDateOfBirth = json[ObjectKeys.patientDateOfBirth];
    }
    if (json[ObjectKeys.patientGender]) {
      this.patientGender = json[ObjectKeys.patientGender];
    }
    if (json[ObjectKeys.patientMobileNumber]) {
      this.patientMobileNumber = json[ObjectKeys.patientMobileNumber];
    }
    if (json[ObjectKeys.patientCountryCode]) {
      this.patientCountryCode = json[ObjectKeys.patientCountryCode];
    }
    if (json[ObjectKeys.patientEmailAddress]) {
      this.patientEmailAddress = json[ObjectKeys.patientEmailAddress];
    }
    if (json[ObjectKeys.totalAmount]) {
      this.totalAmount = json[ObjectKeys.totalAmount];
    }
    if (json[ObjectKeys.itemsMap]) {
      for (let key in json[ObjectKeys.itemsMap]) {
        if (
          json[ObjectKeys.itemsMap][key] &&
          typeof json[ObjectKeys.itemsMap][key] == "object"
        ) {
          let item = new BillingItem();
          item.initFromJSON(json[ObjectKeys.itemsMap][key]);
          this.itemsMap.set(key, item);
        }
      }
    }
  }

  public getDiscount(): number {
    var d = 0;
    if (this.discountType && this.discountNumber) {
      if (this.discountType === "DirectAmount") {
        d = this.discountNumber;
      } else {
        d = (this.discountNumber * this.getSubTotal()) / 100;
      }
    }
    return d;
  }

  public getSubTotal(): number {
    let i = 0;
    this.itemsMap.forEach((val, key) => {
      i += val.quantity * val.price;
    });
    return i;
  }

  public getSubTotalForOPD(): number {
    let i = 0;
    this.itemsMap.forEach((val, key) => {
      if (val.billingType == null || val.billingType == "opd") {
        i += val.quantity * val.price;
      }
    });
    return i;
  }

  public getSubTotalForProcedure(): number {
    let i = 0;
    this.itemsMap.forEach((val, key) => {
      if (val.billingType != null && val.billingType == "procedure") {
        i += val.quantity * val.price;
      }
    });
    return i;
  }

  public getTotal(): number {
    let i = 0;
    let subtotal = this.getSubTotal();
    i += subtotal;
    if (this.adjustmentNumber) {
      i += this.adjustmentNumber;
    }
    if (this.discountNumber && this.discountType) {
      if (this.discountType === "Percentage") {
        i -= (subtotal * this.discountNumber) / 100;
      } else {
        i -= this.discountNumber;
      }
    }
    return i;
  }

  /**
   * Getter appointmentDate
   * @return {string}
   */
  public get appointmentDate(): string {
    return this._appointmentDate;
  }

  /**
   * Setter appointmentDate
   * @param {string} value
   */
  public set appointmentDate(value: string) {
    this._appointmentDate = value;
  }

  /**
   * Getter adjustmentTitle
   * @return {string}
   */
  public get adjustmentTitle(): string {
    return this._adjustmentTitle;
  }

  /**
   * Setter adjustmentTitle
   * @param {string} value
   */
  public set adjustmentTitle(value: string) {
    this._adjustmentTitle = value;
  }

  /**
   * Getter adjustmentNumber
   * @return {number}
   */
  public get adjustmentNumber(): number {
    return this._adjustmentNumber;
  }

  /**
   * Setter adjustmentNumber
   * @param {number} value
   */
  public set adjustmentNumber(value: number) {
    this._adjustmentNumber = value;
  }

  /**
   * Getter discountType
   * @return {string}
   */
  public get discountType(): string {
    return this._discountType;
  }

  /**
   * Setter discountType
   * @param {string} value
   */
  public set discountType(value: string) {
    this._discountType = value;
  }

  /**
   * Getter discountNumber
   * @return {number}
   */
  public get discountNumber(): number {
    return this._discountNumber;
  }

  /**
   * Setter discountNumber
   * @param {number} value
   */
  public set discountNumber(value: number) {
    this._discountNumber = +value;
  }

  /**
   * Getter totalAmount
   * @return {number}
   */
  public get totalAmount(): number {
    return this._totalAmount;
  }

  /**
   * Setter totalAmount
   * @param {number} value
   */
  public set totalAmount(value: number) {
    this._totalAmount = value;
  }

  /**
   * Getter receiptNumber
   * @return {string}
   */
  public get receiptNumber(): string {
    return this._receiptNumber;
  }

  /**
   * Setter receiptNumber
   * @param {string} value
   */
  public set receiptNumber(value: string) {
    this._receiptNumber = value;
  }

  /**
   * Getter billingId
   * @return {string}
   */
  public get billingId(): string {
    return this._billingId;
  }

  /**
   * Setter billingId
   * @param {string} value
   */
  public set billingId(value: string) {
    this._billingId = value;
  }

  /**
   * Getter organisationId
   * @return {string}
   */
  public get organisationId(): string {
    return this._organisationId;
  }

  /**
   * Getter organisationName
   * @return {string}
   */
  public get organisationName(): string {
    return this._organisationName;
  }

  /**
   * Getter date
   * @return {string}
   */
  public get date(): string {
    return this._date;
  }

  /**
   * Getter createdOn
   * @return {number}
   */
  public get createdOn(): number {
    return this._createdOn;
  }

  /**
   * Getter createdById
   * @return {string}
   */
  public get createdById(): string {
    return this._createdById;
  }

  /**
   * Getter createdByName
   * @return {string}
   */
  public get createdByName(): string {
    return this._createdByName;
  }

  /**
   * Getter receiverId
   * @return {string}
   */
  public get receiverId(): string {
    return this._receiverId;
  }

  /**
   * Getter receiverName
   * @return {string}
   */
  public get receiverName(): string {
    return this._receiverName;
  }

  /**
   * Getter patientId
   * @return {string}
   */
  public get patientId(): string {
    return this._patientId;
  }

  /**
   * Getter patientAge
   * @return {string}
   */
  public get patientAge(): string {
    return this._patientAge;
  }

  /**
   * Getter patientDateOfBirth
   * @return {string}
   */
  public get patientDateOfBirth(): string {
    return this._patientDateOfBirth;
  }

  /**
   * Getter patientGender
   * @return {string}
   */
  public get patientGender(): string {
    return this._patientGender;
  }

  /**
   * Getter patientMobileNumber
   * @return {string}
   */
  public get patientMobileNumber(): string {
    return this._patientMobileNumber;
  }

  /**
   * Getter patientCountryCode
   * @return {string}
   */
  public get patientCountryCode(): string {
    return this._patientCountryCode;
  }

  /**
   * Getter patientEmailAddress
   * @return {string}
   */
  public get patientEmailAddress(): string {
    return this._patientEmailAddress;
  }

  /**
   * Getter itemsMap
   * @return {Map<string, BillingItem> }
   */
  public get itemsMap(): Map<string, BillingItem> {
    return this._itemsMap;
  }

  /**
   * Setter organisationId
   * @param {string} value
   */
  public set organisationId(value: string) {
    this._organisationId = value;
  }

  /**
   * Setter organisationName
   * @param {string} value
   */
  public set organisationName(value: string) {
    this._organisationName = value;
  }

  /**
   * Setter date
   * @param {string} value
   */
  public set date(value: string) {
    this._date = value;
  }

  /**
   * Setter createdOn
   * @param {number} value
   */
  public set createdOn(value: number) {
    this._createdOn = value;
  }

  /**
   * Setter createdById
   * @param {string} value
   */
  public set createdById(value: string) {
    this._createdById = value;
  }

  /**
   * Setter createdByName
   * @param {string} value
   */
  public set createdByName(value: string) {
    this._createdByName = value;
  }

  /**
   * Setter receiverId
   * @param {string} value
   */
  public set receiverId(value: string) {
    this._receiverId = value;
  }

  /**
   * Setter receiverName
   * @param {string} value
   */
  public set receiverName(value: string) {
    this._receiverName = value;
  }

  /**
   * Setter patientId
   * @param {string} value
   */
  public set patientId(value: string) {
    this._patientId = value;
  }

  /**
   * Setter patientAge
   * @param {string} value
   */
  public set patientAge(value: string) {
    this._patientAge = value;
  }

  /**
   * Setter patientDateOfBirth
   * @param {string} value
   */
  public set patientDateOfBirth(value: string) {
    this._patientDateOfBirth = value;
  }

  /**
   * Setter patientGender
   * @param {string} value
   */
  public set patientGender(value: string) {
    this._patientGender = value;
  }

  /**
   * Setter patientMobileNumber
   * @param {string} value
   */
  public set patientMobileNumber(value: string) {
    this._patientMobileNumber = value;
  }

  /**
   * Setter patientCountryCode
   * @param {string} value
   */
  public set patientCountryCode(value: string) {
    this._patientCountryCode = value;
  }

  /**
   * Setter patientEmailAddress
   * @param {string} value
   */
  public set patientEmailAddress(value: string) {
    this._patientEmailAddress = value;
  }

  /**
   * Setter itemsMap
   * @param {Map<string, BillingItem> } value
   */
  public set itemsMap(value: Map<string, BillingItem>) {
    this._itemsMap = value;
  }

  /**
   * Getter appointmentId
   * @return {string}
   */
  public get appointmentId(): string {
    return this._appointmentId;
  }

  /**
   * Setter appointmentId
   * @param {string} value
   */
  public set appointmentId(value: string) {
    this._appointmentId = value;
  }

  /**
   * Getter patientName
   * @return {string}
   */
  public get patientName(): string {
    return this._patientName;
  }

  /**
   * Setter patientName
   * @param {string} value
   */
  public set patientName(value: string) {
    this._patientName = value;
  }
}

export class BillingItem implements BasicModelInterface {
  private _billingItemId: string;
  private _title: string;
  private _description: string; //Optional
  private _quantity: number; //Clarification: Int and float both are allowed
  private _price: number; //Clarification: Int and float both are allowed
  private _serviceValidityTimestamp: number; //Optional
  private _serviceQuantity: number; //Optional
  private _serviceId: string; //Optional
  private _doctorId: string; //Optional
  private _doctorName: string; //Optional
  private _appointmentId: string; //Optional

  private _billingType: string; //opd or procedure billing

  toJSON(): {} {
    let json = {};
    if (this.billingItemId) {
      json[ObjectKeys.billingItemId] = this.billingItemId;
    }
    if (this.title) {
      json[ObjectKeys.title] = this.title;
    }
    if (this.description) {
      json[ObjectKeys.description] = this.description;
    }
    if (this.quantity) {
      json[ObjectKeys.quantity] = this.quantity;
    }
    if (this.price) {
      json[ObjectKeys.price] = +this.price;
    }
    if (this.serviceValidityTimestamp) {
      json[ObjectKeys.serviceValidityTimestamp] = this.serviceValidityTimestamp;
    }
    if (this.serviceQuantity) {
      json[ObjectKeys.serviceQuantity] = this.serviceQuantity;
    }
    if (this.serviceId) {
      json[ObjectKeys.serviceId] = this.serviceId;
    }
    if (this.doctorId) {
      json[ObjectKeys.doctorId] = this.doctorId;
    }
    if (this.doctorName) {
      json[ObjectKeys.doctorName] = this.doctorName;
    }
    if (this.appointmentId) {
      json[ObjectKeys.appointmentId] = this.appointmentId;
    }
    if (this.billingType) {
      json[ObjectKeys.billingType] = this.billingType;
    }
    return json;
  }
  initFromJSON(json: {}): void {
    if (json[ObjectKeys.billingItemId]) {
      this.billingItemId = json[ObjectKeys.billingItemId];
    }
    if (json[ObjectKeys.billingType]) {
      this.billingType = json[ObjectKeys.billingType];
    }
    if (json[ObjectKeys.title]) {
      this.title = json[ObjectKeys.title];
    }
    if (json[ObjectKeys.description]) {
      this.description = json[ObjectKeys.description];
    }
    if (json[ObjectKeys.quantity]) {
      this.quantity = json[ObjectKeys.quantity];
    }
    if (json[ObjectKeys.price]) {
      this.price = +json[ObjectKeys.price];
    }
    if (json[ObjectKeys.serviceValidityTimestamp]) {
      this.serviceValidityTimestamp = json[ObjectKeys.serviceValidityTimestamp];
    }
    if (json[ObjectKeys.serviceQuantity]) {
      this.serviceQuantity = json[ObjectKeys.serviceQuantity];
    }
    if (json[ObjectKeys.serviceId]) {
      this.serviceId = json[ObjectKeys.serviceId];
    }
    if (json[ObjectKeys.doctorId]) {
      this.doctorId = json[ObjectKeys.doctorId];
    }
    if (json[ObjectKeys.doctorName]) {
      this.doctorName = json[ObjectKeys.doctorName];
    }
    if (json[ObjectKeys.appointmentId]) {
      this.appointmentId = json[ObjectKeys.appointmentId];
    }
    if (json[ObjectKeys.appointmentId]) {
      this.appointmentId = json[ObjectKeys.appointmentId];
    }
  }

  public getAmount() {
    if (this.quantity && this.price) {
      return this.quantity * this.price;
    } else {
      return 0;
    }
  }

  public isTypeProcedure() {
    return this.billingType && this.billingType == "procedure";
  }

  public isTypeOPD() {
    return this.billingType == null || this.billingType == "opd";
  }

  /**
   * Getter title
   * @return {string}
   */
  public get title(): string {
    return this._title;
  }

  /**
   * Getter description
   * @return {string}
   */
  public get description(): string {
    return this._description;
  }

  /**
   * Getter quantity
   * @return {number}
   */
  public get quantity(): number {
    return this._quantity;
  }

  /**
   * Getter price
   * @return {number}
   */
  public get price(): number {
    return this._price;
  }

  /**
   * Getter serviceValidityTimestamp
   * @return {number}
   */
  public get serviceValidityTimestamp(): number {
    return this._serviceValidityTimestamp;
  }

  /**
   * Getter serviceQuantity
   * @return {number}
   */
  public get serviceQuantity(): number {
    return this._serviceQuantity;
  }

  /**
   * Getter serviceId
   * @return {string}
   */
  public get serviceId(): string {
    return this._serviceId;
  }

  /**
   * Getter doctorId
   * @return {string}
   */
  public get doctorId(): string {
    return this._doctorId;
  }

  /**
   * Getter doctorName
   * @return {string}
   */
  public get doctorName(): string {
    return this._doctorName;
  }

  /**
   * Getter appointmentId
   * @return {string}
   */
  public get appointmentId(): string {
    return this._appointmentId;
  }

  /**
   * Setter title
   * @param {string} value
   */
  public set title(value: string) {
    this._title = value;
  }

  /**
   * Setter description
   * @param {string} value
   */
  public set description(value: string) {
    this._description = value;
  }

  /**
   * Setter quantity
   * @param {number} value
   */
  public set quantity(value: number) {
    this._quantity = value;
  }

  /**
   * Setter price
   * @param {number} value
   */
  public set price(value: number) {
    this._price = value;
  }

  /**
   * Setter serviceValidityTimestamp
   * @param {number} value
   */
  public set serviceValidityTimestamp(value: number) {
    this._serviceValidityTimestamp = value;
  }

  /**
   * Setter serviceQuantity
   * @param {number} value
   */
  public set serviceQuantity(value: number) {
    this._serviceQuantity = value;
  }

  /**
   * Setter serviceId
   * @param {string} value
   */
  public set serviceId(value: string) {
    this._serviceId = value;
  }

  /**
   * Setter doctorId
   * @param {string} value
   */
  public set doctorId(value: string) {
    this._doctorId = value;
  }

  /**
   * Setter doctorName
   * @param {string} value
   */
  public set doctorName(value: string) {
    this._doctorName = value;
  }

  /**
   * Setter appointmentId
   * @param {string} value
   */
  public set appointmentId(value: string) {
    this._appointmentId = value;
  }

  /**
   * Getter billingItemId
   * @return {string}
   */
  public get billingItemId(): string {
    return this._billingItemId;
  }

  /**
   * Setter billingItemId
   * @param {string} value
   */
  public set billingItemId(value: string) {
    this._billingItemId = value;
  }

  /**
   * Getter billingType
   * @return {string}
   */
  public get billingType(): string {
    return this._billingType;
  }

  /**
   * Setter billingType
   * @param {string} value
   */
  public set billingType(value: string) {
    this._billingType = value;
  }
}
