export class VitalMeasurementConstants {
  public static BLOOD_GLUCOSE_UNIT = "mg/dL";
  public static BLOOD_PRESSURE_UNIT = "mm Hg";
  public static PULSE_UNIT = "bpm";
  public static WEIGHT_UNIT = "kg";
  public static HEIGHT_UNIT = "cm";
  public static BMI_UNIT = "kg/m" + "2".sup();
  public static TEMPERATURE_UNIT = "\xB0F";
  public static SPO2_UNIT = "%";
  public static RESPERATORY_RATE_UNIT = "b/min"; //Breath per minute
  public static BP_STANDING = "standing";
  public static BP_SITTING = "sitting";
  public static BP_SUPINE = "supine";

  public static VITAL_TYPE_BLOOD_GLUCOSE = "bg";
  public static VITAL_TYPE_BLOOD_PRESSURE = "bp";
  public static VITAL_TYPE_PULSE = "pulse";
  public static VITAL_TYPE_TEMPERATURE = "temperature";
  public static VITAL_TYPE_HEIGHT = "height";
  public static VITAL_TYPE_WEIGHT = "weight";
  public static VITAL_TYPE_SPO2 = "spo2";
  public static VITAL_TYPE_BMI = "bmi";
  public static VITAL_TYPE_PAIN = "pain";
  public static VITAL_TYPE_RESPERATORY_RATE = "resperatory_rate";

  public static FASTING = "fasting";
  public static BEFORE_BREAKFAST = "before_breakfast";
  public static BEFORE_LUNCH = "before_lunch";
  public static BEFORE_DINNER = "before_dinner";
  public static AFTER_BREAKFAST = "after_breakfast";
  public static AFTER_LUNCH = "after_lunch";
  public static AFTER_DINNER = "after_dinner";
  public static BEDTIME = "bedtime";
  public static RANDOM = "random";

  public static SITTING = "sitting";
  public static STANDING = "standing";
  public static SUPINE = "supine";

  public static BLOOD_GLOCOSE_TIMINGS = [
    {
      label: "Random",
      value: "random"
    },
    {
      label: "Fasting",
      value: "fasting"
    },
    {
      label: "After Breakfast",
      value: "after_breakfast"
    },
    {
      label: "After Lunch",
      value: "after_lunch"
    },
    {
      label: "After Dinner",
      value: "after_dinner"
    },
    {
      label: "Before Breakfast",
      value: "before_breakfast"
    },
    {
      label: "Before Lunch",
      value: "before_lunch"
    },
    {
      label: "Before Dinner",
      value: "before_dinner"
    },
    {
      label: "Bedtime",
      value: "bedtime"
    }
  ];

  public static BLOOD_PRESSURE_POSITIONS = [
    { label: "Sitting", value: "sitting" },
    {
      label: "Standing",
      value: "standing"
    },
    {
      label: "Supine",
      value: "supine"
    }
  ];

  public static PAIN_VITAL_OPTIONS = [
    {
      label: "None",
      value: "0"
    },
    {
      label: "Mild",
      value: "1"
    },
    {
      label: "Moderate",
      value: "2"
    },
    {
      label: "Severe",
      value: "3"
    }
  ];
}
