import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import { Medication } from "src/app/shared/models/Medication/Medication";
import { MedicationAutoSearchService } from "src/app/shared/services/medicationAutoSearch/medication-auto-search.service";

@Component({
  selector: "app-medication-search",
  templateUrl: "./medication-search.component.html",
  styleUrls: ["./medication-search.component.css"]
})
export class MedicationSearchComponent implements OnInit {
  @Input() dosageForm: string;
  @Input() searchText: string = "";
  @Input() placeHolder: string = "Search...";
  @Input() searchUsing: "brand" | "composition" | "both";
  @Input() inputType: "input" | "text-area";
  selectedMedicationObject: Medication;
  // @Input() requestToClear: boolean = false;
  @Output() selectedMedication = new EventEmitter<{
    nameOrComposition: boolean;
    selectedMedication: Medication;
  }>();
  @Output() textWhenClickedOutside = new EventEmitter<string>();
  @Output() setSearchTextAsComposition = new EventEmitter<string>();
  @Output() setSearchTextAsBrand = new EventEmitter<string>();
  // @Output() clearSuccessful = new EventEmitter<boolean>();

  noSearchResults: boolean = false;

  searchingMedication: boolean;
  searchResultMedication: Array<Medication>;

  showSearchResults: boolean = false;

  @ViewChild("searchTextInputElement", { static: false }) searchTextInputElement: ElementRef;

  constructor(
    private _medicationAutoSearchService: MedicationAutoSearchService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    // console.log("Input Changes");
    if (
      !this.selectedMedicationObject &&
      this.searchText &&
      this.searchText.length > 0
    ) {
      this.searchMedication();
    } else if (
      this.selectedMedicationObject &&
      this.searchText != this.selectedMedicationObject.composition &&
      this.searchText &&
      this.searchText.length > 0
    ) {
      this.searchMedication();
    } else if (!this.dosageForm) {
      this.searchText = undefined;
    }
  }

  showNameOrComposition: boolean = true; // true - name, false - composition

  brandSearchResults: Array<Medication> = [];
  compositionSearchResults: Array<Medication> = [];

  clickDetectedOutside() {
    // console.log("Click Detected Outside");
    if (this.showSearchResults) {
      this.textWhenClickedOutside.emit(this.searchText);
      setTimeout(() => {
        this.showSearchResults = false;
      }, 110);
    }
  }

  clickedOnMedicationCellAction(selectedMed: {
    nameOrComposition: boolean;
    selectedMedication: Medication;
  }) {
    let medication = selectedMed.selectedMedication;
    this.showNameOrComposition = selectedMed.nameOrComposition;

    if (this.showNameOrComposition) {
      // Name
      this.searchText = medication.name;
    } else {
      // Composition
      this.searchText = medication.composition;
    }
    this.selectedMedication.emit({
      nameOrComposition: this.showNameOrComposition,
      selectedMedication: medication
    });
    this.selectedMedicationObject = medication;

    setTimeout(() => {
      this.showSearchResults = false;
    }, 110);
  }

  cellClickAction(medication: Medication) {
    if (this.showNameOrComposition) {
      // Name
      this.searchText = medication.name;
    } else {
      // Composition
      this.searchText = medication.composition;
    }
    this.selectedMedication.emit({
      nameOrComposition: this.showNameOrComposition,
      selectedMedication: medication
    });
    this.selectedMedicationObject = medication;

    setTimeout(() => {
      this.showSearchResults = false;
    }, 110);
  }

  emptyPreviousResults() {
    this.searchResultMedication = [];
    this.brandSearchResults = [];
    this.compositionSearchResults = [];
  }

  searchMedication() {
    // this.focusedPatientIdx = 0;
    this.searchingMedication = true;
    let queryString = this.searchText;
    if (this.dosageForm && this.dosageForm.length > 0) {
      queryString += " " + this.dosageForm;
    }
    if (queryString && queryString.length > 1) {
      this.showSearchResults = true;
      this._medicationAutoSearchService
        .medicineTextSearch(queryString)
        .then(response => {
          this.searchingMedication = false;
          this.emptyPreviousResults();
          if (response.hits.hits) {
            this.noSearchResults = false;
            let uniqueMedicineSearchResultsMap: Map<
              string,
              Medication
            > = new Map();
            let uniqueMedicineBrandSearchResultsMap: Map<
              string,
              Medication
            > = new Map();
            this.searchResultMedication = response.hits.hits.map(hit => {
              var thisMedicine: Medication = new Medication();
              thisMedicine.initFromJSON(hit._source);
              if (
                thisMedicine.name &&
                thisMedicine.dosageForm &&
                !uniqueMedicineBrandSearchResultsMap.has(
                  thisMedicine.dosageForm + thisMedicine.name
                )
              ) {
                uniqueMedicineBrandSearchResultsMap.set(
                  thisMedicine.dosageForm + thisMedicine.name,
                  thisMedicine
                );
              }
              uniqueMedicineSearchResultsMap.set(
                thisMedicine.dosageForm + thisMedicine.composition,
                thisMedicine
              );
              return thisMedicine;
            });
            console.log(
              "Medication brand map:",
              uniqueMedicineBrandSearchResultsMap
            );

            //TODO: comment this after testing
            console.log(
              "Medication composition map:",
              uniqueMedicineSearchResultsMap
            ); //TODO: comment this after testing

            this.brandSearchResults = Array.from(
              uniqueMedicineBrandSearchResultsMap.values()
            );
            this.compositionSearchResults = Array.from(
              uniqueMedicineSearchResultsMap.values()
            );
            // this.searchResultMedication = Array.from(
            //   uniqueMedicineSearchResultsMap.values()
            // );
          } else {
            this.noSearchResults = true;
            this.emptyPreviousResults();
          }
        });
      // switch(this.searchUsing){
      //   case 'brand':
      //     {

      //       break;
      //     }
      //   case "composition":{
      //     this._medicationAutoSearchService.medicineTextSearch(queryString).then(response => {
      //       this.searchingMedication = false;
      //       this.searchResultMedication = [];
      //       if (response.hits.hits) {
      //         this.noSearchResults = false;
      //         let uniqueMedicineSearchResultsMap: Map<
      //           string,
      //           Medication
      //         > = new Map();
      //         this.searchResultMedication = response.hits.hits.map(hit => {
      //           var thisMedicine: Medication = new Medication();
      //           thisMedicine.initFromJSON(hit._source);
      //           uniqueMedicineSearchResultsMap.set(
      //             thisMedicine.dosageForm + thisMedicine.composition,
      //             thisMedicine
      //           );
      //           return thisMedicine;
      //         });
      //         this.searchResultMedication = Array.from(
      //           uniqueMedicineSearchResultsMap.values()
      //         );
      //       } else {
      //         this.noSearchResults = true;
      //         this.searchResultMedication = [];
      //       }
      //     });
      //     break;
      //   }
      //   case "both":{
      //     this._medicationAutoSearchService.medicineTextSearch(queryString).then(response => {
      //       this.searchingMedication = false;
      //       this.searchResultMedication = [];
      //       if (response.hits.hits) {
      //         this.noSearchResults = false;
      //         let uniqueMedicineSearchResultsMap: Map<
      //           string,
      //           Medication
      //         > = new Map();
      //         this.searchResultMedication = response.hits.hits.map(hit => {
      //           var thisMedicine: Medication = new Medication();
      //           thisMedicine.initFromJSON(hit._source);
      //           uniqueMedicineSearchResultsMap.set(
      //             thisMedicine.dosageForm + thisMedicine.composition,
      //             thisMedicine
      //           );
      //           return thisMedicine;
      //         });
      //         this.searchResultMedication = Array.from(
      //           uniqueMedicineSearchResultsMap.values()
      //         );
      //       } else {
      //         this.noSearchResults = true;
      //         this.searchResultMedication = [];
      //       }
      //     });
      //     break;
      //   }
      // }
    }
  }

  enterPressedInSearchTextArea() {
    console.log("Search Text on Enter Press: ", this.searchText);
    this.searchText.trim();
    this.returnSearchTextAsComposition();
  }

  returnSearchTextAsComposition() {
    this.showSearchResults = false;
    this.setSearchTextAsComposition.emit(this.searchText);
  }
}
