import { BasicModelInterface } from "../BasicModelInterface";
import { Diagnosis } from "./Diagnosis";
import { Medication } from "../Medication/Medication";
import { ObjectKeys } from "../../constants/object-keys";
import { LatestVitalSnapshot } from "../Vitals/Latest Vital Snapshot";
import { VitalMeasurement } from "../Vitals/Vital Measurement";
import { VitalMeasurementConstants } from "../Vitals/Vital Measurement Constants";

export class Prescription implements BasicModelInterface {
  private _id: string;
  private _organisationId: string;
  private _organisationName: string;
  private _title: string;
  private _prescribedById: string;
  private _prescribedByName: string;
  private _prescribedByDesignation: string;
  private _instructions: string;
  private _patientId: string;
  private _patientName: string;
  private _patientGender: string;
  private _patientAge: number;
  private _patientAgeObject: {} = {};
  private _prescriptionNumber: string;
  private _status: number;
  private _createdOn: number;
  private _updatedOn: number;
  private _diagnosis: Array<Diagnosis> = new Array();
  private _medicines: Map<string, Medication> = new Map();
  private _spoken_draft_medications: Map<string, Medication> = new Map();
  private _remarks: string;
  private _dispenseStatus: number;
  private _date: string;
  private _follow_up_date: string;
  private _latestVitalSnapshot: LatestVitalSnapshot = new LatestVitalSnapshot();
  private _signature: string;
  //Needed to show the number of medicines at frontend
  private _numberOfMedicines: number;

  constructor() {
    this._dispenseStatus = -1;
  }

  toJSON(): {} {
    let json = {};
    if (this.id) {
      json[ObjectKeys.id] = this.id;
    }
    if (this.organisationId) {
      json[ObjectKeys.organisationId] = this.organisationId;
    }
    if (this.organisationName) {
      json[ObjectKeys.organisationName] = this.organisationName;
    }
    if (this.title) {
      json[ObjectKeys.title] = this.title;
    }
    if (this.prescribedById) {
      json[ObjectKeys.prescribedById] = this.prescribedById;
    }
    if (this.prescribedByName) {
      json[ObjectKeys.prescribedByName] = this.prescribedByName;
    }
    if (this.prescribedByDesignation) {
      json[ObjectKeys.prescribedByDesignation] = this.prescribedByDesignation;
    }
    if (this.instructions) {
      json[ObjectKeys.instructions] = this.instructions;
    }
    if (this.patientId) {
      json[ObjectKeys.patientId] = this.patientId;
    }
    if (this.patientName) {
      json[ObjectKeys.patientName] = this.patientName;
    }
    if (this.patientGender) {
      json[ObjectKeys.patientGender] = this.patientGender;
    }
    if (this.patientAge) {
      json[ObjectKeys.patientAge] = this.patientAge;
    }
    if (this.patientAgeObject) {
      json[ObjectKeys.patientAgeObject] = this.patientAgeObject;
    }
    if (this.prescriptionNumber) {
      json[ObjectKeys.prescriptionNumber] = this.prescriptionNumber;
    }
    if (this.status) {
      json[ObjectKeys.status] = this.status;
    }
    if (this.createdOn) {
      json[ObjectKeys.createdOn] = this.createdOn;
    }
    if (this.updatedOn) {
      json[ObjectKeys.updatedOn] = this.updatedOn;
    }
    if (this.diagnosis) {
      let temp = [];
      this.diagnosis.forEach(val => {
        temp.push(val.toJSON());
      });
      json[ObjectKeys.diagnosis] = temp;
    }
    if (this.medicines) {
      let temp = {};
      this.medicines.forEach((val, key) => {
        temp[key] = val.toJSON();
      });
      json[ObjectKeys.medicines] = temp;
    }
    if (this.spoken_draft_medications) {
      let temp = {};
      this.spoken_draft_medications.forEach((val, key) => {
        temp[key] = val.toJSON();
      });
      json[ObjectKeys.spoken_draft_medications] = temp;
    }
    if (this.remarks) {
      json[ObjectKeys.remarks] = this.remarks;
    }
    if (this.dispenseStatus) {
      json[ObjectKeys.dispenseStatus] = this.dispenseStatus;
    }
    if (this.date) {
      json[ObjectKeys.date] = this.date;
    }
    if (this.follow_up_date) {
      json[ObjectKeys.follow_up_date] = this.follow_up_date;
    }
    if (this.latestVitalSnapshot && this.latestVitalSnapshot.isValidForPush()) {
      json[ObjectKeys.vitalInfo] = this.latestVitalSnapshot.pushVitalArray();
    }
    if(this.signature){
      json[ObjectKeys.signature] = this.signature;
    }
    return json;
  }
  initFromJSON(json: {}): void {
    if (json[ObjectKeys.id]) {
      this.id = json[ObjectKeys.id];
    }
    if (json[ObjectKeys.organisationId]) {
      this.organisationId = json[ObjectKeys.organisationId];
    }
    if (json[ObjectKeys.organisationName]) {
      this.organisationName = json[ObjectKeys.organisationName];
    }
    if (json[ObjectKeys.title]) {
      this.title = json[ObjectKeys.title];
    }
    if (json[ObjectKeys.prescribedById]) {
      this.prescribedById = json[ObjectKeys.prescribedById];
    }
    if (json[ObjectKeys.prescribedByName]) {
      this.prescribedByName = json[ObjectKeys.prescribedByName];
    }
    if (json[ObjectKeys.prescribedByDesignation]) {
      this.prescribedByDesignation = json[ObjectKeys.prescribedByDesignation];
    }
    if (json[ObjectKeys.instructions]) {
      this.instructions = json[ObjectKeys.instructions];
    }
    if (json[ObjectKeys.patientId]) {
      this.patientId = json[ObjectKeys.patientId];
    }
    if (json[ObjectKeys.patientName]) {
      this.patientName = json[ObjectKeys.patientName];
    }
    if (json[ObjectKeys.patientGender]) {
      this.patientGender = json[ObjectKeys.patientGender];
    }
    if (json[ObjectKeys.patientAge]) {
      this.patientAge = json[ObjectKeys.patientAge];
    }
    if(json[ObjectKeys.patientAgeObject]) {
      this.patientAgeObject = json[ObjectKeys.patientAgeObject];
    }
    if (json[ObjectKeys.prescriptionNumber]) {
      this.prescriptionNumber = json[ObjectKeys.prescriptionNumber];
    }
    if (json[ObjectKeys.status]) {
      this.status = json[ObjectKeys.status];
    }
    if (json[ObjectKeys.createdOn]) {
      this.createdOn = json[ObjectKeys.createdOn];
    }
    if (json[ObjectKeys.updatedOn]) {
      this.updatedOn = json[ObjectKeys.updatedOn];
    }
    if(json[ObjectKeys.signature]){
      this.signature = json[ObjectKeys.signature];
    }
    if (json[ObjectKeys.diagnosis]) {
      let temp = json[ObjectKeys.diagnosis];
      let array = [];
      for (let k of temp) {
        let d: Diagnosis = new Diagnosis();
        d.initFromJSON(k);
        array.push(d);
      }
      this.diagnosis = array;
    }
    if (json[ObjectKeys.medicines]) {
      let temp = json[ObjectKeys.medicines];
      let tempMap: Map<string, Medication> = new Map();
      for (let k in temp) {
        let m: Medication = new Medication();
        m.initFromJSON(temp[k]);
        tempMap.set(k, m);
      }
      this.medicines = tempMap;
    }
    if (json[ObjectKeys.spoken_draft_medications]) {
      let temp = json[ObjectKeys.spoken_draft_medications];
      let tempMap: Map<string, Medication> = new Map();
      for (let k in temp) {
        let m: Medication = new Medication();
        m.initFromJSON(temp[k]);
        tempMap.set(k, m);
      }
      this.spoken_draft_medications = tempMap;
    }
    if (json[ObjectKeys.remarks]) {
      this.remarks = json[ObjectKeys.remarks];
    }
    if (json[ObjectKeys.dispenseStatus]) {
      this.dispenseStatus = json[ObjectKeys.dispenseStatus];
    }
    if (json[ObjectKeys.date]) {
      this.date = json[ObjectKeys.date];
    }
    if (json[ObjectKeys.follow_up_date]) {
      this.follow_up_date = json[ObjectKeys.follow_up_date];
    }
    if (json[ObjectKeys.vitalInfo]) {
      let array = json[ObjectKeys.vitalInfo];
      this.latestVitalSnapshot = new LatestVitalSnapshot();
      for (let a of array) {
        this.latestVitalSnapshot.setVitalFromJSON(a);
      }
    }
  }

  /**
   * Getter signature
   * @return {string}
   */
  public get signature(): string {
    return this._signature;
  }

  /**
   * Setter signature
   * @param {string} value
   */
  public set signature(value: string) {
    this._signature = value;
  }

  /**
   * Getter id
   * @return {string}
   */
  public get id(): string {
    return this._id;
  }

  /**
   * Getter organisationId
   * @return {string}
   */
  public get organisationId(): string {
    return this._organisationId;
  }

  /**
   * Getter organisationName
   * @return {string}
   */
  public get organisationName(): string {
    return this._organisationName;
  }

  /**
   * Getter title
   * @return {string}
   */
  public get title(): string {
    return this._title;
  }

  /**
   * Getter prescribedById
   * @return {string}
   */
  public get prescribedById(): string {
    return this._prescribedById;
  }

  /**
   * Getter prescribedByName
   * @return {string}
   */
  public get prescribedByName(): string {
    return this._prescribedByName;
  }

  /**
   * Getter prescribedByDesignation
   * @return {string}
   */
  public get prescribedByDesignation(): string {
    return this._prescribedByDesignation;
  }

  /**
   * Getter instructions
   * @return {string}
   */
  public get instructions(): string {
    return this._instructions;
  }

  /**
   * Getter patientId
   * @return {string}
   */
  public get patientId(): string {
    return this._patientId;
  }

  /**
   * Getter patientName
   * @return {string}
   */
  public get patientName(): string {
    return this._patientName;
  }

  /**
   * Getter patientGender
   * @return {string}
   */
  public get patientGender(): string {
    return this._patientGender;
  }

  /**
   * Getter patientAge
   * @return {number}
   */
  public get patientAge(): number {
    return this._patientAge;
  }
  /**
   * Getter patientAgeObject
   * @return {{}}
   */
  public get patientAgeObject(): {} {
    return this._patientAgeObject;
  }

  /**
   * Getter prescriptionNumber
   * @return {string}
   */
  public get prescriptionNumber(): string {
    return this._prescriptionNumber;
  }

  /**
   * Getter status
   * @return {number}
   */
  public get status(): number {
    return this._status;
  }

  /**
   * Getter createdOn
   * @return {number}
   */
  public get createdOn(): number {
    return this._createdOn;
  }

  /**
   * Getter updatedOn
   * @return {number}
   */
  public get updatedOn(): number {
    return this._updatedOn;
  }

  /**
   * Getter diagnosis
   * @return {Array<Diagnosis> }
   */
  public get diagnosis(): Array<Diagnosis> {
    return this._diagnosis;
  }

  /**
   * Getter medicines
   * @return {Map<string, Medication> }
   */
  public get medicines(): Map<string, Medication> {
    return this._medicines;
  }

  /**
   * Getter remarks
   * @return {string}
   */
  public get remarks(): string {
    return this._remarks;
  }

  /**
   * Getter dispenseStatus
   * @return {number}
   */
  public get dispenseStatus(): number {
    return this._dispenseStatus;
  }

  /**
   * Getter date
   * @return {string}
   */
  public get date(): string {
    return this._date;
  }

  /**
   * Getter follow_up_date
   * @return {string}
   */
  public get follow_up_date(): string {
    return this._follow_up_date;
  }

  /**
   * Setter id
   * @param {string} value
   */
  public set id(value: string) {
    this._id = value;
  }

  /**
   * Setter organisationId
   * @param {string} value
   */
  public set organisationId(value: string) {
    this._organisationId = value;
  }

  /**
   * Setter organisationName
   * @param {string} value
   */
  public set organisationName(value: string) {
    this._organisationName = value;
  }

  /**
   * Setter title
   * @param {string} value
   */
  public set title(value: string) {
    this._title = value;
  }

  /**
   * Setter prescribedById
   * @param {string} value
   */
  public set prescribedById(value: string) {
    this._prescribedById = value;
  }

  /**
   * Setter prescribedByName
   * @param {string} value
   */
  public set prescribedByName(value: string) {
    this._prescribedByName = value;
  }

  /**
   * Setter prescribedByDesignation
   * @param {string} value
   */
  public set prescribedByDesignation(value: string) {
    this._prescribedByDesignation = value;
  }

  /**
   * Setter instructions
   * @param {string} value
   */
  public set instructions(value: string) {
    this._instructions = value;
  }

  /**
   * Setter patientId
   * @param {string} value
   */
  public set patientId(value: string) {
    this._patientId = value;
  }

  /**
   * Setter patientName
   * @param {string} value
   */
  public set patientName(value: string) {
    this._patientName = value;
  }

  /**
   * Setter patientGender
   * @param {string} value
   */
  public set patientGender(value: string) {
    this._patientGender = value;
  }

  /**
   * Setter patientAge
   * @param {number} value
   */
  public set patientAge(value: number) {
    this._patientAge = value;
  }
  /**
   * Setter patientAge
   * @param {number} value
   */
  public set patientAgeObject(value: {}) {
    this._patientAgeObject = value;
  }

  /**
   * Setter prescriptionNumber
   * @param {string} value
   */
  public set prescriptionNumber(value: string) {
    this._prescriptionNumber = value;
  }

  /**
   * Setter status
   * @param {number} value
   */
  public set status(value: number) {
    this._status = value;
  }

  /**
   * Setter createdOn
   * @param {number} value
   */
  public set createdOn(value: number) {
    this._createdOn = value;
  }

  /**
   * Setter updatedOn
   * @param {number} value
   */
  public set updatedOn(value: number) {
    this._updatedOn = value;
  }

  /**
   * Setter diagnosis
   * @param {Array<Diagnosis> } value
   */
  public set diagnosis(value: Array<Diagnosis>) {
    this._diagnosis = value;
  }

  /**
   * Setter medicines
   * @param {Map<string, Medication> } value
   */
  public set medicines(value: Map<string, Medication>) {
    this._medicines = value;
  }

  /**
   * Setter remarks
   * @param {string} value
   */
  public set remarks(value: string) {
    this._remarks = value;
  }

  /**
   * Setter dispenseStatus
   * @param {number} value
   */
  public set dispenseStatus(value: number) {
    this._dispenseStatus = value;
  }

  /**
   * Setter date
   * @param {string} value
   */
  public set date(value: string) {
    this._date = value;
  }

  /**
   * Setter follow_up_date
   * @param {string} value
   */
  public set follow_up_date(value: string) {
    this._follow_up_date = value;
  }

  /**
   * Getter numberOfMedicines
   * @return {number}
   */
  public get numberOfMedicines(): number {
    return this._numberOfMedicines;
  }

  /**
   * Setter numberOfMedicines
   * @param {number} value
   */
  public set numberOfMedicines(value: number) {
    this._numberOfMedicines = value;
  }

  /**
   * Getter spoken_draft_medications
   * @return {Map<string,Medication> }
   */
  public get spoken_draft_medications(): Map<string, Medication> {
    return this._spoken_draft_medications;
  }

  /**
   * Setter spoken_draft_medications
   * @param {Map<string,Medication> } value
   */
  public set spoken_draft_medications(value: Map<string, Medication>) {
    this._spoken_draft_medications = value;
  }

  /**
   * Getter latestVitalSnapshot
   * @return {LatestVitalSnapshot}
   */
  public get latestVitalSnapshot(): LatestVitalSnapshot {
    return this._latestVitalSnapshot;
  }

  /**
   * Setter latestVitalSnapshot
   * @param {LatestVitalSnapshot} value
   */
  public set latestVitalSnapshot(value: LatestVitalSnapshot) {
    this._latestVitalSnapshot = value;
  }
}
