import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";

@Pipe({
  name: "dateFilter"
})
export class DateFilterPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    let argsArray: Array<boolean> = args;
    if(argsArray.length < 2) {
      return null;
    }
    let shouldDisplayDay = argsArray[0];
    let shouldDisplayYear = argsArray[1];
    var dateFormatString = "";
    if (shouldDisplayDay) {
      dateFormatString += "EEE, ";
    }
    dateFormatString += "dd MMM";
    if (shouldDisplayYear) {
      dateFormatString += " yyyy";
    }
    if (value && typeof value === "object") {
      let date = new Date(value.year, value.month - 1, value.day);
      // "EEE, dd MMM yyyy"
      return super.transform(date, dateFormatString);
    } else if (value && typeof value === "string") {
      var day = value.split("-");
      let date = new Date(
        parseInt(day[0]),
        parseInt(day[1]) - 1,
        parseInt(day[2])
      );
      return super.transform(date, dateFormatString);
    } else {
      return null;
    }
  }
}
