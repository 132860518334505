import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";
import { Medication } from "../../models/Medication/Medication";
import { DentalChartEntry } from "../../models/Patient/Dental/DentalChartEntry";
import { Appointment } from "../../models/Scheduling/Appointment";

@Injectable({
  providedIn: "root"
})
export class AssistantStateManagementService {
  private currentStateWithMeta = new Subject<{
    state: CurrentAssistantState;
    meta: Object;
  }>();
  private _currentMetaDataForAssistant: {} = {};

  constructor() {}

  public listener(): Observable<{
    state: CurrentAssistantState;
    meta: Object;
  }> {
    return this.currentStateWithMeta.asObservable();
  }

  public openViewAppointment(appointment: Appointment) {
    return this.currentStateWithMeta.next({
      state: CurrentAssistantState.ViewAppointment,
      meta: appointment
    });
  }

  public closeViewAppointment() {
    return this.currentStateWithMeta.next({
      state: CurrentAssistantState.HomeSweetHome,
      meta: null
    });
  }

  public openMedicationForm(med: Medication) {
    return this.currentStateWithMeta.next({
      state: CurrentAssistantState.MedicationForm,
      meta: med
    });
  }

  public closeMedicationForm() {
    return this.currentStateWithMeta.next({
      state: CurrentAssistantState.HomeSweetHome,
      meta: null
    });
  }

  public openTop50MedsList() {
    return this.currentStateWithMeta.next({
      state: CurrentAssistantState.Top50MedsList,
      meta: null
    });
  }

  public closeTop50MedsList() {
    return this.currentStateWithMeta.next({
      state: CurrentAssistantState.HomeSweetHome,
      meta: null
    });
  }

  public openDentalChartForm(dentalChartEntry?: DentalChartEntry) {
    return this.currentStateWithMeta.next({
      state: CurrentAssistantState.DentalChartEntry,
      meta: dentalChartEntry
    });
  }

  public closeDentalChartForm() {
    return this.currentStateWithMeta.next({
      state: CurrentAssistantState.HomeSweetHome,
      meta: null
    });
  }

  public activateVoiceAssistant(metaData?: {}) {
    if (metaData) {
      this.currentMetaDataForAssistant = metaData;
    }else{
      this.currentMetaDataForAssistant = {};
    }
    return this.currentStateWithMeta.next({
      state: CurrentAssistantState.HomeSweetHome,
      meta: "activate_voice_mode"
    });
  }

  /**
   * Getter currentMetaDataForAssistant
   * @return {{} }
   */
  public get currentMetaDataForAssistant(): {} {
    return this._currentMetaDataForAssistant;
  }

  /**
   * Setter currentMetaDataForAssistant
   * @param {{} } value
   */
  public set currentMetaDataForAssistant(value: {}) {
    this._currentMetaDataForAssistant = value;
  }
}

export enum CurrentAssistantState {
  "HomeSweetHome",
  "MedicationForm",
  "DentalChartEntry",
  "ViewAppointment",
  "Top50MedsList"
}
