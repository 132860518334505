import { Pipe, PipeTransform } from '@angular/core';
import { Appointment } from '../models/Scheduling/Appointment';

@Pipe({
  name: 'appointmentListSearch'
})
export class AppointmentListSearchPipe implements PipeTransform {

  transform(items: Appointment[], arg1: any): any {
    if (items == null) {
      return items;
    }
    let search = "";
    if(arg1){
       search = (arg1+"").toLocaleLowerCase();
    }
    return items.filter(item => {
      if (arg1 == null || arg1.length == "") {
        return true;
      } else if (arg1 && item.patientName && item.patientName.toLowerCase().indexOf(search) > -1) {
        return true;
      }
      return false;
    });
  }

}
