import { UsernameSearchComponent } from "./username-search/username-search.component";
import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";

const routes: Routes = [
  // {
  //     path: "",
  //     component: UsernameSearchComponent
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProviderSignupRoutingModule {}
