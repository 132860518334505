export class MedicationDosageFormConstants {
  public static TABLET = {
    value: "tablet",
    label: "Tablet"
  };
  public static CAPSULE = {
    value: "capsule",
    label: "Capsule"
  };
  public static DROPS = {
    value: "drops",
    label: "Drops"
  };
  public static PASTE = {
    value: "paste",
    label: "Paste"
  };
  public static TOOTHPASTE = {
    value: "toothpaste",
    label: "Toothpaste"
  };
  public static TOOTHBRUSH = {
    value: "toothbrush",
    label: "Toothbrush"
  };
  public static SOLUTION = {
    value: "solution",
    label: "Solution"
  };
  public static OINTMENT = {
    value: "ointment",
    label: "Ointment"
  };
  public static SYRUP = {
    value: "syrup",
    label: "Syrup"
  };
  public static DRY_SYRUP = {
    value: "dry syrup",
    label: "Dry syrup"
  };
  public static SUSPENSION = {
    value: "suspension",
    label: "Suspension"
  };
  public static INJECTION = {
    value: "injection",
    label: "Injection"
  };
  public static IV_FLUID = {
    value: "iv fluid",
    label: "IV Fluid"
  };
  public static POWDER = {
    value: "powder",
    label: "Powder"
  };
  public static GRANULES = {
    value: "granules",
    label: "Granules"
  };
  public static CREAM = {
    value: "cream",
    label: "Cream"
  };
  public static INHALER = {
    value: "inhaler",
    label: "Inhaler"
  };
  public static RESPULES = {
    value: "respules",
    label: "Respules"
  };
  public static BANDAGE = {
    value: "bandage",
    label: "Bandage"
  };

  public static LANGUAGE_LABEL = {
    tablet: {
      hi: "गोली"
    },
    capsule: {
      hi: "कैप्सूल"
    },
    drops: {
      hi: "ड्रॉप"
    },
    paste: {
      hi: "पेस्ट"
    },
    toothpaste: {
      hi: "टूथपेस्ट"
    },
    toothbrush: {
      hi: "टूथब्रश"
    },
    solution: {
      hi: "सोलुशन"
    },
    ointment: {
      hi: "ओईंटमेंट"
    },
    syrup: {
      hi: "सीरप"
    },
    "dry syrup": {
      hi: "ड्राय सीरप"
    },
    suspension: {
      hi: "सस्पेंशन"
    },
    injection: {
      hi: "इंजेक्शन"
    },
    "iv fluid": {
      hi: "आइवि फ़्लुइड"
    },
    powder: {
      hi: "पावडर"
    },
    granules: {
      hi: "ग्रान्युलस"
    },
    cream: {
      hi: "क्रीम"
    },
    inhaler: {
      hi: "इन्हेलर"
    },
    respules: {
      hi: "रेस्प्युलस"
    },
    bandage: {
      hi: "बेंडेज"
    }
  };

  public static ALL = [
    MedicationDosageFormConstants.TABLET,
    MedicationDosageFormConstants.CAPSULE,
    MedicationDosageFormConstants.SYRUP,
    MedicationDosageFormConstants.DRY_SYRUP,
    MedicationDosageFormConstants.SUSPENSION,
    MedicationDosageFormConstants.INJECTION,
    MedicationDosageFormConstants.IV_FLUID,
    MedicationDosageFormConstants.BANDAGE,
    MedicationDosageFormConstants.POWDER,
    MedicationDosageFormConstants.GRANULES,
    MedicationDosageFormConstants.DROPS,
    MedicationDosageFormConstants.OINTMENT,
    MedicationDosageFormConstants.CREAM,
    MedicationDosageFormConstants.RESPULES,
    MedicationDosageFormConstants.SOLUTION,
    MedicationDosageFormConstants.INHALER,
    MedicationDosageFormConstants.PASTE,
    MedicationDosageFormConstants.TOOTHPASTE,
    MedicationDosageFormConstants.TOOTHBRUSH
  ];
}
