export enum QueueTabOption {
  Default = "default",
  Queue = "queue",
  Scheduled = "scheduled",
  All = "all"
}

export class SchedulingConstants {
  public static TimeSlotLengthOptions = [
    {
      label: "5 min.",
      value: 5
    },
    {
      label: "10 min.",
      value: 10
    },
    {
      label: "15 min.",
      value: 15
    },
    {
      label: "20 min.",
      value: 20
    },
    {
      label: "30 min.",
      value: 30
    },
    {
      label: "45 min.",
      value: 45
    },
    {
      label: "1 hour",
      value: 60
    }
  ];
  public static MaxVisitsPerSlotOptions = [
    {
      label: "1 visit",
      value: 1
    },
    {
      label: "2 visits.",
      value: 2
    },
    {
      label: "3 visits.",
      value: 3
    },
    {
      label: "4 visits",
      value: 4
    },
    {
      label: "5 visits.",
      value: 5
    },
    {
      label: "6 visits.",
      value: 6
    },
    {
      label: "7 visits.",
      value: 7
    },
    {
      label: "8 visits.",
      value: 8
    },
    {
      label: "9 visits.",
      value: 9
    },
    {
      label: "10 visits.",
      value: 10
    }
  ];
}
