import { Injectable } from "@angular/core";
import { PrescriptionTemplate } from "../../models/Prescription/PrescriptionTemplate";
import { Subject, Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ImportProtocolSyncService {
  subject = new Subject<PrescriptionTemplate>();
  constructor() {}

  public listener(): Observable<PrescriptionTemplate> {
    return this.subject.asObservable();
  }

  public sendBackToPrescription(prescriptionTemplate: PrescriptionTemplate) {
    return this.subject.next(prescriptionTemplate);
  }
}
