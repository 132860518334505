import { Component, OnInit } from "@angular/core";
import { PatientInvestigationModalService } from "../../services/patient-investigation-view/patient-investigation-modal.service";
import { FileInfo } from "../../models/Patient/Dental/Reports";
import { PDFDocumentProxy } from "ng2-pdf-viewer";
import {
  IMAGEVIEWER_CONFIG,
  ImageViewerConfig,
  createButtonConfig
} from "@hallysonh/ngx-imageviewer";

const MY_IMAGEVIEWER_CONFIG: ImageViewerConfig = {
  nextPageButton: createButtonConfig("navigate_next", "Next page", 0, false),
  beforePageButton: createButtonConfig(
    "navigate_before",
    "Previous page",
    1,
    false
  ),
  zoomInButton: createButtonConfig("zoom_in", "Zoom in", 1),
  rotateLeftButton: createButtonConfig("rotate_left", "Rotate left", 2, false),
  rotateRightButton: createButtonConfig(
    "rotate_right",
    "Rotate right",
    3,
    false
  ),
  resetButton: createButtonConfig("autorenew", "Reset", 4, false)
};
@Component({
  selector: "app-patient-investigation-view-modal",
  templateUrl: "./patient-investigation-view-modal.component.html",
  styleUrls: ["./patient-investigation-view-modal.component.css"],
  providers: [
    {
      provide: IMAGEVIEWER_CONFIG,
      useValue: MY_IMAGEVIEWER_CONFIG
    }
  ]
})
export class PatientInvestigationViewModalComponent implements OnInit {
  lottieConfig: any;
  selectedFile: FileInfo;
  isPdfLoaded: Boolean = false;
  constructor(
    private _patientInvestigationService: PatientInvestigationModalService
  ) {}

  ngOnInit() {
    this.lottieConfig = this.getLottieConfig();
    this._patientInvestigationService.listener().subscribe(val => {
      this.selectedFile = val;
    });
  }

  getLottieConfig(): {} {
    return {
      path: "../../../../assets/lottie/paper.json",
      renderer: "canvas",
      autoplay: true,
      loop: true
    };
  }

  onCloseImage() {
    this.isPdfLoaded = false;
    this._patientInvestigationService.close();
  }

  onClosePdf() {
    this._patientInvestigationService.close();
  }

  callBackFn(pdf: PDFDocumentProxy) {
    // do anything with "pdf"
    if (pdf) {
      this.isPdfLoaded = true;
    }
  }
}
