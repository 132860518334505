import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { PrescriptionTemplate } from "src/app/shared/models/Prescription/PrescriptionTemplate";
import { ImportProtocolSyncService } from "src/app/shared/services/component-communication/import-protocol-sync.service";
import {
  Router,
  ActivatedRoute,
  NavigationStart,
  NavigationEnd
} from "@angular/router";
import { SideSectionDialogManagementService } from "src/app/shared/services/component-communication/side-section-dialog-management.service";
import { Medication } from "src/app/shared/models/Medication/Medication";
import { Patient } from "src/app/shared/models/Patient/Patient";
import { ScheduleAppointmentModalService } from "src/app/shared/services/modalStateManagement/schedule-appointment-modal.service";
import { PatientRegistrationModalService } from "src/app/shared/services/modalStateManagement/patient-registration-modal.service";
import { NotifyService } from "src/app/shared/services/notify/notify.service";
import { AssistantStateManagementService } from "src/app/shared/services/component-communication/asssitant-state-management.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-assistant-quick-actions",
  templateUrl: "./assistant-quick-actions.component.html",
  styleUrls: ["./assistant-quick-actions.component.css"]
})
export class AssistantQuickActionsComponent implements OnInit, OnDestroy {
  newPrescriptionRoute = "newPrescription";
  patientChartRoute = "patientChart";
  dashboardRoute = "dashboard";
  opdQueueRoute = "opdQueue";
  opdCalendarRoute = "opdCalendar";
  patientsListRoute = "patientsList";
  settingsRoute = "settings";

  unknownRoute = "unknown";
  SideSectionDialogManagementService;

  currentRoute: string = "unknown";

  newPrescriptionRouteLoaded: boolean = false;
  patientProfileRouteLoaded: boolean = false;
  @Input() patient: Patient = new Patient();

  routeSubscription: Subscription;
  constructor(
    private _importProtocolSyncService: ImportProtocolSyncService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _assistantStateManagementService: AssistantStateManagementService,
    private _scheduleAppointmentModalService: ScheduleAppointmentModalService,
    private _patientRegistrationModalService: PatientRegistrationModalService,
    private _notifyService: NotifyService
  ) {}

  ngOnInit() {
    this.pathCheck(this._router.url);
    this.routeSubscription = this._router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        let path = this._router.url;
        console.log("path", path);
        this.pathCheck(path);
      }
    });
  }

  shouldLoadStandardActions(): boolean {
    return (
      this.currentRoute == this.dashboardRoute ||
      this.currentRoute == this.opdCalendarRoute ||
      this.currentRoute == this.opdQueueRoute ||
      this.currentRoute == this.patientsListRoute ||
      this.currentRoute == this.settingsRoute
    );
  }

  pathCheck(path: string) {
    if (path.indexOf("prescriptions") > -1) {
      this.currentRoute = this.newPrescriptionRoute;
      // this.top50MedsClicked();//This is default for prescription route
    } else if (
      path.indexOf("home") > -1 &&
      path.indexOf("patient-detail") > -1
    ) {
      //This should have higher priority then it's caller routes (eg. opd/today or opd/calendar or patients ...)
      this.currentRoute = this.patientChartRoute;
    } else if (path.indexOf("/dashboard") > -1) {
      this.currentRoute = this.dashboardRoute;
    } else if (path.indexOf("opd/calendar") > -1) {
      this.currentRoute = this.opdCalendarRoute;
    } else if (path.indexOf("opd/today") > -1) {
      this.currentRoute = this.opdQueueRoute;
    } else if (path.indexOf("/patients") > -1) {
      this.currentRoute = this.patientsListRoute;
    } else if (path.indexOf("/settings") > -1) {
      this.currentRoute = this.settingsRoute;
    } else {
      this.currentRoute = this.unknownRoute;
    }

    // this.newPrescriptionPathCheck(path);
    // this.patientProfilePathCheck(path);
  }

  // patientProfilePathCheck(path: string) {
  //   if (path.indexOf("home") > -1 && path.indexOf("patient-detail") > -1) {
  //     this.patientProfileRouteLoaded = true;
  //   } else {
  //     this.patientProfileRouteLoaded = false;
  //   }
  // }

  // newPrescriptionPathCheck(path: string) {
  //   if (path.indexOf("prescriptions") > -1) {
  //     this.newPrescriptionRouteLoaded = true;
  //   } else {
  //     this.newPrescriptionRouteLoaded = false;
  //   }
  // }

  newPatientReg() {}

  newMedicationClicked() {
    this._assistantStateManagementService.openMedicationForm(new Medication());
  }

  bookAppointmentClicked() {
    this._scheduleAppointmentModalService.open();
    if (this.patient && this.patient.docvitaId) {
      this._scheduleAppointmentModalService.selectedPatient(this.patient);
    }
  }

  top50MedsClicked() {
    this._assistantStateManagementService.openTop50MedsList();
  }

  importProtocol(prescriptionTemplate: PrescriptionTemplate) {
    this._importProtocolSyncService.sendBackToPrescription(
      prescriptionTemplate
    );
  }

  onNewPrescriptionClicked() {
    let path = this._router.url;
    if (path.indexOf("patient-detail") > -1) {
      let finalPathForRouting = path.substring(
        0,
        path.indexOf("patient-detail/")
      );
      let sub = path.substring(
        path.indexOf("patient-detail/") + "patient-detail/".length
      );
      let patientId = sub.substring(0, sub.indexOf("/"));
      if (patientId != null && patientId.length > 0) {
        finalPathForRouting +=
          "patient-detail/" + patientId + "/prescriptions/new";
        this._router.navigateByUrl(finalPathForRouting);
      } else {
        this._notifyService.showErrorMessage(
          "Something went wrong. Please try again."
        );
      }
    }
  }

  onNewConsentFormClicked() {
    let path = this._router.url;
    if (path.indexOf("patient-detail") > -1) {
      let finalPathForRouting = path.substring(
        0,
        path.indexOf("patient-detail/")
      );
      let sub = path.substring(
        path.indexOf("patient-detail/") + "patient-detail/".length
      );
      let patientId = sub.substring(0, sub.indexOf("/"));
      if (patientId != null && patientId.length > 0) {
        finalPathForRouting +=
          "patient-detail/" + patientId + "/consent-form/new";
        this._router.navigateByUrl(finalPathForRouting);
      } else {
        this._notifyService.showErrorMessage(
          "Something went wrong. Please try again."
        );
      }
    }
  }

  onNewInstrucitonClicked() {
    let path = this._router.url;
    if (path.indexOf("patient-detail") > -1) {
      let finalPathForRouting = path.substring(
        0,
        path.indexOf("patient-detail/")
      );
      let sub = path.substring(
        path.indexOf("patient-detail/") + "patient-detail/".length
      );
      let patientId = sub.substring(0, sub.indexOf("/"));
      if (patientId != null && patientId.length > 0) {
        finalPathForRouting +=
          "patient-detail/" + patientId + "/instruction/new";
        this._router.navigateByUrl(finalPathForRouting);
      } else {
        this._notifyService.showErrorMessage(
          "Something went wrong. Please try again."
        );
      }
    }
  }

  onAppointmentClicked() {
    this._scheduleAppointmentModalService.open();
  }

  onPatientClicked() {
    this._patientRegistrationModalService.clearInitialText();
    this._patientRegistrationModalService.openPatientProfileEditing();
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }
}
