export class MedicationFrequencyConstants {
  public static VAL_ONCE = {
    label: "Once",
    value: 1
  };
  public static VAL_TWICE = {
    label: "Twice",
    value: 2
  };
  public static VAL_THRICE = {
    label: "Thrice",
    value: 3
  };
  public static VAL_FOUR_TIMES = {
    label: "Four Times",
    value: 4
  };
  public static TYPE_A_DAY = {
    label: "a day",
    value: "a day"
  };
  public static TYPE_A_WEEK = {
    label: "a week",
    value: "a week"
  };
  public static TYPE_EVERY_HOUR = {
    label: "every hour",
    value: "every hour"
  };
  public static TYPE_EVERY_4_HOURS = {
    label: "every 4 hours",
    value: "every 4 hours"
  };
  public static TYPE_EVERY_6_HOURS = {
    label: "every 6 hours",
    value: "every 6 hours"
  };
  public static TYPE_EVERY_8_HOURS = {
    label: "every 8 hours",
    value: "every 8 hours"
  };
  public static TYPE_EVERY_12_HOURS = {
    label: "every 12 hours",
    value: "every 12 hours"
  };
  public static TYPE_STAT = {
    label: "stat",
    value: "stat"
  };
  public static TYPE_SOS = {
    label: "sos",
    value: "sos"
  };
  public static TYPE_FREELY = {
    label: "Freely",
    value: "Freely"
  };
  public static TYPE_AS_NEEDED = {
    label: "As Needed",
    value: "As Needed"
  };

  public static MASTER_MAP = {
    "Once a day": {
      value: MedicationFrequencyConstants.VAL_ONCE.value,
      type: MedicationFrequencyConstants.TYPE_A_DAY.value,
      displayTiming: true,
      displaySpecificTiming: true,
      possibleTimingOptions: [
        { timing1: 1, timing2: 0, timing3: 0 },
        { timing1: 0, timing2: 1, timing3: 0 },
        { timing1: 0, timing2: 0, timing3: 1 }
      ]
    },
    "Twice a day": {
      value: MedicationFrequencyConstants.VAL_TWICE.value,
      type: MedicationFrequencyConstants.TYPE_A_DAY.value,
      displayTiming: true,
      displaySpecificTiming: true,
      possibleTimingOptions: [
        { timing1: 1, timing2: 0, timing3: 1 },
        { timing1: 1, timing2: 1, timing3: 0 },
        { timing1: 0, timing2: 1, timing3: 1 }
      ]
    },
    "Thrice a day": {
      value: MedicationFrequencyConstants.VAL_THRICE.value,
      type: MedicationFrequencyConstants.TYPE_A_DAY.value,
      displayTiming: true,
      displaySpecificTiming: true,
      selectedTimingOption: { timing1: 1, timing2: 1, timing3: 1 }
    },
    STAT: {
      intepretation: "stat (immediately)",
      type: MedicationFrequencyConstants.TYPE_STAT.value,
      displayTiming: false,
      displaySpecificTiming: true
    },
    SOS: {
      intepretation: "sos (if necessary)",
      type: MedicationFrequencyConstants.TYPE_SOS.value,
      displayTiming: false,
      displaySpecificTiming: true
    },
    "Four times a day": {
      value: MedicationFrequencyConstants.VAL_FOUR_TIMES.value,
      type: MedicationFrequencyConstants.TYPE_A_DAY.value,
      displayTiming: false,
      displaySpecificTiming: true
    },
    "Every 4 hours": {
      type: MedicationFrequencyConstants.TYPE_EVERY_4_HOURS.value,
      displayTiming: false,
      displaySpecificTiming: true
    },
    "Every 6 hours": {
      type: MedicationFrequencyConstants.TYPE_EVERY_6_HOURS.value,
      displayTiming: false,
      displaySpecificTiming: true
    },
    "Every 8 hours": {
      type: MedicationFrequencyConstants.TYPE_EVERY_8_HOURS.value,
      displayTiming: true,
      displaySpecificTiming: true,
      selectedTimingOption: { timing1: 1, timing2: 1, timing3: 1 }
    },
    "Every 12 hours": {
      type: MedicationFrequencyConstants.TYPE_EVERY_12_HOURS.value,
      displayTiming: true,
      displaySpecificTiming: true,
      selectedTimingOption: { timing1: 1, timing2: 0, timing3: 1 }
    },
    "per hour": {
      value: MedicationFrequencyConstants.VAL_ONCE.value,
      type: MedicationFrequencyConstants.TYPE_EVERY_HOUR.value,
      displayTiming: false,
      displaySpecificTiming: true
    },
    "Once a week": {
      value: MedicationFrequencyConstants.VAL_ONCE.value,
      type: MedicationFrequencyConstants.TYPE_A_WEEK.value,
      displayTiming: false,
      displaySpecificTiming: true
    },
    "Twice a week": {
      value: MedicationFrequencyConstants.VAL_TWICE.value,
      type: MedicationFrequencyConstants.TYPE_A_WEEK.value,
      displayTiming: false,
      displaySpecificTiming: true
    },
    "Thrice a week": {
      value: MedicationFrequencyConstants.VAL_THRICE.value,
      type: MedicationFrequencyConstants.TYPE_A_WEEK.value,
      displayTiming: false,
      displaySpecificTiming: true
    },
    "Four times a week": {
      value: MedicationFrequencyConstants.VAL_FOUR_TIMES.value,
      type: MedicationFrequencyConstants.TYPE_A_WEEK.value,
      displayTiming: false,
      displaySpecificTiming: true
    },
    Freely: {
      type: MedicationFrequencyConstants.TYPE_FREELY.value,
      displayTiming: false,
      displaySpecificTiming: true
    },
    "As Needed": {
      type: MedicationFrequencyConstants.TYPE_AS_NEEDED.value,
      displayTiming: false,
      displaySpecificTiming: true
    }
  };
}

export class MedicationFrequencyTextConstants {
  public static PerHour = "per hour";
  public static OnceADay = "Once a day";
  public static TwiceADay = "Twice a day";
  public static ThriceADay = "Thrice a day";
  public static FourTimesADay = "Four times a day";
  public static EveryFourHours = "Every 4 hours";
  public static EverySixHours = "Every 6 hours";
  public static EveryEightHours = "Every 8 hours";
  public static EveryTwelveHours = "Every 12 hours";
  public static OnceAWeek = "Once a week";
  public static TwiceAWeek = "Twice a week";
  public static ThriceAWeek = "Thrice a week";
  public static FourTimesAWeek = "Four times a week";
  public static STAT = "STAT";
  public static SOS = "SOS";
  public static Freely = "Freely";
  public static AsNeeded = "As Needed";

  public static LANGUAGE_MASTER_MAP = {
    "per hour": {
      hi: "हर घंटे"
    },
    "Once a day": {
      hi: "दिन मे एक बार"
    },
    "Twice a day": {
      hi: "दिन मे दो बार"
    },
    "Thrice a day": {
      hi: "दिन मे तीन बार"
    },
    "Four times a day": {
      hi: "दिन मे चार बार"
    },
    "Every 4 hours": {
      hi: "हर चार घंटे में"
    },
    "Every 6 hours": {
      hi: "हर छह घंटे में"
    },
    "Every 8 hours": {
      hi: "हर आठ घंटे में"
    },
    "Every 12 hours": {
      hi: "हर बारह घंटे में"
    },
    "Once a week": {
      hi: "हफ्ते मेँ एक बार"
    },
    "Twice a week": {
      hi: "हफ्ते में दो बार"
    },
    "Thrice a week": {
      hi: "हफ्ते में तीन बार"
    },
    "Four times a week": {
      hi: "हफ्ते में चार बार"
    },
    STAT: {
      hi: "अभी"
    },
    SOS: {
      hi: "जरूरत के मुताबिक"
    },
    Freely: {},
    "As Needed": {
      hi: "यदि आवश्यक"
    }
  };
}
