import { Component, OnInit, Input } from "@angular/core";
import { Appointment } from "../../models/Scheduling/Appointment";
import { PaymentDialogService } from "../../services/modalStateManagement/payment-dialog.service";
import { SmsService } from "src/app/shared/services/payment-sms/sms.service";
declare var $: any;
import * as env from "../../../../environments/environment";
import { GlobalVariables } from "src/app/globar-var/globarVariables";
import { NotifyService } from "../../services/notify/notify.service";

@Component({
  selector: "app-payment-dialog",
  templateUrl: "./payment-dialog.component.html",
  styleUrls: ["./payment-dialog.component.css"]
})
export class PaymentDialogComponent implements OnInit {
  @Input() appointment: Appointment = new Appointment();
  patientName: string = "";
  mobileNumber: string = "";
  email: string = "";
  amount: number;
  purpose: {} = {
    consultation: false,
    treatment: false,
    investigation: false,
    medicine: false,
    other: false
  };
  constructor(
    private _paymentDialogService: PaymentDialogService,
    private _paymentService: SmsService,
    private _notifyService: NotifyService
  ) {}

  ngOnInit() {
    this._paymentDialogService.listener().subscribe(val => {
      if (val) {
        this.appointment = val;
        if (this.appointment.patientName) {
          this.patientName = this.appointment.patientName;
        }
        if (this.appointment.getContactNumber()) {
          this.mobileNumber = this.appointment.getContactNumber().substring(2);
        }
        if (this.appointment.patientPrimaryEmail) {
          this.email = this.appointment.patientPrimaryEmail;
        }
      }
    });
  }

  closeModal() {
    this._paymentDialogService.close();
  }

  sendSms() {
    let transactionDetails = {
      customerName: this.patientName,
      customerPhone: this.mobileNumber,
      customerEmail: this.email,
      returnUrl: env.environment["return-url"],
      orderAmount: this.amount,
      notifyUrl: env.environment["notify-url"]
    };

    if (this.email.length > 0) {
      transactionDetails["customerEmail"] = this.email;
    } else {
      transactionDetails["customerEmail"] = "payments@docvita.in";
    }

    let metaData = {
      organisationId: GlobalVariables.getOrganisationId(),
      organisationName: GlobalVariables.getOrganisationName(),
      organisationShortUrl: GlobalVariables.getOrganisation().uniqueShortLink,
      patientId: this.appointment.patientId,
      patientGender: this.appointment.patientGender
    };

    if (this.purpose["consultation"] === true) {
      metaData["isConsultation"] = true;
    }
    if (this.purpose["treatment"] === true) {
      metaData["isTreatment"] = true;
    }
    if (this.purpose["investigation"] === true) {
      metaData["isInvestigation"] = true;
    }
    if (this.purpose["medicine"] === true) {
      metaData["isMedicine"] = true;
    }
    if (this.purpose["other"] === true) {
      metaData["isMedicine"] = true;
    }
    if (this.fieldValid()) {
      // call api
      this._paymentService
        .sendPaymentSms(transactionDetails, metaData)
        .then(res => {
          this._notifyService.showSuccessMessage("Message sent successfully");
        })
        .catch(err => {
          console.log(err);
          this._paymentDialogService.close();
        });
    }
    this._paymentDialogService.close();
  }

  fieldValid() {
    if (this.patientName.length == 0) {
      this._notifyService.showErrorMessage("Please enter Patient Name");
      return false;
    }
    let phoneno = /^\d{10}$/;
    if (!this.mobileNumber.match(phoneno)) {
      this._notifyService.showErrorMessage("Please enter valid mobile number");
      return false;
    }
    if (!this.amount) {
      this._notifyService.showErrorMessage("Please enter valid amount");
      return false;
    }
    return true;
  }

  purposeChange(event, name: string) {
    this.purpose[name] = event.target.checked;
  }

  instantFill(amount): void {
    this.amount = amount;
  }
}
