import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";
import { SpeechRecognizerService } from "../shared/web-speech/services/speech-recognizer.service";
import { SpeechNotification } from "../shared/web-speech/model/speech-notification";
import { SpeechError } from "../shared/web-speech/model/speech-error";
import { NotifyService } from "../shared/services/notify/notify.service";
import { GlobalVariables } from "../globar-var/globarVariables";
import { ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { OrganisationProfileOperationsService } from "../shared/services/organisation/organisation-profile-operations.service";
import {
  OrganisationMember,
  Organisation,
  PlanInfo
} from "../shared/models/MD - Member";
import { Patient } from "../shared/models/Patient/Patient";
import { PatientRegistrationModalService } from "../shared/services/modalStateManagement/patient-registration-modal.service";
import { Subscription } from "rxjs";
import { ScheduleAppointmentModalService } from "../shared/services/modalStateManagement/schedule-appointment-modal.service";
import {
  VoiceService,
  VoiceAssistantStates
} from "../shared/services/voice/voice.service";
import * as firebase from "firebase";
import {
  FirestoreCollections,
  ObjectKeys
} from "../shared/constants/object-keys";
import { PrescriptionTemplate } from "../shared/models/Prescription/PrescriptionTemplate";
import {
  ActivatedRoute,
  Router,
  Event,
  NavigationEnd,
  NavigationStart
} from "@angular/router";
import { DateActions } from "../shared/models/Demographics";
import { TokenRegistrationService } from "../shared/services/token-registration/token-registration.service";
import { SideSectionDialogManagementService } from "../shared/services/component-communication/side-section-dialog-management.service";
import { Medication } from "../shared/models/Medication/Medication";
import { DentalChartEntry } from "../shared/models/Patient/Dental/DentalChartEntry";
import { ViewAppointmentDialogService } from "../shared/services/modalStateManagement/view-appointment-dialog.service";
import { Appointment } from "../shared/models/Scheduling/Appointment";
import { HotkeysService } from "../shared/services/hotkeys/hotkeys.service";
import { Observable, Subject } from "rxjs";
import { DictationHandlerService } from "../shared/services/dictation-handler.service";

declare var jQuery: any;

@Component({
  selector: "app-dashboard",
  templateUrl: "./common-layout.component.html",
  styleUrls: ["./common-layout.component.css"],
  providers: [SpeechRecognizerService]
})
export class CommonLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  defaultImagePath: string = "assets/images/img-2.jpg";

  public app: any;
  public headerThemes: any;
  public changeHeader: any;
  public sidenavThemes: any;
  public changeSidenav: any;
  public headerSelected: any;
  public sidenavSelected: any;
  public searchActived: any;
  public searchModel: any;

  public assistantState: VoiceAssistantStates;

  // Variables for Voice Assistant
  spokenVoiceTextOnDisplay: string; // text in transit > final version
  voiceAssistantActive: boolean = false; // While we're listening to the user
  voiceAssistantProcessing: boolean = false; // While we're talking to our servers

  // newMedicationShowBool: boolean = false;
  // medicationInput: Medication = new Medication();

  //Voice recognizer variables
  currentLanguage: string;
  languages: string[] = ["en-IN", "es-US"];
  finalTranscript: string = "";
  liveSpokenText: string = "";

  routerSubscription: Subscription;
  voiceServiceSubscription: Subscription;

  public selectedPatientForEditing: Patient = new Patient();
  public selectedPatientsForAppointment: Patient[] = [];
  initialText: string = "";
  private subscriptionAppointmentSchedulingService: Subscription;
  private subscriptionPatientRegModalService: Subscription;
  private subscriptionPatientRegModalStateTracker: Subscription;
  private subscriptionPatientInitialTextSidePanelService: Subscription;
  private subscriptionSpeechStart: Subscription;
  private subscriptionSpeechEnd: Subscription;
  private subscriptionSpeechResult: Subscription;
  private subscriptionSpeechError: Subscription;
  private dictationHandlerSubscription: Subscription;
  private soundNotificationSubscription;
  memberName: string = "";
  memberEmail: string = "";
  memberPhotoUrl: string;
  currentActivePlan: PlanInfo = new PlanInfo();

  // Variable to control scheduled modal hiding for patient registration
  hideSchedulingDialog: boolean = false;
  enterFullScreenMode: boolean = false; // toggle full screen mode based on this

  subTabsVisibility: boolean = true;

  private canAccessConsentFormModule: boolean = false;
  private canAccessDentistryModule: boolean = false;
  private canAccessInstructionsModule: boolean = false;

  constructor(
    private _organisationProfileOperationsService: OrganisationProfileOperationsService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private _notifyService: NotifyService,
    private _patientRegistrationModalService: PatientRegistrationModalService,
    private _scheduleAppointmentModalService: ScheduleAppointmentModalService,
    private _voiceService: VoiceService,
    private _tokenRegistrationService: TokenRegistrationService,
    private _sideSectionDialogManagementService: SideSectionDialogManagementService,
    private _viewAppointmentDialogService: ViewAppointmentDialogService,
    private _hotKeysService: HotkeysService,
    private _speechRecognizerService: SpeechRecognizerService,
    private _dictationHandlerService: DictationHandlerService
  ) {
    this.app = {
      layout: {
        sidePanelOpen: false,
        isMenuOpened: true,
        isMenuCollapsed: false,
        themeConfigOpen: false,
        rtlActived: false,
        searchActived: false
      }
    };

    this.headerThemes = [
      "header-default",
      "header-primary",
      "header-info",
      "header-success",
      "header-danger",
      "header-dark"
    ];
    this.changeHeader = changeHeader;

    function changeHeader(headerTheme) {
      this.headerSelected = headerTheme;
    }

    this.sidenavThemes = ["sidenav-default", "side-nav-dark"];
    this.changeSidenav = changeSidenav;

    function changeSidenav(sidenavTheme) {
      this.sidenavSelected = sidenavTheme;
    }
    this.memberName = GlobalVariables.getMemberName();
    this.memberEmail = GlobalVariables.getMember().primaryEmail;
    this.memberPhotoUrl =
      GlobalVariables.getMember().photoUrl || this.defaultImagePath;

    setTimeout(() => {
      this.checkForSubtabsVisibility();
      // console.log("Subtitle Visibility: ", this.subTabsVisibility);
      // console.log("At the path: ", this._router.url);
    }, 100);

    //[Setting up speech things]
    this._speechRecognizerService.initialize();
    this.currentLanguage = this.languages[0];
  }

  handleSounds() {
    let ref = firebase
      .database()
      .ref()
      .child("sound_notifications")
      .child(GlobalVariables.getOrganisationId());
    this.soundNotificationSubscription = ref.on(
      "child_added",
      val => {
        if (
          val &&
          val.exists() &&
          GlobalVariables.getOrganisation().isOPDSoundNotificationON
        ) {
          //TODO: also add the check for audio on setting
          let type = val.child("type").val();
          switch (type) {
            case "arrived": {
              new Audio("../../assets/audio/notification_1_sound.wav").play();
              break;
            }
            case "completed": {
              new Audio("../../assets/audio/beep.mp3").play();
              break;
            }
          }
        }
      },
      err => {
        console.error(err);
      }
    );
  }

  private unsubscribeDictationListener() {
    if (this.dictationHandlerSubscription) {
      this.dictationHandlerSubscription.unsubscribe();
    }
  }

  private listenerForDictationService() {
    // this.unsubscribeDictationListener();
    this.dictationHandlerSubscription = this._dictationHandlerService
      .actionsListener()
      .subscribe(val => {
        if (val) {
          switch (val) {
            case "start": {
              this._speechRecognizerService.start(new Date().getTime());
              break;
            }
            case "stop": {
              this._speechRecognizerService.stop();
              break;
            }
          }
        }
      });
  }

  private activateListenersForSpeechRecognisation() {
    this.unsubscribeSpeech();
    this.subscriptionSpeechStart = this._speechRecognizerService
      .onStart()
      .subscribe(data => {
        this.liveSpokenText = "";
      });

    this.subscriptionSpeechEnd = this._speechRecognizerService
      .onEnd()
      .subscribe(data => {
        console.log("onEnd: VoiceAssistant");
      });

    this.subscriptionSpeechResult = this._speechRecognizerService
      .onResult()
      .subscribe((data: SpeechNotification) => {
        // console.log("Got results:")
        if (data && data.content) {
          var message = data.content.trim();
          if (data.info === "final_transcript" && message.length > 0) {
            this.finalTranscript = message;
            this._dictationHandlerService.masterTransmitter(null, message);
          } else if (data.info === "interim_transcript" && message.length > 0) {
            this.liveSpokenText = message;
            this._dictationHandlerService.masterTransmitter(message, null);
          }
        }
      });

    this.subscriptionSpeechError = this._speechRecognizerService
      .onError()
      .subscribe(data => {
        switch (data.error) {
          case SpeechError.BLOCKED:
          case SpeechError.NOT_ALLOWED:
            this._notifyService.showErrorMessage(
              "Your browser is not authorized to access your microphone. Verify that your browser has access to your microphone and try again."
            );
            break;
          case SpeechError.NO_SPEECH:
            this._notifyService.showErrorMessage(
              "No speech has been detected. Please try again."
            );
            break;
          case SpeechError.NO_MICROPHONE:
            this._notifyService.showErrorMessage(
              "Microphone is not available. Plese verify the connection of your microphone and try again."
            );
            break;
          default:
            break;
        }
      });
  }

  private unsubscribeSpeech() {
    if (this.subscriptionSpeechStart) {
      this.subscriptionSpeechStart.unsubscribe();
    }
    if (this.subscriptionSpeechEnd) {
      this.subscriptionSpeechEnd.unsubscribe();
    }
    if (this.subscriptionSpeechError) {
      this.subscriptionSpeechError.unsubscribe();
    }
    if (this.subscriptionSpeechResult) {
      this.subscriptionSpeechResult.unsubscribe();
    }
  }

  close() {
    this._sideSectionDialogManagementService.hide();
  }

  private checkForSubtabsVisibility() {
    var classes = [".tab-pane", ".fade", ".active", ".show"]; // (Notice the `.`)
    this.subTabsVisibility = $("div").is(classes.join(""));
  }

  private fetchLoggedInMemberProfile() {
    if (GlobalVariables.getMemberId()) {
      this._organisationProfileOperationsService
        .getMemberInfo(GlobalVariables.getMemberId())
        .then(response => {
          if (response) {
            let statusCode = response["statusCode"];
            if (statusCode && statusCode == 200) {
              let body = response["body"];
              let dataJSON = body["data"];
              let member: OrganisationMember = new OrganisationMember();
              member.initFromJSON(dataJSON);
              GlobalVariables.setMember(member);
              this.memberPhotoUrl = member.photoUrl || this.defaultImagePath;
              let organisationJSON = body["organisation"];
              let organisation: Organisation = new Organisation();
              organisation.initFromJSON(organisationJSON);
              GlobalVariables.setOrganisation(organisation);

              let membersJSONArray = body["members"];
              for (let memJSON of membersJSONArray) {
                let member: OrganisationMember = new OrganisationMember();
                member.initFromJSON(memJSON);
                GlobalVariables.setMemberInMap(member);
              }
              //TODO: check if the plan is not selected then redirect it to plan selection page.
              this.checkForPlans();
              this.currentPlanActive();
            } else {
              //handle error code
            }
          }
        })
        .catch(err => {
          console.error(err);
        });
    }
  }

  private currentPlanActive() {
    this.currentActivePlan = GlobalVariables.getOrganisation().currentActivePlan(
      DateActions.getDateString(new Date())
    );
  }

  private checkForPlans() {
    if (
      GlobalVariables.getOrganisation().planInfo == null ||
      GlobalVariables.getOrganisation().planInfo.size == 0 ||
      !GlobalVariables.getOrganisation().hasActivePlan(
        DateActions.getDateString(new Date())
      )
    ) {
      this._router.navigateByUrl("plan-and-billing/choose-plan");
    }
  }

  ngOnInit() {
    this.activateListenersForSpeechRecognisation();
    this.listenerForDictationService();
    this.fetchLoggedInMemberProfile();
    this.subscribeAppointmentSchedulingService();
    this.subscribePatientRegistrationModalService();
    this.subscribePatientInitialTextSidePanelService();
    this.fetchPrescriptionTemplates();
    this.subsribeToActivatedRouteForFullScreen();

    if (this.isPathEligibleForFullScreen(this._router.url)) {
      this.enterFullScreenMode = true;
    } else {
      this.enterFullScreenMode = false;
    }

    this.canAccessConsentFormModule = GlobalVariables.getMember().canAccessConsentForm(
      GlobalVariables.getOrganisationId()
    );
    this.canAccessDentistryModule = GlobalVariables.getMember().canAccessDentistry(
      GlobalVariables.getOrganisationId()
    );
    this.canAccessInstructionsModule = GlobalVariables.getMember().canAccessInstructions(
      GlobalVariables.getOrganisationId()
    );

    this.checkForNotificationPermission();
    this.handleSounds();
  }

  isPathEligibleForFullScreen(urlString: string): boolean {
    return (
      urlString.indexOf("patient-detail") != -1 ||
      urlString.indexOf("patient-chart-dental") != -1
    );
  }

  subsribeToActivatedRouteForFullScreen() {
    this.routerSubscription = this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (this.isPathEligibleForFullScreen(this._router.url)) {
          this.enterFullScreenMode = true;
        } else {
          this.enterFullScreenMode = false;
        }
        setTimeout(() => {
          this.checkForSubtabsVisibility();
          // console.log("Subtitle Visibility: ", this.subTabsVisibility);
          // console.log("At the path: ", this._router.url);
        }, 100);
      }
      // console.log("Common Layout Route Events: ", event);
    });
    // this._activatedRoute.url.subscribe(observer => {
    //   console.log("Common Layout URl: ", observer.values);
    // });
    // this._activatedRoute.url.subscribe(
    //   observer:
    // )
  }

  /**
   * Listen to calls for opening and closing of appointment side panel
   */
  subscribeAppointmentSchedulingService() {
    this.subscriptionAppointmentSchedulingService = this._scheduleAppointmentModalService
      .getSelectedPatient()
      .subscribe(user => {
        this.selectedPatientsForAppointment = [];
        let u = new Patient();
        u.initFromJSON(user.toJSON());
        this.selectedPatientsForAppointment.push(u);
        this._scheduleAppointmentModalService.open();
      });
  }

  /**
   * Listen to calls for opening and closing of patient side panel
   */
  subscribePatientRegistrationModalService() {
    this.subscriptionPatientRegModalService = this._patientRegistrationModalService
      .getSelectedInProfileEditing()
      .subscribe(user => {
        this.hideSchedulingDialog = true;
        this.selectedPatientForEditing = new Patient();
        this.selectedPatientForEditing.initFromJSON(user.toJSON());
        setTimeout(time => {
          this._patientRegistrationModalService.openPatientProfileEditing();
        }, 110);
      });

    this.subscriptionPatientRegModalStateTracker = this._patientRegistrationModalService
      .getModalStateTracker()
      .subscribe((currentState: boolean) => {
        this.hideSchedulingDialog = currentState;
      });
  }

  /**
   * For initial text passed in patient form
   */
  subscribePatientInitialTextSidePanelService() {
    this.subscriptionPatientInitialTextSidePanelService = this._patientRegistrationModalService
      .getInitialText()
      .subscribe(str => {
        if (str != null && str.length > 0) {
          this.initialText = str;
        }
      });
  }

  /**
   * Open patient registration modal
   * @param modal - patient registration modal
   */
  openPatientRegistrationModal(modal) {
    this.initialText = "";
    this._patientRegistrationModalService.clearInitialText();
    this.selectedPatientForEditing = new Patient();
    this._patientRegistrationModalService.openPatientProfileEditing();
    this.hideSchedulingDialog = true;
    // this.sidePanelService.openPatientProfileEditing();
  }

  ngAfterViewInit() {
    // this.modalService.open(this.patientRegModal);
    // this.open(this.patientRegModal);
    // this.onClickScheduleNewVisit();
  }

  onSaveCalled(patient: Patient) {
    this._patientRegistrationModalService.setNewAddedPatient(patient);
  }

  // onSaveCalled(userJSON: {}) {
  //   //Add it to subject which is being listened by many components
  //   if (userJSON && userJSON["patient"]) {
  //     let u: Patient = new Patient();
  //     u.initFromJSON(userJSON["patient"]);
  //     this._patientRegistrationModalService.setNewAddedPatient(u);
  //   }
  // }

  onClickScheduleNewVisit() {
    this._scheduleAppointmentModalService.open();
  }

  ngOnDestroy() {
    if (this.soundNotificationSubscription) {
      this.soundNotificationSubscription();
    }
    this.unsubscribeSpeech();
    this.unsubscribeDictationListener();
    if (this.subscriptionAppointmentSchedulingService) {
      this.subscriptionAppointmentSchedulingService.unsubscribe();
    }
    if (this.subscriptionPatientRegModalStateTracker) {
      this.subscriptionPatientRegModalStateTracker.unsubscribe();
    }
    if (this.voiceServiceSubscription)
      this.voiceServiceSubscription.unsubscribe();
    if (this.routerSubscription) this.routerSubscription.unsubscribe();
  }

  fetchPrescriptionTemplates() {
    firebase
      .firestore()
      .collection(FirestoreCollections.ORGANISATION_DATA)
      .doc(GlobalVariables.getOrganisationId())
      .collection(FirestoreCollections.PRESCRIPTION_TEMPLATE)
      .get()
      .then(snapshot => {
        if (!snapshot.empty) {
          snapshot.forEach(val => {
            let p: PrescriptionTemplate = new PrescriptionTemplate();
            p.initFromJSON(val.data());
            GlobalVariables.prescriptionTampletes.set(
              p.prescriptionTemplateId,
              p
            );
          });
          // console.log("Prescription Templates:",GlobalVariables.prescriptionTampletes)
        }
      });
  }

  goToMyAccount() {
    this._router.navigateByUrl(
      "settings/user-accounts-list/" + GlobalVariables.getMemberId()
    );
  }

  goToPlanAndBillingHistory() {
    this._router.navigateByUrl("plan-and-billing/history");
  }

  checkForNotificationPermission() {
    if (Notification != null && Notification.requestPermission()) {
      Notification.requestPermission().then(permission => {
        if (permission === "granted") {
          // console.log("Notification permission granted.");
          firebase
            .messaging()
            .getToken()
            .then(currentToken => {
              if (currentToken) {
                this.registerToken(currentToken);
              } else {
                // Show permission request.
                console.log(
                  "No Instance ID token available. Request permission to generate one."
                );
                // Show permission UI.
              }
            })
            .catch(err => {
              console.log("An error occurred while retrieving token. ", err);
            });
        } else {
          console.log("Unable to get permission to notify.");
        }
      });
    }
  }

  registerToken(token: string) {
    return new Promise((resolve, reject) => {
      let memberId = GlobalVariables.getMemberId();
      let organisationId = GlobalVariables.getOrganisationId();
      if (token == null || organisationId == null || memberId == null) {
        reject();
      } else {
        //Do service network call here
        this._tokenRegistrationService
          .registerToken(memberId, organisationId, token)
          .then(resp => {
            if (
              resp != null &&
              resp["body"] != null &&
              resp["body"]["statusCode"] == 200 &&
              resp["body"]["success"]
            ) {
              resolve();
            } else {
              reject();
            }
          })
          .catch(err => {
            reject();
          });
      }
    });
  }

  logout() {
    firebase
      .messaging()
      .getToken()
      .then(token => {
        this.signout(token);
      })
      .catch(err => {
        this.signout();
      });
  }

  signout(token?: string) {
    if (token) {
      this._tokenRegistrationService.deregisterToken(
        GlobalVariables.getMemberId(),
        GlobalVariables.getOrganisationId(),
        token
      );
    }
    this._router.navigateByUrl("auth");
    firebase.auth().signOut();
    GlobalVariables.clearValues();
    this._notifyService.showSuccessMessage("Signing out...");
  }

  featureComingSoon() {
    this._notifyService.showSuccessMessage(
      "We're working on it. :)",
      "Coming Soon"
    );
  }

  andOperator(bool1: boolean, bool2: boolean): boolean {
    return bool1 && bool2;
  }
  // keyboard shortcut for voice activation
  @HostListener("window:keydown.meta.shift.d", ["$event"]) d(e: KeyboardEvent) {
    this._hotKeysService.voiceActivation();
  }
  @HostListener("window:keydown.meta.shift.s", ["$event"]) s(e: KeyboardEvent) {
    this._hotKeysService.voiceDisable();
  }
}
