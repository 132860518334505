import { Injectable } from "@angular/core";
import { SpeechNotification } from "../model/speech-notification";
import { SpeechError } from "../model/speech-error";
import { AppWindow } from "../model/app-window";
import { Observable } from "rxjs";
import { MedicationAutoSearchService } from "../../services/medicationAutoSearch/medication-auto-search.service";
import { GlobalVariables } from "src/app/globar-var/globarVariables";
import { Medication } from "../../models/Medication/Medication";
import { NotifyService } from "../../services/notify/notify.service";
const { webkitSpeechRecognition, webkitSpeechGrammarList }: AppWindow = <
  AppWindow
>window;
@Injectable()
export class SpeechRecognizerService {
  recognition: any;
  speechRecognitionList: any;
  startTimestamp;
  ignoreOnEnd: boolean;
  language: string;
  medicationNames: string[] = []; //To add them in grammar
  private static _medicationAutoSearchService;
  constructor(
    private _medicationAutoSearchService: MedicationAutoSearchService
  ) {
    SpeechRecognizerService._medicationAutoSearchService = this._medicationAutoSearchService;
  }
  initNewInstance() {
    return new SpeechRecognizerService(this._medicationAutoSearchService);
  }
  initialize(language?: string): void {
    if (webkitSpeechRecognition != null) {
      this.recognition = new webkitSpeechRecognition();
      var grammar =
        "#JSGF V1.0; grammar medWords; public <medWords> = disimpaction ;";
      let wakeWords = ["docvita", "doc vita", "Doc Vita"];
      var grammarWakeWord =
        "#JSGF V1.0; grammar medWords; public <wakeWords> = " +
        wakeWords.join(" | ") +
        " ;";
      this.speechRecognitionList = new webkitSpeechGrammarList();
      this.speechRecognitionList.addFromString(grammar, 1);
      this.speechRecognitionList.addFromString(grammarWakeWord, 1);
      this.recognition.grammars = this.speechRecognitionList;
      this.recognition.continuous = false;
      this.recognition.interimResults = true;
      this.setLanguage(language);
      this.addGrammarForMedications();
    } else {
    }
  }
  setLanguage(language?: string) {
    if (this.recognition != null) {
      if (language) {
        this.recognition.lang = language;
      } else {
        this.recognition.lang = "en-IN";
      }
    }
  }
  start(timestamp) {
    if (this.recognition != null) {
      this.startTimestamp = timestamp;
      this.recognition.start();
    }
  }
  isBrowserSupported() {
    return webkitSpeechRecognition != null;
  }
  onStart(): Observable<SpeechNotification> {
    if (!this.recognition && webkitSpeechRecognition != null) {
      this.initialize(this.language);
    }
    return new Observable(observer => {
      if (this.recognition != null) {
        this.recognition.onstart = () => {
          observer.next({
            info: "info_speak_now"
          });
        };
      } else {
      }
    });
  }
  onEnd(): Observable<SpeechNotification> {
    return new Observable(observer => {
      if (this.recognition != null) {
        this.recognition.onend = () => {
          if (this.ignoreOnEnd) {
            return;
          }
          observer.next({
            info: "info_start"
          });
        };
      } else {
        observer.next({});
      }
    });
  }
  onResult(): Observable<SpeechNotification> {
    return new Observable(observer => {
      if (this.recognition != null) {
        this.recognition.onresult = event => {
          let interimTranscript = "";
          let finalTranscript = "";
          for (let i = event.resultIndex; i < event.results.length; ++i) {
            if (event.results[i].isFinal) {
              finalTranscript += event.results[i][0].transcript;
            } else {
              interimTranscript += event.results[i][0].transcript;
            }
          }
          observer.next({
            info: "final_transcript",
            content: finalTranscript
          });
          observer.next({
            info: "interim_transcript",
            content: interimTranscript
          });
        };
      } else {
        observer.next({});
      }
    });
  }
  onError(): Observable<SpeechNotification> {
    return new Observable(observer => {
      if (this.recognition != null) {
        this.recognition.onerror = event => {
          let result: SpeechError;
          if (event.error === "no-speech") {
            result = SpeechError.NO_SPEECH;
            this.ignoreOnEnd = true;
          }
          if (event.error === "audio-capture") {
            result = SpeechError.NO_MICROPHONE;
            this.ignoreOnEnd = true;
          }
          if (event.error === "not-allowed") {
            if (event.timeStamp - this.startTimestamp < 100) {
              result = SpeechError.BLOCKED;
            } else {
              result = SpeechError.NOT_ALLOWED;
            }
            this.ignoreOnEnd = true;
          }
          observer.next({
            error: result
          });
        };
      } else {
      }
    });
  }
  onErrorWithNotification(
    notifyService: NotifyService
  ): Observable<SpeechNotification> {
    return new Observable(observer => {
      if (this.recognition != null) {
        this.recognition.onerror = event => {
          let result: SpeechError;
          if (event.error === "no-speech") {
            result = SpeechError.NO_SPEECH;
            this.ignoreOnEnd = true;
            notifyService.showErrorMessage(
              "No speech has been detected. Please try again."
            );
          }
          if (event.error === "audio-capture") {
            result = SpeechError.NO_MICROPHONE;
            this.ignoreOnEnd = true;
            notifyService.showErrorMessage(
              "Microphone is not available. Plese verify the connection of your microphone and try again."
            );
          }
          if (event.error === "not-allowed") {
            if (event.timeStamp - this.startTimestamp < 100) {
              result = SpeechError.BLOCKED;
            } else {
              result = SpeechError.NOT_ALLOWED;
            }
            notifyService.showErrorMessage(
              "Your browser is not authorized to access your microphone. Verify that your browser has access to your microphone and try again."
            );
            this.ignoreOnEnd = true;
          }
          observer.next({
            error: result
          });
        };
      } else {
      }
    });
  }
  stop() {
    this.recognition.stop();
  }
  addGrammarForMedications() {
    this._medicationAutoSearchService
      .allMedicineResults(GlobalVariables.getOrganisationId())
      .then(results => {
        let medications: Medication[] = <Medication[]>results;
        for (let m of medications) {
          if (m.name) {
            this.medicationNames.push(m.name);
          }
          if (m.composition) {
            this.medicationNames.push(m.composition);
          }
        }
        if (this.speechRecognitionList) {
          let gmr =
            "#JSGF V1.0; grammar medNames; public <medNames> = " +
            this.medicationNames.join(" | ") +
            " ;";
          this.speechRecognitionList.addFromString(gmr, 1);
          this.recognition.grammars = this.speechRecognitionList;
        }
      })
      .catch(err => {});
  }
}
