import { Injectable } from '@angular/core';
import { Appointment } from '../../models/Scheduling/Appointment';
import { Subject, Observable } from 'rxjs';
declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class ViewAppointmentDialogService {

  private subject = new Subject<Appointment>();
  constructor() { }
  open(appointment:Appointment){
     $('#view-appointment-dialog').modal("show");
    this.subject.next(appointment);
  }
  close(){
     $('#view-appointment-dialog').modal("hide");
  }
  public listener(): Observable<Appointment> {
    return this.subject.asObservable();
  }
}
