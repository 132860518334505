import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class ProviderUsernameCheckService {
  constructor(private _http: HttpClient) {}

  public checkAvailibilty(urlString: string) {
    return new Promise((resolve, reject) => {
      let body = {};
      body["shortLink"] = urlString;
      let val = this._http
        .post(
          "https://india-pro-test.sencillo.in/v1/qr-link/check-availability",
          body
        )
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }
}
