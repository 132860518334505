import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Patient } from '../../models/Patient/Patient';
declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class PatientProfileDialogService {
  private subjectModalOpened = new Subject<Patient>();
  constructor() { }
  open(patient:Patient){
    $('#patient-profile-dialog').modal("show");
    this.subjectModalOpened.next(patient);
  }
  close(){
    $('#patient-profile-dialog').modal("hide");
  }
  public modalListener(): Observable<Patient> {
    return this.subjectModalOpened.asObservable();
  }
}
