import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as elasticsearch from "elasticsearch-browser";

@Injectable({
  providedIn: 'root'
})
export class ElasticsearchHelperService {

  auth = environment.elasticSearchAPIKey.length > 0 ? environment.elasticSearchAPIKey : null;
  client = new elasticsearch.Client({
    host: environment.elasticSearch,
    httpAuth: this.auth,
  });
  constructor() { }

}

