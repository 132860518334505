import { Component, OnInit } from "@angular/core";
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationError
} from "@angular/router";
import { NotifyService } from "./shared/services/notify/notify.service";
import * as firebase from "firebase";
import { TokenRegistrationService } from "./shared/services/token-registration/token-registration.service";
import { GlobalVariables } from "./globar-var/globarVariables";
import { Helpers } from "./helpers";
import * as jquery from "jquery";
declare var $: any;
// console.log(`jQuery version: ${$.fn.jquery}`);
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html"
})
export class AppComponent implements OnInit {
  constructor(
    private _router: Router,
    private notifyService: NotifyService,
    private _tokenRegistrationService: TokenRegistrationService
  ) {}

  ngOnInit() {
    this._router.events.subscribe(route => {
      if (route instanceof NavigationStart) {
        // Helpers.setLoading(true);
      }
      if (route instanceof NavigationEnd || route instanceof NavigationError) {
        window.scrollTo(0, 0);
        // Initialize page: handlers ...
        Helpers.initPage();
        Helpers.setLoading(false);
      }
    });

    this.tokenRefreshListener();
  }

  playAudio() {
    let audio = new Audio();
    audio.src = "../assets/audio/notification_1_sound.wav";
    audio.load();
    audio.play();
  }

  tokenRefreshListener() {
    if (firebase.messaging.isSupported()) {
      let messaging = firebase.messaging();
      // Callback fired if Instance ID token is updated.
      messaging.onTokenRefresh(() => {
        messaging
          .getToken()
          .then(refreshedToken => {
            this._tokenRegistrationService.registerToken(
              GlobalVariables.getMemberId(),
              GlobalVariables.getOrganisationId(),
              refreshedToken
            );
          })
          .catch(err => {
            console.log("Unable to retrieve refreshed token ", err);
          });
      });
      // Handle incoming messages. Called when:
      // - a message is received while the app has focus
      // - the user clicks on an app notification created by a service worker
      //   `messaging.setBackgroundMessageHandler` handler.
      messaging.onMessage(payload => {
        // console.log("Message received. ", payload);
        const notificationTitle = "Foreground Message Title";
        const notificationOptions = {
          body: "Foreground Message body.",
          vibrate: [200, 100, 200, 100, 200, 100, 200],
          icon: "../assets/images/logo-sm.png"
        };
        if (!("Notification" in window)) {
          console.log("This browser does not support system notifications");
        }
        // Let's check whether notification permissions have already been granted
        else if (Notification.permission === "granted") {
          // If it's okay let's create a notification
          var notification = new Notification(
            notificationTitle,
            notificationOptions
          );
          notification.onclick = function(event) {
            console.log("Notification clicked");
          };
          this.playAudio();
        }
        // ...
      });
    }
  }
}
