import { Injectable } from "@angular/core";
import { Subject, Observable, BehaviorSubject } from "rxjs";
import { FileInfo } from "../../models/Patient/Dental/Reports";
declare var $: any;

@Injectable({
  providedIn: "root"
})
export class PatientInvestigationModalService {
  private subject = new Subject<FileInfo>();
  private reportData = new BehaviorSubject({});
  currentMessage = this.reportData.asObservable();
  constructor() {}

  changeMessage(data: {}) {
    this.reportData.next(data);
  }

  open(obj: FileInfo) {
    $("#patient-investigation-modal").modal("show");
    this.subject.next(obj);
  }
  close() {
    $("#patient-investigation-modal").modal("hide");
  }
  public listener(): Observable<FileInfo> {
    return this.subject.asObservable();
  }
}
