import { Injectable } from "@angular/core";
import { OrganisationMember } from "../../models/MD - Member";
import { Appointment } from "../../models/Scheduling/Appointment";
import { Subject, Observable } from "rxjs";
import { Patient } from "../../models/Patient/Patient";
declare var $: any;

/**
 * This service will handle states for schedule appointment modal
 */
@Injectable({
  providedIn: "root"
})
export class ScheduleAppointmentModalService {
  private subjectDoctor = new Subject<OrganisationMember>(); //This is to handle appointment booking flow with maintaining the selection of doctor
  private subjectAppointment = new Subject<Appointment>(); //This is to handle appointment booking flow with maintaining the selection of doctor
  private subject = new Subject<Patient>();
  private subjectModalOpenedAgain = new Subject<any>();

  constructor() {}

  public selectedAppointment(appointment: Appointment) {
    this.subjectAppointment.next(appointment);
  }

  public selectDoctor(doctor: OrganisationMember) {
    this.subjectDoctor.next(doctor);
  }

  public selectedPatient(user: Patient) {
    this.subject.next(user);
  }

  public clearPatient() {
    this.subject.next();
  }

  public getSelectedDoctor(): Observable<OrganisationMember> {
    return this.subjectDoctor.asObservable();
  }

  public getSelectedAppointment(): Observable<Appointment> {
    return this.subjectAppointment.asObservable();
  }

  public getSelectedPatient(): Observable<any> {
    return this.subject.asObservable();
  }

  /**
   * Open appointment side panel modal
   */
  public open() {
    $("#patient-appointment-schedule-modal-fs").modal("show");
    // $("#calendarInModal").fc({});
    // $("#patient-appointment-schedule-modal-fs").on(
    //   "shown.bs.modal",
    //   function() {
    //   }
    //   );
    // $("#calendarInModal").render();
    // console.log("Appointment Modal is Shown");

    //   $('#patient-appointment-schedule-modal-fs').on('shown.bs.modal', function () {
    //     $().fullCalendar('render');
    //  })
    this.subjectModalOpenedAgain.next();
  }

  /**
   * Open appointment side panel modal
   */
  public openWithJSON(json) {
    $("#patient-appointment-schedule-modal-fs").modal("show");
    this.subjectModalOpenedAgain.next(json);
  }

  /**
   * Open appointment side panel modal
   */
  public openForRescheduling() {
    $("#patient-appointment-schedule-modal-fs").modal("show");
    this.subjectModalOpenedAgain.next("rescheduling");
  }

  public close(): void {
    this.selectedAppointment(null);
    $("#patient-appointment-schedule-modal-fs").modal("hide");
  }

  public modalOpenedAgain(): Observable<any> {
    return this.subjectModalOpenedAgain.asObservable();
  }
}
