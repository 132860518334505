import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import * as urls from "../../../../environments/environment";
import { Appointment } from "../../models/Scheduling/Appointment";
import { Patient } from "../../models/Patient/Patient";
import { ObjectKeys } from "../../constants/object-keys";
import { GlobalVariables } from "src/app/globar-var/globarVariables";

@Injectable({
  providedIn: "root"
})
export class AppointmentOperationsService {
  constructor(private _http: HttpClient) {}

  //Book appointment
  public book(
    appointment: Appointment,
    selectedPatient: Patient[],
    patientIdToFollowUpIdMap?: {}
  ) {
    return new Promise((resolve, reject) => {
      let body = appointment.toJSON();
      if (patientIdToFollowUpIdMap) {
        body["patientIdToFollowUpIdMap"] = patientIdToFollowUpIdMap;
      }
      let patientsArray = [];
      selectedPatient.forEach(p => {
        let temp = {};
        temp["patientId"] = p.docvitaId;
        temp["patientName"] = p.getNameWithSalutation();
        if (p.gender) {
          temp["patientGender"] = p.gender;
        }
        if (p.age) {
          temp["patientAge"] = p.age;
        }
        if (p.ageObject) {
          temp["patientAgeObject"] = p.ageObject;
        }
        if (
          p.organisationConnections.get(GlobalVariables.getOrganisationId()) &&
          p.organisationConnections.get(GlobalVariables.getOrganisationId())
            .uhid &&
          p.organisationConnections.get(GlobalVariables.getOrganisationId())
            .uhid.length > 0
        ) {
          temp["uhid"] = p.organisationConnections.get(
            GlobalVariables.getOrganisationId()
          ).uhid;
        }
        if (
          p.primaryContactNumber &&
          p.primaryContactNumber.countryCode &&
          p.primaryContactNumber.number
        ) {
          temp["patientPrimaryContactNumber"] =
            p.primaryContactNumber.countryCode + p.primaryContactNumber.number;
        }
        if (p.primaryEmail) {
          temp["patientPrimaryEmail"] = p.primaryEmail;
        }
        patientsArray.push(temp);
      });
      body[ObjectKeys.patientsArray] = patientsArray;
      let val = this._http
        .post(urls.environment["book-appointment"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  //Complete appointment
  public complete(appointmentObject: Appointment) {
    return new Promise((resolve, reject) => {
      let body = appointmentObject.toJSON();
      let val = this._http
        .post(urls.environment["complete-appointment"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  //End Visit and Complete appointment
  public endAndComplete(appointmentObject: Appointment) {
    return new Promise((resolve, reject) => {
      let body = appointmentObject.toJSON();
      let val = this._http
        .post(urls.environment["end-complete-appointment"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  //Confirmed appointment
  // public confirm(appointmentObject: Appointment) {
  //   return new Promise((resolve, reject) => {
  //     let body = appointmentObject.toJSON();
  //     let val = this._http
  //       .post(urls.environment["confirm-appointment"], body)
  //       .subscribe(
  //         (res: Response) => {
  //           if (val != null) {
  //             resolve(res);
  //           }
  //         },
  //         err => {
  //           console.log(err);
  //           reject(err);
  //         }
  //       );
  //   });
  // }

  //Cancel appointment
  public cancel(appointmentObject: Appointment) {
    return new Promise((resolve, reject) => {
      let body = appointmentObject.toJSON();
      let val = this._http
        .post(urls.environment["cancel-appointment"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  //Delete appointment
  public delete(appointmentObject: Appointment) {
    return new Promise((resolve, reject) => {
      let params = {};
      let options = {};
      params["appointmentId"] = appointmentObject.appointmentId;
      params["organisationId"] = appointmentObject.organisationId;
      params["patientId"] = appointmentObject.patientId;
      params["date"] = appointmentObject.date;
      options["params"] = params;
      let val = this._http
        .delete(urls.environment["delete-appointment"], options)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  public sendAppointmentRemaindersManually(
    appointmentsArray: Array<Appointment>,
    organisationId
  ) {
    return new Promise((resolve, reject) => {
      let body = {};
      let appointmentJSONArray = [];
      appointmentsArray.forEach(appt => {
        appointmentJSONArray.push(appt.toJSON());
      });
      body["appointmentsArray"] = appointmentJSONArray;
      body[ObjectKeys.organisationId] = GlobalVariables.getOrganisationId();
      let val = this._http
        .post(urls.environment["send-appointments-remainder"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            // alert()
            console.log(err);
            reject(err);
          }
        );
    });
  }

  public printAppointmentList(
    locationId: string,
    doctorId: string,
    date: string
  ) {
    return new Promise((resolve, reject) => {
      let body = {};
      body[ObjectKeys.organisationId] = GlobalVariables.getOrganisationId();
      body[ObjectKeys.locationId] = locationId;
      body[ObjectKeys.doctorId] = doctorId;
      body[ObjectKeys.date] = date;
      let options = {};
      let headers = {
        "Content-Type": "application/pdf",
        Accept: "application/pdf"
      };
      options["headers"] = headers;
      options["responseType"] = "arraybuffer";
      let val = this._http
        .post(urls.environment["get-appointment-list"], body, options)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  //Arrive appointment
  public arrive(appointmentObject: Appointment) {
    return new Promise((resolve, reject) => {
      let body = appointmentObject.toJSON();
      let val = this._http
        .post(urls.environment["arrive-appointment"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  //Arrive appointment
  public noshow(appointmentObject: Appointment) {
    return new Promise((resolve, reject) => {
      let body = appointmentObject.toJSON();
      let val = this._http
        .put(urls.environment["noshow-appointment"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  //Reschedule appointment
  public reschedule(oldAppointment: Appointment, newAppointment: Appointment) {
    return new Promise((resolve, reject) => {
      let body = {};
      body["oldAppointmentJSON"] = oldAppointment.toJSON();
      body["newAppointmentJSON"] = newAppointment.toJSON();
      let val = this._http
        .post(urls.environment["reschedule-appointment"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  //Add to queue appointment
  public addToQueue(
    organisationId: string,
    organisationName: string,
    doctorId: string,
    doctorName: string,
    patient: Patient,
    date: string,
    locationId: string,
    locationName: string,
    duration?: number,
    locationGoogleMapsLink?: string,
    purposeOfVisitId?: string,
    purposeOfVisitTitle?: string,
    purposeOfVisitShortForm?: string
  ) {
    return new Promise((resolve, reject) => {
      let body = {};
      body[ObjectKeys.duration] = duration;
      body[ObjectKeys.organisationId] = organisationId;
      body[ObjectKeys.organisationName] = organisationName;
      body[ObjectKeys.doctorId] = doctorId;
      body[ObjectKeys.doctorName] = doctorName;
      body[ObjectKeys.date] = date;
      body[ObjectKeys.patientId] = patient.docvitaId;
      body[ObjectKeys.patientName] = patient.getNameWithSalutation();
      if (patient.age && patient.age > 0) {
        body[ObjectKeys.patientAge] = patient.age;
      }
      if (patient.ageObject) {
        body[ObjectKeys.patientAgeObject] = patient.ageObject;
      }
      if (patient.gender) {
        body[ObjectKeys.patientGender] = patient.gender;
      }
      if (
        patient.primaryContactNumber &&
        patient.primaryContactNumber.countryCode &&
        patient.primaryContactNumber.number
      ) {
        body["patientPrimaryContactNumber"] =
          patient.primaryContactNumber.countryCode +
          patient.primaryContactNumber.number;
      }
      if (patient.primaryEmail) {
        body[ObjectKeys.patientPrimaryEmail] = patient.primaryEmail;
      }
      body[ObjectKeys.locationId] = locationId;
      body[ObjectKeys.locationName] = locationName;
      if (locationGoogleMapsLink) {
        body[ObjectKeys.locationGoogleMapsLink] = locationGoogleMapsLink;
      }
      if (purposeOfVisitId) {
        body[ObjectKeys.purposeOfVisitId] = purposeOfVisitId;
      }
      if (purposeOfVisitTitle) {
        body[ObjectKeys.purposeOfVisitTitle] = purposeOfVisitTitle;
      }
      if (purposeOfVisitShortForm) {
        body[ObjectKeys.purposeOfVisitShortForm] = purposeOfVisitShortForm;
      }
      let val = this._http
        .post(urls.environment["add-to-queue"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  public generateManualToken(
    organisationId: string,
    date: string,
    upper: Appointment,
    lower: Appointment,
    mover: Appointment
  ) {
    return new Promise((resolve, reject) => {
      let body = {};
      body["organisationId"] = organisationId;
      body["date"] = date;
      body["upper"] = upper;
      body["lower"] = lower;
      body["mover"] = mover;
      let val = this._http
        .post(urls.environment["generate-manual-token"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  // update purpose of visit
  public purposeOfVisit(appointmentObject: Appointment) {
    return new Promise((resolve, reject) => {
      let body = appointmentObject.toJSON();
      let val = this._http
        .put(urls.environment["update-appointment-purpose"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  public startVisit(organisationId: string, appointment: Appointment) {
    return new Promise((resolve, reject) => {
      // let body = appointment.toJSON();
      let body = {};
      body["appointmentId"] = appointment.appointmentId;
      body["organisationId"] = appointment.organisationId;
      body["date"] = appointment.date;
      let val = this._http
        .put(urls.environment["create-start-visit-timestamp"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  public endVisit(organisationId: string, appointment: Appointment) {
    return new Promise((resolve, reject) => {
      let body = {};
      body["appointmentId"] = appointment.appointmentId;
      body["organisationId"] = organisationId;
      body["date"] = appointment.date;
      console.log(body);
      let val = this._http
        .put(urls.environment["create-end-visit-timestamp"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  public getNextAppointment(appointment: Appointment) {
    return new Promise((resolve, reject) => {
      let body = {};
      body["patientId"] = appointment.patientId;
      body["organisationId"] = appointment.organisationId;
      body["appointmentObject"] = appointment.toJSON();
      let val = this._http
        .post(urls.environment["get-next-appointment"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  public bookRequestedAppointment(appointment: Appointment) {
    return new Promise((resolve, reject) => {
      let body = appointment.toJSON();
      let val = this._http
        .post(urls.environment["book-requested-appointment"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  public rejectAppointment(appointment: Appointment) {
    return new Promise((resolve, reject) => {
      let body = appointment.toJSON();
      let val = this._http
        .post(urls.environment["reject-requested-appointment"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }
}
