import { Injectable } from "@angular/core";
import { Appointment } from "../../models/Scheduling/Appointment";
import { Subject, Observable } from "rxjs";
declare var $: any;

@Injectable({
  providedIn: "root"
})
export class PaymentDialogService {
  private subject = new Subject<Appointment>();
  constructor() {}
  open(appointment: Appointment) {
    $("#payment-dailog").modal("show");
    this.subject.next(appointment);
  }
  close() {
    $("#payment-dailog").modal("hide");
  }
  public listener(): Observable<Appointment> {
    return this.subject.asObservable();
  }
}
