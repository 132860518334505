import { BasicModelInterface } from "../../BasicModelInterface";
import { Treatment } from "./Treatment";
import { Examination } from "./Examination";
import { ObjectKeys } from "../../../constants/object-keys";

export class DentalChartEntry implements BasicModelInterface {
  private _dentalChartEntryId: string;
  private _caseHistoryId: string;
  private _createdOn: number;
  private _createdBy: string;
  private _createdByName: string;
  private _currentLoggedInMemberId: string;
  private _currentLoggedInMemberName: string;
  private _organisationId: string;
  private _patientId: string;
  private _teeth: string[] = [];
  private _isMinor: string;
  private _examinations: Map<string, Examination> = new Map<
    string,
    Examination
  >();
  private _treatments: Map<string, Treatment> = new Map<string, Treatment>();
  private _examinationsName: string; // For frontend
  private _treatmentsName: string; // For frontend
  private _remark: string;
  private _date: string;
  // dental case history
  // dental chart entry objects
  // examination and treatment obj
  //

  get dentalChartEntryId(): string {
    return this._dentalChartEntryId;
  }

  set dentalChartEntryId(value: string) {
    this._dentalChartEntryId = value;
  }

  get caseHistoryId(): string {
    return this._caseHistoryId;
  }

  set caseHistoryId(value: string) {
    this._caseHistoryId = value;
  }

  get createdOn(): number {
    return this._createdOn;
  }

  set createdOn(value: number) {
    this._createdOn = value;
  }

  get createdBy(): string {
    return this._createdBy;
  }

  set createdBy(value: string) {
    this._createdBy = value;
  }

  get createdByName(): string {
    return this._createdByName;
  }

  set createdByName(value: string) {
    this._createdByName = value;
  }

  get currentLoggedInMemberId(): string {
    return this._currentLoggedInMemberId;
  }

  set currentLoggedInMemberId(value: string) {
    this._currentLoggedInMemberId = value;
  }

  get currentLoggedInMemberName(): string {
    return this._currentLoggedInMemberName;
  }

  set currentLoggedInMemberName(value: string) {
    this._currentLoggedInMemberName = value;
  }

  get organisationId(): string {
    return this._organisationId;
  }

  set organisationId(value: string) {
    this._organisationId = value;
  }

  get patientId(): string {
    return this._patientId;
  }

  set patientId(value: string) {
    this._patientId = value;
  }

  get teeth(): string[] {
    return this._teeth;
  }

  set teeth(value: string[]) {
    this._teeth = value;
  }

  get isMinor(): string {
    return this._isMinor;
  }

  set isMinor(value: string) {
    this._isMinor = value;
  }

  get examinations(): Map<string, Examination> {
    return this._examinations;
  }

  set examinations(value: Map<string, Examination>) {
    this._examinations = value;
  }

  get treatments(): Map<string, Treatment> {
    return this._treatments;
  }

  set treatments(value: Map<string, Treatment>) {
    this._treatments = value;
  }

  get examinationsName(): string {
    return this._examinationsName;
  }

  set examinationsName(value: string) {
    this._examinationsName = value;
  }

  get treatmentsName(): string {
    return this._treatmentsName;
  }

  set treatmentsName(value: string) {
    this._treatmentsName = value;
  }

  get remark(): string {
    return this._remark;
  }

  set remark(value: string) {
    this._remark = value;
  }

  get date(): string {
    return this._date;
  }

  set date(value: string) {
    this._date = value;
  }

  initFromJSON(json: {}): void {
    if (json[ObjectKeys.id]) {
      this.dentalChartEntryId = json[ObjectKeys.id];
    }
    if (json[ObjectKeys.caseHistoryId]) {
      this.caseHistoryId = json[ObjectKeys.caseHistoryId];
    }
    if (json[ObjectKeys.createdOn]) {
      this.createdOn = json[ObjectKeys.createdOn];
    }
    if (json[ObjectKeys.createdBy]) {
      this.createdBy = json[ObjectKeys.createdBy];
    }
    if (json[ObjectKeys.createdByName]) {
      this.createdByName = json[ObjectKeys.createdByName];
    }
    if (json[ObjectKeys.currentlyLoggedInMemberId]) {
      this.currentLoggedInMemberId = json[ObjectKeys.currentlyLoggedInMemberId];
    }
    if (json[ObjectKeys.currentlyLoggedInMemberName]) {
      this.currentLoggedInMemberName =
        json[ObjectKeys.currentlyLoggedInMemberName];
    }
    if (json[ObjectKeys.organisationId]) {
      this.organisationId = json[ObjectKeys.organisationId];
    }
    if (json[ObjectKeys.patientId]) {
      this.patientId = json[ObjectKeys.patientId];
    }
    if (json[ObjectKeys.teeth]) {
      json[ObjectKeys.teeth].forEach(value => {
        this.teeth.push(value);
      });
    }
    if (json[ObjectKeys.isMinor]) {
      this.isMinor = json[ObjectKeys.isMinor];
    }
    if (json[ObjectKeys.examinations]) {
      const temp = json[ObjectKeys.examinations];
      let map: Map<string, Examination> = new Map<string, Examination>();
      for (let k in temp) {
        let a = new Examination();
        a.initFromJSON(temp[k]);
        map.set(k, a);
      }
      this.examinations = map;
      this.examinationsName = this.getExaminationForTable();
    }
    if (json[ObjectKeys.treatments]) {
      const temp = json[ObjectKeys.treatments];
      let map: Map<string, Treatment> = new Map<string, Treatment>();
      for (let k in temp) {
        let a = new Treatment();
        a.initFromJSON(temp[k]);
        map.set(k, a);
      }
      this.treatments = map;
      this.treatmentsName = this.getTreatmentForTable();
    }
    if (json[ObjectKeys.remark]) {
      this.remark = json[ObjectKeys.remark];
    }
    if (json[ObjectKeys.date]) {
      this.date = json[ObjectKeys.date];
    }
  }

  toJSON(): {} {
    let json = {};
    if (this.dentalChartEntryId) {
      json[ObjectKeys.id] = this.dentalChartEntryId;
    }
    if (this.caseHistoryId) {
      json[ObjectKeys.caseHistoryId] = this.caseHistoryId;
    }
    if (this.createdOn) {
      json[ObjectKeys.createdOn] = this.createdOn;
    }
    if (this.createdBy) {
      json[ObjectKeys.createdBy] = this.createdBy;
    }
    if (this.createdByName) {
      json[ObjectKeys.createdByName] = this.createdByName;
    }
    if (this.currentLoggedInMemberId) {
      json[ObjectKeys.currentlyLoggedInMemberId] = this.currentLoggedInMemberId;
    }
    if (this.currentLoggedInMemberName) {
      json[
        ObjectKeys.currentlyLoggedInMemberName
      ] = this.currentLoggedInMemberName;
    }
    if (this.organisationId) {
      json[ObjectKeys.organisationId] = this.organisationId;
    }
    if (this.patientId) {
      json[ObjectKeys.patientId] = this.patientId;
    }
    if (this.teeth) {
      json[ObjectKeys.teeth] = this.teeth;
    }
    if (this.isMinor) {
      json[ObjectKeys.isMinor] = this.isMinor;
    }
    if (this.examinations) {
      let temp = {};
      this.examinations.forEach((value, key) => {
        temp[key] = value.toJSON();
      });
      json[ObjectKeys.examinations] = temp;
    }
    if (this.treatments) {
      let temp = {};
      this.treatments.forEach((value, key) => {
        temp[key] = value.toJSON();
      });
      json[ObjectKeys.treatments] = temp;
    }
    if (this.remark) {
      json[ObjectKeys.remark] = this.remark;
    }
    if (this.date) {
      json[ObjectKeys.date] = this.date;
    }
    return json;
  }

  public getExaminationForTable() {
    let examinations1 = "";

    this.examinations.forEach(value => {
      if (value.groupLabel) {
        examinations1 += value.groupLabel + " - " + value.label + ", ";
      } else {
        examinations1 += value.label + ", ";
      }
    });

    return examinations1.replace(/,\s*$/, "");
  }

  public getTreatmentForTable() {
    let treatments1 = "";

    this.treatments.forEach(value => {
      if (value.groupLabel) {
        treatments1 += value.groupLabel + " - " + value.label + ", ";
      } else {
        treatments1 += value.label + ", ";
      }
    });

    return treatments1.replace(/,\s*$/, "");
  }
}
