import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { PatientProfileDialogService } from "../../services/modalStateManagement/patient-profile-dialog.service";
import { Patient } from "../../models/Patient/Patient";
// import { PatientProfileService } from "../../services/patient/patient-profile.service";
// import { PatientRegistrationModalService } from "../../services/modalStateManagement/patient-registration-modal.service";

@Component({
  selector: "app-patient-profile-dialog",
  templateUrl: "./patient-profile-dialog.component.html",
  styleUrls: ["./patient-profile-dialog.component.css"]
})
export class PatientProfileDialogComponent implements OnInit {
  @Output() addToQueue = new EventEmitter<Patient>();

  @Output() scheduleVisit = new EventEmitter<Patient>();

  showPatientProfile: Patient = new Patient();
  patient: Patient = new Patient();
  constructor(
    private _patientProfileDialogService: PatientProfileDialogService
  ) {}

  ngOnInit() {
    this._patientProfileDialogService.modalListener().subscribe(val => {
      if (val) {
        this.patient = val;
      }
    });
  }

  closeThisModal() {
    this._patientProfileDialogService.close();
  }
  requestToAddToQueue(success: boolean, patient: Patient) {
    this.addToQueue.emit(patient);
    this.closeProfile();
  }
  closeProfile() {
    this.showPatientProfile = new Patient();
  }
}
