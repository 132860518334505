import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as urls from "../../../../environments/environment";
import { ObjectKeys } from "../../constants/object-keys";

@Injectable({
  providedIn: 'root'
})
export class OpdScheduleOperationsService {

  constructor(private _http: HttpClient) { }

  //Add timeslot in weekly schedule
  public saveNewSlotInWeeklySchedule = (day:string,organisationId:string,locationId:string,slot:{},memberId:string)=>{
    return new Promise((resolve, reject) => {
      let body = {day,organisationId,locationId,slot,memberId}
      let val = this._http
        .post(urls.environment["weekly-slot"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  //delete timeslot from weekly schedule
  public deleteSlotInWeeklySchedule = (day:string,organisationId:string,locationId:string,slot:{},memberId:string)=>{
    return new Promise((resolve, reject) => {
      let body = {}
      body["day"] = day;
      body["organisationId"] = organisationId;
      body["locationId"] = locationId;
      body["slot"] = slot;
      body["memberId"] = memberId;
      let options = {}
      options["params"]=body
      let val = this._http
        .delete(urls.environment["weekly-slot"], options)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }
  //update timeslot in weekly schedule
  public updateSlotInWeeklySchedule = (day:string,organisationId:string,locationId:string,slot:{},oldSlot:{},memberId:string)=>{
    return new Promise((resolve, reject) => {
      let body = {}
      body["day"] = day;
      body["organisationId"] = organisationId;
      body["locationId"] = locationId;
      body["slot"] = slot;
      body["oldSlot"] = oldSlot;
      body["memberId"] = memberId;
      let val = this._http
        .put(urls.environment["weekly-slot"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  //Add timeslot in specific availability schedule
  public createDateSpecificAvailabilitySlot = (date:string,organisationId:string,locationId:string,slot:{},memberId:string)=>{
    return new Promise((resolve, reject) => {
      let body = {date,organisationId,locationId,slot,memberId}
      let val = this._http
        .post(urls.environment["specific-availability-slot"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }
  //delete timeslot from specific availability schedule
  public deleteDateSpecificAvailabilitySlot = (date:string,organisationId:string,locationId:string,slot:{},memberId:string)=>{
    return new Promise((resolve, reject) => {
      let body = {}
      body["date"] = date;
      body["organisationId"] = organisationId;
      body["locationId"] = locationId;
      body["slot"] = slot;
      body["memberId"] = memberId;
      let options = {}
      options["params"]=body
      let val = this._http
        .delete(urls.environment["specific-availability-slot"], options)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }
  //update timeslot in specific availability schedule
  public updateDateSpecificAvailabilitySlot = (date:string,organisationId:string,locationId:string,slot:{}, oldSlot:{},memberId:string)=>{
    return new Promise((resolve, reject) => {
      let body = {}
      body["date"] = date;
      body["organisationId"] = organisationId;
      body["locationId"] = locationId;
      body["slot"] = slot;
      body["oldSlot"] = oldSlot;
      body["memberId"] = memberId;
      let val = this._http
        .put(urls.environment["specific-availability-slot"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }
  
  //Add timeslot in specific unavailability schedule
  public createDateSpecificUnavailabilitySlot = (date:string,organisationId:string,locationId:string,slot:{},memberId:string)=>{
    return new Promise((resolve, reject) => {
      let body = {}
      body["date"] = date;
      body["organisationId"] = organisationId;
      body["locationId"] = locationId;
      body["slot"] = slot;
      body["memberId"] = memberId;
      let options = {}
      options["params"]=body
      let val = this._http
        .delete(urls.environment["specific-unavailability-slot"], options)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }
  //delete timeslot from specific unavailability schedule
  public deleteDateSpecificUnavailabilitySlot = (date:string,organisationId:string,locationId:string,slot:{},memberId:string)=>{
    return new Promise((resolve, reject) => {
      let body = {}
      body["date"] = date;
      body["organisationId"] = organisationId;
      body["locationId"] = locationId;
      body["slot"] = slot;
      body["memberId"] = memberId;
      let options = {}
      options["params"]=body
      let val = this._http
        .delete(urls.environment["specific-unavailability-slot"],options)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }
  //update timeslot in specific unavailability schedule
  public updateDateSpecificUnavailabilitySlot = (date:string,organisationId:string,locationId:string,slot:{}, oldSlot:{},memberId:string)=>{
    return new Promise((resolve, reject) => {
      let body = {}
      body["date"] = date;
      body["organisationId"] = organisationId;
      body["locationId"] = locationId;
      body["slot"] = slot;
      body["oldSlot"] = oldSlot;
      body["memberId"] = memberId;
      let val = this._http
        .put(urls.environment["specific-unavailability-slot"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  //Fetch doctor schedule by location and date
  public fetchDoctorScheduleForDateAndLocation = (date:string, organisationId:string, locationId:string, doctorId:string)=>{
    return new Promise((resolve, reject) => {
      let options = {};
      let params = {};
      params["date"] = date;
      params["organisationId"] = organisationId;
      params["locationId"] = locationId;
      params["doctorId"] = doctorId;
      options["params"] = params;
      let val = this._http
        .get(urls.environment["fetch-member-schedule"], options)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  public fetchDoctorScheduleSlotsForDateAndLocation = (date:string, organisationId:string, locationId:string, doctorId:string)=>{
    return new Promise((resolve, reject) => {
      let options = {};
      let params = {};
      params["date"] = date;
      params["organisationId"] = organisationId;
      params["locationId"] = locationId;
      params["memberId"] = doctorId;
      options["params"] = params;
      let val = this._http
        .get(urls.environment["fetch-member-slots"], options)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  public fetchDoctorScheduleSlotsForDateAndLocationWithMultipleDates = (start_date:string
    , organisationId:string, locationId:string, doctorId:string,
    days:number)=>{
    return new Promise((resolve, reject) => {
      let options = {};
      let params = {};
      params["start_date"] = start_date;
      params["organisationId"] = organisationId;
      params["locationId"] = locationId;
      params["memberId"] = doctorId;
      params["days"] = days;
      options["params"] = params;
      let val = this._http
        .get(urls.environment["fetch-member-slots-with-multiple-dates"], options)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  //Fetch all locations list from organisation profile
  public fetchAvailableDoctorsForSpecificDateAndLocation = (date:string, organisationId:string, locationId:string)=>{
    return new Promise((resolve, reject) => {
      let options = {};
      let params = {};
      params["date"] = date;
      params["organisationId"] = organisationId;
      params["locationId"] = locationId;
      options["params"] = params;
      let val = this._http
        .get(urls.environment["list-of-available-doctors"], options)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }
  
  
}
