import { Component, OnInit, Input, Output } from "@angular/core";
import {
  VoiceAssistantMessage,
  VoiceAssistantMessageStatus
} from "src/app/shared/models/VoiceAssistantMessage";
import { VoiceService } from "src/app/shared/services/voice/voice.service";
import { SideSectionDialogManagementService } from "src/app/shared/services/component-communication/side-section-dialog-management.service";
import { SpeechSynthesizerService } from "src/app/shared/web-speech/services/speech-synthesizer.service";
import { ScheduleAppointmentModalService } from "src/app/shared/services/modalStateManagement/schedule-appointment-modal.service";
import { MedicationStatus } from "../../shared/models/Medication/Medication";
import { PatientProfileSyncService } from "src/app/shared/services/component-communication/patient-profile-sync.service";
import { Router } from "@angular/router";
import { AssistantStateManagementService } from "src/app/shared/services/component-communication/asssitant-state-management.service";

@Component({
  selector: "app-voice-assistant-message-cell",
  templateUrl: "./voice-assistant-message-cell.component.html",
  styleUrls: ["./voice-assistant-message-cell.component.css"]
})
export class VoiceAssistantMessageCellComponent implements OnInit {
  VoiceAssistantMessageStatus = VoiceAssistantMessageStatus;
  @Input() message: VoiceAssistantMessage;
  lottieConfig: any;

  constructor(
    private _voiceService: VoiceService,
    private _speechSynthesizerService: SpeechSynthesizerService,
    private _scheduleAppointmentModalService: ScheduleAppointmentModalService,
    private _sideSectionDialogManagementService: SideSectionDialogManagementService,
    private _patientProfileSyncService: PatientProfileSyncService,
    private _assistantStateManagementService: AssistantStateManagementService,
    private _router: Router
  ) {
    this.lottieConfig = {
      path: "../../../assets/lottie/google-voice-assistant.json",
      renderer: "canvas",
      autoplay: true,
      loop: true
    };
    this._speechSynthesizerService.initSynthesis();
  }

  ngOnInit() {
    if (
      this.message.networkCallJSON != null &&
      this.message.networkCallJSON["networkCallDone"] == null
    ) {
      this.contactBrain();
    }
  }

  contactBrain() {
    this.message.networkCallJSON["networkCallDone"] = true;
    //Contacting brain
    this._voiceService
      .dialog(this.message.networkCallJSON["text"])
      .then(resp => {
        this.message.text = "Done";
        this.message.status = VoiceAssistantMessageStatus.SENT_BY_ASSISTANT;
        if (resp && resp["statusCode"] && resp["statusCode"] == 200) {
          let body = resp["body"];
          let intent = body["intent"];
          let json = body["json"];
          switch (intent) {
            case "APPOINTMENT": {
              let pId = this._patientProfileSyncService.extractPatientIdFromPath(
                this._router.url
              );
              if (
                this._assistantStateManagementService
                  .currentMetaDataForAssistant &&
                this._assistantStateManagementService
                  .currentMetaDataForAssistant["patientId"]
              ) {
                pId = this._assistantStateManagementService
                  .currentMetaDataForAssistant["patientId"];
              }
              if (pId != null) {
                this._patientProfileSyncService.getPatient(pId);
                this._scheduleAppointmentModalService.openWithJSON({
                  type: "patientId",
                  patientId: pId
                });
              } else {
                this._scheduleAppointmentModalService.open();
              }
              setTimeout(time => {
                this._voiceService.setAppointmentJSON(json);
              }, 110);
              break;
            }
            case "MEDICINE": {
              let medForSending = this._voiceService.convertMedicationJSONtoObject(
                json
              );
              let spokenMedicationCommand = this.message.networkCallJSON[
                "text"
              ];
              medForSending.spokenCommands.push(spokenMedicationCommand);
              this._sideSectionDialogManagementService.sendBackToPrescription(
                medForSending,
                MedicationStatus.converted_nlu
              );
              // this._voiceService.setMedicationJSON(json);
              // setTimeout(time => {
              //   this._voiceService.clearMedicationJSON();
              // }, 1000); //Clear values so that when you open a new medicine form it will not come pre populated with old voice command json
              break;
            }
            case "VITAL": {
              this._voiceService.setVitalJSON(json);
              setTimeout(time => {
                this._voiceService.clearVitalJSON();
              }, 1000); //Clear values so that when you open a new medicine form it will not come pre populated with old voice command json
              break;
            }
            case "BLOOD_GROUP": {
              this._voiceService.setBloodGroup(json["blood_group_type"]);
              setTimeout(time => {
                this._voiceService.setBloodGroup(null);
              }, 1000); //Clear values
              break;
            }
            case "ALLERGY": {
              this._voiceService.setAllergy(json);
              setTimeout(time => {
                this._voiceService.setAllergy(null);
              }, 1000);
              break;
            }
            case "DIAGNOSIS": {
              this._voiceService.setDiagnosisEntry(json);
              setTimeout(time => {
                this._voiceService.setDiagnosisEntry(null);
              }, 1000);
              break;
            }
            case "PATIENT_HISTORY": {
              this._voiceService.setPastHistory(json);
              setTimeout(time => {
                this._voiceService.setPastHistory(null);
              }, 1000);
              break;
            }
            case "NEW_PRESCRIPTION": {
              if (
                this._assistantStateManagementService
                  .currentMetaDataForAssistant &&
                this._assistantStateManagementService
                  .currentMetaDataForAssistant["patientId"]
              ) {
                let url = "";
                if (
                  this._assistantStateManagementService
                    .currentMetaDataForAssistant["path"]
                ) {
                  url = this._assistantStateManagementService
                    .currentMetaDataForAssistant["path"];
                  url +=
                    "/patient-detail/" +
                    this._assistantStateManagementService
                      .currentMetaDataForAssistant["patientId"] +
                    "/prescriptions/new";
                  url += "?source=opd-list";
                  this._router.navigateByUrl(url);
                } else {
                  url =
                    "patient-detail/" +
                    this._assistantStateManagementService
                      .currentMetaDataForAssistant["patientId"] +
                    "/prescriptions/new";
                  this._router.navigate([url], {
                    queryParams: { source: "opd-list" }
                  });
                }
              }
              this._voiceService.setNewPrescription(json);
              setTimeout(time => {
                this._voiceService.setNewPrescription(null);
              }, 1000);
              break;
            }
            case "PRESCRIPTION_TEMPLATE": {
              this._voiceService.setPrescriptionTemplate(json);
              setTimeout(time => {
                this._voiceService.clearPrescriptionTemplate();
              }, 1000);
              break;
            }
          }
        } else {
          //handle it later
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}
