import { BasicModelInterface } from "./BasicModelInterface";

export enum VoiceAssistantMessageStatus {
  SENT_BY_USER = 1,
  SENT_BY_ASSISTANT = 2,
  WAITING_FOR_RESPONSE = 3,
  ASSISTANT_SUGGESTED_COMMAND_ACTIONS = 4
}

export class VoiceAssistantMessage {
  private _id: string;
  private _text: string;
  private _status: VoiceAssistantMessageStatus; //This is enum
  // private _showOnLeft: boolean; //Just for the UI adjustment
  private _timestamp: number;
  private _networkCallJSON: {};

  initialize(id, status, text?) {
    this.id = id;
    if (text) {
      this.text = text;
    }
    this.status = status;
    // this.canShowOnLeft(status);
    this.timestamp = new Date().getTime();
  }

  /**
   * Getter networkCallJSON
   * @return {{}}
   */
  public get networkCallJSON(): {} {
    return this._networkCallJSON;
  }

  /**
   * Setter networkCallJSON
   * @param {{}} value
   */
  public set networkCallJSON(value: {}) {
    this._networkCallJSON = value;
  }

  /**
   * Getter timestamp
   * @return {number}
   */
  public get timestamp(): number {
    return this._timestamp;
  }

  /**
   * Setter timestamp
   * @param {number} value
   */
  public set timestamp(value: number) {
    this._timestamp = value;
  }

  /**
   * Getter id
   * @return {string}
   */
  public get id(): string {
    return this._id;
  }

  /**
   * Getter text
   * @return {string}
   */
  public get text(): string {
    return this._text;
  }

  /**
   * Getter status
   * @return {VoiceAssistantMessageStatus}
   */
  public get status(): VoiceAssistantMessageStatus {
    return this._status;
  }

  /**
   * Getter showOnLeft
   * @return {boolean}
   */
  public get showOnLeft(): boolean {
    // return this._showOnLeft;
    switch (this.status) {
      case VoiceAssistantMessageStatus.SENT_BY_ASSISTANT:
        return true;
        break;
      case VoiceAssistantMessageStatus.SENT_BY_USER:
        return false;
        break;
      case VoiceAssistantMessageStatus.WAITING_FOR_RESPONSE:
        return true;
        break;
      default:
        return true;
        break;
    }
  }

  /**
   * Setter id
   * @param {string} value
   */
  public set id(value: string) {
    this._id = value;
  }

  /**
   * Setter text
   * @param {string} value
   */
  public set text(value: string) {
    this._text = value;
  }

  /**
   * Setter status
   * @param {VoiceAssistantMessageStatus} value
   */
  public set status(value: VoiceAssistantMessageStatus) {
    this._status = value;
    // this.canShowOnLeft(this.status);
  }

  // /**
  //  * Setter showOnLeft
  //  * @param {boolean} value
  //  */
  // public set showOnLeft(value: boolean) {
  //   this._showOnLeft = value;
  // }

  /**
   * Getter isWaitingForResponse
   * @return {boolean}
   */
  public get isWaitingForResponse(): boolean {
    return this.isType(VoiceAssistantMessageStatus.WAITING_FOR_RESPONSE);
    // return this.status == ;
  }

  public isType(val: VoiceAssistantMessageStatus): boolean {
    return this.status == val;
  }

  // canShowOnLeft(val: VoiceAssistantMessageStatus) {
  //   if (val == VoiceAssistantMessageStatus.SENT_BY_USER) {
  //     this.showOnLeft = false;
  //   } else {
  //     this.showOnLeft = true;
  //   }
  // }
}
