import { Person, PersonAccountStatus } from "./Person";
import { AddressObject, OrganisationLocationObject } from "./Demographics";
import { BasicModelInterface } from "./BasicModelInterface";
import { ObjectKeys } from "../constants/object-keys";

export class OrganisationMember extends Person implements BasicModelInterface {
  // Unique Identifiers for our system
  private _docvitaId: string;

  // Basic Info - Inherit from Person Object

  // Employment Information Map: Organisation Id -> Membership Details
  private _organisationMembership: Map<string, OrganisationMembershipDetails>;

  private _isAuthorisedToWritePrescription: boolean; // if this exists
  private _medicalProfessionalMeta: MedicalProfessionalMeta;
  // Prescription Preferences
  private _prescriptionPrintSettings: Map<string, PrescriptionPrintSettings>;
  private _prescriptionMarginLeft: number;
  private _prescriptionMarginRight: number;
  private _prescriptionSignature: string;

  constructor() {
    super();
  }

  toJSON(): {} {
    let json = super.toJSON();
    if (this.docvitaId) {
      json[ObjectKeys.docvitaId] = this.docvitaId;
    }
    if (this.isAuthorisedToWritePrescription) {
      json[
        ObjectKeys.isAuthorisedToWritePrescription
      ] = this.isAuthorisedToWritePrescription;
    }
    if (this.medicalProfessionalMeta) {
      json[
        ObjectKeys.medicalProfessionalMeta
      ] = this.medicalProfessionalMeta.toJSON();
    }
    if (this.organisationMembership) {
      let temp = {};
      this.organisationMembership.forEach((value, key) => {
        temp[key] = value.toJSON();
      });
      json[ObjectKeys.organisationMembership] = temp;
    }
    if (this.prescriptionMarginLeft) {
      json[ObjectKeys.prescriptionMarginLeft] = this.prescriptionMarginLeft;
    }
    if (this.prescriptionMarginRight) {
      json[ObjectKeys.prescriptionMarginRight] = this.prescriptionMarginRight;
    }
    if (this.prescriptionSignature) {
      json[ObjectKeys.prescriptionSignature] = this.prescriptionSignature;
    }
    if (this.prescriptionPrintSettings) {
      let temp = {};
      this.prescriptionPrintSettings.forEach((value, key) => {
        temp[key] = value.toJSON();
      });
      json[ObjectKeys.prescriptionPrintSettings] = temp;
    }
    return json;
  }
  initFromJSON(json: {}): void {
    super.initFromJSON(json);
    if (json[ObjectKeys.docvitaId]) {
      this.docvitaId = json[ObjectKeys.docvitaId];
    }
    if (json[ObjectKeys.prescriptionMarginLeft]) {
      this.prescriptionMarginLeft = json[ObjectKeys.prescriptionMarginLeft];
    }
    if (json[ObjectKeys.prescriptionSignature]) {
      this.prescriptionSignature = json[ObjectKeys.prescriptionSignature];
    }
    if (json[ObjectKeys.prescriptionMarginRight]) {
      this.prescriptionMarginRight = json[ObjectKeys.prescriptionMarginRight];
    }
    if (json[ObjectKeys.isAuthorisedToWritePrescription]) {
      this.isAuthorisedToWritePrescription =
        json[ObjectKeys.isAuthorisedToWritePrescription];
    }
    if (json[ObjectKeys.medicalProfessionalMeta]) {
      let obj = new MedicalProfessionalMeta();
      obj.initFromJSON(json[ObjectKeys.medicalProfessionalMeta]);
      this.medicalProfessionalMeta = obj;
    }
    if (json[ObjectKeys.organisationMembership]) {
      let map = new Map<string, OrganisationMembershipDetails>();
      let temp = json[ObjectKeys.organisationMembership];
      for (let t in temp) {
        let organisationMembershipObj: OrganisationMembershipDetails = new OrganisationMembershipDetails();
        organisationMembershipObj.initFromJSON(temp[t]);
        map.set(t, organisationMembershipObj);
      }
      this.organisationMembership = map;
    }
    if (json[ObjectKeys.prescriptionPrintSettings]) {
      let map = new Map<string, PrescriptionPrintSettings>();
      let temp = json[ObjectKeys.prescriptionPrintSettings];
      for (let t in temp) {
        let obj: PrescriptionPrintSettings = new PrescriptionPrintSettings();
        obj.initFromJSON(temp[t]);
        map.set(t, obj);
      }
      this.prescriptionPrintSettings = map;
    }
  }

  /**
   * Getter prescriptionSignature
   * @return {string}
   */
  public get prescriptionSignature(): string {
    return this._prescriptionSignature;
  }

  /**
   * Setter prescriptionSignature
   * @param {string} value
   */
  public set prescriptionSignature(value: string) {
    this._prescriptionSignature = value;
  }

  /**
   * Getter prescriptionMarginRight
   * @return {number}
   */
  public get prescriptionMarginRight(): number {
    return this._prescriptionMarginRight;
  }

  /**
   * Setter prescriptionMarginRight
   * @param {number} value
   */
  public set prescriptionMarginRight(value: number) {
    this._prescriptionMarginRight = value;
  }

  /**
   * Getter prescriptionMarginLeft
   * @return {number}
   */
  public get prescriptionMarginLeft(): number {
    return this._prescriptionMarginLeft;
  }

  /**
   * Setter prescriptionMarginLeft
   * @param {number} value
   */
  public set prescriptionMarginLeft(value: number) {
    this._prescriptionMarginLeft = value;
  }

  /**
   * Getter docvitaId
   * @return {string}
   */
  public get docvitaId(): string {
    return this._docvitaId;
  }

  /**
   * Setter docvitaId
   * @param {string} value
   */
  public set docvitaId(value: string) {
    this._docvitaId = value;
  }

  /**
   * Getter organisationMembership
   * @return {Map<string, OrganisationMembershipDetails>}
   */
  public get organisationMembership(): Map<
    string,
    OrganisationMembershipDetails
  > {
    return this._organisationMembership;
  }

  /**
   * Setter organisationMembership
   * @param {Map<string, OrganisationMembershipDetails>} value
   */
  public set organisationMembership(
    value: Map<string, OrganisationMembershipDetails>
  ) {
    this._organisationMembership = value;
  }

  /**
   * Getter isAuthorisedToWritePrescription
   * @return {boolean}
   */
  public get isAuthorisedToWritePrescription(): boolean {
    return this._isAuthorisedToWritePrescription;
  }

  /**
   * Setter isAuthorisedToWritePrescription
   * @param {boolean} value
   */
  public set isAuthorisedToWritePrescription(value: boolean) {
    this._isAuthorisedToWritePrescription = value;
  }

  /**
   * Getter medicalProfessionalMeta
   * @return {MedicalProfessionalMeta}
   */
  public get medicalProfessionalMeta(): MedicalProfessionalMeta {
    return this._medicalProfessionalMeta;
  }

  /**
   * Setter medicalProfessionalMeta
   * @param {MedicalProfessionalMeta} value
   */
  public set medicalProfessionalMeta(value: MedicalProfessionalMeta) {
    this._medicalProfessionalMeta = value;
  }

  /**
   * Getter prescriptionPrintSettings
   * @return {Map<string, PrescriptionPrintSettings>}
   */
  public get prescriptionPrintSettings(): Map<
    string,
    PrescriptionPrintSettings
  > {
    return this._prescriptionPrintSettings;
  }

  /**
   * Setter prescriptionPrintSettings
   * @param {Map<string, PrescriptionPrintSettings>} value
   */
  public set prescriptionPrintSettings(
    value: Map<string, PrescriptionPrintSettings>
  ) {
    this._prescriptionPrintSettings = value;
  }

  /**
   * helper function for getting member's designation
   * @param organisationId
   */
  public getDesignation(organisationId: string): string {
    if (
      this.organisationMembership &&
      this.organisationMembership.has(organisationId)
    ) {
      return this.organisationMembership.get(organisationId).designation;
    } else {
      return null;
    }
  }

  /**
   * helper function for can delete patient permission check
   * @param organisationId
   */
  public canRemovePatient(organisationId: string): boolean {
    if (
      this.organisationMembership &&
      this.organisationMembership.has(organisationId) &&
      this.organisationMembership.get(organisationId).modules_array &&
      this.organisationMembership
        .get(organisationId)
        .modules_array.includes("can_remove_patient")
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * helper function for can delete patient permission check
   * @param organisationId
   */
  public canPrintSchedule(organisationId: string): boolean {
    if (
      this.organisationMembership &&
      this.organisationMembership.has(organisationId) &&
      this.organisationMembership.get(organisationId).modules_array &&
      this.organisationMembership
        .get(organisationId)
        .modules_array.includes("can_print_schedule")
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * helper function for consent form permission check
   * @param organisationId
   */
  public canAccessConsentForm(organisationId: string): boolean {
    if (
      this.organisationMembership &&
      this.organisationMembership.has(organisationId) &&
      this.organisationMembership.get(organisationId).modules_array &&
      this.organisationMembership
        .get(organisationId)
        .modules_array.includes("consent_form_module_on")
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * helper function for dentistry permission check
   * @param organisationId
   */
  public canAccessDentistry(organisationId: string): boolean {
    if (
      this.organisationMembership &&
      this.organisationMembership.has(organisationId) &&
      this.organisationMembership.get(organisationId).modules_array &&
      this.organisationMembership
        .get(organisationId)
        .modules_array.includes("dentistry_module_on")
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * helper function for dentistry permission check
   * @param organisationId
   */
  public canAccessInstructions(organisationId: string): boolean {
    if (
      this.organisationMembership &&
      this.organisationMembership.has(organisationId) &&
      this.organisationMembership.get(organisationId).modules_array &&
      this.organisationMembership
        .get(organisationId)
        .modules_array.includes("instructions_module_on")
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * helper function for getting member's profile status
   * @param organisationId
   */
  public getProfileStatus(organisationId: string): PersonAccountStatus {
    if (
      this.organisationMembership &&
      this.organisationMembership.has(organisationId)
    ) {
      return this.organisationMembership.get(organisationId).profile_status;
    } else {
      return null;
    }
  }

  /**
   * helper function for getting member's profile status
   * @param organisationId
   */
  public getOpdScheduleAvailability(organisationId: string): boolean {
    if (
      this.organisationMembership &&
      this.organisationMembership.has(organisationId)
    ) {
      return this.organisationMembership.get(organisationId)
        .opd_schedule_availability;
    } else {
      return false;
    }
  }
}

export class PrescriptionPrintSettings implements BasicModelInterface {
  private _prescriptionPrintSettingsId: string;
  private _prescriptionPrintSettingsTitle: string;
  private _topMargin: number; // in cm
  private _bottomMargin: number;
  private _signature: string; // HTML string
  private _needsSpecificTypeOfDiagnosis: boolean; // needsSpecificDiagnosis: True -> Show Primary, Secondary, Provisional otherwise hide the type of diagnosis

  constructor() {}

  toJSON(): {} {
    let json = {};
    if (this.prescriptionPrintSettingsId) {
      json[
        ObjectKeys.prescriptionPrintSettingsId
      ] = this.prescriptionPrintSettingsId;
    }
    if (this.prescriptionPrintSettingsTitle) {
      json[
        ObjectKeys.prescriptionPrintSettingsTitle
      ] = this.prescriptionPrintSettingsTitle;
    }
    if (this.topMargin) {
      json[ObjectKeys.topMargin] = this.topMargin;
    }
    if (this.bottomMargin) {
      json[ObjectKeys.bottomMargin] = this.bottomMargin;
    }
    if (this.signature) {
      json[ObjectKeys.signature] = this.signature;
    }
    if (this.needsSpecificTypeOfDiagnosis) {
      json[
        ObjectKeys.needsSpecificTypeOfDiagnosis
      ] = this.needsSpecificTypeOfDiagnosis;
    }
    return json;
  }
  initFromJSON(json: {}): void {
    if (json[ObjectKeys.prescriptionPrintSettingsId]) {
      this.prescriptionPrintSettingsId =
        json[ObjectKeys.prescriptionPrintSettingsId];
    }
    if (json[ObjectKeys.prescriptionPrintSettingsTitle]) {
      this.prescriptionPrintSettingsTitle =
        json[ObjectKeys.prescriptionPrintSettingsTitle];
    }
    if (json[ObjectKeys.topMargin]) {
      this.topMargin = json[ObjectKeys.topMargin];
    }
    if (json[ObjectKeys.bottomMargin]) {
      this.bottomMargin = json[ObjectKeys.bottomMargin];
    }
    if (json[ObjectKeys.signature]) {
      this.signature = json[ObjectKeys.signature];
    }
    if (json[ObjectKeys.needsSpecificTypeOfDiagnosis]) {
      this.needsSpecificTypeOfDiagnosis =
        json[ObjectKeys.needsSpecificTypeOfDiagnosis];
    }
  }

  /**
   * Getter prescriptionPrintSettingsId
   * @return {string}
   */
  public get prescriptionPrintSettingsId(): string {
    return this._prescriptionPrintSettingsId;
  }

  /**
   * Setter prescriptionPrintSettingsId
   * @param {string} value
   */
  public set prescriptionPrintSettingsId(value: string) {
    this._prescriptionPrintSettingsId = value;
  }

  /**
   * Getter prescriptionPrintSettingsTitle
   * @return {string}
   */
  public get prescriptionPrintSettingsTitle(): string {
    return this._prescriptionPrintSettingsTitle;
  }

  /**
   * Setter prescriptionPrintSettingsTitle
   * @param {string} value
   */
  public set prescriptionPrintSettingsTitle(value: string) {
    this._prescriptionPrintSettingsTitle = value;
  }

  /**
   * Getter topMargin
   * @return {number}
   */
  public get topMargin(): number {
    return this._topMargin;
  }

  /**
   * Setter topMargin
   * @param {number} value
   */
  public set topMargin(value: number) {
    this._topMargin = value;
  }

  /**
   * Getter bottomMargin
   * @return {number}
   */
  public get bottomMargin(): number {
    return this._bottomMargin;
  }

  /**
   * Setter bottomMargin
   * @param {number} value
   */
  public set bottomMargin(value: number) {
    this._bottomMargin = value;
  }

  /**
   * Getter signature
   * @return {string}
   */
  public get signature(): string {
    return this._signature;
  }

  /**
   * Setter signature
   * @param {string} value
   */
  public set signature(value: string) {
    this._signature = value;
  }

  /**
   * Getter needsSpecificTypeOfDiagnosis
   * @return {boolean}
   */
  public get needsSpecificTypeOfDiagnosis(): boolean {
    return this._needsSpecificTypeOfDiagnosis;
  }

  /**
   * Setter needsSpecificTypeOfDiagnosis
   * @param {boolean} value
   */
  public set needsSpecificTypeOfDiagnosis(value: boolean) {
    this._needsSpecificTypeOfDiagnosis = value;
  }
}

export class OrganisationMembershipDetails implements BasicModelInterface {
  private _organisationId: string;
  private _organisationName: string; //Intentional Duplication
  private _organisationLogoUrl: string; //Intentional Duplication
  private _designation: string;
  private _modules_array: string[] = [];
  private _priveleges: Map<string, boolean>;
  private _profile_status: PersonAccountStatus;
  private _opd_schedule_availability: boolean;
  private _receiveDailyOPDSMS: boolean;

  constructor() {
    this.profile_status = PersonAccountStatus.ACTIVE;
  }

  toJSON(): {} {
    let json = {};
    if (this.organisationId) {
      json[ObjectKeys.organisationId] = this.organisationId;
    }
    if (this.organisationName) {
      json[ObjectKeys.organisationName] = this.organisationName;
    }
    if (this.modules_array) {
      json[ObjectKeys.modules_array] = this.modules_array;
    }
    if (this.organisationLogoUrl) {
      json[ObjectKeys.organisationLogoUrl] = this.organisationLogoUrl;
    }
    if (this.designation) {
      json[ObjectKeys.designation] = this.designation;
    }
    if (this.opd_schedule_availability) {
      json[
        ObjectKeys.opd_schedule_availability
      ] = this.opd_schedule_availability;
    }
    if (this.receiveDailyOPDSMS) {
      json[ObjectKeys.receiveDailyOPDSMS] = this.receiveDailyOPDSMS;
    }
    if (this.profile_status) {
      json[ObjectKeys.profile_status] = this.profile_status;
    }
    if (this.priveleges) {
      let temp = {};
      this.priveleges.forEach((value, key) => {
        temp[key] = value;
      });
      json[ObjectKeys.priveleges] = temp;
    }
    return json;
  }
  initFromJSON(json: {}): void {
    if (json[ObjectKeys.organisationId]) {
      this.organisationId = json[ObjectKeys.organisationId];
    }
    if (json[ObjectKeys.modules_array]) {
      this.modules_array = json[ObjectKeys.modules_array];
    }
    if (json[ObjectKeys.profile_status]) {
      this.profile_status = json[ObjectKeys.profile_status];
    }
    if (json[ObjectKeys.opd_schedule_availability]) {
      this.opd_schedule_availability =
        json[ObjectKeys.opd_schedule_availability];
    }
    if (json[ObjectKeys.organisationName]) {
      this.organisationName = json[ObjectKeys.organisationName];
    }
    if (json[ObjectKeys.organisationLogoUrl]) {
      this.organisationLogoUrl = json[ObjectKeys.organisationLogoUrl];
    }
    if (json[ObjectKeys.designation]) {
      this.designation = json[ObjectKeys.designation];
    }
    if (json[ObjectKeys.receiveDailyOPDSMS]) {
      this.receiveDailyOPDSMS = json[ObjectKeys.receiveDailyOPDSMS];
    }
    if (json[ObjectKeys.priveleges]) {
      let map = new Map<string, boolean>();
      let temp = json[ObjectKeys.priveleges];
      for (let t in temp) {
        map.set(t, temp[t]);
      }
      this.priveleges = map;
    }
  }

  /**
   * Getter modules_array
   * @return {string[] }
   */
  public get modules_array(): string[] {
    return this._modules_array;
  }

  /**
   * Setter modules_array
   * @param {string[] } value
   */
  public set modules_array(value: string[]) {
    this._modules_array = value;
  }

  /**
   * Getter opd_schedule_availability
   * @return {boolean}
   */
  public get opd_schedule_availability(): boolean {
    return this._opd_schedule_availability;
  }

  /**
   * Setter opd_schedule_availability
   * @param {boolean} value
   */
  public set opd_schedule_availability(value: boolean) {
    this._opd_schedule_availability = value;
  }

  /**
   * Getter profile_status
   * @return {PersonAccountStatus}
   */
  public get profile_status(): PersonAccountStatus {
    return this._profile_status;
  }

  /**
   * Setter profile_status
   * @param {PersonAccountStatus} value
   */
  public set profile_status(value: PersonAccountStatus) {
    this._profile_status = value;
  }

  /**
   * Getter organisationId
   * @return {string}
   */
  public get organisationId(): string {
    return this._organisationId;
  }

  /**
   * Getter organisationId
   * @return {boolean}
   */
  public get receiveDailyOPDSMS(): boolean {
    return this._receiveDailyOPDSMS;
  }
  /**
   * Setter organisationId
   * @param {string} value
   */
  public set receiveDailyOPDSMS(value: boolean) {
    this._receiveDailyOPDSMS = value;
  }

  /**
   * Setter organisationId
   * @param {string} value
   */
  public set organisationId(value: string) {
    this._organisationId = value;
  }

  /**
   * Getter organisationName
   * @return {string}
   */
  public get organisationName(): string {
    return this._organisationName;
  }

  /**
   * Setter organisationName
   * @param {string} value
   */
  public set organisationName(value: string) {
    this._organisationName = value;
  }

  /**
   * Getter organisationLogoUrl
   * @return {string}
   */
  public get organisationLogoUrl(): string {
    return this._organisationLogoUrl;
  }

  /**
   * Setter organisationLogoUrl
   * @param {string} value
   */
  public set organisationLogoUrl(value: string) {
    this._organisationLogoUrl = value;
  }

  /**
   * Getter designation
   * @return {string}
   */
  public get designation(): string {
    return this._designation;
  }

  /**
   * Setter designation
   * @param {string} value
   */
  public set designation(value: string) {
    this._designation = value;
  }

  /**
   * Getter priveleges
   * @return {Map<string, boolean>}
   */
  public get priveleges(): Map<string, boolean> {
    return this._priveleges;
  }

  /**
   * Setter priveleges
   * @param {Map<string, boolean>} value
   */
  public set priveleges(value: Map<string, boolean>) {
    this._priveleges = value;
  }
}

export class AppointmentProfile implements BasicModelInterface {
  private _appointmentProfileId: string;
  private _name: string;
  private _description: string;
  private _shortForm: string;
  private _durationValue: number;
  private _durationUnit: string;

  private _generallyIncludedConsultation: Map<string, number> = new Map();
  private _generallyIncludedProcedures: Map<string, number> = new Map();

  toJSON(): {} {
    let json = {};
    if (this.appointmentProfileId) {
      json[ObjectKeys.appointmentProfileId] = this.appointmentProfileId;
    }
    if (this.name) {
      json[ObjectKeys.nameKey] = this.name;
    }
    if (this.description) {
      json[ObjectKeys.description] = this.description;
    }
    if (this.shortForm) {
      json[ObjectKeys.shortForm] = this.shortForm;
    }
    if (this.durationValue) {
      json[ObjectKeys.durationValue] = this.durationValue;
    }
    if (this.durationUnit) {
      json[ObjectKeys.durationUnit] = this.durationUnit;
    }
    if (this.generallyIncludedConsultation) {
      let temp = {};
      this.generallyIncludedConsultation.forEach((val, key) => {
        temp[key] = val;
      });
      json[ObjectKeys.generallyIncludedConsultation] = temp;
    }
    if (this.generallyIncludedProcedures) {
      let temp = {};
      this.generallyIncludedProcedures.forEach((val, key) => {
        temp[key] = val;
      });
      json[ObjectKeys.generallyIncludedProcedures] = temp;
    }
    return json;
  }
  initFromJSON(json: {}): void {
    if (json[ObjectKeys.appointmentProfileId]) {
      this.appointmentProfileId = json[ObjectKeys.appointmentProfileId];
    }
    if (json[ObjectKeys.nameKey]) {
      this.name = json[ObjectKeys.nameKey];
    }
    if (json[ObjectKeys.description]) {
      this.description = json[ObjectKeys.description];
    }
    if (json[ObjectKeys.shortForm]) {
      this.shortForm = json[ObjectKeys.shortForm];
    }
    if (json[ObjectKeys.durationValue]) {
      this.durationValue = json[ObjectKeys.durationValue];
    }
    if (json[ObjectKeys.durationUnit]) {
      this.durationUnit = json[ObjectKeys.durationUnit];
    }
    if (json[ObjectKeys.generallyIncludedConsultation]) {
      for (let id in json[ObjectKeys.generallyIncludedConsultation]) {
        this.generallyIncludedConsultation.set(
          id,
          json[ObjectKeys.generallyIncludedConsultation][id]
        );
      }
    }
    if (json[ObjectKeys.generallyIncludedProcedures]) {
      for (let id in json[ObjectKeys.generallyIncludedProcedures]) {
        this.generallyIncludedProcedures.set(
          id,
          json[ObjectKeys.generallyIncludedProcedures][id]
        );
      }
    }
  }

  public convertDurationInMinutes(): number {
    let d: number = null;
    if (this.durationUnit && this.durationValue) {
      switch (this.durationUnit) {
        case "minute": {
          d = this.durationValue;
          break;
        }
        case "hour": {
          d = this.durationValue * 60;
          break;
        }
      }
    }
    return d;
  }

  /**
   * Getter durationValue
   * @return {number}
   */
  public get durationValue(): number {
    return this._durationValue;
  }

  /**
   * Setter durationValue
   * @param {number} value
   */
  public set durationValue(value: number) {
    this._durationValue = value;
  }

  /**
   * Getter durationUnit
   * @return {string}
   */
  public get durationUnit(): string {
    return this._durationUnit;
  }

  /**
   * Setter durationUnit
   * @param {string} value
   */
  public set durationUnit(value: string) {
    this._durationUnit = value;
  }

  /**
   * Getter appointmentProfileId
   * @return {string}
   */
  public get appointmentProfileId(): string {
    return this._appointmentProfileId;
  }

  /**
   * Setter appointmentProfileId
   * @param {string} value
   */
  public set appointmentProfileId(value: string) {
    this._appointmentProfileId = value;
  }

  /**
   * Getter name
   * @return {string}
   */
  public get name(): string {
    return this._name;
  }

  /**
   * Setter name
   * @param {string} value
   */
  public set name(value: string) {
    this._name = value;
  }

  /**
   * Getter description
   * @return {string}
   */
  public get description(): string {
    return this._description;
  }

  /**
   * Setter description
   * @param {string} value
   */
  public set description(value: string) {
    this._description = value;
  }

  /**
   * Getter shortForm
   * @return {string}
   */
  public get shortForm(): string {
    return this._shortForm;
  }

  /**
   * Setter shortForm
   * @param {string} value
   */
  public set shortForm(value: string) {
    this._shortForm = value;
  }

  /**
   * Getter generallyIncludedConsultation
   * @return {Map<string, number> }
   */
  public get generallyIncludedConsultation(): Map<string, number> {
    return this._generallyIncludedConsultation;
  }

  /**
   * Setter generallyIncludedConsultation
   * @param {Map<string, number> } value
   */
  public set generallyIncludedConsultation(value: Map<string, number>) {
    this._generallyIncludedConsultation = value;
  }

  /**
   * Getter generallyIncludedProcedures
   * @return {Map<string, number> }
   */
  public get generallyIncludedProcedures(): Map<string, number> {
    return this._generallyIncludedProcedures;
  }

  /**
   * Setter generallyIncludedProcedures
   * @param {Map<string, number> } value
   */
  public set generallyIncludedProcedures(value: Map<string, number>) {
    this._generallyIncludedProcedures = value;
  }
}

export class OPDPrescriptionSettings implements BasicModelInterface {
  private _isFinalDiagnosisOn: boolean;
  private _isProvisionalDiagnosisOn: boolean;
  private _isComplaintsOn: boolean;
  private _isFindingsOn: boolean;
  private _isSystemicExaminationOn: boolean;
  private _isPrescriptionV1On: boolean;

  toJSON(): {} {
    let json = {};

    if (this.isFinalDiagnosisOn !== null) {
      json[ObjectKeys.isFinalDiagnosisOn] = this.isFinalDiagnosisOn;
    }

    if (this.isProvisionalDiagnosisOn !== null) {
      json[ObjectKeys.isProvisionalDiagnosisOn] = this.isProvisionalDiagnosisOn;
    }

    if (this.isComplaintsOn !== null) {
      json[ObjectKeys.isComplaintsOn] = this.isComplaintsOn;
    }
    if (this.isFindingsOn !== null) {
      json[ObjectKeys.isFindingsOn] = this.isFindingsOn;
    }
    if (this.isSystemicExaminationOn !== null) {
      json[ObjectKeys.isSystemicExaminationOn] = this.isSystemicExaminationOn;
    }
    if (this.isPrescriptionV1On !== null) {
      json[ObjectKeys.isPrescriptionV1On] = this.isPrescriptionV1On;
    }

    return json;
  }

  initFromJSON(json: {}): void {
    if (
      json[ObjectKeys.isFinalDiagnosisOn] !== null &&
      json[ObjectKeys.isFinalDiagnosisOn] !== undefined
    ) {
      this.isFinalDiagnosisOn = json[ObjectKeys.isFinalDiagnosisOn];
    }

    if (
      json[ObjectKeys.isProvisionalDiagnosisOn] !== null &&
      json[ObjectKeys.isProvisionalDiagnosisOn] !== undefined
    ) {
      this.isProvisionalDiagnosisOn = json[ObjectKeys.isProvisionalDiagnosisOn];
    }

    if (
      json[ObjectKeys.isComplaintsOn] !== null &&
      json[ObjectKeys.isComplaintsOn] !== undefined
    ) {
      this.isComplaintsOn = json[ObjectKeys.isComplaintsOn];
    }

    if (
      json[ObjectKeys.isFindingsOn] !== null &&
      json[ObjectKeys.isFindingsOn] !== undefined
    ) {
      this.isFindingsOn = json[ObjectKeys.isFindingsOn];
    }

    if (
      json[ObjectKeys.isSystemicExaminationOn] !== null &&
      json[ObjectKeys.isSystemicExaminationOn] !== undefined
    ) {
      this.isSystemicExaminationOn = json[ObjectKeys.isSystemicExaminationOn];
    }
    if (
      json[ObjectKeys.isPrescriptionV1On] !== null &&
      json[ObjectKeys.isPrescriptionV1On] !== undefined
    ) {
      this.isPrescriptionV1On = json[ObjectKeys.isPrescriptionV1On];
    }
  }

  /**
   * Getter isFinalDiagnosisOn
   * @return {boolean}
   */
  public get isFinalDiagnosisOn(): boolean {
    return this._isFinalDiagnosisOn;
  }

  /**
   * Setter isFinalDiagnosisOn
   * @param {boolean} value
   */
  public set isFinalDiagnosisOn(value: boolean) {
    this._isFinalDiagnosisOn = value;
  }

  /**
   * Getter isProvisionalDiagnosisOn
   * @return {boolean}
   */
  public get isProvisionalDiagnosisOn(): boolean {
    return this._isProvisionalDiagnosisOn;
  }

  /**
   * Setter isProvisionalDiagnosisOn
   * @param {boolean} value
   */
  public set isProvisionalDiagnosisOn(value: boolean) {
    this._isProvisionalDiagnosisOn = value;
  }

  /**
   * Getter isComplaintsOn
   * @return {boolean}
   */
  public get isComplaintsOn(): boolean {
    return this._isComplaintsOn;
  }

  /**
   * Setter isComplaintsOn
   * @param {boolean} value
   */
  public set isComplaintsOn(value: boolean) {
    this._isComplaintsOn = value;
  }

  /**
   * Getter isFindingsOn
   * @return {boolean}
   */
  public get isFindingsOn(): boolean {
    return this._isFindingsOn;
  }

  /**
   * Setter isFindingsOn
   * @param {boolean} value
   */
  public set isFindingsOn(value: boolean) {
    this._isFindingsOn = value;
  }

  /**
   * Getter isSystemicExaminationOn
   * @return {boolean}
   */
  public get isSystemicExaminationOn(): boolean {
    return this._isSystemicExaminationOn;
  }

  /**
   * Setter isSystemicExaminationOn
   * @param {boolean} value
   */
  public set isSystemicExaminationOn(value: boolean) {
    this._isSystemicExaminationOn = value;
  }

  /**
   * Getter isPrescriptionV1On
   * @return {boolean}
   */
  public get isPrescriptionV1On(): boolean {
    return this._isPrescriptionV1On;
  }

  /**
   * Setter isPrescriptionV1On
   * @param {boolean} value
   */
  public set isPrescriptionV1On(value: boolean) {
    this._isPrescriptionV1On = value;
  }
}

export class Organisation implements BasicModelInterface {
  private _organisationId: string;

  private _type: "clinic" | "doctor" | "smallHospital";
  private _billingAccountId: string;

  // Basic Organisation Info
  private _organisationLogoUrl: string;
  private _organisationName: string;
  private _primaryContactNumber: string;
  private _secondaryContactNumbers: string[];
  private _primaryAddress: AddressObject;
  private _secondaryAddresses: AddressObject[];
  //Patient resgistration form settings
  private _patientRegistrationFormSettings: PatientRegistrationFormSettings = new PatientRegistrationFormSettings();
  private _opdPrescriptionSettings: OPDPrescriptionSettings = new OPDPrescriptionSettings();

  private _uniqueShortLink: string;
  // Super Admin & Admin Links
  private _superAdminInfo: Map<string, AdminInfo>;
  private _adminInfo: Map<string, AdminInfo>;

  private _appointmentProfilesMap: Map<string, AppointmentProfile> = new Map();

  private _isOPDSoundNotificationON: boolean;

  private _associatedCorporation: Map<string, Corporation> = new Map();

  private _primaryMedicalProfessional: {
    memberId: string;
    memberName: string;
    details: MedicalProfessionalMeta;
  };

  // Members, Locations & Schedule
  private _members: Map<string, OrganisationMember>; // all members who belong to the organisation, map with basic details
  private _locations: Map<string, OrganisationLocationObject>;
  private _planInfo: Map<string, PlanInfo>;
  private _opdCharges: Map<string, OPDTypeAndFee>;
  private _procedureCharges: Map<string, procedureTypeAndFee>;
  private _slotLength: number; //In minutes.
  private _minScheduleTime: string; //HH:mm 24 hours format
  private _maxScheduleTime: string; //HH:mm 24 hours format

  toJSON(): {} {
    let json = {};
    if (this.organisationId) {
      json[ObjectKeys.organisationId] = this.organisationId;
    }
    if (this.uniqueShortLink) {
      json[ObjectKeys.uniqueShortLink] = this.uniqueShortLink;
    }
    if (this.isOPDSoundNotificationON) {
      json[ObjectKeys.isOPDSoundNotificationON] = this.isOPDSoundNotificationON;
    }
    if (this.type) {
      json[ObjectKeys.type] = this.type;
    }
    if (this.organisationLogoUrl) {
      json[ObjectKeys.organisationLogoUrl] = this.organisationLogoUrl;
    }
    if (this.organisationName) {
      json[ObjectKeys.organisationName] = this.organisationName;
    }
    if (this.primaryContactNumber) {
      json[ObjectKeys.primaryContactNumber] = this.primaryContactNumber;
    }
    if (this.secondaryContactNumbers) {
      json[ObjectKeys.secondaryContactNumbers] = this.secondaryContactNumbers;
    }
    if (this.primaryAddress) {
      json[ObjectKeys.primaryAddress] = this.primaryAddress.toJSON();
    }
    if (this.patientRegistrationFormSettings) {
      json[
        ObjectKeys.patientRegistrationFormSettings
      ] = this.patientRegistrationFormSettings.toJSON();
    }
    if (this.opdPrescriptionSettings) {
      json[
        ObjectKeys.opdPrescriptionSettings
      ] = this.opdPrescriptionSettings.toJSON();
    }
    if (this.secondaryAddresses) {
      let temp = [];
      this.secondaryAddresses.forEach((value, key) => {
        temp.push(value.toJSON());
      });
      json[ObjectKeys.secondaryAddresses] = temp;
    }
    if (this.superAdminInfo) {
      let temp = {};
      this.superAdminInfo.forEach((value, key) => {
        temp[key] = value.toJSON();
      });
      json[ObjectKeys.superAdminInfo] = temp;
    }
    if (this.adminInfo) {
      let temp = {};
      this.adminInfo.forEach((value, key) => {
        temp[key] = value.toJSON();
      });
      json[ObjectKeys.adminInfo] = temp;
    }
    if (this.members) {
      json[ObjectKeys.members] = Array.from(this.members.keys());
    }
    if (this.locations) {
      let temp = {};
      this.locations.forEach((value, key) => {
        temp[key] = value.toJSON();
      });
      json[ObjectKeys.locations] = temp;
    }
    if (this.planInfo) {
      let temp = {};
      this.planInfo.forEach((value, key) => {
        temp[key] = value.toJSON();
      });
      json[ObjectKeys.planInfo] = temp;
    }
    if (this.primaryMedicalProfessional) {
      let temp = {};
      if (this.primaryMedicalProfessional.memberId) {
        temp[ObjectKeys.memberId] = this.primaryMedicalProfessional.memberId;
      }
      if (this.primaryMedicalProfessional.memberName) {
        temp[
          ObjectKeys.memberName
        ] = this.primaryMedicalProfessional.memberName;
      }
      if (this.primaryMedicalProfessional.details) {
        temp[
          ObjectKeys.details
        ] = this.primaryMedicalProfessional.details.toJSON();
      }
    }
    if (this.slotLength) {
      json[ObjectKeys.opdSchedule] = {};
      json[ObjectKeys.opdSchedule][ObjectKeys.slot_length] = this.slotLength;
    }
    if (this.minScheduleTime) {
      if (json[ObjectKeys.opdSchedule] == null) {
        json[ObjectKeys.opdSchedule] = {};
      }
      json[ObjectKeys.opdSchedule][
        ObjectKeys.minScheduleTime
      ] = this.minScheduleTime;
    }
    if (this.maxScheduleTime) {
      if (json[ObjectKeys.opdSchedule] == null) {
        json[ObjectKeys.opdSchedule] = {};
      }
      json[ObjectKeys.opdSchedule][
        ObjectKeys.maxScheduleTime
      ] = this.maxScheduleTime;
    }
    if (this.opdCharges) {
      let temp = {};
      this.opdCharges.forEach((value, key) => {
        temp[key] = value.toJSON();
      });
      if (json[ObjectKeys.opdSchedule] == null) {
        json[ObjectKeys.opdSchedule] = {};
      }
      json[ObjectKeys.opdSchedule][ObjectKeys.opdCharges] = temp;
    }
    if (this.procedureCharges) {
      let temp = {};
      this.procedureCharges.forEach((value, key) => {
        temp[key] = value.toJSON();
      });
      if (json[ObjectKeys.opdSchedule] == null) {
        json[ObjectKeys.opdSchedule] = {};
      }
      json[ObjectKeys.opdSchedule][ObjectKeys.procedureCharges] = temp;
    }

    if (this.appointmentProfilesMap) {
      let temp = {};
      this.appointmentProfilesMap.forEach((value, key) => {
        temp[key] = value.toJSON();
      });
      json[ObjectKeys.appointmentProfiles] = temp;
    }

    if (this.associatedCorporation) {
      let itemsJSON = {};
      this.associatedCorporation.forEach((val, key) => {
        itemsJSON[key] = val.toJSON();
      });
      json[ObjectKeys.associatedCorporation] = itemsJSON;
    }

    return json;
  }

  initFromJSON(json: {}): void {
    if (json[ObjectKeys.organisationId]) {
      this.organisationId = json[ObjectKeys.organisationId];
    }
    if (json[ObjectKeys.uniqueShortLink]) {
      this.uniqueShortLink = json[ObjectKeys.uniqueShortLink];
    }
    if (json[ObjectKeys.isOPDSoundNotificationON]) {
      this.isOPDSoundNotificationON = json[ObjectKeys.isOPDSoundNotificationON];
    }
    if (json[ObjectKeys.type]) {
      this.type = json[ObjectKeys.type];
    }
    if (json[ObjectKeys.billingAccountId]) {
      this.billingAccountId = json[ObjectKeys.billingAccountId];
    }
    if (json[ObjectKeys.organisationLogoUrl]) {
      this.organisationLogoUrl = json[ObjectKeys.organisationLogoUrl];
    }
    if (json[ObjectKeys.organisationName]) {
      this.organisationName = json[ObjectKeys.organisationName];
    }
    if (json[ObjectKeys.primaryContactNumber]) {
      this.primaryContactNumber = json[ObjectKeys.primaryContactNumber];
    }
    if (json[ObjectKeys.primaryAddress]) {
      let addr = new AddressObject();
      addr.initFromJSON(json[ObjectKeys.primaryAddress]);
      this.primaryAddress = addr;
    }
    if (json[ObjectKeys.patientRegistrationFormSettings]) {
      let temp = new PatientRegistrationFormSettings();
      temp.initFromJSON(json[ObjectKeys.patientRegistrationFormSettings]);
      this.patientRegistrationFormSettings = temp;
    }

    if (json[ObjectKeys.opdPrescriptionSettings]) {
      let temp = new OPDPrescriptionSettings();
      temp.initFromJSON(json[ObjectKeys.opdPrescriptionSettings]);
      this.opdPrescriptionSettings = temp;
    }

    if (json[ObjectKeys.secondaryContactNumbers]) {
      this.secondaryContactNumbers = json[ObjectKeys.secondaryContactNumbers];
    }
    if (json[ObjectKeys.secondaryAddresses]) {
      let array = [];
      let temp = json[ObjectKeys.secondaryAddresses];
      temp.forEach(value => {
        let addr = new AddressObject();
        addr.initFromJSON(value);
        array.push(addr);
      });
      this.secondaryAddresses = array;
    }
    if (json[ObjectKeys.superAdminInfo]) {
      let map = new Map<string, AdminInfo>();
      let temp = json[ObjectKeys.superAdminInfo];
      for (let t in temp) {
        let adminInfo: AdminInfo = new AdminInfo();
        adminInfo.initFromJSON(temp[t]);
        map.set(adminInfo.memberId, adminInfo);
      }
      this.superAdminInfo = map;
    }
    if (json[ObjectKeys.adminInfo]) {
      let map = new Map<string, AdminInfo>();
      let temp = json[ObjectKeys.adminInfo];
      for (let t in temp) {
        let adminInfo: AdminInfo = new AdminInfo();
        adminInfo.initFromJSON(temp[t]);
        map.set(adminInfo.memberId, adminInfo);
      }
      this.adminInfo = map;
    }

    if (json[ObjectKeys.associatedCorporation]) {
      let temp = json[ObjectKeys.associatedCorporation];
      let tempMap: Map<string, Corporation> = new Map();
      for (let k in temp) {
        let m: Corporation = new Corporation();
        m.initFromJSON(temp[k]);
        tempMap.set(k, m);
      }
      this.associatedCorporation = tempMap;
    }

    if (json[ObjectKeys.members]) {
      let temp = json[ObjectKeys.members];
      let map = new Map<string, OrganisationMember>();
      for (let t of temp) {
        let member = new OrganisationMember();
        map.set(t, member);
      }
      this.members = map;
    }
    if (json[ObjectKeys.locations]) {
      let map = new Map<string, OrganisationLocationObject>();
      let temp = json[ObjectKeys.locations];
      for (let t in temp) {
        let organisationLocationObject: OrganisationLocationObject = new OrganisationLocationObject();
        organisationLocationObject.initFromJSON(temp[t]);
        map.set(t, organisationLocationObject);
      }
      this.locations = map;
    }
    if (json[ObjectKeys.planInfo]) {
      let map = new Map<string, PlanInfo>();
      let temp = json[ObjectKeys.planInfo];
      for (let t in temp) {
        let planInfo: PlanInfo = new PlanInfo();
        planInfo.initFromJSON(temp[t]);
        map.set(planInfo.planId, planInfo);
      }
      this.planInfo = map;
    }
    if (json[ObjectKeys.primaryMedicalProfessional]) {
      let temp = json[ObjectKeys.primaryMedicalProfessional];
      let details = new MedicalProfessionalMeta();
      details.initFromJSON(temp[ObjectKeys.details]);
      this.primaryMedicalProfessional = {
        memberId: temp[ObjectKeys.memberId],
        memberName: temp[ObjectKeys.memberName],
        details: details
      };
    }
    if (json[ObjectKeys.appointmentProfiles]) {
      for (let appointmentProfileId in json[ObjectKeys.appointmentProfiles]) {
        let a = new AppointmentProfile();
        a.initFromJSON(
          json[ObjectKeys.appointmentProfiles][appointmentProfileId]
        );
        this.appointmentProfilesMap.set(appointmentProfileId, a);
      }
    }
    if (
      json[ObjectKeys.opdSchedule] &&
      json[ObjectKeys.opdSchedule][ObjectKeys.minScheduleTime]
    ) {
      this.minScheduleTime =
        json[ObjectKeys.opdSchedule][ObjectKeys.minScheduleTime];
    }
    if (
      json[ObjectKeys.opdSchedule] &&
      json[ObjectKeys.opdSchedule][ObjectKeys.maxScheduleTime]
    ) {
      this.maxScheduleTime =
        json[ObjectKeys.opdSchedule][ObjectKeys.maxScheduleTime];
    }
    if (
      json[ObjectKeys.opdSchedule] &&
      json[ObjectKeys.opdSchedule][ObjectKeys.slot_length]
    ) {
      this._slotLength = +json[ObjectKeys.opdSchedule][ObjectKeys.slot_length];
    }

    if (
      json[ObjectKeys.opdSchedule] &&
      json[ObjectKeys.opdSchedule][ObjectKeys.opdCharges]
    ) {
      let map = new Map<string, OPDTypeAndFee>();
      let temp = json[ObjectKeys.opdSchedule][ObjectKeys.opdCharges];
      for (let t in temp) {
        let opdTypeAndFee: OPDTypeAndFee = new OPDTypeAndFee();
        opdTypeAndFee.initFromJSON(temp[t]);
        map.set(t, opdTypeAndFee);
      }
      this.opdCharges = map;
    }
    if (
      json[ObjectKeys.opdSchedule] &&
      json[ObjectKeys.opdSchedule][ObjectKeys.procedureCharges]
    ) {
      let map = new Map<string, procedureTypeAndFee>();
      let temp = json[ObjectKeys.opdSchedule][ObjectKeys.procedureCharges];
      for (let t in temp) {
        let procedureType: procedureTypeAndFee = new procedureTypeAndFee();
        procedureType.initFromJSON(temp[t]);
        map.set(t, procedureType);
      }
      this.procedureCharges = map;
    }
  }

  /**
   * Getter uniqueShortLink
   * @return {string}
   */
  public get uniqueShortLink(): string {
    return this._uniqueShortLink;
  }

  /**
   * Setter uniqueShortLink
   * @param {string} value
   */
  public set uniqueShortLink(value: string) {
    this._uniqueShortLink = value;
  }

  /**
   * Getter associatedCorporation
   * @return {Map<string, Corporation> }
   */
  public get associatedCorporation(): Map<string, Corporation> {
    return this._associatedCorporation;
  }

  /**
   * Setter associatedCorporation
   * @param {Map<string, Corporation> } value
   */
  public set associatedCorporation(value: Map<string, Corporation>) {
    this._associatedCorporation = value;
  }

  /**
   * Getter minScheduleTime
   * @return {string}
   */
  public get minScheduleTime(): string {
    return this._minScheduleTime;
  }

  /**
   * Setter minScheduleTime
   * @param {string} value
   */
  public set minScheduleTime(value: string) {
    this._minScheduleTime = value;
  }

  /**
   * Getter maxScheduleTime
   * @return {string}
   */
  public get maxScheduleTime(): string {
    return this._maxScheduleTime;
  }

  /**
   * Setter maxScheduleTime
   * @param {string} value
   */
  public set maxScheduleTime(value: string) {
    this._maxScheduleTime = value;
  }

  /**
   * Getter appointmentProfilesMap
   * @return {Map<string,AppointmentProfile> }
   */
  public get appointmentProfilesMap(): Map<string, AppointmentProfile> {
    return this._appointmentProfilesMap;
  }

  /**
   * Setter appointmentProfilesMap
   * @param {Map<string,AppointmentProfile> } value
   */
  public set appointmentProfilesMap(value: Map<string, AppointmentProfile>) {
    this._appointmentProfilesMap = value;
  }

  /**
   * Getter procedureCharges
   * @return {Map<string, procedureTypeAndFee>}
   */
  public get procedureCharges(): Map<string, procedureTypeAndFee> {
    return this._procedureCharges;
  }

  /**
   * Setter procedureCharges
   * @param {Map<string, procedureTypeAndFee>} value
   */
  public set procedureCharges(value: Map<string, procedureTypeAndFee>) {
    this._procedureCharges = value;
  }

  /**
   * Getter opdPrescriptionSettings
   * @return {OPDPrescriptionSettings }
   */
  public get opdPrescriptionSettings(): OPDPrescriptionSettings {
    return this._opdPrescriptionSettings;
  }

  /**
   * Setter opdPrescriptionSettings
   * @param {OPDPrescriptionSettings } value
   */
  public set opdPrescriptionSettings(value: OPDPrescriptionSettings) {
    this._opdPrescriptionSettings = value;
  }

  /**
   * Getter isOPDSoundNotificationON
   * @return {boolean}
   */
  public get isOPDSoundNotificationON(): boolean {
    return this._isOPDSoundNotificationON;
  }

  /**
   * Setter isOPDSoundNotificationON
   * @param {boolean} value
   */
  public set isOPDSoundNotificationON(value: boolean) {
    this._isOPDSoundNotificationON = value;
  }

  /**
   * Getter opdCharges
   * @return {Map<string, OPDTypeAndFee>}
   */
  public get opdCharges(): Map<string, OPDTypeAndFee> {
    return this._opdCharges;
  }

  /**
   * Setter opdCharges
   * @param {Map<string, OPDTypeAndFee>} value
   */
  public set opdCharges(value: Map<string, OPDTypeAndFee>) {
    this._opdCharges = value;
  }

  /**
   * Getter slotLength
   * @return {number}
   */
  public get slotLength(): number {
    return this._slotLength;
  }

  /**
   * Setter slotLength
   * @param {number} value
   */
  public set slotLength(value: number) {
    this._slotLength = value;
  }

  /**
   * This is to check if the organisation has any active plans as of now
   * @param {string} date - (YYYY-MM-DD)date from which you want to check validity. Generally today's date
   */
  hasActivePlan(date: string) {
    let hasActivePlan = false;
    this.planInfo.forEach((val, key) => {
      if (
        date.localeCompare(val.startDate) >= 0 &&
        date.localeCompare(val.expiryDate) <= 0
      ) {
        hasActivePlan = true;
      }
    });
    return hasActivePlan;
  }

  /**
   * This is to check if the organisation has any active plans as of now
   * @param {string} date - (YYYY-MM-DD)date from which you want to check validity. Generally today's date
   */
  currentActivePlan(date: string) {
    let plan: PlanInfo = null;
    let array = Array.from(this.planInfo.values()).sort((a, b) => {
      return a.expiryDate.localeCompare(b.expiryDate);
    });
    array.forEach(val => {
      if (
        date.localeCompare(val.startDate) >= 0 &&
        date.localeCompare(val.expiryDate) <= 0 &&
        !plan
      ) {
        plan = val;
      }
    });
    return plan;
  }

  /**
     * Getter primaryMedicalProfessional
     * @return {{
      memberId: string;
      memberName: string;
      details: MedicalProfessionalMeta;
    }}
     */
  public get primaryMedicalProfessional(): {
    memberId: string;
    memberName: string;
    details: MedicalProfessionalMeta;
  } {
    return this._primaryMedicalProfessional;
  }

  /**
       * Setter primaryMedicalProfessional
       * @param {{
      memberId: string;
      memberName: string;
      details: MedicalProfessionalMeta;
    }} value
       */
  public set primaryMedicalProfessional(value: {
    memberId: string;
    memberName: string;
    details: MedicalProfessionalMeta;
  }) {
    this._primaryMedicalProfessional = value;
  }

  /**
   * Getter type
   * @return {"clinic" | "doctor" | "smallHospital"}
   */
  public get type(): "clinic" | "doctor" | "smallHospital" {
    return this._type;
  }

  /**
   * Setter type
   * @param {"clinic" | "doctor" | "smallHospital"} value
   */
  public set type(value: "clinic" | "doctor" | "smallHospital") {
    this._type = value;
  }

  /**
   * Getter organisationId
   * @return {string}
   */
  public get organisationId(): string {
    return this._organisationId;
  }

  /**
   * Setter organisationId
   * @param {string} value
   */
  public set organisationId(value: string) {
    this._organisationId = value;
  }

  /**
   * Getter billingAccountId
   * @return {string}
   */
  public get billingAccountId(): string {
    return this._billingAccountId;
  }

  /**
   * Setter billingAccountId
   * @param {string} value
   */
  public set billingAccountId(value: string) {
    this._billingAccountId = value;
  }

  /**
   * Getter organisationLogoUrl
   * @return {string}
   */
  public get organisationLogoUrl(): string {
    return this._organisationLogoUrl;
  }

  /**
   * Setter organisationLogoUrl
   * @param {string} value
   */
  public set organisationLogoUrl(value: string) {
    this._organisationLogoUrl = value;
  }

  /**
   * Getter organisationName
   * @return {string}
   */
  public get organisationName(): string {
    return this._organisationName;
  }

  /**
   * Setter organisationName
   * @param {string} value
   */
  public set organisationName(value: string) {
    this._organisationName = value;
  }

  /**
   * Getter primaryContactNumber
   * @return {string}
   */
  public get primaryContactNumber(): string {
    return this._primaryContactNumber;
  }

  /**
   * Setter primaryContactNumber
   * @param {string} value
   */
  public set primaryContactNumber(value: string) {
    this._primaryContactNumber = value;
  }

  /**
   * Getter secondaryContactNumbers
   * @return {string[]}
   */
  public get secondaryContactNumbers(): string[] {
    return this._secondaryContactNumbers;
  }

  /**
   * Setter secondaryContactNumbers
   * @param {string[]} value
   */
  public set secondaryContactNumbers(value: string[]) {
    this._secondaryContactNumbers = value;
  }

  /**
   * Getter primaryAddress
   * @return {AddressObject}
   */
  public get primaryAddress(): AddressObject {
    return this._primaryAddress;
  }

  /**
   * Getter patientRegistrationFormSettings
   * @return {PatientRegistrationFormSettings}
   */
  public get patientRegistrationFormSettings(): PatientRegistrationFormSettings {
    return this._patientRegistrationFormSettings;
  }

  /**
   * Setter patientRegistrationFormSettings
   * @param {PatientRegistrationFormSettings} value
   */
  public set patientRegistrationFormSettings(
    value: PatientRegistrationFormSettings
  ) {
    this._patientRegistrationFormSettings = value;
  }

  /**
   * Setter primaryAddress
   * @param {AddressObject} value
   */
  public set primaryAddress(value: AddressObject) {
    this._primaryAddress = value;
  }

  /**
   * Getter secondaryAddresses
   * @return {AddressObject[]}
   */
  public get secondaryAddresses(): AddressObject[] {
    return this._secondaryAddresses;
  }

  /**
   * Setter secondaryAddresses
   * @param {AddressObject[]} value
   */
  public set secondaryAddresses(value: AddressObject[]) {
    this._secondaryAddresses = value;
  }

  /**
   * Getter superAdminInfo
   * @return {Map<string, AdminInfo>}
   */
  public get superAdminInfo(): Map<string, AdminInfo> {
    return this._superAdminInfo;
  }

  /**
   * Setter superAdminInfo
   * @param {Map<string, AdminInfo>} value
   */
  public set superAdminInfo(value: Map<string, AdminInfo>) {
    this._superAdminInfo = value;
  }

  /**
   * Getter adminInfo
   * @return {Map<string, AdminInfo>}
   */
  public get adminInfo(): Map<string, AdminInfo> {
    return this._adminInfo;
  }

  /**
   * Setter adminInfo
   * @param {Map<string, AdminInfo>} value
   */
  public set adminInfo(value: Map<string, AdminInfo>) {
    this._adminInfo = value;
  }

  /**
   * Getter members
   * @return {Map<string, OrganisationMember>}
   */
  public get members(): Map<string, OrganisationMember> {
    return this._members;
  }

  /**
   * Setter members
   * @param {Map<string, OrganisationMember>} value
   */
  public set members(value: Map<string, OrganisationMember>) {
    this._members = value;
  }

  /**
   * Getter locations
   * @return {Map<string, OrganisationLocationObject>}
   */
  public get locations(): Map<string, OrganisationLocationObject> {
    return this._locations;
  }

  /**
   * Setter locations
   * @param {Map<string, OrganisationLocationObject>} value
   */
  public set locations(value: Map<string, OrganisationLocationObject>) {
    this._locations = value;
  }

  /**
   * Getter planInfo
   * @return {Map<string, PlanInfo>}
   */
  public get planInfo(): Map<string, PlanInfo> {
    return this._planInfo;
  }

  /**
   * Setter planInfo
   * @param {Map<string, PlanInfo>} value
   */
  public set planInfo(value: Map<string, PlanInfo>) {
    this._planInfo = value;
  }
}

export class Corporation implements BasicModelInterface {
  private _name: string;
  private _hint: string;
  private _id: string;
  private _hasCustomId: boolean;
  private _isDefault: boolean;

  constructor() {}

  toJSON(): {} {
    let json = {};
    if (this.name) {
      json[ObjectKeys.nameKey] = this.name;
    }
    if (this.hint) {
      json[ObjectKeys.hint] = this.hint;
    }
    if (this.id) {
      json[ObjectKeys.id] = this.id;
    }
    if (this.isDefault != null) {
      json[ObjectKeys.isDefault] = this.isDefault;
    }
    if (this.hasCustomId != null) {
      json[ObjectKeys.hasCustomId] = this.hasCustomId;
    }
    return json;
  }

  initFromJSON(json: {}): void {
    if (json[ObjectKeys.nameKey]) {
      this.name = json[ObjectKeys.nameKey];
    }
    if (json[ObjectKeys.id]) {
      this.id = json[ObjectKeys.id];
    }
    if (json[ObjectKeys.hasCustomId] != null) {
      this.hasCustomId = json[ObjectKeys.hasCustomId];
    }
    if (json[ObjectKeys.hint]) {
      this.hint = json[ObjectKeys.hint];
    }
    if (json[ObjectKeys.isDefault] != null) {
      this.isDefault = json[ObjectKeys.isDefault];
    }
  }

  /**
   * Getter isDefault
   * @return {boolean}
   */
  public get isDefault(): boolean {
    return this._isDefault;
  }

  /**
   * Setter isDefault
   * @param {boolean} value
   */
  public set isDefault(value: boolean) {
    this._isDefault = value;
  }

  /**
   * Getter hasCustomId
   * @return {boolean}
   */
  public get hasCustomId(): boolean {
    return this._hasCustomId;
  }

  /**
   * Setter hasCustomId
   * @param {boolean} value
   */
  public set hasCustomId(value: boolean) {
    this._hasCustomId = value;
  }

  /**
   * Getter id
   * @return {string}
   */
  public get id(): string {
    return this._id;
  }

  /**
   * Setter id
   * @param {string} value
   */
  public set id(value: string) {
    this._id = value;
  }

  /**
   * Getter hint
   * @return {string}
   */
  public get hint(): string {
    return this._hint;
  }

  /**
   * Setter hint
   * @param {string} value
   */
  public set hint(value: string) {
    this._hint = value;
  }

  /**
   * Getter name
   * @return {string}
   */
  public get name(): string {
    return this._name;
  }

  /**
   * Setter name
   * @param {string} value
   */
  public set name(value: string) {
    this._name = value;
  }
}

export class PlanInfo implements BasicModelInterface {
  private _planId: string;
  private _planName: string;
  private _planPrivileges: string[];
  private _expiryDate: string;
  private _startDate: string;

  constructor() {}

  toJSON(): {} {
    let json = {};
    if (this.planId) {
      json[ObjectKeys.planId] = this.planId;
    }
    if (this.planName) {
      json[ObjectKeys.planName] = this.planName;
    }
    if (this.expiryDate) {
      json[ObjectKeys.expiryDate] = this.expiryDate;
    }
    if (this.startDate) {
      json[ObjectKeys.startDate] = this.startDate;
    }
    if (this.planPrivileges) {
      json[ObjectKeys.planPrivileges] = this.planPrivileges;
    }
    return json;
  }

  initFromJSON(json: {}): void {
    if (json[ObjectKeys.planId]) {
      this.planId = json[ObjectKeys.planId];
    }
    if (json[ObjectKeys.planName]) {
      this.planName = json[ObjectKeys.planName];
    }
    if (json[ObjectKeys.expiryDate]) {
      this.expiryDate = json[ObjectKeys.expiryDate];
    }
    if (json[ObjectKeys.startDate]) {
      this.startDate = json[ObjectKeys.startDate];
    }
    if (json[ObjectKeys.planPrivileges]) {
      this.planPrivileges = json[ObjectKeys.planPrivileges];
    }
  }

  /**
   * Getter planId
   * @return {string}
   */
  public get planId(): string {
    return this._planId;
  }

  /**
   * Setter planId
   * @param {string} value
   */
  public set planId(value: string) {
    this._planId = value;
  }

  /**
   * Getter planName
   * @return {string}
   */
  public get planName(): string {
    return this._planName;
  }

  /**
   * Setter planName
   * @param {string} value
   */
  public set planName(value: string) {
    this._planName = value;
  }

  /**
   * Getter planPrivileges
   * @return {string[]}
   */
  public get planPrivileges(): string[] {
    return this._planPrivileges;
  }

  /**
   * Setter planPrivileges
   * @param {string[]} value
   */
  public set planPrivileges(value: string[]) {
    this._planPrivileges = value;
  }

  /**
   * Getter expiryDate
   * @return {string}
   */
  public get expiryDate(): string {
    return this._expiryDate;
  }

  /**
   * Setter expiryDate
   * @param {string} value
   */
  public set expiryDate(value: string) {
    this._expiryDate = value;
  }

  /**
   * Getter startDate
   * @return {string}
   */
  public get startDate(): string {
    return this._startDate;
  }

  /**
   * Setter startDate
   * @param {string} value
   */
  public set startDate(value: string) {
    this._startDate = value;
  }
}

export class AdminInfo implements BasicModelInterface {
  private _memberId: string;
  private _priveleges: Map<string, boolean>;

  constructor() {}

  toJSON(): {} {
    let json = {};
    if (this.memberId) {
      json[ObjectKeys.memberId] = this.memberId;
    }
    if (this.priveleges) {
      let temp = {};
      this.priveleges.forEach((value, key) => {
        temp[key] = value;
      });
      json[ObjectKeys.priveleges] = temp;
    }
    return json;
  }
  initFromJSON(json: {}): void {
    if (json[ObjectKeys.memberId]) {
      this.memberId = json[ObjectKeys.memberId];
    }
    if (json[ObjectKeys.priveleges]) {
      let map = new Map<string, boolean>();
      let temp = json[ObjectKeys.priveleges];
      for (let t in temp) {
        map.set(t, temp[t]);
      }
      this.priveleges = map;
    }
  }

  /**
   * Getter memberId
   * @return {string}
   */
  public get memberId(): string {
    return this._memberId;
  }

  /**
   * Setter memberId
   * @param {string} value
   */
  public set memberId(value: string) {
    this._memberId = value;
  }

  /**
   * Getter priveleges
   * @return {Map<string, boolean>}
   */
  public get priveleges(): Map<string, boolean> {
    return this._priveleges;
  }

  /**
   * Setter priveleges
   * @param {Map<string, boolean>} value
   */
  public set priveleges(value: Map<string, boolean>) {
    this._priveleges = value;
  }
}

export class MedicalProfessionalMeta implements BasicModelInterface {
  private _registrationNumber: string;
  private _registrationDetails: string;
  private _qualification: string;
  private _specialty: string;

  constructor() {}

  toJSON(): {} {
    let json = {};
    if (this.registrationDetails) {
      json[ObjectKeys.registrationDetails] = this.registrationDetails;
    }
    if (this.qualification) {
      json[ObjectKeys.qualification] = this.qualification;
    }
    if (this.registrationNumber) {
      json[ObjectKeys.registrationNumber] = this.registrationNumber;
    }
    if (this.specialty) {
      json[ObjectKeys.specialty] = this.specialty;
    }
    return json;
  }
  initFromJSON(json: {}): void {
    if (json[ObjectKeys.registrationNumber]) {
      this.registrationNumber = json[ObjectKeys.registrationNumber];
    }
    if (json[ObjectKeys.registrationDetails]) {
      this.registrationDetails = json[ObjectKeys.registrationDetails];
    }
    if (json[ObjectKeys.qualification]) {
      this.qualification = json[ObjectKeys.qualification];
    }
    if (json[ObjectKeys.specialty]) {
      this.specialty = json[ObjectKeys.specialty];
    }
  }

  /**
   * Getter registrationNumber
   * @return {string}
   */
  public get registrationNumber(): string {
    return this._registrationNumber;
  }

  /**
   * Setter registrationNumber
   * @param {string} value
   */
  public set registrationNumber(value: string) {
    this._registrationNumber = value;
  }

  /**
   * Getter registrationDetails
   * @return {string}
   */
  public get registrationDetails(): string {
    return this._registrationDetails;
  }

  /**
   * Setter registrationDetails
   * @param {string} value
   */
  public set registrationDetails(value: string) {
    this._registrationDetails = value;
  }

  /**
   * Getter qualification
   * @return {string}
   */
  public get qualification(): string {
    return this._qualification;
  }

  /**
   * Setter qualification
   * @param {string} value
   */
  public set qualification(value: string) {
    this._qualification = value;
  }

  /**
   * Getter specialty
   * @return {string}
   */
  public get specialty(): string {
    return this._specialty;
  }

  /**
   * Setter specialty
   * @param {string} value
   */
  public set specialty(value: string) {
    this._specialty = value;
  }
}

export class OPDSchedule implements BasicModelInterface {
  private _timeSlotLength: number; // in minutes
  private _maxVisitsPerTimeSlot: number;
  private _opdCharges: Map<string, OPDTypeAndFee>;
  private _procedureCharges: Map<string, procedureTypeAndFee>;
  private _locationTimingsMap: Map<string, LocationTiming>;

  constructor() {}

  toJSON(): {} {
    let json = {};
    if (this.timeSlotLength) {
      json[ObjectKeys.timeSlotLength] = this.timeSlotLength;
    }
    if (this.maxVisitsPerTimeSlot) {
      json[ObjectKeys.maxVisitsPerTimeSlot] = this.maxVisitsPerTimeSlot;
    }
    if (this.opdCharges) {
      let temp = {};
      this.opdCharges.forEach((value, key) => {
        temp[key] = value.toJSON();
      });
      json[ObjectKeys.opdCharges] = temp;
    }
    if (this.procedureCharges) {
      let temp = {};
      this.procedureCharges.forEach((value, key) => {
        temp[key] = value.toJSON();
      });
      json[ObjectKeys.procedureCharges] = temp;
    }
    if (this.locationTimingsMap) {
      let temp = {};
      this.locationTimingsMap.forEach((value, key) => {
        temp[key] = value.toJSON();
      });
      json[ObjectKeys.locationTimingsMap] = temp;
    }
    return json;
  }
  initFromJSON(json: {}): void {
    if (json[ObjectKeys.timeSlotLength]) {
      this.timeSlotLength = json[ObjectKeys.timeSlotLength];
    }
    if (json[ObjectKeys.maxVisitsPerTimeSlot]) {
      this.maxVisitsPerTimeSlot = json[ObjectKeys.maxVisitsPerTimeSlot];
    }
    if (json[ObjectKeys.opdCharges]) {
      let map = new Map<string, OPDTypeAndFee>();
      let temp = json[ObjectKeys.opdCharges];
      for (let t in temp) {
        let opdTypeAndFee: OPDTypeAndFee = new OPDTypeAndFee();
        opdTypeAndFee.initFromJSON(temp[t]);
        map.set(t, opdTypeAndFee);
      }
      this.opdCharges = map;
    }
    if (json[ObjectKeys.procedureCharges]) {
      let map = new Map<string, procedureTypeAndFee>();
      let temp = json[ObjectKeys.procedureCharges];
      for (let t in temp) {
        let procedureType: procedureTypeAndFee = new procedureTypeAndFee();
        procedureType.initFromJSON(temp[t]);
        map.set(t, procedureType);
      }
      this.procedureCharges = map;
    }
    if (json[ObjectKeys.locationTimingsMap]) {
      let map = new Map<string, LocationTiming>();
      let temp = json[ObjectKeys.locationTimingsMap];
      for (let t in temp) {
        let locationTiming: LocationTiming = new LocationTiming();
        locationTiming.initFromJSON(temp[t]);
        map.set(t, locationTiming);
      }
      this.locationTimingsMap = map;
    }
  }

  /**
   * Getter timeSlotLength
   * @return {number}
   */
  public get timeSlotLength(): number {
    return this._timeSlotLength;
  }

  /**
   * Setter timeSlotLength
   * @param {number} value
   */
  public set timeSlotLength(value: number) {
    this._timeSlotLength = value;
  }

  /**
   * Getter maxVisitsPerTimeSlot
   * @return {number}
   */
  public get maxVisitsPerTimeSlot(): number {
    return this._maxVisitsPerTimeSlot;
  }

  /**
   * Setter maxVisitsPerTimeSlot
   * @param {number} value
   */
  public set maxVisitsPerTimeSlot(value: number) {
    this._maxVisitsPerTimeSlot = value;
  }

  /**
   * Getter opdCharges
   * @return {Map<string, OPDTypeAndFee>}
   */
  public get opdCharges(): Map<string, OPDTypeAndFee> {
    return this._opdCharges;
  }

  /**
   * Setter opdCharges
   * @param {Map<string, OPDTypeAndFee>} value
   */
  public set opdCharges(value: Map<string, OPDTypeAndFee>) {
    this._opdCharges = value;
  }
  /**
   * Getter procedureCharges
   * @param {Map<string, procedureTypeAndFee>}
   */
  public get procedureCharges(): Map<string, procedureTypeAndFee> {
    return this._procedureCharges;
  }
  /**
   * Setter procedureCharges
   * @param {Map<string, procedureTypeAndFee>} value
   */
  public set procedureCharges(value: Map<string, procedureTypeAndFee>) {
    this._procedureCharges = value;
  }
  /**
   * Getter locationTimingsMap
   * @return {Map<string, LocationTiming>}
   */
  public get locationTimingsMap(): Map<string, LocationTiming> {
    return this._locationTimingsMap;
  }

  /**
   * Setter locationTimingsMap
   * @param {Map<string, LocationTiming>} value
   */
  public set locationTimingsMap(value: Map<string, LocationTiming>) {
    this._locationTimingsMap = value;
  }
}

export class LocationTiming implements BasicModelInterface {
  private _weeklyTimingMonday: ScheduleTimeSlot[];
  private _weeklyTimingTuesday: ScheduleTimeSlot[];
  private _weeklyTimingWednesday: ScheduleTimeSlot[];
  private _weeklyTimingThursday: ScheduleTimeSlot[];
  private _weeklyTimingFriday: ScheduleTimeSlot[];
  private _weeklyTimingSaturday: ScheduleTimeSlot[];
  private _weeklyTimingSunday: ScheduleTimeSlot[];

  private _availability: Map<string, ScheduleTimeSlot[]>;
  private _unavailability: Map<string, ScheduleTimeSlot[]>;

  constructor() {}

  toJSON(): {} {
    let json = {};
    if (this.weeklyTimingMonday) {
      let temp = [];
      this.weeklyTimingMonday.forEach((scheduleTimeSlot: ScheduleTimeSlot) => {
        temp.push(scheduleTimeSlot.toJSON());
      });
      json[ObjectKeys.weeklyTimingMonday] = temp;
    }
    if (this.weeklyTimingTuesday) {
      let temp = [];
      this.weeklyTimingTuesday.forEach((scheduleTimeSlot: ScheduleTimeSlot) => {
        temp.push(scheduleTimeSlot.toJSON());
      });
      json[ObjectKeys.weeklyTimingTuesday] = temp;
    }
    if (this.weeklyTimingWednesday) {
      let temp = [];
      this.weeklyTimingWednesday.forEach(
        (scheduleTimeSlot: ScheduleTimeSlot) => {
          temp.push(scheduleTimeSlot.toJSON());
        }
      );
      json[ObjectKeys.weeklyTimingWednesday] = temp;
    }
    if (this.weeklyTimingThursday) {
      let temp = [];
      this.weeklyTimingThursday.forEach(
        (scheduleTimeSlot: ScheduleTimeSlot) => {
          temp.push(scheduleTimeSlot.toJSON());
        }
      );
      json[ObjectKeys.weeklyTimingThursday] = temp;
    }
    if (this.weeklyTimingFriday) {
      let temp = [];
      this.weeklyTimingFriday.forEach((scheduleTimeSlot: ScheduleTimeSlot) => {
        temp.push(scheduleTimeSlot.toJSON());
      });
      json[ObjectKeys.weeklyTimingFriday] = temp;
    }
    if (this.weeklyTimingSaturday) {
      let temp = [];
      this.weeklyTimingSaturday.forEach(
        (scheduleTimeSlot: ScheduleTimeSlot) => {
          temp.push(scheduleTimeSlot.toJSON());
        }
      );
      json[ObjectKeys.weeklyTimingSaturday] = temp;
    }
    if (this.weeklyTimingSunday) {
      let temp = [];
      this.weeklyTimingSunday.forEach((scheduleTimeSlot: ScheduleTimeSlot) => {
        temp.push(scheduleTimeSlot.toJSON());
      });
      json[ObjectKeys.weeklyTimingSunday] = temp;
    }
    if (this.availability) {
      let temp = {};
      this.availability.forEach((value, key) => {
        let tempChildArray = [];
        value.forEach((scheduleTimeSlot: ScheduleTimeSlot) => {
          tempChildArray.push(scheduleTimeSlot.toJSON());
        });
        temp[key] = tempChildArray;
      });
      json[ObjectKeys.availability] = temp;
    }
    if (this.unavailability) {
      let temp = {};
      this.unavailability.forEach((value, key) => {
        let tempChildArray = [];
        value.forEach((scheduleTimeSlot: ScheduleTimeSlot) => {
          tempChildArray.push(scheduleTimeSlot.toJSON());
        });
        temp[key] = tempChildArray;
      });
      json[ObjectKeys.unavailability] = temp;
    }
    return json;
  }

  initFromJSON(json: {}): void {
    if (json[ObjectKeys.weeklyTimingMonday]) {
      let array: ScheduleTimeSlot[] = [];
      let scheduleSlotJSONArray = json[ObjectKeys.weeklyTimingMonday];
      for (let schedule of scheduleSlotJSONArray) {
        let scheduleObj: ScheduleTimeSlot = new ScheduleTimeSlot();
        scheduleObj.initFromJSON(schedule);
        array.push(scheduleObj);
      }
      this.weeklyTimingMonday = array;
    }

    if (json[ObjectKeys.weeklyTimingTuesday]) {
      let array: ScheduleTimeSlot[] = [];
      let scheduleSlotJSONArray = json[ObjectKeys.weeklyTimingTuesday];
      for (let schedule of scheduleSlotJSONArray) {
        let scheduleObj: ScheduleTimeSlot = new ScheduleTimeSlot();
        scheduleObj.initFromJSON(schedule);
        array.push(scheduleObj);
      }
      this.weeklyTimingTuesday = array;
    }

    if (json[ObjectKeys.weeklyTimingWednesday]) {
      let array: ScheduleTimeSlot[] = [];
      let scheduleSlotJSONArray = json[ObjectKeys.weeklyTimingWednesday];
      for (let schedule of scheduleSlotJSONArray) {
        let scheduleObj: ScheduleTimeSlot = new ScheduleTimeSlot();
        scheduleObj.initFromJSON(schedule);
        array.push(scheduleObj);
      }
      this.weeklyTimingWednesday = array;
    }

    if (json[ObjectKeys.weeklyTimingThursday]) {
      let array: ScheduleTimeSlot[] = [];
      let scheduleSlotJSONArray = json[ObjectKeys.weeklyTimingThursday];
      for (let schedule of scheduleSlotJSONArray) {
        let scheduleObj: ScheduleTimeSlot = new ScheduleTimeSlot();
        scheduleObj.initFromJSON(schedule);
        array.push(scheduleObj);
      }
      this.weeklyTimingThursday = array;
    }

    if (json[ObjectKeys.weeklyTimingFriday]) {
      let array: ScheduleTimeSlot[] = [];
      let scheduleSlotJSONArray = json[ObjectKeys.weeklyTimingFriday];
      for (let schedule of scheduleSlotJSONArray) {
        let scheduleObj: ScheduleTimeSlot = new ScheduleTimeSlot();
        scheduleObj.initFromJSON(schedule);
        array.push(scheduleObj);
      }
      this.weeklyTimingFriday = array;
    }

    if (json[ObjectKeys.weeklyTimingSaturday]) {
      let array: ScheduleTimeSlot[] = [];
      let scheduleSlotJSONArray = json[ObjectKeys.weeklyTimingSaturday];
      for (let schedule of scheduleSlotJSONArray) {
        let scheduleObj: ScheduleTimeSlot = new ScheduleTimeSlot();
        scheduleObj.initFromJSON(schedule);
        array.push(scheduleObj);
      }
      this.weeklyTimingSaturday = array;
    }

    if (json[ObjectKeys.weeklyTimingSunday]) {
      let array: ScheduleTimeSlot[] = [];
      let scheduleSlotJSONArray = json[ObjectKeys.weeklyTimingSunday];
      for (let schedule of scheduleSlotJSONArray) {
        let scheduleObj: ScheduleTimeSlot = new ScheduleTimeSlot();
        scheduleObj.initFromJSON(schedule);
        array.push(scheduleObj);
      }
      this.weeklyTimingSunday = array;
    }

    if (json[ObjectKeys.availability]) {
      let tempMap = new Map<string, ScheduleTimeSlot[]>();
      let tempJSON = json[ObjectKeys.availability];
      for (let date in tempJSON) {
        let array: ScheduleTimeSlot[] = [];
        let scheduleSlotJSONArray = tempJSON[date];
        for (let schedule of scheduleSlotJSONArray) {
          let scheduleObj: ScheduleTimeSlot = new ScheduleTimeSlot();
          scheduleObj.initFromJSON(schedule);
          array.push(scheduleObj);
        }
        tempMap.set(date, array);
      }
      this.availability = tempMap;
    }
    if (json[ObjectKeys.unavailability]) {
      let tempMap = new Map<string, ScheduleTimeSlot[]>();
      let tempJSON = json[ObjectKeys.unavailability];
      for (let date in tempJSON) {
        let array: ScheduleTimeSlot[] = [];
        let scheduleSlotJSONArray = tempJSON[date];
        for (let schedule of scheduleSlotJSONArray) {
          let scheduleObj: ScheduleTimeSlot = new ScheduleTimeSlot();
          scheduleObj.initFromJSON(schedule);
          array.push(scheduleObj);
        }
        tempMap.set(date, array);
      }
      this.unavailability = tempMap;
    }
  }

  /**
   * Getter weeklyTimingMonday
   * @return {ScheduleTimeSlot[]}
   */
  public get weeklyTimingMonday(): ScheduleTimeSlot[] {
    return this._weeklyTimingMonday;
  }

  /**
   * Setter weeklyTimingMonday
   * @param {ScheduleTimeSlot[]} value
   */
  public set weeklyTimingMonday(value: ScheduleTimeSlot[]) {
    this._weeklyTimingMonday = value;
  }

  /**
   * Getter weeklyTimingTuesday
   * @return {ScheduleTimeSlot[]}
   */
  public get weeklyTimingTuesday(): ScheduleTimeSlot[] {
    return this._weeklyTimingTuesday;
  }

  /**
   * Setter weeklyTimingTuesday
   * @param {ScheduleTimeSlot[]} value
   */
  public set weeklyTimingTuesday(value: ScheduleTimeSlot[]) {
    this._weeklyTimingTuesday = value;
  }

  /**
   * Getter weeklyTimingWednesday
   * @return {ScheduleTimeSlot[]}
   */
  public get weeklyTimingWednesday(): ScheduleTimeSlot[] {
    return this._weeklyTimingWednesday;
  }

  /**
   * Setter weeklyTimingWednesday
   * @param {ScheduleTimeSlot[]} value
   */
  public set weeklyTimingWednesday(value: ScheduleTimeSlot[]) {
    this._weeklyTimingWednesday = value;
  }

  /**
   * Getter weeklyTimingThursday
   * @return {ScheduleTimeSlot[]}
   */
  public get weeklyTimingThursday(): ScheduleTimeSlot[] {
    return this._weeklyTimingThursday;
  }

  /**
   * Setter weeklyTimingThursday
   * @param {ScheduleTimeSlot[]} value
   */
  public set weeklyTimingThursday(value: ScheduleTimeSlot[]) {
    this._weeklyTimingThursday = value;
  }

  /**
   * Getter weeklyTimingFriday
   * @return {ScheduleTimeSlot[]}
   */
  public get weeklyTimingFriday(): ScheduleTimeSlot[] {
    return this._weeklyTimingFriday;
  }

  /**
   * Setter weeklyTimingFriday
   * @param {ScheduleTimeSlot[]} value
   */
  public set weeklyTimingFriday(value: ScheduleTimeSlot[]) {
    this._weeklyTimingFriday = value;
  }

  /**
   * Getter weeklyTimingSaturday
   * @return {ScheduleTimeSlot[]}
   */
  public get weeklyTimingSaturday(): ScheduleTimeSlot[] {
    return this._weeklyTimingSaturday;
  }

  /**
   * Setter weeklyTimingSaturday
   * @param {ScheduleTimeSlot[]} value
   */
  public set weeklyTimingSaturday(value: ScheduleTimeSlot[]) {
    this._weeklyTimingSaturday = value;
  }

  /**
   * Getter weeklyTimingSunday
   * @return {ScheduleTimeSlot[]}
   */
  public get weeklyTimingSunday(): ScheduleTimeSlot[] {
    return this._weeklyTimingSunday;
  }

  /**
   * Setter weeklyTimingSunday
   * @param {ScheduleTimeSlot[]} value
   */
  public set weeklyTimingSunday(value: ScheduleTimeSlot[]) {
    this._weeklyTimingSunday = value;
  }

  /**
   * Getter availability
   * @return {Map<string, ScheduleTimeSlot[]>}
   */
  public get availability(): Map<string, ScheduleTimeSlot[]> {
    return this._availability;
  }

  /**
   * Setter availability
   * @param {Map<string, ScheduleTimeSlot[]>} value
   */
  public set availability(value: Map<string, ScheduleTimeSlot[]>) {
    this._availability = value;
  }

  /**
   * Getter unavailability
   * @return {Map<string, ScheduleTimeSlot[]>}
   */
  public get unavailability(): Map<string, ScheduleTimeSlot[]> {
    return this._unavailability;
  }

  /**
   * Setter unavailability
   * @param {Map<string, ScheduleTimeSlot[]>} value
   */
  public set unavailability(value: Map<string, ScheduleTimeSlot[]>) {
    this._unavailability = value;
  }
}

export class ScheduleTimeSlot implements BasicModelInterface {
  private _startTime: string;
  private _endTime: string;

  constructor() {}

  toJSON(): {} {
    let json = {};
    if (this.startTime) {
      json[ObjectKeys.startTime] = this.startTime;
    }
    if (this.endTime) {
      json[ObjectKeys.endTime] = this.endTime;
    }
    return json;
  }
  initFromJSON(json: {}): void {
    if (json[ObjectKeys.startTime]) {
      this.startTime = json[ObjectKeys.startTime];
    }
    if (json[ObjectKeys.endTime]) {
      this.endTime = json[ObjectKeys.endTime];
    }
  }

  /**
   * Getter startTime
   * @return {string}
   */
  public get startTime(): string {
    return this._startTime;
  }

  /**
   * Setter startTime
   * @param {string} value
   */
  public set startTime(value: string) {
    this._startTime = value;
  }

  /**
   * Getter endTime
   * @return {string}
   */
  public get endTime(): string {
    return this._endTime;
  }

  /**
   * Setter endTime
   * @param {string} value
   */
  public set endTime(value: string) {
    this._endTime = value;
  }
}

export class OPDTypeAndFee implements BasicModelInterface {
  private _serviceId: string;
  private _serviceName: string;
  private _serviceDescription: string; //Optional
  private _doctorWisePricing: Map<string, DoctorPricing> = new Map();
  private _active: boolean;

  constructor() {}

  toJSON(): {} {
    let json = {};
    if (this.serviceId) {
      json[ObjectKeys.serviceId] = this.serviceId;
    }
    if (this.serviceName) {
      json[ObjectKeys.serviceName] = this.serviceName;
    }
    if (this.serviceDescription) {
      json[ObjectKeys.serviceDescription] = this.serviceDescription;
    }
    if (this.active) {
      json[ObjectKeys.active] = this.active;
    }
    if (this.doctorWisePricing) {
      let itemsJSON = {};
      this.doctorWisePricing.forEach((val, key) => {
        itemsJSON[key] = val.toJSON();
      });
      json[ObjectKeys.doctorWisePricing] = itemsJSON;
    }
    return json;
  }

  initFromJSON(json: {}): void {
    if (json[ObjectKeys.serviceId]) {
      this.serviceId = json[ObjectKeys.serviceId];
    }
    if (json[ObjectKeys.serviceName]) {
      this.serviceName = json[ObjectKeys.serviceName];
    }
    if (json[ObjectKeys.serviceDescription]) {
      this.serviceDescription = json[ObjectKeys.serviceDescription];
    }
    if (json[ObjectKeys.active]) {
      this.active = json[ObjectKeys.active];
    }
    if (json[ObjectKeys.doctorWisePricing]) {
      this.doctorWisePricing = json[ObjectKeys.doctorWisePricing];
    }
    if (json[ObjectKeys.doctorWisePricing]) {
      let temp = json[ObjectKeys.doctorWisePricing];
      let tempMap: Map<string, DoctorPricing> = new Map();
      for (let k in temp) {
        let m: DoctorPricing = new DoctorPricing();
        m.initFromJSON(temp[k]);
        tempMap.set(k, m);
      }
      this.doctorWisePricing = tempMap;
    }
  }

  /**
   * Getter doctorWisePricing
   * @return {Map<string, DoctorPricing>}
   */
  public get doctorWisePricing(): Map<string, DoctorPricing> {
    return this._doctorWisePricing;
  }

  /**
   * Setter doctorWisePricing
   * @param {Map<string, DoctorPricing>} value
   */
  public set doctorWisePricing(value: Map<string, DoctorPricing>) {
    this._doctorWisePricing = value;
  }

  /**
   * Getter serviceDescription
   * @return {string}
   */
  public get serviceDescription(): string {
    return this._serviceDescription;
  }

  /**
   * Setter serviceDescription
   * @param {string} value
   */
  public set serviceDescription(value: string) {
    this._serviceDescription = value;
  }

  /**
   * Getter serviceName
   * @return {string}
   */
  public get serviceName(): string {
    return this._serviceName;
  }

  /**
   * Setter serviceName
   * @param {string} value
   */
  public set serviceName(value: string) {
    this._serviceName = value;
  }

  /**
   * Getter active
   * @return {boolean}
   */
  public get active(): boolean {
    return this._active;
  }

  /**
   * Setter active
   * @param {boolean} value
   */
  public set active(value: boolean) {
    this._active = value;
  }

  /**
   * Getter serviceId
   * @return {string}
   */
  public get serviceId(): string {
    return this._serviceId;
  }

  /**
   * Setter serviceId
   * @param {string} value
   */
  public set serviceId(value: string) {
    this._serviceId = value;
  }
}
export class DoctorPricing implements BasicModelInterface {
  private _doctorName: string;
  private _amount: number;
  private _durationValidityNumber: number;
  private _durationValidityUnit: string;
  private _quantityIncluded: number;

  constructor() {}

  toJSON(): {} {
    let json = {};
    if (this.amount) {
      json[ObjectKeys.amount] = this.amount;
    }
    if (this.durationValidityNumber) {
      json[ObjectKeys.durationValidityNumber] = this.durationValidityNumber;
    }
    if (this.quantityIncluded) {
      json[ObjectKeys.quantityIncluded] = this.quantityIncluded;
    }
    if (this.durationValidityUnit) {
      json[ObjectKeys.durationValidityUnit] = this.durationValidityUnit;
    }
    if (this.doctorName) {
      json[ObjectKeys.doctorName] = this.doctorName;
    }
    return json;
  }

  initFromJSON(json: {}): void {
    if (json[ObjectKeys.amount]) {
      this.amount = json[ObjectKeys.amount];
    }
    if (json[ObjectKeys.durationValidityNumber]) {
      this.durationValidityNumber = json[ObjectKeys.durationValidityNumber];
    }
    if (json[ObjectKeys.quantityIncluded]) {
      this.quantityIncluded = json[ObjectKeys.quantityIncluded];
    }
    if (json[ObjectKeys.durationValidityUnit]) {
      this.durationValidityUnit = json[ObjectKeys.durationValidityUnit];
    }
    if (json[ObjectKeys.doctorName]) {
      this.doctorName = json[ObjectKeys.doctorName];
    }
  }

  /**
   * Getter doctorName
   * @return {string}
   */
  public get doctorName(): string {
    return this._doctorName;
  }

  /**
   * Setter doctorName
   * @param {string} value
   */
  public set doctorName(value: string) {
    this._doctorName = value;
  }

  /**
   * Getter durationValidityUnit
   * @return {string}
   */
  public get durationValidityUnit(): string {
    return this._durationValidityUnit;
  }

  /**
   * Setter durationValidityUnit
   * @param {string} value
   */
  public set durationValidityUnit(value: string) {
    this._durationValidityUnit = value;
  }
  /**
   * Getter quantityIncluded
   * @return {string}
   */
  public get quantityIncluded(): number {
    return this._quantityIncluded;
  }

  /**
   * Setter quantityIncluded
   * @param {number} value
   */
  public set quantityIncluded(value: number) {
    this._quantityIncluded = value;
  }
  /**
   * Getter amount
   * @return {string}
   */
  public get amount(): number {
    return this._amount;
  }

  /**
   * Setter amount
   * @param {number} value
   */
  public set amount(value: number) {
    this._amount = value;
  }

  /**
   * Getter durationValidityNumber
   * @return {number}
   */
  public get durationValidityNumber(): number {
    return this._durationValidityNumber;
  }

  /**
   * Setter durationValidityNumber
   * @param {number} value
   */
  public set durationValidityNumber(value: number) {
    this._durationValidityNumber = value;
  }
}
export class procedureTypeAndFee implements BasicModelInterface {
  private _serviceId: string;
  private _serviceName: string;
  private _serviceDescription: string; //Optional
  private _price: number; //Optional
  private _active: boolean;
  private _doctorId: string;
  constructor() {}
  toJSON(): {} {
    let json = {};
    if (this.serviceId) {
      json[ObjectKeys.serviceId] = this.serviceId;
    }
    if (this.serviceName) {
      json[ObjectKeys.serviceName] = this.serviceName;
    }
    if (this.serviceDescription) {
      json[ObjectKeys.serviceDescription] = this.serviceDescription;
    }
    if (this.price) {
      json[ObjectKeys.price] = this.price;
    }
    if (this.active) {
      json[ObjectKeys.active] = this.active;
    }
    if (this.doctorId) {
      json[ObjectKeys.doctorId] = this.doctorId;
    }
    return json;
  }
  initFromJSON(json: {}): void {
    if (json[ObjectKeys.serviceId]) {
      this.serviceId = json[ObjectKeys.serviceId];
    }
    if (json[ObjectKeys.serviceName]) {
      this.serviceName = json[ObjectKeys.serviceName];
    }
    if (json[ObjectKeys.serviceDescription]) {
      this.serviceDescription = json[ObjectKeys.serviceDescription];
    }
    if (json[ObjectKeys.price]) {
      this.price = json[ObjectKeys.price];
    }
    if (json[ObjectKeys.active]) {
      this.active = json[ObjectKeys.active];
    }
    if (json[ObjectKeys.doctorId]) {
      this.doctorId = json[ObjectKeys.doctorId];
    }
  }

  /**
   * Getter active
   * @return {boolean}
   */
  public get active(): boolean {
    return this._active;
  }

  /**
   * Setter active
   * @param {boolean} value
   */
  public set active(value: boolean) {
    this._active = value;
  }

  /**
   * Getter price
   * @return {number}
   */
  public get price(): number {
    return this._price;
  }

  /**
   * Setter price
   * @param {number} value
   */
  public set price(value: number) {
    this._price = value;
  }
  /**
   * Getter serviceDescription
   * @return {string}
   */
  public get serviceDescription(): string {
    return this._serviceDescription;
  }

  /**
   * Setter serviceDescription
   * @param {string} value
   */
  public set serviceDescription(value: string) {
    this._serviceDescription = value;
  }
  /**
   * Getter serviceName
   * @return {string}
   */
  public get serviceName(): string {
    return this._serviceName;
  }

  /**
   * Setter serviceName
   * @param {string} value
   */
  public set serviceName(value: string) {
    this._serviceName = value;
  }
  /**
   * Getter serviceId
   * @return {string}
   */
  public get serviceId(): string {
    return this._serviceId;
  }

  /**
   * Setter serviceId
   * @param {string} value
   */
  public set serviceId(value: string) {
    this._serviceId = value;
  }
  /**
   * Getter doctorId
   * @return {string}
   */
  public get doctorId(): string {
    return this._doctorId;
  }
  /**
   * Setter doctorId
   * @param {string} value
   */
  public set doctorId(value: string) {
    this._doctorId = value;
  }
}

export class PatientRegistrationFormSettings implements BasicModelInterface {
  private _isAddressRequired: boolean;
  private _isDobRequired: boolean;
  private _isEmailRequired: boolean;
  private _isGenderRequired: boolean;
  private _isMobileNumberRequired: boolean;
  private _isNameRequired: boolean;
  private _isPatientAgeObjectRequired: boolean;
  private _isRelationsRequired: boolean;
  private _isReligionRequired: boolean;
  private _isSecondaryMobileRequired: boolean;
  private _isPrimaryLandlineRequired: boolean;
  private _isUhidRequired: boolean;
  private _isAssociatedCorporationRequired: boolean;

  toJSON(): {} {
    let json = {};
    if (this.isAddressRequired !== null) {
      json[ObjectKeys.isAddressRequired] = this.isAddressRequired;
    }
    if (this.isDobRequired !== null) {
      json[ObjectKeys.isDobRequired] = this.isDobRequired;
    }
    if (this.isEmailRequired !== null) {
      json[ObjectKeys.isEmailRequired] = this.isEmailRequired;
    }
    if (this.isGenderRequired !== null) {
      json[ObjectKeys.isGenderRequired] = this.isGenderRequired;
    }
    if (this.isMobileNumberRequired !== null) {
      json[ObjectKeys.isMobileNumberRequired] = this.isMobileNumberRequired;
    }
    if (this.isSecondaryMobileRequired !== null) {
      json[
        ObjectKeys.isSecondaryMobileRequired
      ] = this.isSecondaryMobileRequired;
    }
    if (this.isPrimaryLandlineRequired !== null) {
      json[
        ObjectKeys.isPrimaryLandlineRequired
      ] = this.isPrimaryLandlineRequired;
    }
    if (this.isNameRequired !== null) {
      json[ObjectKeys.isNameRequired] = this.isNameRequired;
    }
    if (this.isPatientAgeObjectRequired !== null) {
      json[
        ObjectKeys.isPatientAgeObjectRequired
      ] = this.isPatientAgeObjectRequired;
    }
    if (this.isRelationsRequired !== null) {
      json[ObjectKeys.isRelationsRequired] = this.isRelationsRequired;
    }
    if (this.isReligionRequired !== null) {
      json[ObjectKeys.isReligionRequired] = this.isReligionRequired;
    }
    if (this.isUhidRequired !== null) {
      json[ObjectKeys.isUhidRequired] = this.isUhidRequired;
    }
    if (this.isAssociatedCorporationRequired !== null) {
      json[
        ObjectKeys.isAssociatedCorporationRequired
      ] = this.isAssociatedCorporationRequired;
    }
    return json;
  }

  initFromJSON(json: {}): void {
    if (
      json[ObjectKeys.isAddressRequired] !== null &&
      json[ObjectKeys.isAddressRequired] !== undefined
    ) {
      this.isAddressRequired = json[ObjectKeys.isAddressRequired];
    }
    if (
      json[ObjectKeys.isDobRequired] !== null &&
      json[ObjectKeys.isDobRequired] !== undefined
    ) {
      this.isDobRequired = json[ObjectKeys.isDobRequired];
    }
    if (
      json[ObjectKeys.isEmailRequired] !== null &&
      json[ObjectKeys.isEmailRequired] !== undefined
    ) {
      this.isEmailRequired = json[ObjectKeys.isEmailRequired];
    }
    if (json[ObjectKeys.isGenderRequired] !== null) {
      this.isGenderRequired = json[ObjectKeys.isGenderRequired];
    }
    if (json[ObjectKeys.isMobileNumberRequired] !== null) {
      this.isMobileNumberRequired = json[ObjectKeys.isMobileNumberRequired];
    }
    if (json[ObjectKeys.isSecondaryMobileRequired] !== null) {
      this.isSecondaryMobileRequired =
        json[ObjectKeys.isSecondaryMobileRequired];
    }
    if (json[ObjectKeys.isPrimaryLandlineRequired] !== null) {
      this.isPrimaryLandlineRequired =
        json[ObjectKeys.isPrimaryLandlineRequired];
    }
    if (json[ObjectKeys.isNameRequired] !== null) {
      this.isNameRequired = json[ObjectKeys.isNameRequired];
    }
    if (json[ObjectKeys.isPatientAgeObjectRequired] !== null) {
      this.isPatientAgeObjectRequired =
        json[ObjectKeys.isPatientAgeObjectRequired];
    }
    if (json[ObjectKeys.isRelationsRequired] !== null) {
      this.isRelationsRequired = json[ObjectKeys.isRelationsRequired];
    }
    if (json[ObjectKeys.isReligionRequired] !== null) {
      this.isReligionRequired = json[ObjectKeys.isReligionRequired];
    }
    if (json[ObjectKeys.isUhidRequired] !== null) {
      this.isUhidRequired = json[ObjectKeys.isUhidRequired];
    }
    if (json[ObjectKeys.isAssociatedCorporationRequired] !== null) {
      this.isAssociatedCorporationRequired =
        json[ObjectKeys.isAssociatedCorporationRequired];
    }
  }

  /**
   * Getter isAssociatedCorporationRequired
   * @return {boolean}
   */
  public get isAssociatedCorporationRequired(): boolean {
    return this._isAssociatedCorporationRequired;
  }

  /**
   * Setter isAssociatedCorporationRequired
   * @param {boolean} value
   */
  public set isAssociatedCorporationRequired(value: boolean) {
    this._isAssociatedCorporationRequired = value;
  }

  /**
   * Getter isUhidRequired
   * @return {boolean}
   */
  public get isUhidRequired(): boolean {
    return this._isUhidRequired;
  }

  /**
   * Setter isUhidRequired
   * @param {boolean} value
   */
  public set isUhidRequired(value: boolean) {
    this._isUhidRequired = value;
  }
  /**
   * Getter isPrimaryLandlineRequired
   * @return {boolean}
   */
  public get isPrimaryLandlineRequired(): boolean {
    return this._isPrimaryLandlineRequired;
  }

  /**
   * Setter isPrimaryLandlineRequired
   * @param {boolean} value
   */
  public set isPrimaryLandlineRequired(value: boolean) {
    this._isPrimaryLandlineRequired = value;
  }

  /**
   * Getter isSecondaryMobileRequired
   * @return {boolean}
   */
  public get isSecondaryMobileRequired(): boolean {
    return this._isSecondaryMobileRequired;
  }

  /**
   * Setter isSecondaryMobileRequired
   * @param {boolean} value
   */
  public set isSecondaryMobileRequired(value: boolean) {
    this._isSecondaryMobileRequired = value;
  }

  /**
   * Getter isMobileNumberRequired
   * @return {boolean}
   */
  public get isMobileNumberRequired(): boolean {
    return this._isMobileNumberRequired;
  }

  /**
   * Setter isMobileNumberRequired
   * @param {boolean} value
   */
  public set isMobileNumberRequired(value: boolean) {
    this._isMobileNumberRequired = value;
  }

  /**
   * Getter isRelationsRequired
   * @return {boolean}
   */
  public get isRelationsRequired(): boolean {
    return this._isRelationsRequired;
  }

  /**
   * Setter isRelationsRequired
   * @param {boolean} value
   */
  public set isRelationsRequired(value: boolean) {
    this._isRelationsRequired = value;
  }

  /**
   * Getter isDobRequired
   * @return {boolean}
   */
  public get isDobRequired(): boolean {
    return this._isDobRequired;
  }

  /**
   * Setter isDobRequired
   * @param {boolean} value
   */
  public set isDobRequired(value: boolean) {
    this._isDobRequired = value;
  }

  /**
   * Getter isPatientAgeObjectRequired
   * @return {boolean}
   */
  public get isPatientAgeObjectRequired(): boolean {
    return this._isPatientAgeObjectRequired;
  }

  /**
   * Setter isPatientAgeObjectRequired
   * @param {boolean} value
   */
  public set isPatientAgeObjectRequired(value: boolean) {
    this._isPatientAgeObjectRequired = value;
  }

  /**
   * Getter  isEmailRequired
   * @return {boolean}
   */
  public get isEmailRequired(): boolean {
    return this._isEmailRequired;
  }

  /**
   * Setter isEmailRequired
   * @param {boolean} value
   */
  public set isEmailRequired(value: boolean) {
    this._isEmailRequired = value;
  }

  /**
   * Getter  isReligionRequired
   * @return {boolean}
   */
  public get isReligionRequired(): boolean {
    return this._isReligionRequired;
  }

  /**
   * Setter isReligionRequired
   * @param {boolean} value
   */
  public set isReligionRequired(value: boolean) {
    this._isReligionRequired = value;
  }

  /**
   * Getter  isAddressRequired
   * @return {boolean}
   */
  public get isAddressRequired(): boolean {
    return this._isAddressRequired;
  }

  /**
   * Setter isAddressRequired
   * @param {boolean} value
   */
  public set isAddressRequired(value: boolean) {
    this._isAddressRequired = value;
  }

  /**
   * Getter  isGenderRequired
   * @return {boolean}
   */
  public get isGenderRequired(): boolean {
    return this._isGenderRequired;
  }

  /**
   * Setter  isGenderRequired
   * @param {boolean} value
   */
  public set isGenderRequired(value: boolean) {
    this._isGenderRequired = value;
  }

  /**
   * Getter  isNameRequired
   * @return {boolean}
   */
  public get isNameRequired(): boolean {
    return this._isNameRequired;
  }

  /**
   * Setter  isNameRequired
   * @param {boolean} value
   */
  public set isNameRequired(value: boolean) {
    this._isNameRequired = value;
  }
}
