import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as urls from "../../../../environments/environment";
@Injectable({
  providedIn: 'root'
})
export class TokenRegistrationService {

  constructor(private _http: HttpClient) {}

  registerToken(memberId, organisationId, token){
    return new Promise((resolve, reject) => {
      let body = {token,organisationId,memberId}
      let val = this._http
        .post(urls.environment["token-registration"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  deregisterToken(memberId, organisationId, token){
    return new Promise((resolve, reject) => {
      let body = {token,organisationId,memberId}
      let val = this._http
        .post(urls.environment["token-deregistration"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }
}
