import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ProviderUsernameCheckService } from "../services/provider-username-check.service";
declare var $: any;
@Component({
  selector: "app-username-search",
  templateUrl: "./username-search.component.html",
  styleUrls: ["./username-search.component.css"]
})
export class UsernameSearchComponent implements OnInit {
  @ViewChild("basicUrl", { static: false })
  basicUrl: ElementRef;
  lottieHeightWidth: Number = 50;
  lottieQRConfig: Object;
  lottieSmileyConfig: Object;
  lottieWorldConfig: Object;
  uniqueName: string = "";
  uniqueNameDuplicate: string = "";
  isAvailable: boolean = false;
  showHelperText: boolean = false;
  constructor(
    private _checkAvailiblity: ProviderUsernameCheckService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _spinnerService: NgxSpinnerService
  ) {
    this.lottieQRConfig = this.getQRLottieConfig();
    this.lottieSmileyConfig = this.getSmileyLottieConfig();
    this.lottieWorldConfig = this.getWorldLottieConfig();
  }

  ngOnInit() {
    this.focusOnURlField();
  }

  getQRLottieConfig(): {} {
    return {
      path: "../../assets/lottie/qr-code.json",
      renderer: "canvas",
      autoplay: true,
      loop: true
    };
  }
  getSmileyLottieConfig(): {} {
    return {
      path: "../../assets/lottie/7079-happy-emoji-smile.json",
      renderer: "canvas",
      autoplay: true,
      loop: true
    };
  }
  getWorldLottieConfig(): {} {
    return {
      path: "../../assets/lottie/9318-globe.json",
      renderer: "canvas",
      autoplay: true,
      loop: true
    };
  }

  checkIfAvailable() {
    if (this.uniqueName.length != 0) {
      this.uniqueNameDuplicate = this.uniqueName;
      this._spinnerService.show();
      this._checkAvailiblity
        .checkAvailibilty(this.uniqueName)
        .then(res => {
          if (res["body"].success == true) {
            $("#reserveForMeToday").modal("show");
            // this.showHelperText = true;
            // this.isAvailable = true;
            this._spinnerService.hide();
          } else if (res["body"].success == false) {
            this.showHelperText = true;
            this.isAvailable = false;
            this._spinnerService.hide();
          }
        })
        .catch(err => {
          this._spinnerService.hide();
          console.log(err);
        });
    } else {
      alert("Please enter your unique url");
    }
  }

  reserveUrlForMe() {
    $("#reserveForMeToday").modal("hide");
    this._router.navigate(["./signup"], {
      relativeTo: this._activatedRoute,
      queryParams: { url: this.uniqueName }
    });
  }

  clearErrorMessage(): void {
    this.showHelperText = false;
    this.isAvailable = false;
  }
  focusOnURlField() {
    setTimeout(() => {
      this.basicUrl.nativeElement.focus();
    }, 230);
  }
}
