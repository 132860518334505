import { Injectable } from "@angular/core";
import {
  Medication,
  MedicationStatus
} from "../../models/Medication/Medication";
import { Subject, Observable } from "rxjs";
import { DentalChartEntry } from "../../models/Patient/Dental/DentalChartEntry";
import { Appointment } from "../../models/Scheduling/Appointment";

@Injectable({
  providedIn: "root"
})
export class SideSectionDialogManagementService {
  // medication: Medication = new Medication();
  subject = new Subject<Map<string, Object>>();
  subjectPrescription = new Subject<Map<string, Object>>();

  constructor() {}

  public listener(): Observable<Map<string, Object>> {
    return this.subject.asObservable();
  }

  public hide() {
    let map = new Map<string, Object>();
    map.set("show", false);
    map.set("type", "new_medicine");
    return this.subject.next(map);
  }

  public listenForUpdatedMed() {
    return this.subjectPrescription.asObservable();
  }

  public sendBackToPrescription(med: Medication, status: MedicationStatus) {
    med.status = status;
    let map: Map<string, Object> = new Map<string, Object>();
    map.set("med", med);
    // map.set("entryByVoice",entryByVoice);
    return this.subjectPrescription.next(map);
  }

  public open(med: Medication) {
    this.hideAll();
    // this.medication = med;
    let map = new Map<string, Object>();
    map.set("type", "new_medicine");
    map.set("show", true);
    map.set("medication", med);
    return this.subject.next(map);
  }

  public openViewAppointment(appointment: Appointment) {
    this.hideAll();
    let map = new Map<string, Object>();
    map.set("type", "view_appointment");
    map.set("show", true);
    map.set("appointment", appointment);
    return this.subject.next(map);
  }

  public openDentalChartEntry(
    isEditing: boolean,
    caller: string,
    dentalChartEntry?: DentalChartEntry
  ) {
    this.hideAll();
    let map = new Map<string, Object>();
    map.set("type", "new_dental_entry");
    map.set("show", true);
    map.set("isEditing", isEditing);
    map.set("dentalChartEntry", dentalChartEntry);
    map.set("caller", caller);
    return this.subject.next(map);
  }

  public hideDentalChartEntry() {
    let map = new Map<string, Object>();
    map.set("show", false);
    map.set("type", "new_dental_entry");
    return this.subject.next(map);
  }

  public hideViewAppointment() {
    let map = new Map<string, Object>();
    map.set("show", false);
    map.set("type", "view_appointment");
    return this.subject.next(map);
  }

  public hideAll() {
    this.hide();
    this.hideDentalChartEntry();
    this.hideViewAppointment();
  }
}
