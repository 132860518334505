import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { NotifyService } from "./notify/notify.service";

export enum DictationCaller {
  "ASSISTANT" = "assistant",
  "DIAGNOSIS" = "diagnosis",
  "FINDINGS" = "findings",
  "SYSTEMIC" = "systemic",
  "COMPLAINTS" = "complaints",
  "PRO_DIAGNOSIS" = "pro-diagnosis",
  "REMARKS" = "remarks",
  "INVESTIGATION_ADVICED" = "investigation_adviced",
  "IPD_COMPLAINT_OF" = "ipd_complaint_of",
  "IPD_FINDINGS" = "ipd_findings",
  "IPD_FINDINGS_VITALS" = "ipd_findings_vitals",
  "IPD_MAJOR_POSITIVE_INVESTIGATION_FINDINGS" = "ipd_major_positive_investigation_findings",
  "IPD_MEDICATIONS_OR_TREATMENTS_GiVEN" = "ipd_medications_or_treatments_given",
  "IPD_CONDITION_AT_THE_TIME_OF_DISCHARGE" = "ipd_condition_at_the_time_of_discharge",
  "IPD_CONDITION_AT_THE_TIME_OF_DISCHARGE_VITALS" = "ipd_condition_at_the_time_of_discharge_vitals",
  "IPD_TREATMENT_ON_DISCHARGE" = "ipd_treatment_on_discharge",
  "IPD_FOLLOW_UP_ADVICE" = "ipd_follow_up_advice",
  "IPD_EVALUATION_COMPLAIN" = "ipd_evaluation_complain",
  "IPD_EVALUATION_HISTORY" = "ipd_evaluation_history",
  "IPD_EVALUATION_KNOWN_CASE" = "ipd_evaluation_known_case",
  "IPD_ASSESSMENT_GENERAL_CONDITION" = "ipd_assessment_general_condition",
  "IPD_ASSESSMENT_ON_EXAMINATION" = "ipd_assessment_on_examination",
  "IPD_ASSESSMENT_ABDOMEN" = "ipd_assessment_abdomen",
  "IPD_ASSESSMENT_CHEST" = "ipd_assessment_chest",
  "IPD_ASSESSMENT_BL" = "ipd_assessment_bl",
  "IPD_ASSESSMENT_CVS" = "ipd_assessment_cvs",
  "IPD_ASSESSMENT_CNS" = "ipd_assessment_cns",
  "IPD_ADVICE_REMARKS" = "ipd_advice_remarks",
  "IPD_TREATMENT_REMARKS" = "ipd_treatment_remarks",
  "IPD_TREATMENT_DIAGNOSIS" = "ipd_treatment_diagnosis",
  "IPD_URINE_PHYSICAL_EXAMINATION" = "ipd_urine_physical_examination",
  "IPD_URINE_CHEMICAL_EXAMINATION" = "ipd_urine_chemical_examination",
  "IPD_URINE_MICROSCOPIC_EXAMINATION" = "ipd_urine_microscopic_examination",
  "URINE_REMARKS" = "urine_remarks",
  // SUGAR
  "IPD_POST_PRANDIAL_PLASMA_GLUCOSE" = "post_prandial_glucose",
  "IDP_FASTING_PLASMA_GLUCOSE" = "fasting_plasma_glucose",
  "IPD_SUGAR_REMARKS" = "sugar_remarks",
  // LIPID PROFILE
  "TOTAL_LIPID_PROFILE" = "total_lipid_profile",
  "UREA_UREA_NITROGEN" = "urea_urea_nitrogen",
  "LIPID_REMARKS" = "lipid_remarks",
  "CREATININE" = "creatinine",
  "URIC_ACID" = "uric_acid",
  // CBC
  "IPD_CBC" = "ipd_cbc",
  "IPD_CBC_REMARKS" = "ipd_cbc_remarks",
  // CHEST XRAY
  "IPD_CHEST_XRAY" = "ipd_chest_xray",
  "IPD_CHEST_XRAY_REMARKS" = "ipd_chest_xray_remarks",
  "DENTAL_CHIEF_COMPLAINT" = "dental_chief_complaint"
}

@Injectable({
  providedIn: "root"
})
export class DictationHandlerService {
  //Maintain the current caller
  currentCaller: DictationCaller = null;
  continuousMode: boolean = false;
  actions: Subject<string> = new Subject();

  //A new subject for each caller
  assistantSubject: Subject<{}> = new Subject();
  diagnosisSubject: Subject<{}> = new Subject();
  findingsSubject: Subject<{}> = new Subject();

  systemicSubject: Subject<{}> = new Subject();
  complaintsSubject: Subject<{}> = new Subject();
  proDiagnosisSubject: Subject<{}> = new Subject();
  remarksSubject: Subject<{}> = new Subject();
  investigationSubject: Subject<{}> = new Subject();
  dentalChiefComplaintSubject: Subject<{}> = new Subject();

  ipdComplaintOfSubject: Subject<{}> = new Subject();
  ipdFindingsSubject: Subject<{}> = new Subject();
  ipdfindingsVitalsSubject: Subject<{}> = new Subject();
  ipdMajorPositiveInvestigationFindingsSubject: Subject<{}> = new Subject();
  ipdMedicationOrTreatmentGivenSubject: Subject<{}> = new Subject();
  ipdTreatmentOnDischargeSubject: Subject<{}> = new Subject();
  ipdFollowUpAdviceSubject: Subject<{}> = new Subject();
  ipdConditionAtTheTimeOfDischargeSubject: Subject<{}> = new Subject();
  ipdConditionAtTheTimeOfDischargeVitalsSubject: Subject<{}> = new Subject();

  ipdEvaluationComplainSubject: Subject<{}> = new Subject();
  ipdEvaluationHistorySubject: Subject<{}> = new Subject();
  ipdEvaluationKnownCaseSubject: Subject<{}> = new Subject();

  ipdAssessmentGeneralConditionSubject: Subject<{}> = new Subject();
  ipdAssessmentOnExaminationSubject: Subject<{}> = new Subject();
  ipdAssessmentAbdomenSubject: Subject<{}> = new Subject();
  ipdAssessmentChestSubject: Subject<{}> = new Subject();
  ipdAssessmentBLSubject: Subject<{}> = new Subject();
  ipdAssessmentCVSSubject: Subject<{}> = new Subject();
  ipdAssessmentCNSSubject: Subject<{}> = new Subject();

  ipdAdviceRemarksSubject: Subject<{}> = new Subject();

  ipdTreatmentRemarksSubject: Subject<{}> = new Subject();
  ipdTreatmentDiagnosisSubject: Subject<{}> = new Subject();

  ipdUrinePhysicalExaminationSubject: Subject<{}> = new Subject();
  ipdUrineChemicalExaminationSubject: Subject<{}> = new Subject();
  ipdUrineMicroscopicExaminationSubject: Subject<{}> = new Subject();
  ipdUrineRemarksSubject: Subject<{}> = new Subject();

  // sugar
  ipdSugarPostPlasmaGlucoseSubject: Subject<{}> = new Subject();
  ipdSugarFastingPlasmaGlucoseSubject: Subject<{}> = new Subject();
  ipdSugarRemarksSubject: Subject<{}> = new Subject();

  // lipid
  ipdTotalLipidProfileSubject: Subject<{}> = new Subject();
  ipdUreaNitrogenSubject: Subject<{}> = new Subject();
  ipdLipidRemarksSubject: Subject<{}> = new Subject();
  ipdCreatinineSubject: Subject<{}> = new Subject();
  ipdUricAcidSubject: Subject<{}> = new Subject();

  // cbc
  ipdCbcSubject: Subject<{}> = new Subject();
  ipdCbcRemarksSubject: Subject<{}> = new Subject();

  // xray
  ipdChestXraySubject: Subject<{}> = new Subject();
  ipdChestXrayRemarksSubject: Subject<{}> = new Subject();

  constructor(private _notifyService: NotifyService) {}

  actionsListener() {
    return this.actions.asObservable();
  }

  startListening(callerName: DictationCaller, continuousMode?: boolean) {
    if (this.currentCaller == null) {
      //Call the start speech function
      this.currentCaller = callerName;
      this.continuousMode = continuousMode;
      if (continuousMode) {
        this.actions.next("start_continuous");
      } else {
        this.actions.next("start");
      }
      return true;
    } else {
      //TODO: notify user for the already in use
      this._notifyService.showWarningMessage("Already in use");
      return false;
    }
  }

  stopListening(callerName: DictationCaller) {
    if (callerName == this.currentCaller) {
      //Call the stop speech function
      this.currentCaller = null;
      this.actions.next("stop");
      return true;
    } else {
      //TODO: notify user for not enough permission or try again
      this._notifyService.showWarningMessage("Please try again");
      return false;
    }
  }

  //Will be called by speech recognizer service in case of any error
  stoppedAbruptly() {
    this.currentCaller = null;
  }

  masterTransmitter(interimText?: string, finalText?: string) {
    if (this.currentCaller) {
      switch (this.currentCaller) {
        case DictationCaller.ASSISTANT: {
          this.assistantSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.IPD_COMPLAINT_OF: {
          this.ipdComplaintOfSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.IPD_CONDITION_AT_THE_TIME_OF_DISCHARGE: {
          this.ipdConditionAtTheTimeOfDischargeSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.IPD_CONDITION_AT_THE_TIME_OF_DISCHARGE_VITALS: {
          this.ipdConditionAtTheTimeOfDischargeVitalsSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.IPD_FINDINGS: {
          this.ipdFindingsSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.IPD_FINDINGS_VITALS: {
          this.ipdfindingsVitalsSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.IPD_MAJOR_POSITIVE_INVESTIGATION_FINDINGS: {
          this.ipdMajorPositiveInvestigationFindingsSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.IPD_MEDICATIONS_OR_TREATMENTS_GiVEN: {
          this.ipdMedicationOrTreatmentGivenSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.IPD_TREATMENT_ON_DISCHARGE: {
          this.ipdTreatmentOnDischargeSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.IPD_FOLLOW_UP_ADVICE: {
          this.ipdFollowUpAdviceSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.DIAGNOSIS: {
          this.diagnosisSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.FINDINGS: {
          this.findingsSubject.next(this.messageFormat(interimText, finalText));
          break;
        }
        case DictationCaller.SYSTEMIC: {
          this.systemicSubject.next(this.messageFormat(interimText, finalText));
          break;
        }
        case DictationCaller.COMPLAINTS: {
          this.complaintsSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.PRO_DIAGNOSIS: {
          this.proDiagnosisSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.REMARKS: {
          this.remarksSubject.next(this.messageFormat(interimText, finalText));
          break;
        }
        case DictationCaller.INVESTIGATION_ADVICED: {
          this.investigationSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.IPD_EVALUATION_COMPLAIN: {
          this.ipdEvaluationComplainSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.IPD_EVALUATION_HISTORY: {
          this.ipdEvaluationHistorySubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.IPD_EVALUATION_KNOWN_CASE: {
          this.ipdEvaluationKnownCaseSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.IPD_ASSESSMENT_GENERAL_CONDITION: {
          this.ipdAssessmentGeneralConditionSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.IPD_ASSESSMENT_ON_EXAMINATION: {
          this.ipdAssessmentOnExaminationSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.IPD_ASSESSMENT_ABDOMEN: {
          this.ipdAssessmentAbdomenSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.IPD_ASSESSMENT_CHEST: {
          this.ipdAssessmentChestSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.IPD_ASSESSMENT_BL: {
          this.ipdAssessmentBLSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.IPD_ASSESSMENT_CVS: {
          this.ipdAssessmentCVSSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.IPD_ASSESSMENT_CNS: {
          this.ipdAssessmentCNSSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.IPD_ADVICE_REMARKS: {
          this.ipdAdviceRemarksSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.IPD_TREATMENT_REMARKS: {
          this.ipdTreatmentRemarksSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.IPD_TREATMENT_DIAGNOSIS: {
          this.ipdTreatmentDiagnosisSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.IPD_URINE_PHYSICAL_EXAMINATION: {
          this.ipdUrinePhysicalExaminationSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.IPD_URINE_CHEMICAL_EXAMINATION: {
          this.ipdUrineChemicalExaminationSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.IPD_URINE_MICROSCOPIC_EXAMINATION: {
          this.ipdUrineMicroscopicExaminationSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.URINE_REMARKS: {
          this.ipdUrineRemarksSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        // sugar
        case DictationCaller.IPD_POST_PRANDIAL_PLASMA_GLUCOSE: {
          this.ipdSugarPostPlasmaGlucoseSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.IDP_FASTING_PLASMA_GLUCOSE: {
          this.ipdSugarFastingPlasmaGlucoseSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.IPD_SUGAR_REMARKS: {
          this.remarksSubject.next(this.messageFormat(interimText, finalText));
          break;
        }
        // lipid
        case DictationCaller.TOTAL_LIPID_PROFILE: {
          this.ipdTotalLipidProfileSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.UREA_UREA_NITROGEN: {
          this.ipdUreaNitrogenSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.LIPID_REMARKS: {
          this.ipdLipidRemarksSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.CREATININE: {
          this.ipdCreatinineSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.URIC_ACID: {
          this.ipdUricAcidSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.IPD_CBC: {
          this.ipdCbcSubject.next(this.messageFormat(interimText, finalText));
          break;
        }
        case DictationCaller.IPD_CBC_REMARKS: {
          this.ipdCbcRemarksSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.IPD_CHEST_XRAY: {
          this.ipdChestXraySubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.IPD_CHEST_XRAY_REMARKS: {
          this.ipdChestXrayRemarksSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
        case DictationCaller.DENTAL_CHIEF_COMPLAINT: {
          this.dentalChiefComplaintSubject.next(
            this.messageFormat(interimText, finalText)
          );
          break;
        }
      }
      if (finalText && !this.continuousMode) {
        this.currentCaller = null;
      }
    } else {
      //Do nothing for now
    }
  }

  messageFormat(interimText?: string, finalText?: string) {
    return {
      interimText: interimText,
      finalText: finalText
    };
  }

  assistantListener() {
    return this.assistantSubject.asObservable();
  }

  diagnosisListener() {
    return this.diagnosisSubject.asObservable();
  }
  findingsListener() {
    return this.findingsSubject.asObservable();
  }
  systemicListener() {
    return this.systemicSubject.asObservable();
  }
  complaintsListener() {
    return this.complaintsSubject.asObservable();
  }
  proDiagnosisListener() {
    return this.proDiagnosisSubject.asObservable();
  }
  remarkListener() {
    return this.remarksSubject.asObservable();
  }
  investigationListner() {
    return this.investigationSubject.asObservable();
  }
  ipdComplaintOfListner() {
    return this.ipdComplaintOfSubject.asObservable();
  }
  ipdFindingsListner() {
    return this.ipdFindingsSubject.asObservable();
  }
  ipdMajorPositiveInvestigationFindingsListner() {
    return this.ipdMajorPositiveInvestigationFindingsSubject.asObservable();
  }
  ipdMedicationOrtreatmentGivenListner() {
    return this.ipdMedicationOrTreatmentGivenSubject.asObservable();
  }
  ipdConditionAtTheTimeOfDischargeListener() {
    return this.ipdConditionAtTheTimeOfDischargeSubject.asObservable();
  }
  ipdConditionAtTheTimeOfDischargeVitalsListener() {
    return this.ipdConditionAtTheTimeOfDischargeVitalsSubject.asObservable();
  }
  ipdTreatmentOnDischargeListener() {
    return this.ipdTreatmentOnDischargeSubject.asObservable();
  }
  ipdFollowUpAdviceListener() {
    return this.ipdFollowUpAdviceSubject.asObservable();
  }
  public ipdEvaluationComplainListener() {
    return this.ipdEvaluationComplainSubject.asObservable();
  }
  ipdEvaluationHistoryListener() {
    return this.ipdEvaluationHistorySubject.asObservable();
  }
  ipdEvaluationKnownCaseListener() {
    return this.ipdEvaluationKnownCaseSubject.asObservable();
  }
  ipdAssessmentGeneralConditionListener() {
    return this.ipdAssessmentGeneralConditionSubject.asObservable();
  }
  ipdAssessmentOnExaminationListener() {
    return this.ipdAssessmentOnExaminationSubject.asObservable();
  }
  ipdAssessmentAbdomenListener() {
    return this.ipdAssessmentAbdomenSubject.asObservable();
  }
  ipdAssessmentChestListener() {
    return this.ipdAssessmentChestSubject.asObservable();
  }
  ipdAssessmentBLListener() {
    return this.ipdAssessmentBLSubject.asObservable();
  }
  ipdAssessmentCVSListener() {
    return this.ipdAssessmentCVSSubject.asObservable();
  }
  ipdAssessmentCNSListener() {
    return this.ipdAssessmentCNSSubject.asObservable();
  }
  ipdAdviceRemarksListener() {
    return this.ipdAdviceRemarksSubject.asObservable();
  }
  ipdTreatmentRemarksListener() {
    return this.ipdTreatmentRemarksSubject.asObservable();
  }
  ipdTreatmentDiagnosisListener() {
    return this.ipdTreatmentDiagnosisSubject.asObservable();
  }
  ipdUrinePhysicalExamination() {
    return this.ipdUrinePhysicalExaminationSubject.asObservable();
  }
  ipdUrineChemicalExamination() {
    return this.ipdUrineChemicalExaminationSubject.asObservable();
  }
  ipdUrineMicroscopicExamination() {
    return this.ipdUrineMicroscopicExaminationSubject.asObservable();
  }
  ipdUrineRemarks() {
    return this.ipdUrineRemarksSubject.asObservable();
  }
  ipdFindingsVitals() {
    return this.ipdfindingsVitalsSubject.asObservable();
  }
  // sugar
  ipdSugarPostPlasma() {
    return this.ipdSugarPostPlasmaGlucoseSubject.asObservable();
  }
  ipdSugarFastingPlasma() {
    return this.ipdSugarFastingPlasmaGlucoseSubject.asObservable();
  }
  ipdSugarRemarks() {
    return this.remarksSubject.asObservable();
  }
  // lipid
  ipdTotalLipid() {
    return this.ipdTotalLipidProfileSubject.asObservable();
  }
  ipdUreaAndNitrogen() {
    return this.ipdUreaNitrogenSubject.asObservable();
  }
  ipdLipidRemarks() {
    return this.ipdLipidRemarksSubject.asObservable();
  }
  ipdCreatinine() {
    return this.ipdCreatinineSubject.asObservable();
  }
  ipdUricAcid() {
    return this.ipdUricAcidSubject.asObservable();
  }
  ipdCBC() {
    return this.ipdCbcSubject.asObservable();
  }
  ipdCBCRemarks() {
    return this.ipdCbcRemarksSubject.asObservable();
  }
  ipdChestXray() {
    return this.ipdChestXraySubject.asObservable();
  }
  ipdChestXrayRemarks() {
    return this.ipdChestXrayRemarksSubject.asObservable();
  }
  dentalChiefComplaint() {
    return this.dentalChiefComplaintSubject.asObservable();
  }
}
