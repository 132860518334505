import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import * as urls from "../../../../environments/environment";
import { DentalChartEntry } from "../../models/Patient/Dental/DentalChartEntry";

@Injectable({
  providedIn: "root"
})
export class DentalChartEntryService {
  constructor(private _http: HttpClient) {}

  /**
   * Create dental chart entry function
   */
  create(
    patientId: string,
    organisationId: string,
    createdById: string,
    createdByName: string,
    caseHistoryId?: string,
    dentalChartEntry?: DentalChartEntry
  ) {
    return new Promise((resolve, reject) => {
      const body = {};
      body["patientId"] = patientId;
      body["organisationId"] = organisationId;
      body["createdById"] = createdById;
      body["createdByName"] = createdByName;
      // body["dentalChartEntryJSON"] = dentalChartEntry.toJSON();
      if (caseHistoryId) {
        body["caseHistoryId"] = caseHistoryId;
      }
      const val = this._http
        .post(urls.environment["dental-chart-entry"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  /**
   * Update dental chart entry function
   */
  update(
    dentalChartEntry: DentalChartEntry,
    patientId: string,
    organisationId: string,
    createdById: string,
    createdByName: string,
    caseHistoryId?: string
  ) {
    return new Promise((resolve, reject) => {
      const body = {};
      body["patientId"] = patientId;
      body["dentalChartEntryId"] = dentalChartEntry.dentalChartEntryId;
      body["organisationId"] = organisationId;
      body["createdById"] = createdById;
      body["createdByName"] = createdByName;
      body["dentalChartEntryJSON"] = dentalChartEntry.toJSON();
      if (caseHistoryId) {
        body["caseHistoryId"] = caseHistoryId;
      }
      const val = this._http
        .put(urls.environment["dental-chart-entry"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  /**
   * Update dental chart entry function
   */
  delete(
    dentalChartEntryId: string,
    patientId: string,
    organisationId: string,
    createdById: string,
    createdByName: string,
    caseHistoryId?: string
  ) {
    return new Promise((resolve, reject) => {
      const body = {};
      body["patientId"] = patientId;
      body["dentalChartEntryId"] = dentalChartEntryId;
      body["organisationId"] = organisationId;
      body["createdById"] = createdById;
      body["createdByName"] = createdByName;
      if (caseHistoryId) {
        body["caseHistoryId"] = caseHistoryId;
      }
      let options = {};
      options["params"] = body;
      const val = this._http
        .delete(urls.environment["dental-chart-entry"], options)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }
}
