export class ObjectKeys {
  public static id = "id";
  public static docvitaId = "docvitaId";
  public static duration = "duration";
  public static salutation = "salutation";
  public static nameKey = "name";
  public static gender = "gender";
  public static dateOfBirth = "dateOfBirth";
  public static email = "email";
  public static countrySTDCode = "countrySTDCode";
  public static language = "language";
  public static locality = "locality";
  public static isDefault = "isDefault";
  public static associatedCorporation = "associatedCorporation";
  public static associatedCoporationMap = "associatedCoporationMap";
  public static customId = "customId";
  public static requestedAppointmentId = "requestedAppointmentId";
  public static isAssociatedCorporationRequired =
    "isAssociatedCorporationRequired";
  public static hasCustomId = "hasCustomId";
  public static associatedCorporationId = "associatedCorporationId";
  public static primaryTelephoneNumber = "primaryTelephoneNumber";
  public static isPrimaryLandlineRequired = "isPrimaryLandlineRequired";
  public static isSecondaryMobileRequired = "isSecondaryMobileRequired";
  public static isUhidRequired = "isUhidRequired";
  public static amount = "amount";
  public static hint = "hint";
  public static durationValidityNumber = "durationValidityNumber";
  public static secondaryContactNumber = "secondaryContactNumber";
  public static durationValidityUnit = "durationValidityUnit";
  public static quantityIncluded = "quantityIncluded";
  public static doctorWisePricing = "doctorWisePricing";
  public static startVisitTimestamp = "startVisitTimestamp";
  public static shortForm = "shortForm";
  public static generallyIncludedConsultation = "generallyIncludedConsultation";
  public static generallyIncludedProcedures = "generallyIncludedProcedures";
  public static isFindingsOn = "isFindingsOn";
  public static endVisitTimestamp = "endVisitTimestamp";
  public static opdPrescriptionSettings = "opdPrescriptionSettings";
  public static isOPDSoundNotificationON = "isOPDSoundNotificationON";
  public static isSystemicExaminationOn = "isSystemicExaminationOn";
  public static isPrescriptionV1On = "isPrescriptionV1On";
  public static organisationMembership = "organisationMembership";
  public static prescriptionSignature = "prescriptionSignature";
  public static isFinalDiagnosisOn = "isFinalDiagnosisOn";
  public static isProvisionalDiagnosisOn = "isProvisionalDiagnosisOn";
  public static isComplaintsOn = "isComplaintsOn";
  public static url = "url";
  public static reportId = "reportId";
  public static fileId = "fileId";
  public static files = "files";
  public static timestamp = "timestamp";
  public static isAuthorisedToWritePrescription =
    "isAuthorisedToWritePrescription";
  public static prescriptionMarginLeft = "prescriptionMarginLeft";
  public static prescriptionMarginRight = "prescriptionMarginRight";
  public static medicalProfessionalMeta = "medicalProfessionalMeta";
  public static prescriptionPrintSettings = "prescriptionPrintSettings";
  public static prescriptionPrintSettingsId = "prescriptionPrintSettingsId";
  public static prescriptionPrintSettingsTitle =
    "prescriptionPrintSettingsTitle";
  public static minScheduleTime = "minScheduleTime";
  public static maxScheduleTime = "maxScheduleTime";
  public static topMargin = "topMargin";
  public static bottomMargin = "bottomMargin";
  public static isWalkIn = "isWalkIn";
  public static signature = "signature";
  public static needsSpecificTypeOfDiagnosis = "needsSpecificTypeOfDiagnosis";
  public static organisationId = "organisationId";
  public static uniqueShortLink = "uniqueShortLink";
  public static organisationName = "organisationName";
  public static organisationLogoUrl = "organisationLogoUrl";
  public static designation = "designation";
  public static receiveDailyOPDSMS = "receiveDailyOPDSMS";
  public static isMobileNumberRequired = "isMobileNumberRequired";
  public static isRelationsRequired = "isRelationsRequired";
  public static isDobRequired = "isDobRequired";
  public static isPatientAgeObjectRequired = "isPatientAgeObjectRequired";
  public static isEmailRequired = "isEmailRequired";
  public static isReligionRequired = "isReligionRequired";
  public static isAddressRequired = "isAddressRequired";
  public static isGenderRequired = "isGenderRequired";
  public static isNameRequired = "isNameRequired";
  public static patientRegistrationFormSettings =
    "patientRegistrationFormSettings";
  public static modules_array = "modules_array";
  public static profile_status = "profile_status";
  public static opd_schedule_availability = "opd_schedule_availability";
  public static opdSchedule = "opdSchedule";
  public static slot_length = "slot_length";
  public static priveleges = "priveleges";
  public static departmentIds = "departmentIds";
  public static type = "type";
  public static billingAccountId = "billingAccountId";
  public static billingType = "billingType";
  public static primaryContactNumber = "primaryContactNumber";
  public static secondaryContactNumbers = "secondaryContactNumbers";
  public static primaryAddress = "primaryAddress";
  public static secondaryAddresses = "secondaryAddresses";
  public static superAdminInfo = "superAdminInfo";
  public static adminInfo = "adminInfo";
  public static primaryMedicalProfessional = "primaryMedicalProfessional";
  public static members = "members";
  public static locations = "locations";
  public static planInfo = "planInfo";
  public static planId = "planId";
  public static planName = "planName";
  public static planPrivileges = "planPrivileges";
  public static expiryDate = "expiryDate";
  public static startDate = "startDate";
  public static memberId = "memberId";
  public static currentlyLoggedInMemberId = "currentlyLoggedInMemberId";
  public static currentlyLoggedInMemberName = "currentlyLoggedInMemberName";
  public static details = "details";
  public static memberName = "memberName";
  public static registrationNumber = "registrationNumber";
  public static registrationDetails = "registrationDetails";
  public static qualification = "qualification";
  public static specialty = "specialty";
  public static timeSlotLength = "timeSlotLength";
  public static maxVisitsPerTimeSlot = "maxVisitsPerTimeSlot";
  public static opdCharges = "opdCharges";
  public static procedureCharges = "procedureCharges";
  public static locationTimingsMap = "locationTimingsMap";
  public static weeklyTimingMonday = "weeklyTimingMonday";
  public static weeklyTimingTuesday = "weeklyTimingTuesday";
  public static weeklyTimingWednesday = "weeklyTimingWednesday";
  public static weeklyTimingThursday = "weeklyTimingThursday";
  public static weeklyTimingFriday = "weeklyTimingFriday";
  public static weeklyTimingSaturday = "weeklyTimingSaturday";
  public static weeklyTimingSunday = "weeklyTimingSunday";
  public static availability = "availability";
  public static unavailability = "unavailability";
  public static startTime = "startTime";
  public static endTime = "endTime";
  public static serviceId = "serviceId";
  public static serviceName = "serviceName";
  public static serviceDescription = "serviceDescription";
  public static price = "price";
  public static active = "active";
  public static photoUrl = "photoUrl";
  public static age = "age";
  public static ageObject = "ageObject";
  public static qrCodeId = "qrCodeId";
  public static status = "status";
  public static primaryEmail = "primaryEmail";
  public static secondaryEmails = "secondaryEmails";
  public static hasDownloadedDocVitaApp = "hasDownloadedDocVitaApp";
  public static lastSeenOnDocVitaApp = "lastSeenOnDocVitaApp";
  public static SocialLoginInfo = "SocialLoginInfo";
  public static connectionId = "connectionId";
  public static uhid = "uhid";
  public static connectedById = "connectedById";
  public static connectedByName = "connectedByName";
  public static connectedOn = "connectedOn";
  public static lastInteractedOn = "lastInteractedOn";
  public static healthInfoAccessStatus = "healthInfoAccessStatus";
  public static healthInfoAccessUpdatedOn = "healthInfoAccessUpdatedOn";
  public static displayName = "displayName";
  public static socialEmailId = "socialEmailId";
  public static socialPhoneNumber = "socialPhoneNumber";
  public static socialLoginProviderId = "socialLoginProviderId";
  public static isEmailVerified = "isEmailVerified";
  public static bloodGroup = "bloodGroup";
  public static allergies = "allergies";
  public static pastMedicalHistory = "pastMedicalHistory";
  public static weight = "weight";
  public static height = "height";
  public static bmi = "bmi";
  public static organisationConnections = "organisationConnections";
  public static appointmentId = "appointmentId";
  public static date = "date";
  public static doctorId = "doctorId";
  public static doctorName = "doctorName";
  public static locationId = "locationId";
  public static locationName = "locationName";
  public static locationGoogleMapsLink = "locationGoogleMapsLink";
  public static timeslot = "timeslot";
  public static timeOfArrival = "timeOfArrival";
  public static purposeOfVisitId = "purposeOfVisitId";
  public static purposeOfVisitTitle = "purposeOfVisitTitle";
  public static queueNumber = "queueNumber";
  public static noteFromPatient = "noteFromPatient";
  public static reasonForDecliningRequest = "reasonForDecliningRequest";
  public static patientId = "patientId";
  public static patientName = "patientName";
  public static patientShortBio = "patientShortBio";
  public static patientAge = "patientAge";
  public static patientAgeObject = "patientAgeObject";
  public static patientGender = "patientGender";
  public static patientPrimaryContactNumber = "patientPrimaryContactNumber";
  public static patientPrimaryEmail = "patientPrimaryEmail";
  public static patientsArray = "patientsArray";
  public static organisationLocationId = "organisationLocationId";
  public static logoUrl = "logoUrl";
  public static title = "title";
  public static address = "address";
  public static numberVal = "number";
  public static contactNumberId = "contactNumberId";
  public static countryCode = "countryCode";
  public static isVerified = "isVerified";
  public static user_id = "user_id";
  public static prescription_id = "prescription_id";
  public static activeMedicationId = "activeMedicationId";
  public static dosageSummary = "dosageSummary";
  public static category = "category";
  public static quickNotes = "quickNotes";
  public static quickNoteId = "quickNoteId";
  public static allergyId = "allergyId";
  public static createdOn = "createdOn";
  public static createdBy = "createdBy";
  public static createdByName = "createdByName";
  public static text = "text";
  public static medicalHistoryId = "medicalHistoryId";
  public static diagnosisType = "diagnosisType";
  public static timing1 = "timing1";
  public static timing2 = "timing2";
  public static timing3 = "timing3";
  public static timing4 = "timing3";
  public static dosageVal = "dosageVal";
  public static dosageUnit = "dosageUnit";
  public static medicationId = "medicationId";
  public static orderingNumber = "orderingNumber";

  public static appointmentProfiles = "appointmentProfiles";
  public static appointmentProfileId = "appointmentProfileId";
  public static purposeOfVisitShortForm = "purposeOfVisitShortForm";

  public static dosageForm = "dosageForm";
  public static route = "route";
  public static composition = "composition";
  public static isGeneric = "isGeneric";
  public static strengthValue = "strengthValue";
  public static strengthUnit = "strengthUnit";
  public static masterDosageTrend = "masterDosageTrend";
  public static isAfterMeals = "isAfterMeals";
  public static isBeforeMeals = "isBeforeMeals";
  public static isEmptyStomach = "isEmptyStomach";
  public static isBedtime = "isBedtime";
  public static durationText = "durationText";
  public static durationValue = "durationValue";
  public static durationUnit = "durationUnit";
  public static displayTitle = "displayTitle";
  public static frequencyText = "frequencyText";
  public static frequencyValue = "frequencyValue";
  public static frequencyUnit = "frequencyUnit";
  public static intakeTimeGapFromFoodValue = "intakeTimeGapFromFoodValue";
  public static intakeTimeGapFromFoodUnit = "intakeTimeGapFromFoodUnit";
  public static instructions = "instructions";
  public static displayTiming = "displayTiming";
  public static displaySpecificTiming = "displaySpecificTiming";
  public static possibleTimingOptions = "possibleTimingOptions";
  public static databaseMedicationId = "databaseMedicationId";
  public static databaseOwner = "databaseOwner";
  public static prescribedById = "prescribedById";
  public static prescribedByName = "prescribedByName";
  public static prescribedByDesignation = "prescribedByDesignation";
  public static providerId = "providerId";
  public static prescriptionNumber = "prescriptionNumber";
  public static updatedOn = "updatedOn";
  public static diagnosis = "diagnosis";
  public static medicines = "medicines";
  public static spoken_draft_medications = "spoken_draft_medications";
  public static remarks = "remarks";
  public static dispenseStatus = "dispenseStatus";
  public static follow_up_date = "follow_up_date";
  public static addressId = "addressId";
  public static line1 = "line1";
  public static line2 = "line2";
  public static city = "city";
  public static state = "state";
  public static zipCode = "zipCode";
  public static country = "country";
  public static primaryContactPhone = "primaryContactPhone";
  public static secondaryContactPhones = "secondaryContactPhones";
  public static googleMapsLocationUrl = "googleMapsLocationUrl";
  public static appointments = "appointments";
  public static prescription = "prescription";
  public static vitals = "vitals";
  public static prescriptionTemplateId = "prescriptionTemplateId";
  public static whoHasAccess = "whoHasAccess";
  public static vitalMeasurementId = "vitalMeasurementId";
  public static vitalValue1 = "vitalValue1";
  public static vitalValue2 = "vitalValue2";
  public static vitalUnit = "vitalUnit";
  public static vitalInfo = "vitalInfo";
  public static measuredOn = "measuredOn";
  public static docvitaPatientId = "docvitaPatientId";
  public static vitalType = "vitalType";
  public static welcomeSMS = "welcomeSMS";
  public static appointment = "appointment";
  public static bookAppointment = "bookAppointment";
  public static cancelAppointment = "cancelAppointment";
  public static completeAppointment = "completeAppointment";
  public static appointmentReminder = "appointmentReminder";
  public static sendSMS = "sendSMS";
  public static appointmentDayMorning = "appointmentDayMorning";
  public static oneDayBefore = "oneDayBefore";
  public static threeHoursBefore = "threeHoursBefore";
  public static smsFormat = "smsFormat";
  public static smsFormat_HI = "smsFormat_HI";

  public static content = "content";
  public static companionName = "companionName";
  public static companionRelationship = "companionRelationship";
  public static patientSignatureURL = "patientSignatureURL";
  public static patientSignatureTimestamp = "patientSignatureTimestamp";
  public static companionSignatureURL = "companionSignatureURL";
  public static companionSignatureTimestamp = "companionSignatureTimestamp";
  public static languageOfExplaination = "languageOfExplaination";
  public static chiefComplaint = "chiefComplaint";
  public static calculus = "calculus";
  public static stains = "stains";
  public static investigations = "investigations";
  public static dentalChartEntryIds = "dentalChartEntryIds";
  public static periodontalChartId = "periodontalChartId";
  public static caseHistoryId = "caseHistoryId";
  public static dentalChartEntry = "dentalChartEntry";
  public static dentalChartEntryId = "dentalChartEntryId";
  public static dentalChartEntries = "dentalChartEntries";
  public static value = "value";
  public static label = "label";
  public static group = "group";
  public static groupLabel = "groupLabel";
  public static options = "options";
  public static teeth = "teeth";
  public static isMinor = "isMinor";
  public static isSelected = "isSelected";
  public static reports = "reports";
  public static updates = "updates";
  public static examinationIds = "examinationIds";
  public static examinations = "examinations";
  public static treatmentIds = "treatmentIds";
  public static treatments = "treatments";
  public static remark = "remark";
  public static subtype = "subtype";
  public static subtypeTitle = "subtypeTitle";

  public static habits = "habits";
  public static currentMedication = "currentMedication";
  public static dentalHistory = "dentalHistory";
  public static medicalHistory = "medicalHistory";

  public static billingId = "billingId";
  public static totalAmountPaid = "totalAmountPaid";
  public static receiptNumber = "receiptNumber";
  public static createdById = "createdById";
  public static receiverId = "receiverId";
  public static receiverName = "receiverName";
  public static patientDateOfBirth = "patientDateOfBirth";
  public static patientMobileNumber = "patientMobileNumber";
  public static patientCountryCode = "patientCountryCode";
  public static patientEmailAddress = "patientEmailAddress";
  public static itemsMap = "itemsMap";

  public static billingItemId = "billingItemId";
  public static description = "description";
  public static quantity = "quantity";
  public static serviceValidityTimestamp = "serviceValidityTimestamp";
  public static serviceQuantity = "serviceQuantity";

  public static totalAmount = "totalAmount";
  public static discountType = "discountType";
  public static discountNumber = "discountNumber";
  public static adjustmentTitle = "adjustmentTitle";
  public static adjustmentNumber = "adjustmentNumber";
  public static token = "token";

  public static mobileNumber = "mobileNumber";
  public static isConsultationChecked = "isConsultationChecked";
  public static isMedicineChecked = "isMedicineChecked";
  public static isInvestigationChecked = "isInvestigationChecked";
  public static isOtherChecked = "isOtherChecked";
  public static isTreatmentChecked = "isTreatmentChecked";

  public static actualAmount = "actualAmount";
  public static closingBalance = "closingBalance";
  public static isTransactionProcessed = "isTransactionProcessed";
  public static orderAmount = "orderAmount";
  public static orderExpiryTime = "orderExpiryTime";
  public static orderId = "orderId";
  public static orderStatus = "orderStatus";
  public static paymentMode = "paymentMode";
  public static referenceId = "referenceId";
  public static txMsg = "txMsg";
  public static txStatus = "txStatus";
  public static txTime = "txTime";
  public static metaData = "metaData";

  public static fileName = "fileName";
  public static fileType = "fileType";
}

export class DiscountType {
  public static Percentage = "PERCENTAGE";
  public static DirectAmount = "DIRECTAMOUNT";
}

export class AppointmentStatus {
  public static APPOINTMENT_CONFIRMED = "CONFIRMED";
  public static APPOINTMENT_ARRIVED = "ARRIVED";
  public static APPOINTMENT_MISSED = "MISSED";
  public static APPOINTMENT_CANCELLED = "CANCELLED";
  public static APPOINTMENT_COMPLETED = "COMPLETED";
  public static APPOINTMENT_REQUESTED = "REQUESTED";
  public static APPOINTMENT_REQUEST_DECLINED = "REQUEST_DECLINED";
}

export class FirestoreCollections {
  public static USER_PROFILE_COLLECTION = "user_profile";
  public static BILLING_ACCOUNTS = "billing_accounts";
  public static ORGANIZATIONS = "organizations";
  public static MEMBERS = "members";
  public static USER_DETAILS = "user_details";
  public static PRESCRIPTION_TEMPLATE = "prescription_templates";
  public static ORGANISATION_DATA = "organisation_data";
  public static PRESCRIPTION = "prescription";
  public static MEDICATION_AUTO_SEARCH = "medication_auto_search";
  public static PATIENT_COMMUNICATION = "patient_communication";
  public static ORGANISATION_PATIENT_COMMUNICATIONS =
    "organisation_patient_communications";
  public static ORGANISATION_ACCOUNT_BALANCES = "organisation_account_balances";
  public static APPOINTMENTS = "appointments";
  public static REQUESTED_APPOINTMENTS = "requested_appointments";
  public static ORGANISATION_APPOINTMENTS = "organisation_appointments";
  public static OPD_SUBCOLLECTION = "opd";
  public static PATIENT_CONSENT_FORMS = "patient_consent_forms";
  public static PATIENT_INSTRUCTIONS = "patient_instructions";
  public static DENTAL_CASE_HISTORY = "dental_case_history";
  public static DENTAL_TREATMENTS = "dental_treatments";
  public static DENTAL_CHART_DETAILS = "dental_chart_details";
  public static ORGANISATION_BILLING = "organisation_billing";
  public static BILLING_SUBCOLLECTION = "billing";
}

export class SelectizeConfigs {
  public static singleSelectConfig: any = {
    labelField: "label",
    valueField: "value",
    searchField: ["label"],
    maxItem: 1
  };
  public static singleSelectWithCreateConfig: any = {
    labelField: "label",
    valueField: "value",
    searchField: ["label"],
    maxItems: 1,
    create: function(input) {
      return {
        value: input,
        label: input
      };
    }
  };
}

export class HealthInformationConstants {
  public static BLOOD_GROUPS = [
    {
      label: "A+",
      value: "0"
    },
    {
      label: "A-",
      value: "1"
    },
    {
      label: "B+",
      value: "2"
    },
    {
      label: "B-",
      value: "3"
    },
    {
      label: "O+",
      value: "4"
    },
    {
      label: "O-",
      value: "5"
    },
    {
      label: "AB+",
      value: "6"
    },
    {
      label: "AB-",
      value: "7"
    }
  ];
}
