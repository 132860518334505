import { Component, OnInit } from "@angular/core";
import {
  Router,
  ActivatedRoute,
  NavigationStart,
  NavigationEnd
} from "@angular/router";
import { PatientProfileSyncService } from "../../shared/services/component-communication/patient-profile-sync.service";
import { GlobalVariables } from "src/app/globar-var/globarVariables";

@Component({
  selector: "[app-patient-chart-navbar]",
  templateUrl: "./patient-chart-navbar.component.html",
  styleUrls: ["./patient-chart-navbar.component.css"]
})
export class PatientChartNavbarComponent implements OnInit {
  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _patientProfileSyncService: PatientProfileSyncService
  ) {}

  currentPath = "patients";
  patientsPath: string = "patients";

  dentistryTabPath = "dentistry";
  homeTabPath = "home";
  // dentistryCaseHistoryTabPath = "case-history-list";
  // dentistryDentalChartTabPath = "dental-chart";
  // dentistryPeriodontalChartTabPath = "periodontal-chart";
  // dentistryTreatmentsTabPath = "treatments";

  patientDocVitaId: string;
  canAccessDentistry: boolean = false;
  ngOnInit() {
    // var self = this;
    // this._router.events.forEach(event => {
    //   if (event instanceof NavigationEnd) {
    //     let currentUrl = self._router.url;
    //     self.patientDocVitaId = self._patientProfileSyncService.extractPatientIdFromPath(
    //       currentUrl
    //     );
    //     if (currentUrl.indexOf(self.patientsPath) > -1) {
    //       self.currentPath = self.patientsPath;
    //     } else {
    //     }
    //     console.log(
    //       "Patient ID in NavBar: ",
    //       currentUrl,
    //       self.patientDocVitaId
    //     );
    //   } else {
    //     console.log("Event is not of this instance");
    //   }
    // });
    this.permissions();
  }

  permissions() {
    this.canAccessDentistry = GlobalVariables.getMember().canAccessDentistry(
      GlobalVariables.getOrganisationId()
    );
  }
  isTabSelected(val: string) {
    let path = this._router.url;
    if (path.indexOf(val) > -1) {
      return true;
    } else {
      return false;
    }
  }

  onTabClicked(routingPath: string) {
    var self = this;
    // console.log("CurrentPath: ", this.currentPath);
    // console.log("DocVita ID: ", this.patientDocVitaId);
    // console.log("Dentistry Tab: ", this.dentistryTabPath);
    let currentUrl = self._router.url;
    self.patientDocVitaId = self._patientProfileSyncService.extractPatientIdFromPath(
      currentUrl
    );
    switch (routingPath) {
      case self.dentistryTabPath:
        self._router.navigate([
          "/" + self.currentPath,
          "patient-detail",
          self.patientDocVitaId,
          self.dentistryTabPath
        ]);
        break;
      case self.homeTabPath:
        self._router.navigate([
          "/" + self.currentPath,
          "patient-detail",
          self.patientDocVitaId,
          self.homeTabPath
        ]);
        break;
      // case self.dentistryCaseHistoryTabPath:
      //   self._router.navigate([
      //     "/" + self.currentPath,
      //     "patient-detail",
      //     self.patientDocVitaId,
      //     self.dentistryTabPath,
      //     self.dentistryCaseHistoryTabPath
      //   ]);
      //   break;
      // case self.dentistryDentalChartTabPath:
      //   self._router.navigate([
      //     "/" + self.currentPath,
      //     "patient-detail",
      //     self.patientDocVitaId,
      //     self.dentistryTabPath,
      //     self.dentistryDentalChartTabPath
      //   ]);
      //   break;
      // case self.dentistryPeriodontalChartTabPath:
      //   self._router.navigate([
      //     "/" + self.currentPath,
      //     "patient-detail",
      //     self.patientDocVitaId,
      //     self.dentistryTabPath,
      //     self.dentistryPeriodontalChartTabPath
      //   ]);
      //   break;
      // case self.dentistryTreatmentsTabPath:
      //   self._router.navigate([
      //     "/" + self.currentPath,
      //     "patient-detail",
      //     self.patientDocVitaId,
      //     self.dentistryTabPath,
      //     self.dentistryTreatmentsTabPath
      //   ]);
      //   break;
      default:
        break;
    }
    // let path = this._router.url;
    // let detailPath = "patient-detail";
    // if (path && path.indexOf(detailPath) > -1) {
    //   let i = path.indexOf(detailPath) + detailPath.length + 1; // index of patient id
    //   let str = path.substring(i);
    //   let j = str.indexOf("/");
    //   this._router.navigateByUrl(path.substr(0, i + j) + "/" + routingPath);
    // }
  }
}
