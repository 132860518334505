import {
  AfterContentInit,
  Directive,
  ElementRef,
  Input,
  AfterViewInit
} from "@angular/core";

@Directive({
  selector: "[appAutofocus]"
})
export class AutofocusDirective implements AfterViewInit {
  @Input() public appAutofocus: boolean;
  public constructor(private el: ElementRef) {}
  // public ngAfterContentInit() {
  //   setTimeout(() => {
  //     this.el.nativeElement.focus();
  //   }, 300);
  // }

  ngAfterViewInit() {
    this.el.nativeElement.focus();
    console.log("focus called", this.el);
  }
}
