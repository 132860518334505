import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";
import { DentalChartEntry } from "../../models/Patient/Dental/DentalChartEntry";

@Injectable({
  providedIn: "root"
})
export class NewDentalChartService {
  // dentalEntry: Map<string, DentalChartEntry> = new Map();
  subject = new Subject<Map<string, any>>();
  constructor() {}
  getMessage(): Observable<Map<string, any>> {
    return this.subject.asObservable();
  }
  sendMessage(data: DentalChartEntry, isGroupTeeth: boolean) {
    console.log("this is data", data);
    let map = new Map<string, any>();
    map.set("isGroupTeeth", isGroupTeeth);
    map.set("data", data);
    this.subject.next(map);
  }
  clearMessages() {
    this.subject.next();
  }
}
