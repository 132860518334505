import { Component, OnInit, Output, EventEmitter, OnDestroy } from "@angular/core";
import {
  SelectizeConfigs,
  FirestoreCollections,
  ObjectKeys
} from "src/app/shared/constants/object-keys";
import { GlobalVariables } from "src/app/globar-var/globarVariables";
import { PrescriptionTemplate } from "src/app/shared/models/Prescription/PrescriptionTemplate";
import { NotifyService } from "src/app/shared/services/notify/notify.service";
import { VoiceService } from "src/app/shared/services/voice/voice.service";
import { PrescriptionService } from "src/app/shared/services/prescription/prescription.service";
import * as firebase from "firebase";
import { Subscription } from "rxjs";
@Component({
  selector: "app-import-protocol",
  templateUrl: "./import-protocol.component.html",
  styleUrls: ["./import-protocol.component.css"]
})
export class ImportProtocolComponent implements OnInit, OnDestroy {
  singleSelectConfig = SelectizeConfigs.singleSelectConfig;
  selectedProtocolValue: string;

  availableProtocols = [];
  subscriptionProtocol: Subscription;
  @Output() selectedPrescriptionTemplate = new EventEmitter<
    PrescriptionTemplate
  >();

  constructor(
    private _notifyService: NotifyService,
    private _voiceService: VoiceService,
    private _prescriptionService: PrescriptionService
  ) {}

  ngOnInit() {
    this.fetchFromGlobalVariables();
    this.fetchPrescriptionTemplates();
    this.voiceObserver();
  }

  fetchFromGlobalVariables(){
    GlobalVariables.prescriptionTampletes.forEach((val, key) => {
      let body = {};
      body["label"] = val.title;
      body["value"] = key;
      this.availableProtocols.push(body);
    });
  }

  voiceObserver() {
    console.log("IMPORT PROTOCOL OBSEVER CALLED")
    if(this.subscriptionProtocol){
      console.log("IMPORT PROTOCOL DESTROYER CALLED ")
      this.subscriptionProtocol.unsubscribe();
    }
    this.subscriptionProtocol = this._voiceService.getPrescriptionTemplate().subscribe(json => {
      console.log("IMPORT PROTOCOL CALLED START")
      if (json) {
        console.log("IMPORT PROTOCOL CALLED",json)
        let template_search_name = json["prescription_template"];
        if (template_search_name) {
          this._prescriptionService
            .prescriptionTitleSearch(template_search_name, GlobalVariables.getOrganisationId())
            .then(response => {
              console.log("RX TEMPLATE", response);
              if (response.hits.hits && response.hits.hits.length > 0) {
                let json = response.hits.hits[0]._source;
                let prescriptionTemplate: PrescriptionTemplate = new PrescriptionTemplate();
                prescriptionTemplate.initFromJSON(json);
                this.selectedPrescriptionTemplate.emit(prescriptionTemplate);
                this._notifyService.showSuccessMessage(
                  "Imported protocol<br><b>" +
                    prescriptionTemplate.title +
                    "</b>"
                );
              } else {
                this._notifyService.showWarningMessage(
                  "Couldn't find protocol with the title: " +
                    template_search_name
                );
              }
            })
            .catch(err => {
              console.log(err);
              this._notifyService.showWarningMessage(
                "Couldn't find protocol with the title: " + template_search_name
              );
            });
        } else {
          this._notifyService.showWarningMessage(
            "Please specify prescription template name and try again."
          );
        }
      }
    });
  }

  readyToSave() {
    return (
      this.selectedProtocolValue &&
      this.selectedProtocolValue.length > 0 &&
      GlobalVariables.prescriptionTampletes.has(this.selectedProtocolValue)
    );
  }
  onImportClicked() {
    if (GlobalVariables.prescriptionTampletes.has(this.selectedProtocolValue)) {
      let p = GlobalVariables.prescriptionTampletes.get(
        this.selectedProtocolValue
      );
      this.selectedPrescriptionTemplate.emit(p);
      this._notifyService.showSuccessMessage(
        "Imported protocol<br><b>" + p.title + "</b>"
      );
      this.selectedProtocolValue = "";
    }
  }
  newProtocolSelected() {
    console.log("New Protocol Selected: ", this.selectedProtocolValue);
  }

  fetchPrescriptionTemplates() {
    firebase
      .firestore()
      .collection(FirestoreCollections.ORGANISATION_DATA)
      .doc(GlobalVariables.getOrganisationId())
      .collection(FirestoreCollections.PRESCRIPTION_TEMPLATE)
      .get()
      .then(snapshot => {
        if (!snapshot.empty) {
          snapshot.forEach(val => {
            let p: PrescriptionTemplate = new PrescriptionTemplate();
            p.initFromJSON(val.data());
            GlobalVariables.prescriptionTampletes.set(
              p.prescriptionTemplateId,
              p
            );
            let body = {};
            body["label"] = p.title;
            body["value"] = p.prescriptionTemplateId;
            this.availableProtocols.push(body);
          });
          // this.fetchFromGlobalVariables();
          // console.log("Prescription Templates:",GlobalVariables.prescriptionTampletes)
        }
      });
  }

  ngOnDestroy(){
    console.log("Destroying...")
    if(this.subscriptionProtocol){
      console.log("IMPORT PROTOCOL DESTROYER CALLED ")
      this.subscriptionProtocol.unsubscribe();
    }
  }
}
