import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import * as urls from "../../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class SmsService {
  constructor(private _http: HttpClient) {}

  public sendPaymentSms(transactionDetails, metaData) {
    return new Promise((resolve, reject) => {
      let body = {
        transactionDetails,
        metaData
      };
      let val = this._http
        .post(urls.environment["create-order-dynamically"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }
}
