import { Component, OnInit, OnDestroy } from "@angular/core";
import { AssistantStateManagementService } from "../../services/component-communication/asssitant-state-management.service";
import {
  Medication,
  MedicationStatus
} from "../../models/Medication/Medication";
import { SideSectionDialogManagementService } from "../../services/component-communication/side-section-dialog-management.service";
import { GlobalVariables } from "src/app/globar-var/globarVariables";
import { FirestoreCollections } from "../../constants/object-keys";
import * as firebase from "firebase";
import { NotifyService } from "../../services/notify/notify.service";
@Component({
  selector: "app-meds-top-fifty",
  templateUrl: "./meds-top-fifty.component.html",
  styleUrls: ["./meds-top-fifty.component.css"]
})
export class MedsTopFiftyComponent implements OnInit, OnDestroy {
  medicationArray: Medication[] = [];
  tempArray: Medication[] = [];
  searchText: string = "";
  subscription;
  constructor(
    private _assistantStateManagementService: AssistantStateManagementService,
    private _sideSectionDialogManagementService: SideSectionDialogManagementService,
    private _notifyService: NotifyService
  ) {}

  ngOnInit() {
    this.fetchMeds();
  }

  fetchMeds() {
    firebase
      .firestore()
      .collection(FirestoreCollections.ORGANISATION_DATA)
      .doc(GlobalVariables.getOrganisationId())
      .collection(FirestoreCollections.MEDICATION_AUTO_SEARCH)
      .limit(50)
      .get()
      .then(
        snap => {
          this.medicationArray.splice(0, this.medicationArray.length);
          if (!snap.empty) {
            snap.forEach(doc => {
              let masterObjectJSON = doc.data();
              let masterMedicationObject = new Medication();
              masterMedicationObject.initFromJSON(masterObjectJSON);

              if (masterObjectJSON && masterObjectJSON["commonDosages"]) {
                for (let c in masterObjectJSON["commonDosages"]) {
                  let m = new Medication();
                  m.initFromJSON(masterObjectJSON["commonDosages"][c]);
                  m.medicationId = c;
                  if (masterMedicationObject.name) {
                    m.name = masterMedicationObject.name;
                  } else {
                  }
                  if (masterMedicationObject.composition) {
                    m.composition = masterMedicationObject.composition;
                  } else {
                  }
                  if (masterMedicationObject.route) {
                    m.route = masterMedicationObject.route;
                  } else {
                  }
                  if (masterMedicationObject.dosageForm) {
                    m.dosageForm = masterMedicationObject.dosageForm;
                  } else {
                  }
                  this.medicationArray.push(m);
                  this.tempArray = this.medicationArray.slice();
                }
              } else if (
                masterObjectJSON["commonDosages"] == null ||
                Object.keys(masterObjectJSON["commonDosages"]).length == 0
              ) {
                this.medicationArray.push(masterMedicationObject);
              }
            });
          } else {
            this._notifyService.showErrorMessage(
              "Something went wrong. Please refresh the page or go back to the medication list"
            );
          }
        },
        err => {
          console.error(err);
        }
      );
  }

  saveMedication(medication: Medication) {
    this._sideSectionDialogManagementService.sendBackToPrescription(
      medication,
      MedicationStatus.form_typed
    );
    this._sideSectionDialogManagementService.hide();
  }

  search() {
    let searchTerm = this.searchText.toLowerCase();
    this.tempArray.length = 0;
    for (let i = 0; i < this.medicationArray.length; i++) {
      let medName = this.medicationArray[i].nameForDisplay;
      if (medName.toLowerCase().indexOf(searchTerm) != -1) {
        this.tempArray.push(this.medicationArray[i]);
      }
    }
    if ((this.searchText = "")) {
      this.tempArray = this.medicationArray.slice();
    }
  }

  onCloseClicked() {
    this._assistantStateManagementService.closeTop50MedsList();
  }

  ngOnDestroy() {}
}
