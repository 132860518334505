import {
  OnInit,
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from "@angular/core";
import { SelectizeConfigs } from "../shared/constants/object-keys";
import { PersonConstants } from "../shared/models/Person";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import {
  Router,
  ActivatedRoute,
  NavigationStart,
  NavigationEnd
} from "@angular/router";
import {
  NgbDateParserFormatter,
  NgbDatepicker,
  NgbModal
} from "@ng-bootstrap/ng-bootstrap";
import { NgbDateFRParserFormatter } from "../shared/services/datePicker/NgbDateFRParserFormatter";
import {
  Patient,
  AssociatedCorporation
} from "../shared/models/Patient/Patient";
import { ContactNumber, DateActions } from "../shared/models/Demographics";
import { PatientProfileService } from "../shared/services/patient/patient-profile.service";
import { GlobalVariables } from "../globar-var/globarVariables";
import { NotifyService } from "../shared/services/notify/notify.service";
import { PatientRegistrationModalService } from "../shared/services/modalStateManagement/patient-registration-modal.service";
import { PatientProfileDialogService } from "../shared/services/modalStateManagement/patient-profile-dialog.service";
import { ScheduleAppointmentModalService } from "../shared/services/modalStateManagement/schedule-appointment-modal.service";
import { AppointmentOperationsService } from "../shared/services/opd/appointment-operations.service";
import * as moment from "moment";
import {
  OrganisationMember,
  AppointmentProfile,
  Corporation
} from "../shared/models/MD - Member";

@Component({
  selector: "app-patient-registration",
  templateUrl: "./patient-registration.component.html",
  styleUrls: ["./patient-registration.component.css"],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter }
  ]
})
export class PatientRegistrationComponent implements OnInit {
  newPrescriptionRoute = "newPrescription";
  singleSelectConfig = SelectizeConfigs.singleSelectConfig;
  salutationOptions = PersonConstants.SALUTATIONS;
  countryCodeOptions = PersonConstants.COUNTRY_CODES;
  genderOptions = PersonConstants.GENDER;
  languageOptions = PersonConstants.LANGUAGE;
  inEditingMode: boolean = false;
  uhid: string;
  selectedAssociationName: string;
  locationId: string = "";
  selectedDoctorId = "";
  locationOptions = [];
  availableDoctorOptions = [];

  selectedPatient: Patient;
  unknownRoute = "unknown";
  SideSectionDialogManagementService;

  currentRoute: string = "unknown";
  minDateForFollowUp = {
    day: 1,
    month: 1,
    year: 1900
  };

  dataVal;

  //Patient Registration form settings
  isAddressRequired: boolean;
  isDobRequired: boolean;
  isEmailRequired: boolean;
  isGenderRequired: boolean;
  isMobileNumberRequired: boolean;
  isSecondaryMobileRequired: boolean;
  isPrimaryLandlineRequired: boolean;
  isNameRequired: boolean;
  isPatientAgeObjectRequired: boolean;
  isRelationsRequired: boolean;
  isReligionRequired: boolean;
  isUhidRequired: boolean;
  isAssociatedCorporationRequired: boolean;

  customPattern = {
    D: {
      pattern: new RegExp("\\d"),
      symbol: "D"
    },
    M: {
      pattern: new RegExp("\\d"),
      symbol: "M"
    },
    Y: {
      pattern: new RegExp("\\d"),
      symbol: "Y"
    }
  };

  public mask = {
    guide: true,
    showMask: true,
    mask: [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]
  };

  appointmentProfilesMap: Map<string, AppointmentProfile> = new Map();
  associatedCorportationMap: Map<string, Corporation> = new Map();
  availableVisitTypes = [];
  categoryOptions = [];
  selectedPurposeOfVisitId: string;
  associatedCorporationId: string;
  selectedCorporationMap: Map<string, AssociatedCorporation> = new Map();
  selectedSlotLength = 30;
  disabled: boolean = false;
  isAssociatedCorporationHasCustomID: boolean;
  selectedAssociation: string = "";
  customUserId: string = "";
  // Dynamically building the form
  salutationFC = new FormControl("mr");
  patient: Patient = new Patient();
  @Input() initialText: string;
  @Output() onPatientSaveorUpdate = new EventEmitter<Patient>();

  // for editing an existing patient
  @Input() userForEditing: Patient;

  isSavingPatient = false; // to control saving of the whole card

  searchResultsConnectedPatients: Array<Patient> = [];
  searchResultsNotConnectedPatients: Array<Patient> = [];

  constructor(
    private _patientProfileService: PatientProfileService,
    private _patientRegistrationModalService: PatientRegistrationModalService,
    private _notifyService: NotifyService,
    private _patientProfileDialogService: PatientProfileDialogService,
    private _scheduleAppointmentModalService: ScheduleAppointmentModalService,
    private _appointmentOperationsService: AppointmentOperationsService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    var self = this;
    this.appointmentProfilesMap.clear();
    this.associatedCorportationMap.clear();
    this.selectedCorporationMap.clear();
    this.selectedDoctorId = "";
    this.appointmentProfilesMap = GlobalVariables.getOrganisation().appointmentProfilesMap;
    this.availableVisitTypes = [];
    this.appointmentProfilesMap.forEach(val => {
      this.availableVisitTypes.push({
        value: val.appointmentProfileId,
        label: val.name
      });
    });

    this.associatedCorportationMap = GlobalVariables.getOrganisation().associatedCorporation;
    this.categoryOptions = [];
    this.associatedCorportationMap.forEach(val => {
      this.categoryOptions.push({
        value: val.id,
        label: val.name
      });
    });

    this.categoryOptions.sort((a, b) => {
      if (a && b && a["label"] && b["label"]) {
        return (a["label"] + "").localeCompare(b["label"] + "");
      } else {
        return 0;
      }
    });

    if (this.categoryOptions.length > 0) {
      this.associatedCorporationId = this.categoryOptions[0]["value"];
    }

    this.selectedSlotLength = GlobalVariables.getOrganisation().slotLength;
    this.availableVisitTypes.sort((a, b) => {
      if (a && b && a["label"] && b["label"]) {
        return (a["label"] + "").localeCompare(b["label"] + "");
      } else {
        return 0;
      }
    });
    if (this.appointmentProfilesMap.get(this.availableVisitTypes[0].value)) {
      let data = this.appointmentProfilesMap.get(
        this.availableVisitTypes[0].value
      );
      if (data.convertDurationInMinutes()) {
        this.selectedSlotLength = data.convertDurationInMinutes();
      }
    }
    if (this.availableVisitTypes.length > 0) {
      this.selectedPurposeOfVisitId = this.availableVisitTypes[0]["value"];
    }
    this.availableVisitTypes.sort((a, b) => {
      if (a && b && a["label"] && b["label"]) {
        return (a["label"] + "").localeCompare(b["label"] + "");
      } else {
        return 0;
      }
    });

    //Setting the booleans for the fields in registration form
    this.getDoctorsList();
    this.loadAvailableLocations();
    this._patientRegistrationModalService.modalOpenedAgain().subscribe(val => {
      //This will be called everytime this modal is opened
      this.selectedPatient = null;
      if (localStorage.getItem("selectedDoctorId")) {
        this.selectedDoctorId = localStorage.getItem("selectedDoctorId");
      }
      this.patient = new Patient();
      this.dataVal = "";
      this.searchResultsConnectedPatients.splice(
        0,
        this.searchResultsConnectedPatients.length
      );
      if (this.userForEditing && this.userForEditing.docvitaId != null) {
        // in Editing Mode
        this.initializePatient();
        this.inEditingMode = true;
        this.patient = this.userForEditing;
        this.uhid = this.patient.getUHID(GlobalVariables.getOrganisationId());
        this.selectedCorporationMap = this.patient.organisationConnections.get(
          GlobalVariables.getOrganisationId()
        ).associatedCoporationMap;
        if (this.selectedCorporationMap.size > 0) {
          this.associatedCorporationId = this.selectedCorporationMap
            .keys()
            .next().value;
        }

        // if (patientConnectionsData.associatedCorporationId != null) {
        //   this.associatedCorporationId =
        //     patientConnectionsData.associatedCorporationId;
        // }
        // if (patientConnectionsData.customId) {
        //   this.customUserId = patientConnectionsData.customId;
        // }
        this.disabled = true;
      } else {
        this.initializePatient();
        this.inEditingMode = false;
        // in New Registration Mode
        // if initial text contains a number, populate user id
        const regexp = new RegExp("[a-z]*[0-9][a-z]*");
        const initialTextContainsNumericValue = regexp.test(this.initialText);
        if (!this.initialText) {
        } else if (initialTextContainsNumericValue) {
          // populate user id
          this.patient.primaryContactNumber.number = this.initialText;
        } else {
          // populate user name
          this.patient.name = this.initialText;
        }
      }

      if (val && val["patientProfile"]) {
      }
    });
    //Initialize registration form settings
    this.initializeRegistrationFormSettings();

    // this.patientRegistrationForm.valueChanges.subscribe(value => {
    //   // console.log(value);
    // });
  }

  selectPurposeOfVisit(purposeOfVisitId: string) {
    if (purposeOfVisitId && this.appointmentProfilesMap.has(purposeOfVisitId)) {
      this.selectedPurposeOfVisitId = purposeOfVisitId;
    }
  }

  updateDuration(id: string) {
    let data = this.appointmentProfilesMap.get(id);
    if (data.convertDurationInMinutes()) {
      this.selectedSlotLength = data.convertDurationInMinutes();
    }
  }

  updateCategory(id: string) {
    let data = this.associatedCorportationMap.get(id);
    if (data.hasCustomId && data["hasCustomId"] == true) {
      this.isAssociatedCorporationHasCustomID = true;
    } else {
      this.isAssociatedCorporationHasCustomID = false;
    }
    this.selectedAssociationName = data.name;
    this.associatedCorporationId = id;
    if (
      this.selectedCorporationMap &&
      this.selectedCorporationMap.has(this.associatedCorporationId) &&
      this.selectedCorporationMap.get(this.associatedCorporationId).customId !=
        ""
    ) {
      this.customUserId = this.selectedCorporationMap.get(
        this.associatedCorporationId
      ).customId;
    } else {
      this.customUserId = "";
    }
  }

  setCustomId(event) {
    let data = this.associatedCorportationMap.get(this.associatedCorporationId);
    if (data.hasCustomId && event.length > 0) {
      let obj = new AssociatedCorporation();
      obj["name"] = data.name;
      obj["customId"] = event;
      this.selectedCorporationMap.set(this.associatedCorporationId, obj);
    } else if (data.hasCustomId && event.length == 0) {
      this.selectedCorporationMap.delete(this.associatedCorporationId);
    }
  }

  /**
   * Load locations from organisation obejct
   */
  loadAvailableLocations() {
    GlobalVariables.getOrganisation().locations.forEach((val, key) => {
      this.locationId = key;
      let json = {};
      json["label"] = val.title;
      json["value"] = key;
      this.locationOptions.push(json);
    });

    if (this.pathHasLocationId()) {
      this.locationId = this.pathHasLocationId();
    } else {
      if (this.locationOptions.length > 0) {
        this.locationId = this.locationOptions[0]["value"];
      }
    }
  }
  pathHasDoctorId() {
    let id = null;
    let url = this._router.url;
    if (
      url &&
      (url.indexOf("opd/today") > -1 || url.indexOf("opd/calendar") > -1)
    ) {
      let localStrArray = url.split("/");
      // console.log(localStrArray);
      if (localStrArray && localStrArray.length >= 4) {
        id = localStrArray[4];
      }
    }
    return id;
  }

  pathHasLocationId() {
    let id = null;
    let url = this._router.url;
    if (
      url &&
      (url.indexOf("opd/today") > -1 || url.indexOf("opd/calendar") > -1)
    ) {
      let localStrArray = url.split("/");
      // console.log(localStrArray);
      if (localStrArray && localStrArray.length >= 4) {
        id = localStrArray[3];
      }
    }
    return id;
  }

  doctors: Map<string, OrganisationMember> = new Map();

  getDoctorsList() {
    this.doctors.clear();
    this.availableDoctorOptions.splice(0, this.doctors.size);
    GlobalVariables.getOpdScheduleAvailableMembersMap(
      GlobalVariables.getOrganisationId()
    ).forEach((val, key) => {
      let temp = {};
      temp["label"] = val.name;
      temp["value"] = key;
      this.availableDoctorOptions.push(temp);
      this.doctors.set(key, val);
    });
    this.availableDoctorOptions.sort((a, b) => {
      return (a.value + "").localeCompare(b.value + "");
    });
    let docId = GlobalVariables.getMemberId();
    if (localStorage.getItem("selectedDoctorId")) {
      docId = localStorage.getItem("selectedDoctorId");
    } else if (
      !GlobalVariables.getMember().getOpdScheduleAvailability(
        GlobalVariables.getOrganisationId()
      ) &&
      this.availableDoctorOptions.length > 0
    ) {
      docId = this.availableDoctorOptions[0]["value"];
    }
    this.selectDoctor(docId);
  }

  selectDoctor(memberId: string) {
    if (this.availableDoctorOptions.length > 0) {
      for (let a of this.availableDoctorOptions) {
        if (a["value"] == memberId) {
          this.selectedDoctorId = memberId;
        }
      }
    }
  }

  initializePatient() {
    this.patient = new Patient();
    this.patient.gender = "male";
    this.patient.primaryContactNumber.countryCode = "91";
    this.patient.primaryTelephoneNumber.countryCode = "91";
    this.patient.secondaryContactNumber.countryCode = "91";
    this.patient.salutation = "mr";
    this.patient.language = "english";
  }

  initializeRegistrationFormSettings() {
    let regFormSettings = GlobalVariables.getOrganisation()[
      "patientRegistrationFormSettings"
    ];
    this.isAddressRequired = regFormSettings["isAddressRequired"];
    this.isDobRequired = regFormSettings["isDobRequired"];
    this.isEmailRequired = regFormSettings["isEmailRequired"];
    this.isGenderRequired = regFormSettings["isGenderRequired"];
    this.isMobileNumberRequired = regFormSettings["isMobileNumberRequired"];
    this.isAssociatedCorporationRequired =
      regFormSettings["isAssociatedCorporationRequired"];
    this.isSecondaryMobileRequired =
      regFormSettings["isSecondaryMobileRequired"];
    this.isPrimaryLandlineRequired =
      regFormSettings["isPrimaryLandlineRequired"];
    this.isUhidRequired = regFormSettings["isUhidRequired"];
    this.isNameRequired = regFormSettings["isNameRequired"];
    this.isPatientAgeObjectRequired =
      regFormSettings["isPatientAgeObjectRequired"];
    this.isRelationsRequired = regFormSettings["isRelationsRequired"];
    this.isReligionRequired = regFormSettings["isReligionRequired"];
  }

  isValidForSaving(): boolean {
    // Edit this function for checking the patient object validity
    const doesPatientHaveName =
      this.patient.name != null && this.patient.name.length > 0;

    const doesPatientHaveGender =
      this.patient.gender != null && this.patient.gender.length > 0;

    // TODO: Implement this for multiple country codes later on
    let doesPatientHaveValidContact = false;
    if (
      this.patient.primaryContactNumber.number == null ||
      (this.patient.primaryContactNumber.number != null &&
        this.patient.primaryContactNumber.number.length == 10)
    ) {
      doesPatientHaveValidContact = true;
    }

    return (
      doesPatientHaveName &&
      doesPatientHaveGender &&
      doesPatientHaveValidContact
    );
  }

  closeThisModal() {
    this._patientRegistrationModalService.close();
    this.clearFields();
  }

  clearFields() {
    this.patient = new Patient();
    this.disabled = false;
    this.initialText = "";
    this.uhid = "";
    this.userForEditing = new Patient();
    this.customUserId = "";
    this.selectedDoctorId = "";
    this.selectedCorporationMap.clear();
    if (this.categoryOptions.length > 0) {
      this.associatedCorporationId = this.categoryOptions[0]["value"];
    }
  }

  onSubmit() {
    var self = this;
    self.isSavingPatient = true;
    if (this.patient.docvitaId) {
      //Update
      if (this.selectedCorporationMap.size > 0) {
        this.patient.organisationConnections.get(
          GlobalVariables.getOrganisationId()
        ).associatedCoporationMap = this.selectedCorporationMap;
      }
      this._patientProfileService
        .update(this.patient, GlobalVariables.getOrganisationId())
        .then(resp => {
          if (resp) {
            self.isSavingPatient = false;
            if (resp && resp["statusCode"] && resp["statusCode"] == 200) {
              //handle it here
              this.onPatientSaveorUpdate.emit(this.patient);
              this.closeThisModal();
              this._notifyService.showSuccessMessage("Patient profile saved!");
              // this._patientRegistrationModalService.close();
            } else {
              //handle it here
              this._notifyService.showErrorMessage("Please try again!");
            }
          }
        })
        .catch(err => {
          self.isSavingPatient = false;
          console.error(err);
          this._notifyService.showErrorMessage("Please try again");
        });
    } else {
      this._patientProfileService
        .create(
          this.patient,
          GlobalVariables.getMemberId(),
          GlobalVariables.getMemberName(),
          GlobalVariables.getOrganisationId(),
          GlobalVariables.getOrganisationName(),
          this.uhid,
          this.selectedCorporationMap
        )
        .then(resp => {
          self.isSavingPatient = false;
          if (resp) {
            //handle it here
            if (
              resp["statusCode"] === 200 &&
              resp["body"]["success"] == false &&
              resp["body"]["message"] === "uhid already exist"
            ) {
              this._notifyService.showErrorMessage("uhid already exist");
            } else {
              this.patient.docvitaId = resp["body"]["user_id"];
              this.onPatientSaveorUpdate.emit(this.patient);
              this._notifyService.showSuccessMessage("Patient profile saved!");
              this.selectedPatient = this.patient;
              this.clearFields();
              this.getDoctorsList();
              // this._patientRegistrationModalService.close();
            }
          }
        })
        .catch(err => {
          self.isSavingPatient = false;
          console.error(err);
          this._notifyService.showErrorMessage("Please try again");
        });
    }
  }

  searchPatientsByMobile() {
    if (
      this.patient.primaryContactNumber.number &&
      this.patient.primaryContactNumber.number.length > 3
    ) {
      this._patientProfileService
        .patientTextSearch(
          this.patient.primaryContactNumber.number,
          GlobalVariables.getOrganisationId()
        )
        .then(body => {
          if (body != null) {
            this.searchResultsConnectedPatients = body;
          } else {
            this.searchResultsConnectedPatients = [];
          }
          this.searchResultsNotConnectedPatients = [];
        })
        .catch(err => {
          console.error(err);
        });
    } else {
      this.searchResultsConnectedPatients = [];
      this.searchResultsNotConnectedPatients = [];
    }
  }

  searchPatientsByName() {
    if (this.patient.name && this.patient.name.length > 0) {
      this._patientProfileService
        .patientTextSearch(
          this.patient.name,
          GlobalVariables.getOrganisationId()
        )
        .then(body => {
          if (body != null) {
            this.searchResultsConnectedPatients = body;
          } else {
            this.searchResultsConnectedPatients = [];
          }
          this.searchResultsNotConnectedPatients = [];
        })
        .catch(err => {
          console.error(err);
        });
    } else {
      this.searchResultsConnectedPatients = [];
      // this.searchResultsNotConnectedPatients = [];
    }
  }

  goToPatientProfile(event, patient) {
    this.selectedPatient = patient;
    // this._patientProfileDialogService.open(patient);
  }

  returnToSearchList() {
    this.selectedPatient = null;
  }

  writePrescription() {
    let path = this._router.url;

    let finalPathForRouting = path.substring(
      0,
      path.indexOf("patient-detail/")
    );
    let sub = path.substring(
      path.indexOf("patient-detail/") + "patient-detail/".length
    );
    let patientId = sub.substring(0, sub.indexOf("/"));
    if (patientId != null && patientId.length > 0) {
      finalPathForRouting +=
        "patient-detail/" + patientId + "/prescriptions/new";
      this._router.navigateByUrl(finalPathForRouting);
    } else {
      this._notifyService.showErrorMessage(
        "Something went wrong. Please try again."
      );
    }
  }

  requestToAddToQueue() {
    let todayDate = DateActions.getDateString(new Date());
    let selectedLocationId = this.locationId;
    let selectedDoctorId = this.selectedDoctorId;
    let selectedDoctorName;
    let selectedLocationName;
    if (GlobalVariables.getOrganisation().locations.size > 0) {
      GlobalVariables.getOrganisation().locations.forEach((val, key) => {
        if (selectedLocationId == val.organisationLocationId) {
          selectedLocationName = val.title;
        }
      });
    }
    GlobalVariables.getOpdScheduleAvailableMembersMap(
      GlobalVariables.getOrganisationId()
    ).forEach((val, key) => {
      if (selectedDoctorId == val.docvitaId) {
        selectedDoctorName = val.name;
      }
    });
    let selectedPurposeOfVisitTitle: string;
    let selectedPurposeOfVisitShortForm: string;
    if (
      this.selectedPurposeOfVisitId &&
      GlobalVariables.getOrganisation().appointmentProfilesMap &&
      GlobalVariables.getOrganisation().appointmentProfilesMap.has(
        this.selectedPurposeOfVisitId
      )
    ) {
      let obj = GlobalVariables.getOrganisation().appointmentProfilesMap.get(
        this.selectedPurposeOfVisitId
      );
      if (obj.shortForm) {
        selectedPurposeOfVisitShortForm = obj.shortForm;
      }
      if (obj.name) {
        selectedPurposeOfVisitTitle = obj.name;
      }
    }
    if (
      selectedDoctorId &&
      selectedDoctorName &&
      selectedLocationId &&
      selectedLocationName
    ) {
      this._appointmentOperationsService
        .addToQueue(
          GlobalVariables.getOrganisationId(),
          GlobalVariables.getOrganisationName(),
          selectedDoctorId,
          selectedDoctorName,
          this.selectedPatient,
          todayDate,
          selectedLocationId,
          selectedLocationName,
          this.selectedSlotLength,
          null,
          this.selectedPurposeOfVisitId,
          selectedPurposeOfVisitTitle,
          selectedPurposeOfVisitShortForm
        )
        .then(resp => {
          if (resp) {
            this._patientRegistrationModalService.close();
            this._notifyService.showSuccessMessage("Patient added to queue");
          } else {
            this._notifyService.showErrorMessage("Please try again");
          }
        })
        .catch(err => {
          console.error(err);
          this._notifyService.showErrorMessage("Please try again");
        });
    } else {
      this._notifyService.showErrorMessage("Please try again");
    }
  }
  requestToScheduleVisit() {
    this.closeThisModal(); //TODO: DONT CLOSE THE MODAL. OPEN APPOINTMENT BOOKING DIALOG ON TOP.

    this._scheduleAppointmentModalService.openWithJSON({
      type: "patientId",
      patientId: this.selectedPatient.docvitaId
    });
  }
  patientNameSalutationChanged() {
    switch (this.patient.salutation) {
      case "mr":
      case "master":
        this.patient.gender = "male";
        break;
      case "ms":
      case "miss":
        this.patient.gender = "female";
        break;
      case "mrs":
        this.patient.gender = "female";
        break;
      case "dr":
        break;
      default:
        break;
    }
  }

  //Print patient information on the letterhead
  printPatientInfo(patient: Patient) {
    this._patientProfileService
      .printPatientInto(
        patient.docvitaId,
        GlobalVariables.getMemberId(),
        GlobalVariables.getOrganisationId()
      )
      .then((data: BlobPart) => {
        var file = new Blob([data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(file);
        // this.isRefreshing = false;
        window.open(fileURL).print();
        this._notifyService.showSuccessMessage("Pdf generated");
      })
      .catch(err => {
        this._notifyService.showErrorMessage("Please try again.");
      });
  }

  //Date selected via date picker
  onDateSelect() {
    this.dataVal = moment(
      DateActions.getDateJSONToStringWithMonthDiff(
        this.patient.date_of_birth_obj
      ),
      "YYYY-MM-DD"
    ).format("DDMMYYYY");
  }

  dateChangedInput() {
    if (this.dataVal && this.dataVal.length == 8) {
      let json = {};
      json["day"] = +this.dataVal.substring(0, 2);
      json["month"] = +this.dataVal.substring(2, 4);
      json["year"] = +this.dataVal.substring(4, 8);
      this.patient.date_of_birth_obj = json;
    }
  }
}
